import { Button, Loading } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_ADMIN_UPDATE_FARMER, QUERY_ADMIN_GET_FARMER_BY_ID } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminFarmerByIdResponse } from "types/admin";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  AddressIconSelected,
  AddressIconWhite,
  FarmerIconNotSelectedWhite,
  FarmerIconSelected,
  PasswordIconSelected,
  PasswordIconWhite,
} from "components/templates/Icon";

import { TFarmerProfile } from "../../../types/farmer";
import EditFarmerProfilePageStyled from "../EditFarmerProfilePage/EditFarmerProfilePage.styled";
import FarmerForm from "../EditFarmerProfilePage/Farmer/FarmerForm";

const AdminEditFarmerProfilePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [step, setStep] = useState<string>("Farmer");
  const [profileData, setProfileData] = useState<TFarmerProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const formik = useFormik<any>({
    initialValues: profileData || {},
    enableReinitialize: true,
    onSubmit: (values) => handleUpdateProfile(values),
  });

  const dataStep = [
    {
      label: "Farmer",
      selectedIcon: <FarmerIconSelected />,
      notSelectedIcon: <FarmerIconNotSelectedWhite />,
    },
    {
      label: "Address",
      selectedIcon: <AddressIconSelected />,
      notSelectedIcon: <AddressIconWhite />,
    },
    {
      label: "Password",
      selectedIcon: <PasswordIconSelected />,
      notSelectedIcon: <PasswordIconWhite />,
    },
  ];
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const variables = {
            userId: id,
          };
          const response: TAdminFarmerByIdResponse = await client.request(
            QUERY_ADMIN_GET_FARMER_BY_ID,
            variables
          );
          setProfileData(response.adminGetFarmerById);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };
      setIsLoading(true);
      fetchData();
    } else {
      navigate("/farmer-list");
    }
  }, []);

  const handleUpdateProfile = async (values: any) => {
    try {
      setSubmitIsLoading(true);
      const farmerInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        address: values.address,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        subdistrictCode: values.subdistrictCode,
        postcode: values.postcode,
      };
      const variables = {
        farmer: farmerInput,
        userId: id,
      };
      await client.request(MUTATION_ADMIN_UPDATE_FARMER, variables);
      successModal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const successModal = useSuccessModal({
    title: "Profile Edited",
    detail: (
      <div>
        <div>Your profile has been successfully edited</div>
      </div>
    ),
    callBack: () => navigate("/farmer-list"),
  });
  return (
    <EditFarmerProfilePageStyled>
      {isLoading && <Loading />}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>Profile</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-profile-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>
            <FarmerForm form={formik} step={step} />
          </div>
          <div className="footer">
            <div className="footer-group">
              {/* <Link to={"/farmer-report"}> */}
              <Link to={"/farmer-list"}>
                <Button className="cancel-button">Cancel</Button>
              </Link>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>

      <successModal.SuccessModal />
    </EditFarmerProfilePageStyled>
  );
};
export default AdminEditFarmerProfilePage;
