import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";

export const NotificationIcon = styled.div`
  position: relative;
  margin-left: auto;
  cursor: pointer;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
`;

export const Badge = styled.div`
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 9999px;
  background: ${({ theme }) => theme.color.error.base};
`;

export const NotificationModal = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  height: 100%;
`;

export const NotificationModalHead = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  height: 24px;
  margin-bottom: 24px;
  width: 372px;
  justify-content: space-between;
`;

export const NotificationModalReadAll = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  .icon {
    display: flex;
    align-items: center;
    width: 18px;
  }
`;

export const NotificationModalContent = styled.div`
  overflow-y: scroll;
  max-height: 380px;
  margin: 0 8px;
`;

export const NotificationItem = styled.div`
  overflow-y: auto;
  cursor: pointer;
  min-height: 112px;
  padding: 16px;
  width: 372px;
  display: flex;

  &.NEW {
    opacity: 1;
  }
  &.ACKNOWLEDGE {
    opacity: 1;
  }
  &.READ {
    opacity: 0.5;
  }
`;

export const NotificationItemIcon = styled.div`
  margin-right: 16px;

  .PENDING,
  .REVIEWING {
    color: ${({ theme }) => theme.color.grayPrimary.base};
  }
  .REJECTED,
  .EMERGENCY,
  .ABORTED {
    color: ${({ theme }) => theme.color.error.base};
  }
  .ACTIVATED {
    color: ${({ theme }) => theme.color.bluePrimary.base};
  }
  .APPROVED,
  .COMPLETED {
    color: ${({ theme }) => theme.color.success.base};
  }
`;

export const NotificationItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationItemTitle = styled.div`
  cursor: pointer;
  color: 14px;
  margin-bottom: 6px;

  :hover {
    text-decoration: underline;
  }
`;

export const NotificationItemDescription = styled(Text)`
  margin-bottom: 16px;
`;
