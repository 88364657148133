import { z } from "zod";

import { configFileSchema } from "./drone";

export const pilotSignUpSchema = z
  .object({
    firstName: z.string({
      required_error: "firstName is a required field",
    }),
    lastName: z.string({
      required_error: "lastName is a required field",
    }),
    nationalIdNo: z.string({
      required_error: "nationalIdNo is a required field",
    }),
    nationality: z.string({
      required_error: "nationality is a required field",
    }),
    phoneNo: z
      .string({
        required_error: "phoneNo is a required field",
      })
      .min(9, "Phone No must have at least 9 characters")
      .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
    email: z
      .string({
        required_error: "email is a required field",
      })
      .email("Email is not valid"),
    password: z
      .string({
        required_error: "password is a required field",
      })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z.string({
      required_error: "confirmPassword is a required field",
    }),
    license: z.object({
      caatLicenseNo: z.string({
        required_error: "License Number is a required field",
      }),
      caatObjective: z.string({
        required_error: "License Purpose is a required field",
      }),
      caatExpireDate: z.string({
        required_error: "caatExpireDate is a required field",
      }),
      nbtcLicenseNo: z.string({
        required_error: "License Issue Date is a required field",
      }),
      nbtcObjective: z.string({
        required_error: "License Expiry Date is a required field",
      }),
      nbtcExpireDate: z.string({
        required_error: "nbtcExpireDate is a required field",
      }),
    }),
    drone: z.object({
      name: z.string({
        required_error: "Drone Number is a required field",
      }),
      objective: z.string().optional(),
      description: z.string().optional(),
      droneType: z.string({
        required_error: "droneType Date is a required field",
      }),
      brand: z.string({
        required_error: "brand Date is a required field",
      }),
      serialNumber: z.string({
        required_error: "serialNumber is a required field",
      }),
      model: z.string({
        required_error: "model is a required field",
      }),
      weight: z.string({
        required_error: "weight is a required field",
      }),
      extentions: z.string().optional(),
      images: z.object({
        top: z
          .object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
          .array(),
        left: z
          .object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
          .array(),
        bottom: z
          .object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
          .array(),
        right: z
          .object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
          .array(),
      }),
      // battery
      battery: z
        .object({
          brand: z
            .string({
              required_error: "brand is a required field",
            })
            .optional(),
          model: z
            .string({
              required_error: "model is a required field",
            })
            .optional(),
          serialNumber: z
            .string({
              required_error: "amount is a required field",
            })
            .optional(),
          capacity: z
            .string({
              required_error: "capacity is a required field",
            })
            .optional(),
        })
        .array()
        .optional(),
      // Insurance
      insurance: z.array(
        z.object({
          number: z.string({
            required_error: "Insurance number is a required field",
          }),
          coverage: z.number({
            required_error: "Coverage is a required field",
          }),
          expireDate: z.string({
            required_error: "Expire date is a required field",
          }),
          fileList: z
            .array(
              z.object({
                ext: z.string(),
                uid: z.string(),
                file_name: z.string(),
                unique_id: z.string(),
              })
            )
            .refine((array) => array.length > 0, {
              message: "Please upload an image",
            }),
        })
      ),
      // Payload
      payload: z
        .object({
          type: z
            .string({
              required_error: "type is a required field",
            })
            .optional(),
          name: z
            .string({
              required_error: "name is a required field",
            })
            .optional(),
          brand: z
            .string({
              required_error: "brand is a required field",
            })
            .optional(),
          model: z
            .string({
              required_error: "model is a required field",
            })
            .optional(),
          specification: z
            .string({
              required_error: "specification is a required field",
            })
            .optional(),
        })
        .array()
        .optional(),
    }),
    config_file: configFileSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const pilotPayloadDefaultValue = {
  type: "",
  name: "",
  brand: "",
  model: "",
  specification: "",
};

export const pilotBatteryDefaultValue = {
  serialNumber: "",
  brand: "",
  model: "",
  capacity: "",
};

export const pilotInsuranceDefaultValue = {
  number: "",
  coverage: NaN,
  expireDate: "",
  fileList: [],
};

export const droneDefaultValue = {
  id: "",
  name: "",
  description: "",
  droneType: "",
  brand: "",
  serialNumber: "",
  isActive: true,
  model: "",
  weight: "",
  extentions: "-",
  objective: "-",
  images: {
    top: [],
    left: [],
    bottom: [],
    right: [],
  },
  imagesUrls: {
    top: [],
    left: [],
    right: [],
    bottom: [],
  },
  imageTopUrls: [],

  battery: [pilotBatteryDefaultValue],
  droneInsuranceFileUrls: [],
  insurance: [pilotInsuranceDefaultValue],
  payload: [pilotPayloadDefaultValue],
  // config_file: {
  //   fileList: [],
  //   changeLog: [],
  // },
};

export const pilotSignupDefaultValue = {
  firstName: "",
  lastName: "",
  nationalIdNo: "",
  nationality: "",
  email: "",
  phoneNo: "",
  license: {
    caatLicenseNo: "",
    caatObjective: "",
    caatExpireDate: "",
    // caatFileList: [],
    nbtcLicenseNo: "",
    nbtcObjective: "",
    nbtcExpireDate: "",
    // nbtcFileList: [],
  },
  password: "",
  confirmPassword: "",
  drone: droneDefaultValue,
};
