import { Button, Loading } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_ADMIN_UPDATE_PILOT, QUERY_ADMIN_GET_PILOT_BY_ID } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { decrypt } from "core/utils/crypto";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminPilotByIdResponse } from "types/admin";
import { TPilotProfile } from "types/pilot";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  LicenseIconSelected,
  LicenseIconWhite,
  PasswordIconSelected,
  PasswordIconWhite,
  PilotIconSelected,
  PilotIconWhite,
} from "components/templates/Icon";

import EditPilotProfilePageStyled from "./EditPilotProfilePage.styled";
import PilotForm from "./Pilot/PilotForm";

const EditPilotProfilePage = () => {
  const { t } = useTranslation("common");

  const { id } = useParams();
  const [step, setStep] = useState<string>("Pilot");
  const [profileData, setProfileData] = useState<TPilotProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const formik = useFormik<any>({
    initialValues: profileData || {},
    enableReinitialize: true,
    onSubmit: (values) => handleUpdateProfile(values),
  });

  const fetchPilotProfile = async () => {
    try {
      const variables = {
        userId: id,
      };
      const response: TAdminPilotByIdResponse = await client.request(
        QUERY_ADMIN_GET_PILOT_BY_ID,
        variables
      );

      const pilot = {
        ...response.adminGetPilotById,
        email: decrypt(response.adminGetPilotById.email),
        phoneNo: decrypt(response.adminGetPilotById.phoneNo),
        nationalIdNo: decrypt(response.adminGetPilotById.nationalIdNo),
      };

      setProfileData(pilot);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const dataStep = [
    {
      label: t`Pilot`,
      key: "Pilot",
      selectedIcon: <PilotIconSelected />,
      notSelectedIcon: <PilotIconWhite />,
    },
    {
      label: t`License`,
      key: "License",
      selectedIcon: <LicenseIconSelected />,
      notSelectedIcon: <LicenseIconWhite />,
    },
  ];
  useEffect(() => {
    fetchPilotProfile();
  }, []);

  const handleUpdateProfile = async (values: any) => {
    try {
      setSubmitIsLoading(true);
      const pilotInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        license: values.license,
      };
      const variables = {
        pilotProfile: pilotInput,
        pilotId: profileData?.id,
      };
      await client.request(MUTATION_ADMIN_UPDATE_PILOT, variables);
      successModal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const successModal = useSuccessModal({
    title: t`Profile Edited`,
    detail: (
      <div>
        <div>{t`Your profile has been successfully edited`}</div>
      </div>
    ),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditPilotProfilePageStyled>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>{t`Profile`}</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-profile-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>
            <PilotForm form={formik} step={step} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/report"}>
                <Button className="cancel-button">{t`Cancel`}</Button>
              </Link>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t`Save`}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <successModal.SuccessModal />
    </EditPilotProfilePageStyled>
  );
};
export default EditPilotProfilePage;
