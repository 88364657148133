import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import ImageAvatarDefault from "assets/images/person-default.png";
import { ReactComponent as PasswordIcon } from "assets/svgs/password_icon_white.svg";
import { ReactComponent as SignOutIcon } from "assets/svgs/sign_out_icon.svg";
import { FC } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "components/pages/AuthPage/store/auth";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .profile {
    display: flex;
    gap: 16px;

    .detail {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
    }
  }

  .edit-profile-btn {
    color: #ffb32c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .profile-action {
    font-weight: 300;
    display: flex;
    gap: 16px;
    cursor: pointer;
  }
`;
interface IPopoverContent {
  fullName: string;
}
const PopoverContent: FC<IPopoverContent> = ({ fullName }) => {
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const {
    actions: { logout },
    user,
  } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleChangePassword = () => {
    navigate(`/change-password/${user?.userId}`);
  };

  const isFarmer = user?.roles.includes("FARMER");
  const isPilot = user?.roles.includes("PILOT");
  const isAdmin = user?.roles.includes("ADMIN");
  const isAdminServiceProvider = user?.roles.includes("ADMIN_SERVICE_PROVIDER");

  const allowEditProfile = isFarmer || isPilot || isAdminServiceProvider;

  const handleGotoEditProfile = () => {
    if (isFarmer) {
      navigate(`/edit-profile/${user?.userId}`);
    }

    if (isPilot) {
      navigate(`/edit-profile/${user?.userId}`);
    }

    if (isAdminServiceProvider) {
      navigate(`/edit-profile/${user?.userId}`);
    }
  };
  return (
    <Wrapper>
      <div className="profile">
        <Avatar name={fullName} round={true} size={"48"} />

        <div className="detail">
          <span>{fullName}</span>

          {allowEditProfile && (
            <div onClick={handleGotoEditProfile} className="edit-profile-btn cursor-pointer">
              {t`Edit Profile`}
            </div>
          )}
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div className="profile-action" onClick={handleChangePassword}>
        <PasswordIcon width={24} height={24} />
        {t`Change Password`}
      </div>

      <div className="profile-action" onClick={handleLogout}>
        <SignOutIcon />
        {t`Sign out`}
      </div>
    </Wrapper>
  );
};
export default PopoverContent;
