import { Card, Image, Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import AUV from "assets/images/AUV.png";
import DOG from "assets/images/DOG.png";
import UAV from "assets/images/UAV.png";
import USV from "assets/images/USV.png";
import { ReactComponent as IconDeviceOffline } from "assets/svgs/device-offline.svg";
import dayjs from "core/configs/dayjs";

import Battery from "../Battery/Battery";
import UserDetail, { User } from "../UserDetail/UserDetail";

const imageDevice = {
  UAV: UAV,
  AUV: AUV,
  USV: USV,
  DOG: DOG,
  DEFAULT: UAV,
};

const MissionStyled = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .mission-image {
    border-radius: 14px;
  }
  .name {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    .battery {
      display: flex;
      gap: 8px;
      align-items: center;
      .offline {
        color: ${({ theme }) => theme.color.grayPrimary[700]};
      }
    }
  }
`;

type Info = {
  name: string;
  organize: string;
  duration: string;
  updatedAt?: string;
};

type MissionProps = {
  info: Info;
  mission: MissionView.Item;
  users?: User[];
};
const MissionComponent: React.FC<MissionProps> = ({ info, mission, users }) => {
  const theme = useTheme();

  return (
    <MissionStyled>
      <Image
        className="mission-image"
        src={mission.image || imageDevice[mission.type] || imageDevice.DEFAULT}
        width="105px"
        height="105px"
      />
      <div className="name">
        <Text variant="h6">{info.name}</Text>
        <div className="battery">
          {mission.battery ? (
            <Battery value={mission.battery} showText={false} />
          ) : (
            <IconDeviceOffline className="offline" />
          )}
          <Text variant="subtitle2">{info.organize}</Text>
        </div>

        <Text variant="body3" style={{ color: theme.color.grayPrimary[500] }}>
          {dayjs(mission.time).isToday() ? "Today" : dayjs(mission.time).format("DD/MM/YYYY")} •{" "}
          {dayjs(mission.time).format("HH:mm")} • Est. {info.duration}
        </Text>

        <div
          css={css`
            display: flex;
            gap: 16px;
            margin-top: 4px;
          `}
        >
          <div css={{ flex: 1 }}>
            <Text variant="body3" style={{ color: theme.color.grayPrimary[600] }}>
              Altitude
            </Text>
            <Text variant="h4" style={{ color: theme.color.grayPrimary[50] }}>
              {mission.altitude ?? "N/A"}{" "}
              <Text as="span" variant="body3" style={{ display: "inline-block" }}>
                m
              </Text>
            </Text>
          </div>
          <div css={{ flex: 1 }}>
            <Text variant="body3" style={{ color: theme.color.grayPrimary[600] }}>
              Speed
            </Text>
            <Text variant="h4" style={{ color: theme.color.grayPrimary[50] }}>
              {mission.speed ?? "N/A"}{" "}
              <Text as="span" variant="body3" style={{ display: "inline-block" }}>
                km/hr
              </Text>
            </Text>
          </div>
        </div>

        {users?.length ? (
          <div style={{ marginTop: 8 }}>
            {users.map((user, index) => (
              <UserDetail key={index} user={user} />
            ))}
          </div>
        ) : null}
      </div>
    </MissionStyled>
  );
};

export default MissionComponent;
