import { QUERY_GET_DRONE_LIST } from "core/gql/drone";
import { client } from "core/gql/fetchgql";
import { useState } from "react";
import { TGetDroneListResponse } from "types/drone";
import { IMeta } from "types/pagination";
import { IDroneDetail } from "types/pilot";

import DroneListTable from "components/templates/DroneListTable/DroneListTable";

import { useAuth } from "../AuthPage/store/auth";

const AdminDroneListPage = () => {
  const { user } = useAuth();
  const isAdminServiceProvider = user?.roles?.includes("ADMIN_SERVICE_PROVIDER");

  const [droneList, setDroneList] = useState<IDroneDetail[]>([]);
  const [meta, setMeta] = useState<IMeta>();

  const fetchDroneList = async ({
    currentPage,
    pageSize,
    searchName,
  }: {
    currentPage: number;
    pageSize: number;
    searchName: string;
  }) => {
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
        },
        filterByServiceProvider: isAdminServiceProvider,
      };
      const response: TGetDroneListResponse = await client.request(QUERY_GET_DRONE_LIST, variables);
      setDroneList(response.getDroneList.data);
      setMeta(response.getDroneList.meta);
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  return <DroneListTable droneList={droneList} meta={meta} fetchData={fetchDroneList} />;
};
export default AdminDroneListPage;
