import styled from "@emotion/styled";

const DroneFormStyled = styled.div`
  form {
    height: 100%;
  }
  .add-flight-post-box {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;

    backdrop-filter: blur(5px);
    background: rgba(102, 92, 77, 0.9);
    padding: 24px;
    overflow: scroll;
    padding-bottom: 100px;
    .flight-post-filter-list {
      display: flex;
      gap: 8px;
    }
  }
  .text-area-component {
    background-color: white;
    textarea {
      color: black;
    }
  }

  .add-footer-flight-post {
    display: flex;
    gap: 16px;
    padding: 24px 16px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(102, 92, 77, 0.6) 0%,
      rgba(102, 92, 77, 0.53) 45.71%,
      rgba(102, 92, 77, 0) 100%
    );
    backdrop-filter: blur(10px);
    width: 100%;

    .cancel-btn {
      background-color: white;
      color: black;
    }

    .add-btn {
      color: #1a1a1a;
      background-color: #ffc256;
    }
  }
`;

export default DroneFormStyled;
