import styled from "@emotion/styled";

const AddDronePageStyled = styled.div`
  .title {
    color: #fff;

    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  .value {
    color: #9ca3af;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */
  }

  .add-drone-container {
    .form {
      border-radius: 12px;
      background: #414221;
      min-height: calc(100vh - 164px);
      padding: 40px 16px 20px 0;
      height: 100%;
      position: relative;
      .step-section {
        position: absolute;
        left: 100px;
        top: 0;
      }
    }
  }
`;

export default AddDronePageStyled;
