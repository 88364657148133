import { Button } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import { MUTATION_LOGIN } from "core/gql/login";
import { CHANGE_PASSWORD } from "core/gql/user";
import useSuccessModal from "core/hooks/useSuccessModal";
import { changePasswordDefaultValue, changePasswordSchema } from "core/schema/admin";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { PasswordIconSelected, PasswordIconWhite } from "components/templates/Icon";

import { useAuth } from "../AuthPage/store/auth";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangePasswordPageStyled from "./ChangePasswordPage.styled";

type ChangePasswordForm = z.infer<typeof changePasswordSchema>;

const ChangePasswordPage = () => {
  const { t } = useTranslation("common");
  const { user } = useAuth();
  const navigate = useNavigate();

  const [step, setStep] = useState<string>("Password");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  let backUrl = "/";

  if (user?.roles.includes("FARMER")) {
    backUrl = "/farm-list";
  }
  if (user?.roles.includes("PILOT")) {
    backUrl = "/flight-post";
  }
  if (user?.roles.includes("ADMIN")) {
    backUrl = "/admin-service-provider-list";
  }
  if (user?.roles.includes("ADMIN_SERVICE_PROVIDER")) {
    backUrl = "/flight-post";
  }
  if (user?.roles.includes("LEASING")) {
    backUrl = "/leasing-list";
  }

  const successModal = useSuccessModal({
    title: t`Password updated`,
    detail: (
      <div>
        <div>{t`Your password has been successfully updated`}</div>
      </div>
    ),
    callBack: () => navigate(backUrl),
  });

  const dataStep = [
    {
      label: t`Password`,
      key: "Password",
      selectedIcon: <PasswordIconSelected />,
      notSelectedIcon: <PasswordIconWhite />,
    },
  ];

  const formik = useFormik<ChangePasswordForm>({
    initialValues: changePasswordDefaultValue,
    validationSchema: toFormikValidationSchema(changePasswordSchema),
    onSubmit: (values) => handleChangePassword(values),
  });

  const handleChangePassword = async (values: ChangePasswordForm) => {
    setIsSubmitLoading(true);

    try {
      const loginVariables = {
        input: {
          email: user?.email,
          password: values?.currentPassword,
        },
      };

      await client.request(MUTATION_LOGIN, loginVariables);
    } catch (e) {
      setIsSubmitLoading(false);
      formik.setFieldError("currentPassword", "Current password is incorrect. Please try again.");

      return;
    }

    try {
      const changePasswordVariables = {
        input: {
          newPassword: values?.newPassword,
        },
      };

      await client.request(CHANGE_PASSWORD, changePasswordVariables);
      successModal.openModal();
      setIsSubmitLoading(false);
    } catch (e) {
      console.log("error change password", e);
      setIsSubmitLoading(false);
    }
  };

  return (
    <ChangePasswordPageStyled>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>{t`Change Password`}</h3>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>

            <div className="step-container">
              <ChangePasswordForm form={formik} />
            </div>
          </div>

          <div className="footer">
            <div className="footer-group">
              <Link to={backUrl}>
                <Button className="cancel-button">{t(`Cancel`, { ns: "common" })}</Button>
              </Link>

              <Button
                type="submit"
                className={!isSubmitLoading ? "register-button-active" : "register-button"}
              >
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t(`Save`, { ns: "common" })}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <successModal.SuccessModal />
    </ChangePasswordPageStyled>
  );
};

export default ChangePasswordPage;
