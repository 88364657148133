import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  ChartTypeRegistry,
  Legend,
  Tooltip,
} from "chart.js";
import {
  QUERY_ADMIN_FLIGHT_POST_LIST,
  QUERY_ADMIN_GET_DRONE_INFORMATION_BY_SERIAL_NUMBER,
  QUERY_ADMIN_GET_DRONE_TOTAL_FLIGHT_RECORD_BY_SERIAL_NUMBER,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import dayjs from "dayjs";
import { isNil, map } from "lodash";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TAdminDroneInformationBySerialNumberResponse,
  TAdminGetTotalFlightRecordBySerialResponse,
} from "types/admin";
import { ILastPositionType } from "types/droneInformation";
import { IFlightInformation, IFlightTotalRecord } from "types/flightInformation";
import { IMeta } from "types/pagination";

import Map, { HeatPosition } from "components/pages/Map/Map";
import ATable from "components/templates/ATable/ATable";

import { ArgiMode } from "../AdminFlightDetailPage/FlightDetail/FlightDetail";
import { useAuth } from "../AuthPage/store/auth";
import LineChart from "../FarmerReportPage/LineChart";
import ReportCard from "../FarmerReportPage/ReportCard";
import FlightPostSummary from "../FlightPostListPage/FlightPostSummary";
import FlightRecordDetailPageStyled from "../FlightRecordDetailPage/FlightRecordDetailPage.styled";
import { useDisplayHeatPositions, useToCenter } from "../Map/useDisplay";
import FlightPostSummaryAdmin from "./FlightPostSummaryAdmin";

// const b: IFlightInformation = {
//   id: "mock-flight-information-id",
//   agri_mode: "0",
//   flight_field_id: null,
//   start_flight_at: new Date().toISOString(),
//   end_flight_at: new Date().toISOString(),
//   stat_boot_count: 0,
//   stat_flight_length: null,
//   stat_flight_time: 0,
//   stat_service_mode_id: "0",
//   stat_sprayed_area: 0,
//   stat_spread_quantity: 0,
//   notifyMessage: [],
//   createdAt: new Date(),
// };

const StyledContent = styled.div`
  display: flex;
  gap: 24px;

  .card-content {
    width: 200px;
    height: 200px;
    margin: 16px auto auto;
    height: fit-content;
  }

  .chart-flex {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
  }
  .map-card-content {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 16px;
  }
`;
const defaultChartData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: [],
      borderWidth: 1,
    },
  ],
};
const options: ChartOptions<"pie"> = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "white",
      },
    },
  },
  aspectRatio: 0.8,
};
type GetFlightInformationBySerialQuery = {
  serialNumber: string;
  options: {
    currentPage: number;
    itemsPerPage: number;
  };
  filters?: {
    startDate: string;
    endDate: string;
  };
};

type GetTotalFlightRecordQuery = {
  serialNumber: string;
  filters?: {
    startDate: string;
    endDate: string;
  };
};

const AdminDroneFlightPostPage = () => {
  const { t } = useTranslation(["drone", "common"]);

  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);

  const [totalSprayedChartData, setTotalSprayedChartData] = useState<ChartData>(defaultChartData);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [flightPostList, setFlightPostList] = useState<IFlightInformation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [droneRecord, setDroneRecord] = useState<IFlightTotalRecord>();
  const { serialNumber } = useParams();
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const navigate = useNavigate();
  const [lastPositions, setLastPositions] = useState<ILastPositionType[]>();
  const [heatPositions, setHeatPositions] = useState<HeatPosition[]>([]);
  const [polygon, setPolygon] = useState<[number, number][]>([]);

  // ** Date filter **
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  const columns = [
    {
      title: t`Field`,
      dataIndex: "field",
      key: "field",
      width: 200,
      align: "center",
      render: (field: {
        serviced_recipient_firstname?: string;
        serviced_recipient_lastname?: string;
        serviced_recipient_field_type?: string;
      }) => {
        const hasData =
          field?.serviced_recipient_firstname ||
          field?.serviced_recipient_lastname ||
          field?.serviced_recipient_field_type;

        if (!hasData) {
          return "-";
        }
        return `${field?.serviced_recipient_firstname || ""} ${
          field?.serviced_recipient_lastname || ""
        } (${field?.serviced_recipient_field_type || "N/A"})`;
      },
    },

    {
      title: t`Agri mode`,
      dataIndex: "agri_mode",
      key: "agri_mode",
      width: 200,
      align: "center" as AlignType,
      render: (data: string) => {
        if (isNil(data)) {
          return <div>-</div>;
        }
        return (
          <div>
            {data === "0" ? ArgiMode.Spray : ""}
            {data === "1" || data === "2" ? ArgiMode.Spread : ""}
          </div>
        );
      },
    },
    {
      title: t`Total Distance`,
      dataIndex: "totalDistance",
      key: "totalDistance",
      width: 200,
      align: "center",
      render: (data: number) => {
        if (isNil(data)) {
          return <div>0</div>;
        }
        return <div>{data}</div>;
      },
    },

    {
      title: `${t`Sprayed Area`} (${areaUnitLabel})`,
      dataIndex: "stat_sprayed_area",
      key: "stat_sprayed_area",
      width: 200,
      align: "center" as AlignType,
      render: (data: number) => {
        if (isNil(data)) {
          return <div>0</div>;
        }
        return <div>{data}</div>;
      },
    },
    {
      title: `${t`Sprayed Quantity`} (${t(`L`, { ns: "common" })})`,
      dataIndex: "stat_sprayed_quantity",
      key: "stat_sprayed_quantity",
      width: 200,
      align: "center" as AlignType,
      render: (data: number) => {
        if (isNil(data)) {
          return <div>0</div>;
        }
        return <div>{data.toFixed(2)}</div>;
      },
    },
    {
      title: `${t`Spread Quantity`} (${t(`Kg`, { ns: "common" })})`,
      dataIndex: "stat_spread_quantity",
      key: "stat_spread_quantity",
      width: 200,
      align: "center" as AlignType,
      render: (data: string) => {
        if (isNil(data)) {
          return <div>0</div>;
        }
        return <div>{data}</div>;
      },
    },

    {
      title: t`Flight Time`,
      dataIndex: "flightTime",
      key: "flightTime",
      width: 200,
      align: "center",
      render: (data: string) => {
        if (isNil(data)) {
          return <div>0</div>;
        }
        const durationInSeconds = parseInt(data, 10);
        if (durationInSeconds < 60) {
          return `${durationInSeconds} sec`;
        } else {
          const durationInMinutes = Math.round(durationInSeconds / 60);
          return `${durationInMinutes} mins`;
        }
      },
    },

    {
      title: t`Start flight at`,
      dataIndex: "start_flight_at",
      key: "start_flight_at",
      width: 200,
      align: "center" as AlignType,
      render: (data: string, row: any) => {
        return <div>{dayjs(data).format("ddd MMM DD YYYY | HH:mm")}</div>;
      },
    },

    {
      title: t`logs`,
      dataIndex: "id",
      key: "id",
      width: 120,
      align: "center" as AlignType,
      render: (id: string) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/drone/flight-detail/${id}`);
              }}
            >
              <DetailIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const fetchFlightInformation = async (serialNumber: string, page: number, size: number) => {
    try {
      setIsLoading(true);

      let variables: GetFlightInformationBySerialQuery = {
        serialNumber,
        options: {
          currentPage: page,
          itemsPerPage: size,
        },
      };

      if (startDate && endDate) {
        variables = {
          ...variables,
          filters: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        };
      }

      const response: TAdminDroneInformationBySerialNumberResponse = await client.request(
        QUERY_ADMIN_GET_DRONE_INFORMATION_BY_SERIAL_NUMBER,
        variables
      );
      setFlightPostList(
        map(response.adminGetDroneInformationBySerialNumber.data, (item) => {
          const totalDistance =
            item.totalDistance !== undefined ? item.totalDistance.toFixed(2) : 0;
          return {
            ...item.flightInformation,
            field: item.field,
            totalDistance,
            flightTime: item.flightTime,
          };
        })
      );

      setMeta(response.adminGetDroneInformationBySerialNumber.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTotalFlightRecord = async (serialNumber: string) => {
    try {
      let variables: GetTotalFlightRecordQuery = {
        serialNumber,
      };

      if (startDate && endDate) {
        variables = {
          ...variables,
          filters: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        };
      }

      const response: TAdminGetTotalFlightRecordBySerialResponse = await client.request(
        QUERY_ADMIN_GET_DRONE_TOTAL_FLIGHT_RECORD_BY_SERIAL_NUMBER,
        variables
      );
      const chartLabels = response.adminGetTotalFlightRecordBySerial.fieldType.map(
        (record) => record.farmType
      );
      const chartData = response.adminGetTotalFlightRecordBySerial.fieldType.map(
        (record) => record.count
      );
      const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);
      const chartBackgroundColor = Array(chartLabels.length)
        .fill(0)
        .map((_) => `#${randomColor()}`);
      const pieChartData = {
        labels: chartLabels,
        datasets: [
          {
            label: t`# of Sprayed Area`,
            data: chartData,
            backgroundColor: chartBackgroundColor,
            borderWidth: 1,
          },
        ],
      };
      setTotalSprayedChartData(pieChartData);
      setDroneRecord(response.adminGetTotalFlightRecordBySerial);
      setLastPositions(response.adminGetTotalFlightRecordBySerial.lastPositions);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Get the first day of the current year
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);

    setDateRange([firstDayOfYear, currentDate]);
  }, []);

  useEffect(() => {
    if (serialNumber)
      if (startDate && endDate) {
        fetchTotalFlightRecord(serialNumber);
      }
  }, [startDate, endDate]);

  useEffect(() => {
    if (serialNumber) {
      if (startDate && endDate) {
        fetchFlightInformation(serialNumber, currentPage, pageSize);
      }
    }
  }, [currentPage, pageSize, startDate, endDate]);

  useEffect(() => {
    if (lastPositions && lastPositions.length > 0) {
      // heat map data
      const updatedHeatPositions = lastPositions.map((position) => ({
        id: position.flightInformationId,
        coordinates: [position.latitude, position.longitude, 0] as [number, number, number],
      }));
      // set center position of heat map
      const polygonCoordinates = lastPositions.map((position) => [
        position.latitude,
        position.longitude,
      ]);

      const averageCoordinate = polygonCoordinates.reduce(
        (acc, cur) => {
          return [acc[0] + cur[0], acc[1] + cur[1]];
        },
        [0, 0]
      );
      const numberOfCoordinates = polygonCoordinates.length;
      const center = [
        averageCoordinate[0] / numberOfCoordinates,
        averageCoordinate[1] / numberOfCoordinates,
      ];
      const centerTuple = center.slice(0, 2) as [number, number];
      setPolygon([centerTuple]);
      setHeatPositions(updatedHeatPositions);
    }
  }, [lastPositions]);

  useToCenter(polygon);
  useDisplayHeatPositions(heatPositions);

  return (
    <FlightRecordDetailPageStyled>
      <div className="flight-record-detail-header">
        <div className="group">
          <Link to={"/flight-post"}>
            <div className="back-button">
              <Icon icon="ArrowBackward" size={24} />
              <div className="back-button-label">{t`Back to Drone List`}</div>
            </div>
          </Link>
          <div className="flight-record-detail-body">
            <div className="flight-record-title">
              <span>{t`Flight Record`}</span>
            </div>
            <div className="flight-record-detail-other">
              {/* <div className="flight-record-detail-box">
              <span className="label">Drone :</span>
              <span className="value">{droneDetail?.name}</span>
            </div>
            <div className="flight-record-detail-box">
              <span className="label">Model :</span>
              <span className="value">
                {getLabelFromConstant(droneBrandList, droneDetail?.model || "")}
              </span>
            </div> */}
              <div className="flight-record-detail-box">
                <span className="label">{t`Serial :`}</span>
                <span className="value">{serialNumber}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="date-wrapper">
          <DatePicker
            customInput={<InputField suffix={<Icon icon="Calendar" />} />}
            startDate={startDate}
            endDate={endDate}
            onChange={(value: [Date | null, Date | null]) => {
              value[1]?.setHours(23, 59, 59, 999); //set endDate to be the end of the day ex. 23/01/2024 23:59:59:000z
              setDateRange(value);
            }}
            selectsRange
          />
        </div>
      </div>

      <StyledContent>
        <ReportCard title={t`Flight Post Summary`} value={""}>
          <div className="chart-flex">
            <FlightPostSummaryAdmin
              totalFlightHour={`${droneRecord?.totalFlightTime}`}
              totalDistance={`${droneRecord?.totalFlightLength}`}
              totalSprayedArea={`${droneRecord?.totalSprayedArea}`}
              totalSprayedQuantity={`${droneRecord?.totalSprayedQuantity}`}
              totalSpreadQuantity={`${droneRecord?.totalSpreadQuantity}`}
              areaUnitLabel={areaUnitLabel}
            />
          </div>
        </ReportCard>

        <ReportCard title={t`Total Sprayed Area based on farm type`} value={""}>
          <div className="card-content">
            <Pie data={totalSprayedChartData as ChartData<"pie">} options={options} />
          </div>
        </ReportCard>
        <ReportCard title={t`Heat Map`} value={""}>
          <div className="map-card-content">
            <Map />
          </div>
        </ReportCard>
      </StyledContent>

      <ATable
        isLoading={isLoading}
        columns={columns}
        data={flightPostList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </FlightRecordDetailPageStyled>
  );
};

export default AdminDroneFlightPostPage;
