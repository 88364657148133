import {
  Avatar,
  InputField,
  Text,
  TextArea,
  TextAreaField,
} from "@ai-and-robotics-ventures/cumulus-ui";
import { payloadList } from "core/utils/constant";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import ASelect from "components/templates/ASelect/ASelect";

interface IPayloadForm {
  index: number;
  // data: IDataButtonGroup[];
  form: any;
}

const PayloadForm: React.FC<IPayloadForm> = ({ form, index }) => {
  const { t } = useTranslation("form");

  const { values, errors, touched, setFieldValue } = form;
  return (
    <div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`Payload`}
            placeholder={t`Select Payload`}
            options={payloadList}
            value={get(values, `drone.payload[${index}].type`)}
            error={
              get(touched, `drone.payload[${index}].type`) &&
              get(errors, `drone.payload[${index}].type`)
            }
            onChange={(value) => setFieldValue(`drone.payload[${index}].type`, value)}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.payload[${index}].name`}
            label={t`Payload Name`}
            placeholder={t`Enter Payload Name`}
            helperSpace
            onChange={form.handleChange}
            value={get(values, `drone.payload[${index}].name`)}
            error={
              get(touched, `drone.payload[${index}].name`) &&
              get(errors, `drone.payload[${index}].name`)
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.payload[${index}].brand`}
            label={t`Brand`}
            placeholder={t`Enter Payload Brand`}
            onChange={form.handleChange}
            helperSpace
            value={get(values, `drone.payload[${index}].brand`)}
            error={
              get(touched, `drone.payload[${index}].brand`) &&
              get(errors, `drone.payload[${index}].brand`)
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.payload[${index}].model`}
            value={get(values, `drone.payload[${index}].model`)}
            error={
              get(touched, `drone.payload[${index}].model`) &&
              get(errors, `drone.payload[${index}].model`)
            }
            label={t`Model`}
            placeholder={t`Enter Payload Model`}
            onChange={form.handleChange}
            helperSpace
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <TextAreaField
          label={t`Specification`}
          name={`drone.payload[${index}].specification`}
          value={get(values, `drone.payload[${index}].specification`)}
          error={
            get(touched, `drone.payload[${index}].specification`) &&
            get(errors, `drone.payload[${index}].specification`)
          }
          placeholder={t`Enter Payload Specification (ex. Sensor, Resolutions, Storage, etc.)`}
          onChange={form.handleChange}
          rows={4}
          style={{ borderRadius: "12px", background: "white", color: "black" }}
        />
      </div>
    </div>
  );
};

export default PayloadForm;
