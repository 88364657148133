import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { IUploadDetail, UploadType, handleUploadConfigFile } from "core/domains/services/upload";
import { FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import Loading from "../Loading/Loading";
import { IUploadFile } from "../UploadFarmImageList/UploadFarmImageList";

interface IUploadConfigFile {
  label: string;
  onChange: (data: IUploadDetail[], tempUrl: string[]) => void;
  require?: boolean;
  error?: string;
  defaultConfigFileUrlList?: string[];
  defaultConfigList?: IUploadDetail[];
  uploadType?: UploadType;
}

const UploadConfigFile: FC<IUploadConfigFile> = ({
  label,
  require = false,
  onChange,
  error,
  defaultConfigFileUrlList,
  defaultConfigList,
  uploadType,
}) => {
  const { t } = useTranslation("form");
  const theme = useTheme();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadList, setUploadList] = useState<IUploadDetail[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (defaultConfigList) {
      setUploadList(defaultConfigList);
      const formatFileList = defaultConfigList.map((item: IUploadDetail, index: number) => {
        return {
          path: item?.file_name,
          name: item?.file_name,
          type: `text/${item.ext}`,
          webkitRelativePath: "",
          preview: defaultConfigFileUrlList?.[index] as string,
        };
      });
      setFileList(formatFileList);
    }
  }, [defaultConfigFileUrlList, defaultConfigList]);

  const handleDeleteFile = (e: any, index: number) => {
    e.stopPropagation();
    setFileList([]);
    setUploadList([]);
    onChange([], []);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    setIsUploading(true);

    const updatedFiles: IUploadFile[] = acceptedFiles.map((file: any) => {
      const type = "text/config";

      return {
        ...file,
        preview: URL.createObjectURL(file),
        type,
        name: file.name,
        webkitRelativePath: "",
        path: file.name,
      };
    });

    setFileList(updatedFiles);

    const result = await handleUploadConfigFile(acceptedFiles[0], uploadType);
    setIsUploading(false);
    const urlList = updatedFiles.map((tf) => tf.preview);
    onChange([result], urlList);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "text/x-config": [".conf"],
      "application/x-config": [".conf"],
      "text/config": [".conf"],
    },
  });

  const renderPreview = () => {
    return fileList.map((file: any, index: number) => (
      <div key={file.name} style={{ position: "relative", width: "180px", textAlign: "center" }}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(e) => handleDeleteFile(e, index)}
        >
          <Icon
            style={{ cursor: "pointer" }}
            icon="XMark"
            size={14}
            color={theme.color.gray[400]}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon icon="Folder" size={84} color="#FFB32C" />
          <div style={{ fontSize: "16px", marginTop: "8px" }}>{file.name}</div>
          {defaultConfigFileUrlList && defaultConfigFileUrlList[index] && (
            <a
              href={defaultConfigFileUrlList[index]}
              download={file.name}
              style={{
                marginTop: "10px",
                display: "block",
                padding: "10px",
                color: "white",
                borderRadius: "4px",
                textDecoration: "none",
                backgroundColor: "#c8a011",
                transition: "background-color 0.3s ease, transform 0.2s ease",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#d5b164";
                e.currentTarget.style.transform = "scale(1.05)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#c8a011";
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              {t`Download This File`}
            </a>
          )}
        </div>
        {isUploading && <Loading />}
      </div>
    ));
  };

  return (
    <div>
      <div style={{ marginBottom: "4px" }}>
        {label}
        {require && <span style={{ color: "red" }}>*</span>}
      </div>

      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #FFFF",
          borderRadius: "4px",
          padding: "25px",
          textAlign: "center",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "200px",
          position: "relative",
        }}
      >
        <input {...getInputProps()} style={{ display: "none" }} />
        {fileList.length === 0 ? (
          <div>{t`Please upload a config file`}</div>
        ) : (
          <div>{renderPreview()}</div>
        )}
      </div>

      <div className="error-message">{error && error}</div>
    </div>
  );
};

export default UploadConfigFile;
