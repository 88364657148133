import { Button, Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as CirclePlusIcon } from "assets/svgs/circle_plus_icon.svg";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import { QUERY_ADMIN_FARMER_LIST } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import { TAdminFarmerProfile, TAdminFarmerProfileListResponse } from "types/admin";
import { IMeta } from "types/pagination";

import ATable from "components/templates/ATable/ATable";

import FarmerListPageStyled from "./FarmerListPage.styled";

const FarmerListPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [farmerList, setFarmerList] = useState<TAdminFarmerProfile[]>([]);
  const [searchName, setSearchName] = useState<string>("");

  const successModal = useSuccessModal({
    title: "Farmer Disabled",
    detail: (
      <div>
        <div>Your farmer has been successfully disabled</div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
  });

  const errorModal = useErrorModal({
    title: "Are you sure you want to disable this farmer ?",
    detail: (
      <div>
        <div>This farmer will be disabled immediately. </div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
    callBackOnOk() {
      successModal.openModal();
    },
  });

  const columns = [
    {
      title: "Farmer",
      dataIndex: "id",
      key: "id",
      width: 336,
      render: (_: string, record: TAdminFarmerProfile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar name={`${record.firstName} ${record.lastName}`} round={true} size={"40"} />
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {record.firstName} {record.lastName}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Phone no.",
      dataIndex: "phoneNo",
      key: "phoneNo",
      width: 200,
      align: "center" as AlignType,
    },
    {
      title: "Total Farm",
      dataIndex: "totalFarms",
      key: "totalFarms",
      width: 100,
      align: "center" as AlignType,
      // render: (_: string, record: TAdminFarmerProfile) => {
      //   return <div>{record.farms.meta.totalItems}</div>;
      // },
    },
    // {
    //   title: "Total Flight Post",
    //   dataIndex: "flightPost.meta.totalItems",
    //   key: "flightPost.meta.totalItems",
    //   width: 100,
    //   align: "center" as AlignType,
    //   render: (_: string, record: TAdminFarmerProfile) => {
    //     return <div>{record.flightPost.meta.totalItems}</div>;
    //   },
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 350,
      align: "center" as AlignType,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 120,
    //   align: "center" as AlignType,
    //   render: (data: string, record: any) => {
    //     let type: TBadgeType = "normal";
    //     switch (record.status) {
    //       case "active":
    //         type = "success";
    //         break;
    //       case "inprogress":
    //         type = "success";
    //         break;
    //       case "warning":
    //         type = "success";
    //         break;
    //       case "offline":
    //         type = "normal";
    //         break;
    //     }
    //     return <Badge label={data} type={type} />;
    //   },
    // },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 120,
      render: (_: string, record: TAdminFarmerProfile, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {/* <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={true}
              onChange={() => errorModal.openModal()}
              onColor="#FFEDCD"
              onHandleColor="#FFB32C"
              offHandleColor="#FFB32C"
            /> */}
            <Link to={`/edit-farmer/${record.userId}`}>
              <DetailIcon />
            </Link>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const fetchFarmerList = async (currentPage: number, pageSize: number) => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
          alias: "user",
          searchBy: ["firstName", "lastName"],
        },
        optionsOther: {
          currentPage: 1,
          itemsPerPage: 1,
        },
      };
      const response: TAdminFarmerProfileListResponse = await client.request(
        QUERY_ADMIN_FARMER_LIST,
        variables
      );

      setFarmerList(response.adminGetFarmers.data);
      setMeta(response.adminGetFarmers.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handleSearchName = () => {
    setIsLoading(true);
    fetchFarmerList(currentPage, pageSize);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFarmerList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <FarmerListPageStyled>
      <h2>Farmer</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ width: "300px" }}>
            <InputField
              placeholder="Search farmer name"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
              suffix={
                <Icon icon="Search" onClick={handleSearchName} style={{ cursor: "pointer" }} />
              }
            />
          </div>
        </div>
        <Link to={"/add-farmer"}>
          <Button style={{ backgroundColor: "#FFB32C", color: "white" }}>
            <CirclePlusIcon />
            <span style={{ marginLeft: "10px" }}>Add new farmer</span>
          </Button>
        </Link>
      </div>
      <ATable
        isLoading={isLoading}
        columns={columns}
        data={farmerList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </FarmerListPageStyled>
  );
};
export default FarmerListPage;
