import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Table, { TableProps } from "rc-table";
import { ChangeEvent } from "react";

import APagination from "../APagination/APagination";
import Loading from "../Loading/Loading";

interface IATableProps {
  columns: any;
  data: any;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  rowClassName?: string;
  onRowClick?: (data: any, index?: number) => void;
  isLoading?: boolean;
}
const Wrapper = styled.div`
  position: relative;
  .rc-table-container {
    min-height: 500px;
  }
  .rc-table-thead {
    font-size: 14px;
    th {
      font-weight: 400;
    }
  }
  .rc-table-tbody {
    font-weight: 300;
  }
  .rc-table-footer {
    display: flex;
    justify-content: end;
    padding-top: 8px;
    bottom: 20px;
    right: 24px;
  }
`;

const ATable: React.FC<IATableProps> = ({
  columns,
  data,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
  totalItems,
  onRowClick,
  rowClassName,
  isLoading,
}) => {
  return (
    <Wrapper>
      {isLoading && <Loading />}

      <Table
        columns={columns}
        data={data}
        rowClassName={rowClassName}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              event.preventDefault();
              if (onRowClick) {
                onRowClick(record, rowIndex);
              }
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{
          marginTop: "8px",
          borderRadius: "12px",
          background: "#665C4D",
          padding: "16px",
          minHeight: "500px",
        }}
        footer={() => (
          <APagination
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={onPageChange}
            onChangePageSize={onPageSizeChange}
          />
        )}
      />
    </Wrapper>
  );
};

export default ATable;
