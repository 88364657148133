import { Button, Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as CirclePlusIcon } from "assets/svgs/circle_plus_icon.svg";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import { QUERY_ADMIN_PILOT_LIST } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import { decrypt } from "core/utils/crypto";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import { TAdminPilotProfileResponse } from "types/admin";
import { IMeta } from "types/pagination";
import { TPilotProfile } from "types/pilot";

import ATable from "components/templates/ATable/ATable";

import { useAuth } from "../AuthPage/store/auth";
import PilotListPageStyled from "./PilotListPage.styled";

const PilotListPage = () => {
  const { t } = useTranslation("pilot");

  const { user } = useAuth();
  const isAdminServiceProvider = user?.roles?.includes("ADMIN_SERVICE_PROVIDER");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pilotList, setPilotList] = useState<TPilotProfile[]>([]);
  const [meta, setMeta] = useState<IMeta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");

  const successModal = useSuccessModal({
    title: t`Pilot Disabled`,
    detail: (
      <div>
        <div>{t`Your pilot has been successfully disabled`}</div>
        <div>{t`You can re-enable again at anytime`}</div>
      </div>
    ),
  });

  const errorModal = useErrorModal({
    title: t`Are you sure you want to disable this pilot ?`,
    detail: (
      <div>
        <div>{t`This pilot will be disabled immediately.`}</div>
        <div>{t`You can re-enable again at anytime`}</div>
      </div>
    ),
    callBackOnOk() {
      successModal.openModal();
    },
  });

  const columns = [
    {
      title: t`Pilot`,
      dataIndex: "pilot",
      key: "pilot",
      width: 336,
      render: (_: string, record: TPilotProfile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar name={`${record.firstName} ${record.lastName}`} round={true} size={"40"} />
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {record.firstName} {record.lastName}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: t`Phone no.`,
      dataIndex: "phoneNo",
      key: "phoneNo",
      width: 200,
      align: "center" as AlignType,
    },
    {
      title: t`Total Drone`,
      dataIndex: "droneCount",
      key: "droneCount",
      width: 100,
      align: "center" as AlignType,
      // render: (_: string, record: TPilotProfile) => {
      //   return <div>{record.}</div>;
      // },
    },
    // {
    //   title: "Total Mission",
    //   dataIndex: "totalFlightPost",
    //   key: "totalFlightPost",
    //   width: 100,
    //   align: "center" as AlignType,
    //   render: (_: string, record: TPilotProfile) => {
    //     return <div>{record.flightPosts.meta.totalItems}</div>;
    //   },
    // },
    {
      title: t`License`,
      dataIndex: "license",
      key: "license",
      width: 350,
      align: "center" as AlignType,
      render: (_: string, record: TPilotProfile) => {
        return <div>{record.license.caatLicenseNo}</div>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 120,
    //   align: "center" as AlignType,
    //   render: (data: string, record: any) => {
    //     let type: TBadgeType = "normal";
    //     switch (record.status) {
    //       case "active":
    //         type = "success";
    //         break;
    //       case "inprogress":
    //         type = "success";
    //         break;
    //       case "warning":
    //         type = "success";
    //         break;
    //       case "offline":
    //         type = "normal";
    //         break;
    //     }
    //     return <Badge label={data} type={type} />;
    //   },
    // },
    {
      title: t`Action`,
      dataIndex: "",
      key: "action",
      width: 120,
      render: (_: string, record: TPilotProfile, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {/* <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={true}
              onChange={() => errorModal.openModal()}
              onColor="#FFEDCD"
              onHandleColor="#FFB32C"
              offHandleColor="#FFB32C"
            /> */}
            <Link to={`/edit-pilot/${record.userId}`}>
              <DetailIcon />
            </Link>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const fetchPilotList = async (currentPage: number, pageSize: number) => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
          searchBy: ["firstName", "lastName"],
          alias: "user",
        },
        filterByServiceProvider: isAdminServiceProvider,
      };
      const response: TAdminPilotProfileResponse = await client.request(
        QUERY_ADMIN_PILOT_LIST,
        variables
      );

      const pilots = response.adminGetPilots.data?.map((pilot) => {
        return {
          ...pilot,
          email: decrypt(pilot.email),
          phoneNo: decrypt(pilot.phoneNo),
          nationalIdNo: decrypt(pilot.nationalIdNo),
        };
      });

      setPilotList(pilots);
      setMeta(response.adminGetPilots.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handleSearchName = () => {
    setIsLoading(true);
    fetchPilotList(currentPage, pageSize);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPilotList(currentPage, pageSize);
  }, [currentPage, pageSize, searchName]);

  return (
    <PilotListPageStyled>
      <h2>{t`Pilot`}</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ width: "300px" }}>
            <InputField
              placeholder={t`Search pilot name`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
              suffix={
                <Icon icon="Search" onClick={handleSearchName} style={{ cursor: "pointer" }} />
              }
            />
          </div>
        </div>
        {/* <Link to={"/add-pilot"}>
          <Button style={{ backgroundColor: "#FFB32C", color: "white" }}>
            <CirclePlusIcon />
            <span style={{ marginLeft: "10px" }}>Add new pilot</span>
          </Button>
        </Link> */}
      </div>
      <ATable
        isLoading={isLoading}
        columns={columns}
        data={pilotList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </PilotListPageStyled>
  );
};
export default PilotListPage;
