import { InputField, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { UploadType } from "core/domains/services/upload";
import { droneBrandList, droneModelList, droneTypeList } from "core/utils/constant";
import dayjs from "dayjs";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pilot } from "types/pilot";

import ASelect from "components/templates/ASelect/ASelect";
import PilotForm from "components/templates/PilotForm/PilotForm";
import UploadDroneItem from "components/templates/UploadDroneItem/UploadDroneItem";

interface IDroneStep {
  // selected: string;
  // data: IDataButtonGroup[];
  noStep: number;
  totalStep: number;
  isReadOnly: boolean;
  form: any;
  pilotList: Pilot[];
  handleLeaseChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLeased?: boolean;
  isEdit?: boolean;
  onCreatePilotSuccess: () => void;
  onSearchPilot: (value: string) => void;
}

const DroneStep: React.FC<IDroneStep> = ({
  form,
  noStep,
  totalStep,
  isReadOnly,
  handleLeaseChange,
  isLeased,
  pilotList,
  isEdit,
  onCreatePilotSuccess,
  onSearchPilot,
}) => {
  const { t } = useTranslation(["form", "drone", "common"]);
  const theme = useTheme();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isCreatePilotOpen, setIsCreatePilotOpen] = useState<boolean>(false);

  const handleCreatePilotSuccess = () => {
    setIsCreatePilotOpen(false);
    onCreatePilotSuccess();
  };

  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} {noStep}/{totalStep}
      </div>
      <h1>{t(`Drone Information`, { ns: "drone" })}</h1>
      <hr />
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px", alignItems: "end" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`Drone Owner`}
            required
            placeholder={t`Select Drone Owner`}
            options={pilotList.map((pilotItem) => {
              return {
                value: pilotItem.id,
                label: `${pilotItem.firstName} ${pilotItem.lastName}`,
              };
            })}
            value={form.values.drone.userId}
            disabled={isReadOnly}
            onChange={(value) => {
              form.setFieldValue("drone.userId", value);
            }}
            error={get(form, "touched.drone.userId") && get(form, "errors.drone.userId")}
            actionOption={
              <div onClick={() => setIsCreatePilotOpen(true)}>
                {t(`Add New Pilot`, { ns: "drone" })}
              </div>
            }
            onSearch={onSearchPilot}
            filterOption={false}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="drone.name"
            label={t`Drone Name`}
            placeholder={t`Enter Drone Name`}
            onChange={form.handleChange}
            helperSpace
            required
            disabled={isReadOnly}
            value={form.values.drone.name}
            error={get(form, "touched.drone.name") && get(form, "errors.drone.name")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`Drone Type`}
            required
            placeholder={t`Select Drone Type`}
            options={droneTypeList}
            value={form.values.drone.droneType}
            disabled={isReadOnly}
            onChange={(value) => form.setFieldValue("drone.droneType", value)}
            error={get(form, "touched.drone.droneType") && get(form, "errors.drone.droneType")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`Drone Brand`}
            required
            placeholder={t`Select Drone Brand`}
            options={droneBrandList}
            disabled={isReadOnly}
            value={form.values.drone.brand}
            onChange={(value) => form.setFieldValue("drone.brand", value)}
            error={get(form, "touched.drone.brand") && get(form, "errors.drone.brand")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`Drone Model`}
            required
            placeholder={t`Select Drone Model`}
            options={droneModelList}
            disabled={isReadOnly}
            value={form.values.drone.model}
            onChange={(value) => form.setFieldValue("drone.model", value)}
            error={get(form, "touched.drone.model") && get(form, "errors.drone.model")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="drone.weight"
            label={t`Drone Weight (Kg)`}
            required
            placeholder={t`Enter Drone Weight`}
            onChange={form.handleChange}
            helperSpace
            disabled={isReadOnly}
            value={form.values.drone.weight}
            error={get(form, "touched.drone.weight") && get(form, "errors.drone.weight")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="drone.serialNumber"
            required
            label={t`Serial Number`}
            placeholder={t`Enter Serial Number`}
            onChange={form.handleChange}
            helperSpace
            disabled={isReadOnly || isEdit}
            value={form.values.drone.serialNumber}
            error={
              get(form, "touched.drone.serialNumber") && get(form, "errors.drone.serialNumber")
            }
          />
        </div>
      </div>
      <div>
        <h3>{t`Images`}</h3>
        <div className="font-300">
          {t`Refer to the sample photos provided, before uploading the Drone and Remote photos.`}
        </div>
        <div className="font-300">{t`Upload only .jpg or .png, Max file size 5 MB.`}</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <h3>{t(`Drone`, { ns: "drone" })}</h3>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px", flexWrap: "wrap" }}>
        <UploadDroneItem
          label={t`Top`}
          require
          onChange={(value, urlList) => {
            form.setFieldValue(`drone.images.top`, value);
            form.setFieldValue(`drone.url.top`, urlList);
            setTimeout(() => {
              form.validateForm();
            }, 100);
          }}
          defaultImageList={form.values.drone.images.top}
          defaultFileUrlList={form.values.drone.url?.top || form.values.drone.imagesUrls?.top}
          uploadType={UploadType.DRONE}
        />
        <UploadDroneItem
          label={t`Bottom`}
          require
          onChange={(value, urlList) => {
            form.setFieldValue(`drone.images.bottom`, value);
            form.setFieldValue(`drone.url.bottom`, urlList);
            setTimeout(() => {
              form.validateForm();
            }, 100);
            setTimeout(() => {
              form.validateForm();
            }, 100);
          }}
          defaultImageList={form.values.drone.images.bottom}
          defaultFileUrlList={form.values.drone.url?.bottom || form.values.drone.imagesUrls?.bottom}
          uploadType={UploadType.DRONE}
        />
        <UploadDroneItem
          label={t`Left`}
          require
          onChange={(value, urlList) => {
            form.setFieldValue(`drone.images.left`, value);
            form.setFieldValue(`drone.url.left`, urlList);
            setTimeout(() => {
              form.validateForm();
            }, 100);
          }}
          defaultImageList={form.values.drone.images.left}
          defaultFileUrlList={form.values.drone.url?.left || form.values.drone.imagesUrls?.left}
          uploadType={UploadType.DRONE}
          // onChange={(data: IUploadDetail[], tempUrl: IUploadFile[]) =>
          //   form.setFieldValue("drone.images.left", url)
          // }
        />
        <UploadDroneItem
          label={t`Right`}
          require
          onChange={(value, urlList) => {
            form.setFieldValue(`drone.images.right`, value);
            form.setFieldValue(`drone.url.right`, urlList);
          }}
          defaultImageList={form.values.drone.images.right}
          defaultFileUrlList={form.values.drone.url?.right || form.values.drone.imagesUrls?.right}
          uploadType={UploadType.DRONE}
          // onChange={(data: IUploadDetail[], tempUrl: IUploadFile[]) =>
          //   form.setFieldValue("drone.images.right", url)
          // }
        />
      </div>
      <div style={{ marginBottom: "24px", alignItems: "center" }}>
        <h3 style={{ marginRight: "8px" }}>{t(`Leasing`, { ns: "drone" })}</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            onChange={handleLeaseChange}
            checked={isLeased}
            style={{ marginRight: "8px", transform: "scale(1.5)" }}
          />
          <label>{t(`Lease this drone`, { ns: "drone" })}</label>
        </div>
        {isLeased && (
          <>
            <div
              style={{
                display: "flex",
                gap: "24px",
                marginBottom: "24px",
                cursor: "pointer",
                maxWidth: "590px",
              }}
            >
              <InputField
                required={isLeased}
                type="date"
                name="drone.leased.expiryDate"
                label={t`Expiry Date`}
                placeholder={t`Select expiry date`}
                onChange={(e: { target: { value: any } }) => {
                  const date = e.target.value;
                  const expiryDate = dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
                  form.setFieldValue("drone.leased.expiryDate", expiryDate);
                }}
                value={
                  form.values.drone.leased?.expiryDate
                    ? dayjs(form.values.drone.leased.expiryDate).utc().format("YYYY-MM-DD")
                    : ""
                }
                error={
                  get(form, "touched.drone.leased.expiryDate") &&
                  get(form, "errors.drone.leased.expiryDate")
                }
              />
            </div>
          </>
        )}
      </div>

      <Modal isOpen={isCreatePilotOpen} style={{ content: { padding: 0 } }}>
        <PilotForm
          onClose={() => setIsCreatePilotOpen(false)}
          onSuccess={handleCreatePilotSuccess}
        />
      </Modal>

      {/* <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <h3>Remote</h3>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <UploadDroneItem
          label="Front"
          require
          onSuccess={(url: string) => form.setFieldValue("FrontImage", url)}
        />
        <UploadDroneItem
          label="Back"
          require
          onSuccess={(url: string) => form.setFieldValue("BackImage", url)}
        />
      </div> */}
      {/* <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <h3>Serial Number</h3>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <UploadDroneItem
          label="Drone"
          require
          // onSuccess={(url: string) => form.setFieldValue("DroneImage", url)}
          onChange={(value, urlList) => {
            form.setFieldValue(`license.caatFileList`, value);
            form.setFieldValue(`license.caatUrlList`, urlList);
          }}
          defaultImageList={form.values.license.caatFileList}
          defaultFileUrlList={form.values.license.caatUrlList}
        />
        <UploadDroneItem
          label="Remote"
          require
          // onSuccess={(url: string) => form.setFieldValue("RemoteImage", url)}
          onChange={(value, urlList) => {
            form.setFieldValue(`license.nbtcFileList`, value);
            form.setFieldValue(`license.nbtcUrlList`, urlList);
          }}
          defaultImageList={form.values.license.nbtcFileList}
          defaultFileUrlList={form.values.license.nbtcUrlList}
        />
      </div> */}
    </div>
  );
};

export default DroneStep;
