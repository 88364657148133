import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import { QUERY_NATIONALITIES } from "core/gql/master";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TServiceProviderProfile } from "types/admin";
import { TNationalityItem, TNationalityResponse } from "types/master";

import ASelect from "components/templates/ASelect/ASelect";

import AdminStepStyled from "./AdminStep.styled";

interface IAdminStep {
  form: any;
  serviceProviderList?: TServiceProviderProfile[];
  isEdit: boolean;
  isServiceProviderEditable?: boolean;
}
const AdminStep: React.FC<IAdminStep> = ({
  form,
  serviceProviderList,
  isEdit,
  isServiceProviderEditable = true,
}) => {
  const { t } = useTranslation(["form", "admin"]);

  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchNationality();
  }, []);

  return (
    <AdminStepStyled>
      <h3>{t(`Admin Information`, { ns: "admin" })}</h3>
      {isServiceProviderEditable && !isEmpty(serviceProviderList) && (
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <ASelect
              label={t`Service Provider`}
              required
              placeholder={t`Select Service Provider`}
              options={
                serviceProviderList?.map((spItem) => {
                  return {
                    value: spItem.name,
                    label: `${spItem.name}`,
                  };
                }) || []
              }
              value={form.values.serviceProvider?.name || ""}
              onChange={(value) => form.setFieldValue("serviceProvider.name", value)}
              error={get(form, "touched.serviceProvider.name") && get(form, "serviceProvider.name")}
              disabled
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="firstName"
          label={t`First Name`}
          placeholder={t`Enter First Name`}
          onChange={form.handleChange}
          helperSpace
          required
          value={form.values.firstName}
          error={get(form, "touched.firstName") && get(form, "errors.firstName")}
        />
        <InputField
          type="text"
          name="lastName"
          onChange={form.handleChange}
          label={t`Last Name`}
          placeholder={t`Enter Last Name`}
          helperSpace
          required
          value={form.values.lastName}
          error={get(form, "touched.lastName") && get(form, "errors.lastName")}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="nationalIdNo"
            label={t`National ID Number`}
            placeholder={t`Enter National ID Number`}
            onChange={form.handleChange}
            helperSpace
            required
            value={form.values.nationalIdNo}
            error={get(form, "touched.nationalIdNo") && get(form, "errors.nationalIdNo")}
          />
        </div>
        <div
          style={{ flex: 1, display: "flex", justifyContent: "flex-end", flexDirection: "column" }}
        >
          <ASelect
            label={t`Nationality`}
            placeholder={t`Select Nationality`}
            required
            options={nationalityList.map((nation) => {
              return {
                value: nation.nationalityCode,
                label: nation.nationality,
              };
            })}
            value={form.values.nationality}
            // @ts-ignore:
            onChange={(value) => form.setFieldValue("nationality", value)}
            error={get(form, "touched.nationality") && get(form, "errors.nationality")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="email"
          label={t`Email`}
          placeholder={t`Enter Email`}
          helperSpace
          required
          value={form.values.email}
          onChange={form.handleChange}
          disabled={isEdit}
          error={get(form, "touched.email") && get(form, "errors.email")}
        />
        <InputField
          type="text"
          name="phoneNo"
          label={t`Phone Number`}
          placeholder={t`Enter Phone Number`}
          helperSpace
          onChange={form.handleChange}
          required
          value={form.values.phoneNo}
          error={get(form, "touched.phoneNo") && get(form, "errors.phoneNo")}
        />
      </div>

      {!isEdit && (
        <>
          <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
            <div>{t`Password`}</div>
          </div>
          <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
            <InputField
              type="password"
              name="password"
              label={t`Password`}
              placeholder={t`Enter Password`}
              onChange={form.handleChange}
              helperSpace
              required
              value={form.values.password}
              error={get(form, "touched.password") && get(form, "errors.password")}
            />
            <InputField
              type="password"
              name="confirmPassword"
              label={t`Confirm Password`}
              placeholder={t`Enter Password`}
              helperSpace
              required
              value={form.values.confirmPassword}
              error={get(form, "touched.confirmPassword") && get(form, "errors.confirmPassword")}
              onChange={form.handleChange}
            />
          </div>
        </>
      )}
    </AdminStepStyled>
  );
};

export default AdminStep;
