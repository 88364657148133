import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import debounce from "lodash/debounce";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IDroneInformation } from "types/droneInformation";
import { IMeta } from "types/pagination";
import { IDroneDetail } from "types/pilot";

import DroneListItem from "components/templates/DroneListItem/DroneListItem";

import DroneListStepStyled from "./DroneListStepStyled.styled";

interface IDroneListStep {
  onSetStep: (step: string) => void;
  setSelectedDrone: (id: string) => void;
  droneList: IDroneInformation[];
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  loadMoreData?: () => void;
}

const DroneListStep: FC<IDroneListStep> = ({
  onSetStep,
  setSelectedDrone,
  droneList,
  loadMoreData,
  setSearchTerm,
}) => {
  const { t } = useTranslation("drone");

  const [filterDrone, setFilterDrone] = useState<IDroneInformation[]>(droneList);
  const [loading, setLoading] = useState(false);
  const listEndRef = useRef<HTMLDivElement>(null);

  const handleOnFilterDrone = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const debounceSearch = useRef(
    debounce((searchTerm: string) => setSearchTerm(searchTerm), 300)
  ).current;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading && loadMoreData) {
        setLoading(true);
        loadMoreData();
      }
    });

    const currentListEndRef = listEndRef.current;

    if (currentListEndRef) {
      observer.observe(currentListEndRef);
    }

    return () => {
      if (currentListEndRef) {
        observer.unobserve(currentListEndRef);
      }
    };
  }, [loading, loadMoreData]);

  useEffect(() => {
    setFilterDrone(droneList);
  }, [droneList]);

  useEffect(() => {
    setLoading(false);
  }, [droneList]);

  return (
    <DroneListStepStyled>
      <div className="flight-post-box">
        <h2>{t`Drone List`}</h2>
        <div>
          <InputField placeholder={t`Search Drone`} onChange={handleOnFilterDrone} />
        </div>
        {filterDrone.map((drone, index) => (
          <div key={drone.id}>
            <Link to={`/drone/flight-post/${drone.serialNumber}`}>
              <DroneListItem index={index} {...drone} />
            </Link>
          </div>
        ))}
        <div ref={listEndRef}></div>
      </div>
    </DroneListStepStyled>
  );
};
export default DroneListStep;
