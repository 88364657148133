import { z } from "zod";

export const farmerSignSchema = z
  .object({
    firstName: z.string({
      required_error: "First Name is a required field",
    }),
    lastName: z.string({
      required_error: "LastName is a required field",
    }),
    nationalIdNo: z.string({
      required_error: "National Id is a required field",
    }),
    nationality: z.string({
      required_error: "Nationality is a required field",
    }),
    phoneNo: z
      .string({
        required_error: "Phone No is a required field",
      })
      .min(9, "Phone No must have at least 9 characters")
      .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
    address: z.string({
      required_error: "Address is a required field",
    }),
    // completeAddress: z.string().optional().nullable(),
    password: z
      .string({
        required_error: "password is a required field",
      })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z.string({
      required_error: "confirmPassword is a required field",
    }),
    email: z
      .string({
        required_error: "email is a required field",
      })
      .email("Email is not valid"),
    provinceCode: z.string({
      required_error: "province is a required field",
    }),
    districtCode: z.string({
      required_error: "district is a required field",
    }),
    subdistrictCode: z.string({
      required_error: "subDistrict is a required field",
    }),
    postcode: z.string({
      required_error: "postCode is a required field",
    }),
    farms: z
      .object({
        name: z.string({
          required_error: "name is a required field",
        }),
        farmType: z.string({
          required_error: "farmType is a required field",
        }),
        totalArea: z.number({
          required_error: "totalArea is a required field",
        }),
        imageList: z
          .object({
            ext: z.string({
              required_error: "farmType is a required field",
            }),
            uid: z.string({
              required_error: "farmType is a required field",
            }),
            file_name: z.string({
              required_error: "farmType is a required field",
            }),
            unique_id: z.string({
              required_error: "farmType is a required field",
            }),
          })
          .array(),
        address: z.string({
          required_error: "address is a required field",
        }),
        provinceCode: z.string({
          required_error: "province is a required field",
        }),
        districtCode: z.string({
          required_error: "district is a required field",
        }),
        subdistrictCode: z.string({
          required_error: "subDistrict is a required field",
        }),
        postcode: z.string({
          required_error: "postcode is a required field",
        }),
        coordinates: z.array(z.array(z.array(z.number()))),
        // completeAddress: z.string({
        //   required_error: "completeAddress is a required field",
        // }),
      })
      .array()
      .min(1, "At least 1 farm is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const farmerSignupDefaultValue = {
  firstName: "",
  lastName: "",
  nationalIdNo: "",
  email: "",
  nationality: "",
  phoneNo: "",
  address: "",
  provinceCode: "",
  districtCode: "",
  subdistrictCode: "",
  postcode: "",
  // completeAddress: "",
  password: "",
  confirmPassword: "",
  farms: [],
};
