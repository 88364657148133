import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { isArray, pullAt } from "lodash";
import { useEffect, useMemo, useState } from "react";

import AddFarmForm, { TAddFarmForm } from "./AddFarmForm";
import FarmCardList from "./FarmCardList";
import FarmStepStyled from "./FarmStep.styled";

interface IFarmStep {
  noStep: string;
  totalStep: string;
  form: any;
  setDisableChangeStep: (value: boolean) => void;
}
const FarmStep: React.FC<IFarmStep> = ({ form, noStep, totalStep, setDisableChangeStep }) => {
  const theme = useTheme();
  const [farmInfoList, setFarmInfoList] = useState<TAddFarmForm[]>(form.values.farms);
  const [isAddFarm, setIsAddFarm] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    if (setDisableChangeStep) {
      setDisableChangeStep(isAddFarm);
    }
  }, [isAddFarm]);
  const handleSaveFarm = (value: any, index: number) => {
    const result = farmInfoList;
    result[selectedIndex] = value;
    setFarmInfoList(result);
    setIsAddFarm(false);
    form.setFieldValue("farms", farmInfoList);
  };

  useEffect(() => {
    if (form.values.farms) {
      setFarmInfoList(form.values.farms);
    }
  }, [form.values.farms]);

  const handleEditFarm = (index: number) => {
    setIsAddFarm(true);
    setSelectedIndex(index);
  };

  const handleDeleteFarm = (index: number) => {
    pullAt(farmInfoList, index);
    setFarmInfoList([...farmInfoList]);
    form.setFieldValue("farms", farmInfoList);
  };

  const handleAddFarm = () => {
    setIsAddFarm(true);
    setSelectedIndex(farmInfoList.length);
  };

  const handleCancelAddFarm = (index: number) => {
    setIsAddFarm(false);
    pullAt(farmInfoList, index);
    setFarmInfoList([...farmInfoList]);
    form.setFieldValue("farms", farmInfoList);
  };

  const farmError = form.errors.farms;

  return (
    <FarmStepStyled>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        Part {noStep}/{totalStep}
      </div>
      <h1>Farm Information</h1>
      <hr />
      {isAddFarm ? (
        <AddFarmForm
          form={form}
          index={selectedIndex}
          onSaveFarm={handleSaveFarm}
          onCancelForm={handleCancelAddFarm}
        />
      ) : (
        <>
          <FarmCardList
            errorList={form.errors.farms}
            farmList={farmInfoList}
            selectedEditFarm={handleEditFarm}
            onDeleteFarm={handleDeleteFarm}
          />
          {farmInfoList.length ? <hr /> : <></>}
          <div className="add-more-farm-btn" onClick={handleAddFarm}>
            <Icon icon="Plus" size={24} /> Add more farm
          </div>
          {farmError && !isArray(farmError) && <div className="error-message">{farmError}</div>}
        </>
      )}
    </FarmStepStyled>
  );
};

export default FarmStep;
