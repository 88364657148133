import { MUTATION_ADMIN_CREATE_FLIGHT_POST } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import { MUTATION_CREATE_FLIGHT_POST } from "core/gql/flightPost";
import useSuccessModal from "core/hooks/useSuccessModal";
import { FC } from "react";

import DroneForm from "../DroneForm/DroneForm";

// import DroneForm from "components/pages/AdminAddDronePage/DroneForm/DroneForm";

// import FlightPostForm, { IValueFlightPostAdminForm } from "../FlightPostForm/FlightPostForm";

interface IAddDroneStep {
  onCancel: () => void;
  onSetStep?: (value: string) => void;
  onSuccess: () => void;
}
const AddDroneStep: FC<IAddDroneStep> = ({ onSetStep, onCancel, onSuccess }) => {
  const modal = useSuccessModal({
    title: "Flight Post Added",
    detail: (
      <>
        <div>Your flight post has been successfully </div>
        <div>added to the platform</div>
      </>
    ),
    callBack: onSuccess,
  });

  const handleOnSubmit = async (values: any) => {
    const { farmerId, ...rest } = values;
    const variables = {
      flightPostInput: rest,
      farmerId: farmerId,
    };
    await client.request(MUTATION_ADMIN_CREATE_FLIGHT_POST, variables);
    modal.openModal();
  };

  const handleSubmit = async (values: any) => {
    console.log("submit form", values);
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        position: "relative",
      }}
    >
      <DroneForm onSubmit={handleSubmit} onCancel={onCancel} />
      {/* <FlightPostForm onSubmit={handleOnSubmit} onCancel={onCancel} /> */}
      <modal.SuccessModal />
    </div>
  );
};
export default AddDroneStep;
