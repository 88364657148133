export const droneBrandList = [
  { label: "AgriDrone", value: "AgriDrone" },
  { label: "SkyTech", value: "SkyTech" },
  { label: "AeroGlide", value: "AeroGlide" },
  { label: "AquaFly", value: "AquaFly" },
  { label: "VinoDrones", value: "VinoDrones" },
  { label: "FarmWing", value: "FarmWing" },
  { label: "EcoFly", value: "EcoFly" },
  { label: "AquaTech", value: "AquaTech" },
  { label: "GreenFly", value: "GreenFly" },
  { label: "AgroScan", value: "AgroScan" },
  { label: "AiANG", value: "AiANG" },
  { label: "ATi", value: "ATi" },
];
export const droneModelList = [
  { label: "NB-1B", value: "NB1B" },
  { label: "A410-02A", value: "A41002A" },
  { label: "A616-02A", value: "A61602A" },
  { label: "A630-02A", value: "A63002A" },
];

export const droneTypeList = [
  { label: "Micro", value: "Micro" },
  { label: "Mini", value: "Mini" },
  { label: "Standard", value: "Standard" },
  { label: "Large", value: "Large" },
];

export const farmTypeList = [
  { label: "ไร่ผลผลิต", value: "crop" },
  { label: "ฟาร์มสัตว์เลี้ยง", value: "livestock" },
  { label: "ฟาร์มโคนม", value: "dairy" },
  { label: "ฟาร์มปลา", value: "fish" },
  { label: "ไร่องุ่น", value: "vineyard" },
  { label: "ฟาร์มไก่", value: "poultry" },
  { label: "สวนผลไม้", value: "orchard" },
  { label: "ฟาร์มอะควาโปนิคส์", value: "aquaponics" },
  { label: "ฟาร์มไฮโดรโปนิกส์", value: "hydroponics" },
  { label: "โรงเรือนปลูกพืช", value: "greenhouse" },
];
export const NBTCLicenseObjectiveList = [
  {
    label: "เพื่อการเล่นเป็นงานอดิเรก ความบันเทิง หรือเพื่อการกีฬา / Hobby, Entertainment or sport",
    value: "hobby,-entertainment-or-sport",
  },
  {
    label:
      "เพื่อการรายงานเหตุการณ์หรือรายงานจราจร(สื่อมวลชน) / Journalist or traffic report(Mass communication)",
    value: "journalist-or-traffic-report(mass-communication)",
  },
  {
    label:
      "เพื่อการถ่ายภาพ ถ่ายทำหรือการแสดงในภาพยนตร์ หรือรายการโทรทัศน์ / Photography, Videography or TV - Filming",
    value: "photography,-videography-or-tv-filming",
  },
  { label: "เพื่อการวิจัยและพัฒนาอากาศยาน / Science and Research", value: "science-and-research" },
  {
    label:
      "เพื่อการวิจัย ทดลอง ทดสอบ ตามประกาศ กสทช. / Research, experiment, test according to the NBTC announcement",
    value: "research,-experiment,-test-according-to-the-nbtc-announcement",
  },
];
export const payloadList = [
  { label: "Multispectral camera", value: "multispectral-camera" },
  { label: "Thermal camera", value: "thermal-camera" },
  { label: "Lidar sensor", value: "lidar-sensor" },
  { label: "Underwater camera", value: "underwater-camera" },
  { label: "Hyperspectral camera", value: "hyperspectral-camera" },
  { label: "Air quality sensor", value: "air-quality-sensor" },
  { label: "LiDAR scanner", value: "lidar-scanner" },
  { label: "Water quality sensor", value: "water-quality-sensor" },
  { label: "Nutrient analysis kit", value: "nutrient-analysis-kit" },
  { label: "Temperature and humidity sensors", value: "temperature-and-humidity-sensors" },
];

export const CAATLicenseObjectiveList = [
  {
    label: "เพื่อการเล่นเป็นงานอดิเรก ความบันเทิง หรือเพื่อการกีฬา / Hobby, Entertainment or sport",
    value: "hobby,-entertainment-or-sport",
  },
  {
    label:
      "เพื่อการรายงานเหตุการณ์หรือรายงานจราจร(สื่อมวลชน) / Journalist or traffic report(Mass communication)",
    value: "journalist-or-traffic-report(mass-communication)",
  },
  {
    label:
      "เพื่อการถ่ายภาพ ถ่ายทำหรือการแสดงในภาพยนตร์ หรือรายการโทรทัศน์ / Photography, Videography or TV - Filming",
    value: "photography,-videography-or-tv-filming",
  },
  { label: "เพื่อการวิจัยและพัฒนาอากาศยาน / Science and Research", value: "science-and-research" },
  {
    label: "กรณีมีวัตถุประสงค์อื่น ให้ระบุ / Other purpose, specify",
    value: "other-purpose,-specify",
  },
];

export const batteryBrandList = [
  { label: "TATTU", value: "TATTU" },
  { label: "OKCELL", value: "OKCELL" },
];

export const batteryModelList = [
  { label: "16k", value: "16k" },
  { label: "22k", value: "22k" },
  { label: "28k", value: "28k" },
];
