import { Component } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import AiangLogo from "assets/images/AiangLogo.png";
import { QUERY_ADMIN_GET_PROFILE } from "core/gql/admin";
import { QUERY_FARMER_GET_PROFILE } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import { QUERY_PILOT_GET_PROFILE } from "core/gql/pilot";
import { QUERY_USER_INFO } from "core/gql/user";
import { rgba } from "polished";
import { memo, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { TUserInfoResponse } from "types/user";

import { useAuth } from "components/pages/AuthPage/store/auth";

import {
  DroneIconGray,
  DroneIconSelected,
  FarmIconGray,
  FarmIconSelected,
  FarmerIconNotSelectedGray,
  FarmerIconSelected,
  FlightPostIconNotSelected,
  FlightPostIconSelected,
  MapIconNotSelected,
  MapIconSelected,
  PilotIconGray,
  PilotIconSelected,
  ReportIconNotSelected,
  ReportIconSelected,
  SettingIconGray,
  SettingIconSelected,
  UserIconGray,
  UserIconSelected,
} from "../Icon";
import NavIcon from "./NavIcon/NavIcon";
import PopoverContent from "./PopoverContent";

const NavigationStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 72px;
  background: #665c4d;
  backdrop-filter: blur(25px);
  padding: 24px 10px;

  .organization {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: relative;
    .avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
`;

const Navigation = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const { user } = useAuth();
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");

  const navList = [
    {
      label: t`Map`,
      selectedIcon: <MapIconSelected />,
      notSelectedIcon: <MapIconNotSelected />,
      link: "/flight-post",
      visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER", "PILOT"],
    },
    {
      label: t`Admin`,
      selectedIcon: <UserIconSelected />,
      notSelectedIcon: <UserIconGray />,
      link: "/admin-service-provider-list",
      visible: ["ADMIN"],
    },
    // {
    //   label: "Farmer",
    //   selectedIcon: <FarmerIconSelected />,
    //   notSelectedIcon: <FarmerIconNotSelectedGray />,
    //   link: "/farmer-list",
    //   visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER"],
    // },
    // {
    //   label: "Farm",
    //   selectedIcon: <FarmIconSelected />,
    //   notSelectedIcon: <FarmIconGray />,
    //   link: "/farm-list",
    //   visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER", "FARMER"],
    // },
    {
      label: t`Pilot`,
      selectedIcon: <PilotIconSelected />,
      notSelectedIcon: <PilotIconGray />,
      link: "/pilot-list",
      visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER"],
    },
    {
      label: t`Drone`,
      selectedIcon: <DroneIconSelected />,
      notSelectedIcon: <DroneIconGray />,
      link: "/drone-list",
      visible: ["ADMIN_SERVICE_PROVIDER", "PILOT"],
    },
    {
      label: t`Report`,
      selectedIcon: <ReportIconSelected />,
      notSelectedIcon: <ReportIconNotSelected />,
      link: "/report",
      visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER", "PILOT"],
    },
    {
      label: t`Leasing`,
      selectedIcon: <DroneIconSelected />,
      notSelectedIcon: <DroneIconGray />,
      link: "/leasing-list",
      visible: ["LEASING"],
    },
    {
      label: t`Setting`,
      selectedIcon: <SettingIconSelected />,
      notSelectedIcon: <SettingIconGray />,
      link: "/setting",
      visible: ["ADMIN", "ADMIN_SERVICE_PROVIDER", "PILOT", "FARMER", "LEASING"],
    },
  ];

  const fetchUserInfo = async () => {
    try {
      const response: TUserInfoResponse = await client.request(QUERY_USER_INFO);
      setFullName(`${response?.getUserInfo?.firstName} ${response?.getUserInfo?.lastName}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const navListByRole = navList?.filter((item) => {
    return item.visible.some((v) => user?.roles.includes(v as User.Role));
  });

  return (
    <NavigationStyled>
      <div className="organization">
        <img src={AiangLogo} width={28} height="100%" alt="user-avatar" />
        <Popover
          containerStyle={{
            zIndex: "1",
            background: "#414221",
            borderRadius: "10px",
            padding: "16px",
            width: "300px",
            left: "180px",
            top: "-48px",
          }}
          onClickOutside={() => setPopoverOpen(false)}
          isOpen={isPopoverOpen}
          positions={["bottom"]} // preferred positions by priority
          content={<PopoverContent fullName={fullName} />}
        >
          <div onClick={() => setPopoverOpen(!isPopoverOpen)} className="cursor-pointer">
            <Avatar name={fullName} round={true} size={"48"} />
          </div>
        </Popover>

        {navListByRole.map((fl) => {
          const isSelected = location.pathname === fl.link;
          return <NavIcon key={fl.label} {...fl} selected={isSelected} />;
        })}
      </div>
    </NavigationStyled>
  );
};

export default memo(Navigation);
