import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { client } from "core/gql/fetchgql";
import { QUERY_NATIONALITIES } from "core/gql/master";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { TNationalityItem, TNationalityResponse } from "types/master";

import ASelect from "components/templates/ASelect/ASelect";

interface IProfileFarmerForm {
  form: any;
}
const ProfileFarmerForm: FC<IProfileFarmerForm> = ({ form }) => {
  const theme = useTheme();
  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNationality();
  }, []);

  return (
    <div className="">
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        Part 1/2
      </div>
      <h1>Farmer</h1>
      <hr />
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <div className="title">Email</div>
          <div className="value">{form.values.email}</div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="title">Member since</div>
          <div className="value">{dayjs(form.values.createdAt).format("MM/YYYY")}</div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="firstName"
          value={form.values.firstName}
          label="First Name"
          placeholder="Enter First Name"
          onChange={form.handleChange}
          helperSpace
          error={form.touched.firstName && form.errors.firstName}
          required
        />
        <InputField
          type="text"
          name="lastName"
          value={form.values.lastName}
          onChange={form.handleChange}
          label="Last Name"
          placeholder="Enter Last Name"
          helperSpace
          error={form.touched.lastName && form.errors.lastName}
          required
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="nationalIdNo"
            value={form.values.nationalIdNo}
            label="National ID Number"
            placeholder="Enter National ID Number"
            onChange={form.handleChange}
            helperSpace
            error={form.touched.nationalIdNo && form.errors.nationalIdNo}
            required
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <ASelect
            label="Nationality"
            placeholder="Select Nationality"
            required
            options={nationalityList.map((nation) => {
              return {
                value: nation.nationalityCode,
                label: nation.nationality,
              };
            })}
            value={form.values.nationality}
            onChange={(value) => form.setFieldValue("nationality", value)}
            error={form.touched.nationality && form.errors.nationality}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="phoneNo"
          value={form.values.phoneNo}
          label="Phone Number"
          placeholder="Enter Phone Number"
          helperSpace
          error={form.touched.phoneNo && form.errors.phoneNo}
          required
          onChange={form.handleChange}
        />
      </div>
    </div>
  );
};
export default ProfileFarmerForm;
