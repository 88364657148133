import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import { QUERY_FLIGHT_LOG_BY_FLIGHT_POST_ID, QUERY_FLIGHT_POST_BY_ID } from "core/gql/flightPost";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import dayjs from "dayjs";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  EPostStatus,
  TFlightLogByFlightPostIdResponse,
  TFlightLogItem,
  TFlightPostDetail,
  TFlightPostDetailResponse,
} from "types/flightPost";
import { IMeta } from "types/pagination";

import ATable from "components/templates/ATable/ATable";
import AiangStatus from "components/templates/AiangStatus/AiangStatus";
import Badge from "components/templates/Badge/Badge";

import { useAuth } from "../AuthPage/store/auth";
import FlightPostSummary from "../FlightPostListPage/FlightPostSummary";
import FlightPostDetailPageStyled from "./FlightPostDetailPageStyled.styled";

const columns = [
  {
    title: "Log ID",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Date & Time",
    dataIndex: "time",
    key: "time",
    width: 200,
    align: "center" as AlignType,
    render: (data: string) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          <div>{dayjs(data).format("YYYY-MM-DD HH:mm:ss")}</div>
        </div>
      );
    },
  },
  {
    title: "Latitude & Longitude",
    dataIndex: "lat",
    key: "lat",
    width: 250,
    align: "center" as AlignType,
    render: (_: string, record: TFlightLogItem) => {
      return (
        <div
          style={{
            textDecoration: "underline",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {record.lat}, {record.lng} <Icon icon="Map" style={{ marginLeft: "4px" }} />
        </div>
      );
    },
  },
  {
    title: "Speed (kmph)",
    dataIndex: "horizontalSpeed",
    key: "horizontalSpeed",
    width: 200,
    align: "center" as AlignType,
    render: (data: number, record: TFlightLogItem) => {
      if (data) {
        return (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Horizontal:</span>
              <span>{data} kmph</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Vertical:</span>
              <span>{record.verticalSpeed} kmph</span>
            </div>
          </div>
        );
      }
      return <div>-</div>;
    },
  },
  {
    title: "Flight mode",
    dataIndex: "flightMode",
    key: "flightMode",
    width: 150,
    align: "center" as AlignType,
  },
  {
    title: "Height (m)",
    dataIndex: "height",
    key: "height",
    width: 150,
    align: "center" as AlignType,
  },
  {
    title: "Battery PCT(%)",
    dataIndex: "batteryPercent",
    key: "batteryPercent",
    width: 120,
    align: "center" as AlignType,
    render: (data: number) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          <div>{data.toFixed(0)}%</div>
        </div>
      );
    },
  },
];

const FlightPostDetailPage = () => {
  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [flightLogList, setFlightLogList] = useState<TFlightLogItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flightPostDetail, setFlightPostDetail] = useState<TFlightPostDetail>();
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const fetchFlightPostDetail = async () => {
    try {
      const variables = {
        flightPostId: id,
      };
      const response: TFlightPostDetailResponse = await client.request(
        QUERY_FLIGHT_POST_BY_ID,
        variables
      );
      setFlightPostDetail(response.flightPostById);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchFlightLogList = async (currentPage: number, pageSize: number) => {
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
        },
        flightPostId: id,
      };
      const response: TFlightLogByFlightPostIdResponse = await client.request(
        QUERY_FLIGHT_LOG_BY_FLIGHT_POST_ID,
        variables
      );
      setFlightLogList(response.flightLogByFlightPostId.data);
      setMeta(response.flightLogByFlightPostId.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFlightLogList(currentPage, pageSize);
    fetchFlightPostDetail();
  }, [currentPage, pageSize]);

  return (
    <FlightPostDetailPageStyled>
      <div className="flight-post-detail-header">
        <Link to={"/flight-post-list"}>
          <div className="back-button">
            <Icon icon="ArrowBackward" size={24} />
            <div className="back-button-label">Back to Flight Post List</div>
          </div>
        </Link>
        <div className="flight-post-detail-body">
          <div className="flight-post-title">
            <span>{flightPostDetail?.name}</span>
            <AiangStatus status={flightPostDetail?.status || EPostStatus.ACCEPTED} />
          </div>
          <div className="flight-post-detail-other">
            <div className="flight-post-detail-box">
              <span className="label">Location :</span>
              <span className="value">{flightPostDetail?.farm?.name}</span>
            </div>
            <div className="flight-post-detail-box">
              <span className="label">Flight Time :</span>
              <span className="value">
                {dayjs(flightPostDetail?.startTime).format("DD MMMM YYYY")} -{" "}
                {dayjs(flightPostDetail?.endTime).format("DD MMMM YYYY")}
              </span>
            </div>
            <div className="flight-post-detail-box">
              <span className="label">Pilot :</span>
              <span className="value">
                {flightPostDetail?.pilot?.firstName} {flightPostDetail?.pilot?.lastName}
              </span>
            </div>
            <div className="flight-post-detail-box">
              <span className="label">Drone :</span>
              <span className="value">{flightPostDetail?.drone?.name}</span>
            </div>
          </div>
        </div>
      </div>

      <FlightPostSummary
        totalFlightHour={flightPostDetail?.flightDuration}
        totalDistance={flightPostDetail?.totalDistance}
        totalSprayedArea={flightPostDetail?.totalArea}
        totalSprayedWater={flightPostDetail?.totalSprayWater}
        totalFertilizerSprayed={flightPostDetail?.totalSprayFertilizer}
        areaUnitLabel={areaUnitLabel}
      />
      <ATable
        isLoading={isLoading}
        columns={columns}
        rowClassName="font-size-14"
        data={flightLogList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </FlightPostDetailPageStyled>
  );
};
export default FlightPostDetailPage;
