export const paramsToObject = (params: URLSearchParams) => {
  return Object.fromEntries(new URLSearchParams(params));
};

export const objectToParams = (object: object) => {
  return new URLSearchParams({ ...object });
};

export const objectToSearch = (object: object) => {
  return new URLSearchParams({ ...object }).toString();
};

export const setParams = (key: string, value: string) => (params: URLSearchParams) => {
  params.set(key, value);
  return params;
};
