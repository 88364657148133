import "@ai-and-robotics-ventures/cumulus-ui/dist/cumulus-ui.min.css";
import "@fontsource/kanit/300.css";
import "@fontsource/kanit/400.css";
import "@fontsource/kanit/500.css";
import "@fontsource/kanit/600.css";
import "@fontsource/kanit/700.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import AppRouter from "core/routes";

import "./App.css";
import "./assets/select.css";
import "./pagination.css";

function App() {
  return <AppRouter />;
}

export default App;
