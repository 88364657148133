import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { dayjs } from "core/configs";
import { UploadType } from "core/domains/services/upload";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Pilot } from "types/pilot";

import ASelect from "components/templates/ASelect/ASelect";
import UploadDroneItem from "components/templates/UploadDroneItem/UploadDroneItem";

interface InsuranceFormProps {
  index: number;
  // data: IDataButtonGroup[];
  form: any;
  pilotList?: Pilot[];
  showDronePilot?: boolean;
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  form,
  index,
  pilotList,
  showDronePilot = true,
}) => {
  const { t } = useTranslation("form");

  const { values, errors, touched, setFieldValue, validateForm, handleChange } = form;

  return (
    <div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.insurance[${index}].number`}
            label={t`Insurance Number`}
            placeholder={t`Enter Insurance Number`}
            helperSpace
            required
            value={get(values, `drone.insurance[${index}].number`)}
            onChange={handleChange}
            error={get(errors, `drone.insurance[${index}].number`)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            required
            type="date"
            name={`drone.insurance[${index}].expireDate`}
            label={t`Expiry Date`}
            placeholder={t`Enter Insurance Number`}
            onChange={(e: { target: { value: any } }) => {
              const date = e.target.value;
              const expiryDate = dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
              form.setFieldValue(`drone.insurance[${index}].expireDate`, expiryDate);
            }}
            value={dayjs(get(values, `drone.insurance[${index}].expireDate`))
              .utc()
              .format("YYYY-MM-DD")}
            error={
              get(touched, `drone.insurance[${index}].expireDate`) &&
              get(errors, `drone.insurance[${index}].expireDate`)
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="number"
            name={`drone.insurance[${index}].coverage`}
            label={t`Insurance Coverage (Thai Baht)`}
            placeholder={t`Enter Insurance Coverage`}
            helperSpace
            required
            onChange={handleChange}
            value={get(values, `drone.insurance[${index}].coverage`)}
            error={
              get(touched, `drone.insurance[${index}].coverage`) &&
              get(errors, `drone.insurance[${index}].coverage`)
            }
          />
        </div>
      </div>
      {showDronePilot && (
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <ASelect
              required
              label={t`Drone Pilot`}
              placeholder={t`Select Drone Pilot`}
              options={
                pilotList?.map((item) => {
                  return {
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                  };
                }) || []
              }
              value={get(values, `drone.insurance[${index}].users`)}
              error={
                get(touched, `drone.insurance[${index}].users`) &&
                get(errors, `drone.insurance[${index}].users`)
              }
              onChange={(value) => setFieldValue(`drone.insurance[${index}].users`, value)}
              mode="multiple"
              tokenSeparators={[" ", ","]}
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <UploadDroneItem
            label={t`Photo of Insurance`}
            require
            onChange={(value, urlList) => {
              setFieldValue(`drone.insurance[${index}].fileList`, value);
              setFieldValue(`drone.insurance[${index}].urlList`, urlList);

              setTimeout(() => {
                validateForm();
              }, 100);
            }}
            defaultImageList={get(values, `drone.insurance[${index}].fileList`)}
            defaultFileUrlList={
              get(values, `drone.insurance[${index}].urlList`) ||
              get(values, `drone.insurance[${index}].fileUrls`)
            }
            error={
              get(touched, `drone.insurance[${index}].fileList`) &&
              get(errors, `drone.insurance[${index}].fileList`)
            }
            uploadType={UploadType.INSURANCE}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default InsuranceForm;
