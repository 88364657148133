import { gql } from "urql";

export const MUTATION_CREATE_FLIGHT_POST = gql`
  mutation createFlightPost($flightPostInput: CreateFlightPostSchema!) {
    createFlightPost(flightPostInput: $flightPostInput)
  }
`;

export const MUTATION_UPDATE_FLIGHT_POST = gql`
  mutation updateFlightPost($flightPostId: String!, $flightPostInput: CreateFlightPostSchema!) {
    updateFlightPost(flightPostId: $flightPostId, flightPostInput: $flightPostInput)
  }
`;

export const QUERY_FLIGHT_POST_BY_ID = gql`
  query flightPostById($flightPostId: String!) {
    flightPostById(flightPostId: $flightPostId) {
      id
      name
      description
      startTime
      endTime
      status
      pilotEmail
      createdAt
      farm {
        name
        totalArea
      }
      farmer {
        firstName
        lastName
      }
      drone {
        name
        model
        serialNumber
      }
      pilot {
        firstName
        lastName
      }
      averageSpeed
      totalSprayWater
      totalSprayFertilizer
      totalDistance
      totalArea
      flightDuration
    }
  }
`;

export const QUERY_FLIGHT_LOG_BY_FLIGHT_POST_ID = gql`
  query flightLogByFlightPostId($options: PaginationInput!, $flightPostId: String!) {
    flightLogByFlightPostId(options: $options, flightPostId: $flightPostId) {
      data {
        id
        flightPostId
        logId
        lng
        lat
        heading
        horizontalSpeed
        verticalSpeed
        flightMode
        height
        batteryPercent
        time
        takeoffTime
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
