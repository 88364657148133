import { useTheme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import masterplan, { MASTER_PLAN } from "core/domains/services/masterplanFetch";
import _ from "lodash";
import { useMemo } from "react";

import { Volume } from "./Map";

type Props = {
  isEnabled?: boolean;
  minLatLng?: number[];
  maxLatLng?: number[];
  projectId?: string;
};

const useNoFlyZone = ({ isEnabled = true, minLatLng, maxLatLng, projectId = "all" }: Props) => {
  const theme = useTheme();
  // Get public no fly zones
  const publicNoFlyZonesQuery = useQuery<NoFlyZone.Item[]>(
    [MASTER_PLAN.GET_ALL_PUBLIC_NOFLYZONE, { minLatLng, maxLatLng }],
    () =>
      masterplan
        .post(MASTER_PLAN.GET_ALL_PUBLIC_NOFLYZONE, { minLatLng, maxLatLng })
        .then(({ data }) => data?.rslt?.reservedArea || []),
    { initialData: [], enabled: isEnabled, keepPreviousData: true }
  );

  // Get project no fly zones
  const projectNoFlyZonesQuery = useQuery<NoFlyZone.Item[]>(
    [MASTER_PLAN.GET_ALL_PROJECT_NOFLYZONE_BY_ID, projectId],
    () =>
      masterplan
        .get(
          projectId
            ? MASTER_PLAN.GET_ALL_PROJECT_NOFLYZONE
            : `${MASTER_PLAN.GET_ALL_PROJECT_NOFLYZONE_BY_ID}/${projectId}`
        )
        .then(({ data }) => data?.rslt?.reservedArea || []),
    { initialData: [], enabled: isEnabled, keepPreviousData: true }
  );

  const noFlyZones: (NoFlyZone.Item & { isPublic?: boolean })[] = useMemo(
    () => [
      ...publicNoFlyZonesQuery.data?.map((zone) => ({ ...zone, isPublic: true })),
      ...projectNoFlyZonesQuery.data,
    ],
    [publicNoFlyZonesQuery.data, projectNoFlyZonesQuery.data]
  );

  const volumes: Volume[] = useMemo(
    () =>
      noFlyZones
        .filter((zone) => !_.isEmpty(zone.coordinates))
        .map((zone) => {
          let color = theme.color.graySecondary[900];
          let opacity = 0.6;
          let type = "public-no-fly-zone";
          if (!zone.isPublic) {
            color = theme.color.error[500];
            opacity = 0.5;
            type = "no-fly-zone";
          }
          return {
            id: zone._id,
            coordinates: zone.coordinates[0],
            color,
            opacity,
            type,
            ceiling: 1, // TODO: recieve ceiling from api
            floor: 0,
            showOutline: true,
            outlineColor: color,
          };
        }),
    [noFlyZones, theme]
  );

  return { publicNoFlyZonesQuery, projectNoFlyZonesQuery, noFlyZones, volumes };
};

export default useNoFlyZone;
