import { Button, Loading } from "@ai-and-robotics-ventures/cumulus-ui";
import {
  MUTATION_ADMIN_UPDATE_ADMIN_SERVICE_PROVIDER,
  QUERY_ADMIN_GET_ADMIN_SERVICE_PROVICE_BY_ID,
  QUERY_ADMIN_SERVICE_PROVIDER_LIST,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { editAdminSchema } from "core/schema/admin";
import { decrypt } from "core/utils/crypto";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  TAdminAdminSpByEmailResponse,
  TAdminSpProfile,
  TAdminSpProfileListResponse,
  TServiceProviderProfile,
} from "types/admin";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { UserIcon, UserIconSelected } from "components/templates/Icon";

import AdminStep from "../AddAdminServiceProviderPage/AdminStep/AdminStep";
import EditPilotProfilePageStyled from "../EditPilotProfilePage/EditPilotProfilePage.styled";

const AdminEditAdminServiceProviderProfilePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(["admin", "common"]);

  const [step, setStep] = useState<string>("admin");
  const [profileData, setProfileData] = useState<TAdminSpProfile>();
  const [serviceProviderList, setServiceProviderList] = useState<TServiceProviderProfile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const formik = useFormik<any>({
    initialValues: profileData || {},
    validationSchema: toFormikValidationSchema(editAdminSchema),
    enableReinitialize: true,
    onSubmit: (values) => handleUpdateProfile(values),
  });

  const dataStep = [
    {
      label: t`Admin`,
      key: "admin",
      selectedIcon: <UserIconSelected />,
      notSelectedIcon: <UserIcon />,
    },
  ];

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const variables = {
            userId: id,
          };
          const response: TAdminAdminSpByEmailResponse = await client.request(
            QUERY_ADMIN_GET_ADMIN_SERVICE_PROVICE_BY_ID,
            variables
          );

          const adminSp = {
            ...response.adminGetAdminServiceProviderById,
            email: decrypt(response.adminGetAdminServiceProviderById.email),
            nationalIdNo: decrypt(response.adminGetAdminServiceProviderById.nationalIdNo),
            phoneNo: decrypt(response.adminGetAdminServiceProviderById.phoneNo),
          };

          setProfileData(adminSp);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };
      setIsLoading(true);
      fetchData();
    } else {
      navigate("/admin-service-provider-list");
    }
  }, []);

  const fetchServiceProviderList = async () => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
      };
      const response: TAdminSpProfileListResponse = await client.request(
        QUERY_ADMIN_SERVICE_PROVIDER_LIST,
        variables
      );
      setServiceProviderList(response.adminGetServiceProvidersList.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchServiceProviderList();
  }, []);
  const handleUpdateProfile = async (values: any) => {
    try {
      setSubmitIsLoading(true);
      const adminInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        serviceProvider: values.serviceProvider.name,
      };
      const variables = {
        adminServiceProviderProfile: adminInput,
        userId: profileData?.id,
      };
      await client.request(MUTATION_ADMIN_UPDATE_ADMIN_SERVICE_PROVIDER, variables);
      successModal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const successModal = useSuccessModal({
    title: t`Admin Edited`,
    detail: (
      <div>
        <div>{t`Admin has been successfully edited`}</div>
      </div>
    ),
    callBack: () => navigate("/admin-service-provider-list"),
  });
  return (
    <EditPilotProfilePageStyled>
      {isLoading && <Loading />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 16px",
          fontWeight: "bold",
          marginTop: 0,
        }}
      >
        <h1>{t`Profile`}</h1>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-profile-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>
            <AdminStep form={formik} serviceProviderList={serviceProviderList} isEdit={true} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/admin-service-provider-list"}>
                <Button className="cancel-button">{t(`Cancel`, { ns: "common" })}</Button>
              </Link>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t(`Save`, { ns: "common" })}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <successModal.SuccessModal />
    </EditPilotProfilePageStyled>
  );
};
export default AdminEditAdminServiceProviderProfilePage;
