import { Icon, IconButton } from "@ai-and-robotics-ventures/cumulus-ui";
import { Constants, useDraw } from "@ai-and-robotics-ventures/map-library";
import { useTheme } from "@emotion/react";
import * as turf from "@turf/turf";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

import { MapSettingStyled } from "components/pages/Map/MapSettings";
import { useDisplayPolygon } from "components/pages/Map/useDisplay";

import { ARVmapPropsAtom, preMapSettingsAtom } from "../../components/pages/Map/Map";

const useDrawPolygon = ({ coor }: { coor: [number, number][] }) => {
  const theme = useTheme();
  const [pointFlightPlan, setPointFlightPlan] = useState<[number, number][]>([
    // [101.43507586306214, 13.00107398634529],
    // [101.43292961586303, 12.990452686681891],
    // [101.45164489144008, 12.991874461795618],
    // [101.45344773908744, 13.002244810464987],
    // [101.43507586306214, 13.00107398634529],
  ]);
  const setPreMapSettings = useSetAtom(preMapSettingsAtom);

  // MAP

  const ARVmapProps = useAtomValue(ARVmapPropsAtom);
  useDisplayPolygon(coor);
  const onDrawChange = (e: any) => {
    // e.collection?.features.forEach((i: any) => {
    //   const point = turf.point(i.geometry.coordinates);
    //   const polygon = turf.polygon([coor]);
    //   if (!turf.booleanPointInPolygon(point, polygon)) {
    //     onDelete({
    //       pointNumber: i.properties.pointNumber,
    //     });
    //   }
    // });
    // console.log(e.collection);
    const coordinateFlightPlan = e.collection?.features.map(
      (item: any) => item.geometry.coordinates
    );
    setPointFlightPlan(coordinateFlightPlan);
  };

  const onDrawing = (e: any) => {
    e.collection?.features.forEach((i: any) => {
      const point = turf.point(i.geometry.coordinates);
      const polygon = turf.polygon([coor]);
      if (!turf.booleanPointInPolygon(point, polygon)) {
        onDelete({
          pointNumber: i.properties.pointNumber,
        });
      }
    });
    console.log("onDrawing", e?.collection);
    // if (e.collection?.features?.length === 0) {

    //   // return handleClearProjectArea();
    //   return handleDrawClear();
    // }
  };

  const [distances] = useMemo(() => {
    const distances = [];
    // const times = [];
    for (let i = 0; i < pointFlightPlan.length - 1; i++) {
      // distance in {meter}
      const distance = turf.distance(pointFlightPlan[i], pointFlightPlan[i + 1], {
        units: "meters",
      });
      // time in {meter per second}
      // let time = distance / ((values.waypoints[i].speed * 1000) / 60 / 60);
      // if (!values.waypoints[i].speed) time = 0;
      distances.push(distance);
      // times.push(time);
    }
    return [distances];
  }, [pointFlightPlan]);
  const { handleDrawClear, handleSetDrawData, mode, onDelete, setMode } = useDraw({
    id: "flight-plan-zone",
    onDrawCreate: onDrawChange,
    onDrawUpdate: onDrawChange,
    onDrawDelete: onDrawChange,
    onDrawing: onDrawing,
    mode: Constants.Modes.Normal,
    drawModes: [Constants.Modes.LineString],
    ...ARVmapProps,
  });

  const onChangeMode = useCallback(() => {
    setMode(mode === "LINE_STRING" ? "NORMAL" : "LINE_STRING");
  }, [mode, setMode]);

  useEffect(() => {
    setPreMapSettings(
      <MapSettingStyled>
        {mode === "LINE_STRING" ? (
          <IconButton
            css={{ color: theme.color.bluePrimary[400] }}
            fullHeight
            fullWidth
            onClick={onChangeMode}
          >
            <Icon size={15} icon="VectorPath" />
          </IconButton>
        ) : (
          <IconButton
            css={{ color: theme.color.grayPrimary[50] }}
            fullHeight
            fullWidth
            onClick={onChangeMode}
          >
            <Icon size={15} icon="VectorPath" />
          </IconButton>
        )}
        <IconButton fullHeight fullWidth onClick={() => onDelete()}>
          <Icon size={15} icon="Trash" />
        </IconButton>
      </MapSettingStyled>
    );
    return () => {
      setPreMapSettings(<></>);
    };
  }, [
    setMode,
    onDelete,
    setPreMapSettings,
    onChangeMode,
    mode,
    theme.color.bluePrimary,
    theme.color.grayPrimary,
    theme.color.error,
  ]);
  return {
    handleDrawClear,
    handleSetDrawData,
    pointFlightPlan,
    distances,
  };
};
export default useDrawPolygon;
