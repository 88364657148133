import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ChartData } from "chart.js";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TAdminFlightInformationDashboardTableData, TSummaryDashboard } from "types/admin";

import LineChart from "components/pages/FarmerReportPage/LineChart";
import ReportCard from "components/pages/FarmerReportPage/ReportCard";
import ATable from "components/templates/ATable/ATable";

import { FlightInformation, FlightStat, chartOption } from "../hook/useReport";

const StyledWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  .content {
    display: flex;
    gap: 24px;
  }

  .card-content {
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    display: flex;
    .pie-content {
      width: 300px;
      height: 300px;
    }
  }

  .chart-flex {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
  }
`;

interface AdminReportProps {
  summaryDashboard: TSummaryDashboard;
  flightStats: FlightStat[];
  totalSprayedChartData: ChartData;
  flightInformationTableLoading: boolean;
  flightInformation?: FlightInformation;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  areaUnitLabel?: string;
}

const AdminReport: React.FC<AdminReportProps> = ({
  summaryDashboard,
  flightStats,
  totalSprayedChartData,
  flightInformationTableLoading,
  flightInformation,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
  areaUnitLabel,
}) => {
  const { t } = useTranslation(["report", "common"]);

  const totalFlight = summaryDashboard.totalFlightInformation;

  const totalSprayed = summaryDashboard.fieldType.reduce(
    (previous, current) => previous + current.count,
    0
  );

  const totalDrone = summaryDashboard.totalDroneCount;

  const columns = [
    {
      title: t`Farm / Field`,
      dataIndex: "area",
      key: "area",
      align: "center" as AlignType,
      width: 336,
    },
    {
      title: `${t`Distance`} (${t(`Km`, { ns: "common" })})`,
      dataIndex: "totalDistance",
      key: "totalDistance",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Sprayed Area`} (${areaUnitLabel})`,
      dataIndex: "sprayedArea",
      key: "sprayedArea",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Sprayed Quantity`} (${t(`L`, { ns: "common" })})`,
      dataIndex: "sprayedQuantity",
      key: "sprayedQuantity",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Spread Quanitity`} (${t(`Kg`, { ns: "common" })})`,
      dataIndex: "spreadQuantity",
      key: "spreadQuantity",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: t`Drone Id`,
      dataIndex: "droneId",
      key: "droneId",
      width: 200,
      align: "center" as AlignType,
    },
    {
      title: t`Date Created`,
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: 50,
      align: "center" as AlignType,
      render: (row: TAdminFlightInformationDashboardTableData) => (
        <Link to={`/drone/flight-detail/${row.id}`}>
          <Icon icon="DotsVertical" />
        </Link>
      ),
    },
  ];

  return (
    <StyledWrapper>
      <div className="content">
        <ReportCard title={t`Total Flight Post`} value={totalFlight}>
          <div className="chart-flex">
            {flightStats.map((stat, index) => {
              return <LineChart key={index} {...stat} />;
            })}
          </div>
        </ReportCard>

        <ReportCard title={t`Total Sprayed Area based on farm type`} value={totalSprayed}>
          <div className="card-content">
            <div className="pie-content">
              <Pie
                data={totalSprayedChartData as ChartData<"pie">}
                options={chartOption}
                width={300}
              />
            </div>
          </div>
        </ReportCard>

        <ReportCard title={t`Total Drone`} value={totalDrone}>
          <div className="chart-flex">
            <LineChart title={t`Total Drone`} value={totalDrone} />
          </div>
        </ReportCard>
      </div>

      <ATable
        isLoading={flightInformationTableLoading}
        columns={columns}
        data={flightInformation?.data}
        totalItems={flightInformation?.totalItems || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </StyledWrapper>
  );
};

export default AdminReport;
