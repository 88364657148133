import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { pilotBatteryDefaultValue } from "core/schema/pilot";
import { batteryBrandList, batteryModelList } from "core/utils/constant";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BatteryForm from "components/pages/AddDronePage/DroneForm/BatteryStep/BatteryForm";
import ASelect from "components/templates/ASelect/ASelect";

interface IBatteryStep {
  noStep: number;
  totalStep: number;
  form: any;
  isReadOnly: boolean;
}

const BatteryStep: React.FC<IBatteryStep> = ({ form, noStep, totalStep }) => {
  const { t } = useTranslation(["form", "drone", "common"]);

  const theme = useTheme();

  const [batteryList, setBatteryList] = useState(form.values.drone.battery);

  const handleAddBattery = () => {
    batteryList.push(pilotBatteryDefaultValue);
    setBatteryList([...batteryList]);
  };

  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} {noStep}/{totalStep}
      </div>
      <h1>{t(`Battery Information`, { ns: "drone" })}</h1>
      <hr />
      {batteryList.map((battery: any, index: number) => (
        <BatteryForm
          key={index}
          form={form}
          index={index}
          batteryList={batteryList}
          setBatteryList={setBatteryList}
        />
      ))}
      {batteryList.length ? <hr /> : <></>}
      <div className="add-more-payload-btn " onClick={handleAddBattery}>
        <Icon icon="Plus" size={24} /> {t`Add Battery`}
      </div>
    </div>
  );
};

export default BatteryStep;
