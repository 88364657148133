import { DeviceTracking } from "core/domains/Map/useDeviceSubscription";
import { atom, useSetAtom } from "jotai";
import moment from "moment";
import { useMemo } from "react";
import Chart from "react-apexcharts";

export const currentTimeAtom = atom<number>(0);

type Props = {
  data: DeviceTracking[];
};

const TrackingChart: React.FC<Props> = ({ data }) => {
  const setCurrentTime = useSetAtom(currentTimeAtom);

  const options = useMemo(
    () => ({
      chart: {
        id: "tracking",
        toolbar: {
          show: false,
        },
        events: {
          mouseMove: (e: any, chartContext: any, config: any) => {
            const time = config.globals.categoryLabels[config.dataPointIndex];
            if (time) setCurrentTime(Number(moment(time, "DD MMM YYYY HH:mm:ss").format("x")));
          },
        },
      },
      colors: ["#FFBF5F", "#6D87E6"],
      xaxis: {
        categories: data.map((d) => d.timestamp),
        labels: {
          show: false,
          formatter: (value: string) => {
            return moment(value, "x").format("DD MMM YYYY HH:mm:ss");
          },
        },
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: [
        {
          title: {},
          labels: {
            formatter: (value: number) => {
              return `${value.toFixed(0)} m/s`;
            },
            style: { colors: "#909090" },
          },
        },
        {
          opposite: true,
          title: {},
          labels: {
            formatter: (value: number) => {
              return `${value.toFixed(0)} m`;
            },
            style: { colors: "#909090" },
          },
        },
      ],
      legend: {
        position: "top",
        labels: { colors: "#909090" },
      } as any, //ApexLegend,
      tooltip: { theme: "dark" },
      grid: { show: false },
    }),
    [data, setCurrentTime]
  );
  const series = useMemo(
    () => [
      {
        name: "Speed",
        data: data.map((d) => d.horizontal_speed),
      },
      {
        name: "Altitude",
        data: data.map((d) => d.altitude),
      },
    ],
    [data]
  );

  return <Chart options={options} series={series} type="line" width="100%" height="100%" />;
};

export default TrackingChart;
