import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { pilotInsuranceDefaultValue } from "core/schema/drone";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pilot } from "types/pilot";

import { useAuth } from "components/pages/AuthPage/store/auth";

import InsuranceForm from "./InsuranceForm";

interface InsuranceStepProps {
  // selected: string;
  // data: IDataButtonGroup[];
  noStep: number;
  totalStep: number;
  isReadOnly: boolean;
  form: any;
  pilotList?: Pilot[];
  showDronePilot?: boolean;
}

const InsuranceStep: React.FC<InsuranceStepProps> = ({
  form,
  noStep,
  totalStep,
  pilotList,
  showDronePilot,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation(["drone", "common"]);
  const theme = useTheme();

  const [insuranceList, setInsuranceList] = useState(form.values.drone.insurance);

  const handleAddInsurance = () => {
    const insurancePayload = showDronePilot
      ? pilotInsuranceDefaultValue
      : { ...pilotInsuranceDefaultValue, users: [user?.userId] };

    insuranceList.push(insurancePayload);
    setInsuranceList([...insuranceList]);
  };

  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} {noStep}/{totalStep}
      </div>
      <h1>{t`Insurance Information`}</h1>
      <hr />

      {insuranceList.map((_: any, index: number) => {
        return (
          <Fragment key={index}>
            <InsuranceForm
              form={form}
              key={index}
              index={index}
              pilotList={pilotList}
              showDronePilot={showDronePilot}
            />
            {insuranceList.length !== 1 && insuranceList.length !== index + 1 && <hr />}
          </Fragment>
        );
      })}

      {insuranceList.length ? <hr /> : <></>}

      <div className="add-more-payload-btn" onClick={handleAddInsurance}>
        <Icon icon="Plus" size={24} /> {t`Add Insurance`}
      </div>
    </div>
  );
};

export default InsuranceStep;
