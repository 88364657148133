import styled from "@emotion/styled";

const AdminSignupPageStyled = styled.div`
  background: #29371c;
  backdrop-filter: blur(10px);

  .form {
    border-radius: 12px;
    background: #414221;
    height: 100%;
    padding: 40px 16px 20px 0;
    min-height: calc(100vh - 210px);
    position: relative;
    .step-section {
      position: absolute;
      left: 100px;
      top: 0;
    }
  }
`;

export default AdminSignupPageStyled;
