import { FC } from "react";
import { Pilot } from "types/pilot";

import BatteryStep from "./BatteryStep/BatteryStep";
import ConfigurationFileStep from "./ConfigurationFileStep/ConfigurationFileStep";
import DroneFormStyled from "./DroneForm.styled";
import DroneStep from "./DroneStep/DroneStep";
import InsuranceStep from "./InsuranceStep/InsuranceStep";
import PayloadStep from "./PayloadStep/PayloadStep";

interface IDroneForm {
  step: string;
  form: any;
  totalStep: number;
  isEdit?: boolean;
  isReadOnly?: boolean;
  pilotList: Pilot[];
  isLeased?: boolean;
  handleLeaseChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCreatePilotSuccess: () => void;
  onSearchPilot: (value: string) => void;
}

const DroneForm: FC<IDroneForm> = ({
  step,
  form,
  totalStep,
  isReadOnly = false,
  isEdit,
  pilotList,
  isLeased,
  handleLeaseChange,
  onCreatePilotSuccess,
  onSearchPilot,
}) => {
  const renderStep = (step: string) => {
    switch (step) {
      case "Drone":
        return (
          <DroneStep
            pilotList={pilotList}
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 4}
            handleLeaseChange={handleLeaseChange}
            isLeased={isLeased}
            isEdit={isEdit}
            onCreatePilotSuccess={onCreatePilotSuccess}
            onSearchPilot={onSearchPilot}
          />
        );
      case "Payload":
        return (
          <PayloadStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 2}
          />
        );
      case "Battery":
        return (
          <BatteryStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 1}
          />
        );
      case "Insurance":
        return (
          <InsuranceStep
            pilotList={pilotList}
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 3}
          />
        );
      case "ConfigFile":
        return (
          <ConfigurationFileStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep}
            isEdit={isEdit}
          />
        );
    }
  };
  return <DroneFormStyled>{renderStep(step)}</DroneFormStyled>;
};

export default DroneForm;
