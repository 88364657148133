import { Button, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { dayjs } from "core/configs";
import { MUTATION_UPDATE_DRONE_LEASING } from "core/gql/drone";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { droneLeasingSchema } from "core/schema/drone";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { toFormikValidationSchema } from "zod-formik-adapter";

const StyledWrapper = styled.div`
  .form {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
  }

  .form-item {
    display: flex;
    align-items: center;
  }

  .date-wrapper {
    display: "flex";
    gap: 24px;
    margin-bottom: 24px;
    cursor: "pointer";
    max-width: 600px;
  }

  .footer-group {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .cancel-button {
      background: #fff;
      color: rgb(65, 66, 33);
    }
  }
`;

interface LeasingFormProps {
  droneId?: string;
  leasing?: {
    expiryDate: string;
    status: boolean;
  };
  onClose: () => void;
  onSuccess: () => void;
}

const LeasingForm: React.FC<LeasingFormProps> = ({ droneId, leasing, onClose, onSuccess }) => {
  const { t } = useTranslation(["form", "drone", "common"]);

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const modal = useSuccessModal({
    title: t(`Leased Drone Edited`, { ns: "drone" }),
    detail: (
      <>
        <div>{t(`Your leased drone has been successfully edited`, { ns: "drone" })}</div>
      </>
    ),
    callBack: () => onSuccess(),
  });

  const formik = useFormik<any>({
    initialValues: leasing,
    validationSchema: toFormikValidationSchema(droneLeasingSchema),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitLoading(true);

      const variables = {
        droneLeasingInput: {
          droneId,
          expiryDate: values.expiryDate,
          status: values.status,
        },
      };

      await client.request(MUTATION_UPDATE_DRONE_LEASING, variables);

      modal.openModal();
      setIsSubmitLoading(false);
    } catch (error) {
      setIsSubmitLoading(false);

      console.error("Error creating item:", error);
    }
  };

  return (
    <StyledWrapper>
      <h1>{t(`Leasing Information`, { ns: "drone" })}</h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="form">
          <div className="form-item">
            <input
              type="checkbox"
              onChange={(e) => formik.setFieldValue("status", e.target.checked)}
              checked={formik.values.status}
              style={{ marginRight: "8px", transform: "scale(1.5)" }}
            />
            <label>{t`Lease this drone`}</label>
          </div>

          {formik.values.status && (
            <>
              <div className="date-wrapper">
                <InputField
                  required={formik.values.status}
                  type="date"
                  name="expiryDate"
                  label={t`Expiry Date`}
                  placeholder={t`Select expiry date`}
                  onChange={(e: { target: { value: any } }) => {
                    const date = e.target.value;
                    const expiryDate = dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
                    formik.setFieldValue("expiryDate", expiryDate);
                  }}
                  value={
                    formik.values.expiryDate
                      ? dayjs(formik.values.expiryDate).utc().format("YYYY-MM-DD")
                      : ""
                  }
                  error={get(formik, "touched.expiryDate") && get(formik, "errors.expiryDate")}
                />
              </div>
            </>
          )}

          <div className="footer-group">
            <Button type="button" className="cancel-button" onClick={onClose}>
              {t(`Cancel`, { ns: "common" })}
            </Button>

            <Button type="submit" className="register-button-active">
              {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
              {t(`Submit`, { ns: "common" })}
            </Button>
          </div>
        </div>
      </form>

      <modal.SuccessModal />
    </StyledWrapper>
  );
};

export default LeasingForm;
