import { z } from "zod";

export const configFileSchema = z
  .object({
    fileList: z
      .array(
        z.object({
          ext: z.string(),
          uid: z.string(),
          file_name: z.string(),
          unique_id: z.string(),
        })
      )
      .nullable()
      .optional(),
    changeLogString: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const addDroneSchema = z.object({
  drone: z.object({
    name: z.string({
      required_error: "Drone Number is a required field",
    }),
    objective: z.string().optional(),
    description: z.string().optional(),
    droneType: z.string({
      required_error: "droneType Date is a required field",
    }),
    brand: z.string({
      required_error: "brand Date is a required field",
    }),
    serialNumber: z.string({
      required_error: "serialNumber is a required field",
    }),
    model: z.string({
      required_error: "model is a required field",
    }),
    weight: z.string({
      required_error: "weight is a required field",
    }),
    extentions: z.string().optional(),
    images: z.object({
      top: z
        .array(
          z.object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
        )
        .refine((array) => array.length > 0, {
          message: "Please upload image",
        }),
      left: z
        .array(
          z.object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
        )
        .refine((array) => array.length > 0, {
          message: "Please upload image",
        }),
      bottom: z
        .array(
          z.object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
        )
        .refine((array) => array.length > 0, {
          message: "Please upload image",
        }),
      right: z
        .array(
          z.object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
        )
        .refine((array) => array.length > 0, {
          message: "Please upload image",
        }),
    }),
    // Insurance
    insurance: z.array(
      z.object({
        number: z.string({
          required_error: "Insurance number is a required field",
        }),
        coverage: z.number({
          required_error: "Coverage is a required field",
        }),
        expireDate: z.string({
          required_error: "Expire date is a required field",
        }),
        fileList: z
          .array(
            z.object({
              ext: z.string(),
              uid: z.string(),
              file_name: z.string(),
              unique_id: z.string(),
            })
          )
          .refine((array) => array.length > 0, {
            message: "Please upload an image",
          }),
        users: z.array(z.string()),
      })
    ),
    // Leased
    leased: z
      .object({
        status: z.boolean().optional(),
        expiryDate: z.string({ required_error: "expiryDate is a required field" }),
      })
      .optional(),
    config_file: configFileSchema,
    // // battery
    // battery: z
    //   .object({
    //     brand: z
    //       .string({
    //         required_error: "brand is a required field",
    //       })
    //       .optional(),
    //     model: z
    //       .string({
    //         required_error: "model is a required field",
    //       })
    //       .optional(),
    //     amount: z
    //       .string({
    //         required_error: "amount is a required field",
    //       })
    //       .optional(),
    //     capacity: z
    //       .string({
    //         required_error: "capacity is a required field",
    //       })
    //       .optional(),
    //   })
    //   .optional(),

    // // Payload
    // payload: z
    //   .object({
    //     type: z
    //       .string({
    //         required_error: "type is a required field",
    //       })
    //       .optional(),
    //     name: z
    //       .string({
    //         required_error: "name is a required field",
    //       })
    //       .optional(),
    //     brand: z
    //       .string({
    //         required_error: "brand is a required field",
    //       })
    //       .optional(),
    //     model: z
    //       .string({
    //         required_error: "model is a required field",
    //       })
    //       .optional(),
    //     specification: z
    //       .string({
    //         required_error: "specification is a required field",
    //       })
    //       .optional(),
    //   })
    //   .array()
    //   .optional(),
  }),
});

export const droneLeasingSchema = z.object({
  status: z.boolean(),
  expiryDate: z.string({ required_error: "expiryDate is a required field" }),
});

export const pilotPayloadDefaultValue = {
  type: "",
  name: "",
  brand: "",
  model: "",
  specification: "",
};

export const pilotBatteryDefaultValue = {
  serialNumber: "",
  brand: "",
  model: "",
  capacity: "",
};

export const pilotInsuranceDefaultValue = {
  number: "",
  coverage: NaN,
  expireDate: "",
  fileList: [],
};

export const addDroneDefaultValue = {
  drone: {
    name: "",
    description: "",
    droneType: "",
    brand: "",
    serialNumber: "",
    model: "",
    weight: "",
    extentions: "-",
    objective: "-",
    images: {
      top: [],
      left: [],
      bottom: [],
      right: [],
    },
    battery: [pilotBatteryDefaultValue],
    insurance: [pilotInsuranceDefaultValue],
    payload: [pilotPayloadDefaultValue],
  },
};
