import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_ADD_FARM } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { farmDefaultValue, farmSchema } from "core/schema/farm";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { FarmIconSelected, FarmIconWhite } from "components/templates/Icon";

import FarmStep from "../FarmerSignupPage/FarmStep/FarmStep";
import AddFarmPageStyled from "./AddFarmPage.styled";

const AddFarmPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Farm");

  const modal = useSuccessModal({
    title: "New Farm Added",
    detail: (
      <>
        <div>Your farm has been successfully </div>
        <div>added to the platform</div>
      </>
    ),
    callBack: () => navigate("/farm-list"),
  });
  const formik = useFormik<any>({
    initialValues: farmDefaultValue,
    validationSchema: toFormikValidationSchema(farmSchema),
    onSubmit: (values) => handleAddFarm(values),
  });
  const handleClickCancel = () => {
    formik.resetForm();
    navigate("/farm-list");
  };

  const handleAddFarm = async (values: any) => {
    try {
      const addImageInput = values.farms.map((f: any) => {
        delete f.urlList;
        return f;
      });

      const variables = {
        farm: addImageInput,
      };
      await client.request(MUTATION_ADD_FARM, variables);
      modal.openModal();
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  return (
    <AddFarmPageStyled>
      <div style={{ display: "flex", alignItems: "center" }} onClick={handleClickCancel}>
        <Icon icon="ChevronLeft" />
        <h3>Add Farm</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-farm-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Farm",
                    selectedIcon: <FarmIconSelected />,
                    notSelectedIcon: <FarmIconWhite />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <FarmStep
              form={formik}
              totalStep="1"
              noStep="1"
              setDisableChangeStep={() => console.log()}
            />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Button className="cancel-button" onClick={handleClickCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                Add Farm
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AddFarmPageStyled>
  );
};

export const Component = AddFarmPage;
export default AddFarmPage;
