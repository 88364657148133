import { Button, Modal, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { Image } from "@ai-and-robotics-ventures/cumulus-ui";
import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import ImageLogoLogin from "assets/images/LogoLogin.png";
import { ReactComponent as CloseIcon } from "assets/svgs/close_button.svg";
import { ReactComponent as FarmerIcon } from "assets/svgs/farmer_icon.svg";
import { ReactComponent as PilotIcon } from "assets/svgs/pilot_icon.svg";
import { client } from "core/gql/fetchgql";
import { MUTATION_LOGIN } from "core/gql/login";
import { useFormik } from "formik";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TUserInfoResponse } from "types/user";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import packageJson from "../../../../package.json";
import LoginPageStyled from "./LoginPage.styled";
import { useAuth } from "./store/auth";

export async function loader() {
  return null;
}

const loginSchema = z.object({
  email: z
    .string({
      required_error: "Email is a required field",
    })
    .email("Email is not valid"),
  password: z.string({
    required_error: "Password is a required field",
  }),
});
type LoginForm = z.infer<typeof loginSchema>;

const LoginPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [mutationResult, executeMutation] = useMutation(MUTATION_LOGIN);

  const {
    user,
    actions: { login, logout },
  } = useAuth();

  const form = useFormik<LoginForm>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: toFormikValidationSchema(loginSchema),
    onSubmit: (values) => handleOnSubmitLogin(values),
  });

  const handleOnSubmitLogin = async (values: LoginForm) => {
    try {
      const variables = {
        input: values,
      };
      setIsLoading(true);
      const result: any = await client.request(MUTATION_LOGIN, variables);
      login(result.login.token);
      setIsLoading(false);
    } catch (error: any) {
      console.error("Error creating item:");
      setIsLoading(false);
      if (get(error, "response.errors[0].path[0]") === "login") {
        toast({
          title: "",
          description: "Incorrect email or password. Please try again.",
          type: "error",
        });
      } else {
        toast({
          title: "",
          description: "Something errors. Please try again.",
          type: "error",
        });
      }
    }
  };

  // const loginMutate = useMutation(
  //   ({ email, password }: LoginForm) => {
  //     const browser = bowser.getParser(navigator.userAgent);
  //     return authFetch.post(AUTH.POST_LOGIN, {
  //       email,
  //       password,
  //       applicationId: process.env.REACT_APP_UTM_APP_ID,
  //       metadata: {
  //         browser: browser.getBrowser().name,
  //         os: browser.getOS().name,
  //         ipaddress: window.location.origin,
  //       },
  //     });
  //   },
  //   {
  //     onSuccess: (response) => {
  //       if (response.data?.response?.token) {
  //         const user = response.data?.response?.user;
  //         login(
  //           { email: user?.email, ...user },
  //           response.data?.response?.token,
  //           response.data?.response?.refreshToken
  //         );
  //         navigate("/");
  //       } else {
  //         alert("The user was not found or the password was incorrect.");
  //       }
  //     },
  //   }
  // );

  useEffect(() => {
    if (user) {
      if (user.exp > Date.now() / 1000) {
        if (user.roles.includes("FARMER")) {
          navigate("/farm-list");
        }
        if (user.roles.includes("PILOT")) {
          navigate("/flight-post");
        }
        if (user.roles.includes("ADMIN")) {
          navigate("/admin-service-provider-list");
        }
        if (user.roles.includes("ADMIN_SERVICE_PROVIDER")) {
          navigate("/flight-post");
        }
        if (user.roles.includes("LEASING")) {
          navigate("/leasing-list");
        }
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [user]);

  useEffect(() => {
    console.log(packageJson.version);
  }, []);
  return (
    <LoginPageStyled>
      <div className="container">
        <Image src={ImageLogoLogin} width="80" height={150} />
        <form onSubmit={form.handleSubmit}>
          <div className="login-card">
            <InputField
              type="text"
              name="email"
              label="Email"
              placeholder="Enter Email"
              onChange={form.handleChange}
              helperSpace
              error={form.touched.email && form.errors.email}
            />
            <InputField
              type="password"
              name="password"
              label="Password"
              placeholder="Enter Password"
              onChange={form.handleChange}
              helperSpace
              error={form.touched.password && form.errors.password}
            />
            {/* <label className="checkbox-remember cursor-pointer">
              <input type="checkbox" />
              <span className="checkbox-label">Remember me</span>
            </label> */}
            <Button type="submit" fullWidth>
              {isLoading && <ClipLoader size={16} color="#36d7b7" />}
              Login
            </Button>
          </div>
        </form>
        <div className="sign-up-section">
          <div>
            <span
              style={{
                color: theme.color.gray[700],
                fontWeight: 400,
              }}
            >
              Don’t have an account?
            </span>
            <span
              className="font-bold cursor-pointer"
              style={{
                color: theme.color.AiangGrey[900],
                marginLeft: "8px",
              }}
              onClick={() => setShowSignUpModal(true)}
            >
              Sign up
            </span>
          </div>
          {/* <div
            className="font-bold cursor-pointer"
            style={{
              marginTop: "24px",
              color: theme.color.AiangGrey[900],
            }}
          >
            Forgot password?
          </div> */}
        </div>
        <div style={{ color: "transparent", position: "absolute", bottom: 0, left: 0 }}>
          {packageJson?.version}
        </div>
      </div>
      <Modal
        className="sign-up-modal"
        isOpen={showSignUpModal}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "400px",
            width: "500px",
            borderRadius: "20px",
            padding: "60px",
            border: "1px solid #3E4754",
            background: "#414221",
            boxShadow: "4px 4px 50px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "24px" }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "24px" }}
          >
            <div
              style={{ color: "white", fontSize: "24px", lineHeight: "32px", fontWeight: "bold" }}
            >
              Select User Type
            </div>
            <div
              style={{
                color: theme.color.grayPrimary[500],
                fontSize: "16px",
                lineHeight: "28px",
                opacity: "0.5",
              }}
            >
              To continue, select your role in this project
            </div>
          </div>

          <div style={{ color: "white", display: "flex", alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                width: "200px",
                height: "200px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Link to="/farmer-register" style={{ textDecoration: "inherit", color: "white" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "80px",
                      height: "80px",
                      padding: "8px",
                      alignItems: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <FarmerIcon />
                  </div>
                  <div>Farmer</div>
                </div>
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                width: "200px",
                height: "200px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderLeft: "1px solid #6B7280",
                cursor: "pointer",
              }}
            >
              <Link to="/pilot-register" style={{ textDecoration: "inherit", color: "white" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <PilotIcon />
                  Pilot
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div
          onClick={() => setShowSignUpModal(false)}
          style={{
            position: "absolute",
            right: "24px",
            top: "24px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </div>
      </Modal>
    </LoginPageStyled>
  );
};

export const Component = LoginPage;
export default LoginPage;
