import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as BatteryNotSelectedIcon } from "assets/svgs/battery_icon_not_selected.svg";
import { ReactComponent as BatterySelectedIcon } from "assets/svgs/battery_icon_selected.svg";
import { ReactComponent as InsuranceNotSelectedIcon } from "assets/svgs/insurance_icon_not_selected.svg";
import { ReactComponent as InsuranceSelectedIcon } from "assets/svgs/insurance_icon_selected.svg";
import { ReactComponent as PayloadNotSelectedIcon } from "assets/svgs/payload_icon_not _selected.svg";
import { ReactComponent as PayloadSelectedIcon } from "assets/svgs/payload_icon_selected.svg";
import { QUERY_ADMIN_PILOT_ALL_LIST } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import { MUTATION_UPDATE_DRONE, QUERY_DRONE_BY_SERIAL_NUMBER } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addDroneSchema } from "core/schema/drone";
import { droneDefaultValue } from "core/schema/pilot";
import { decrypt } from "core/utils/crypto";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminPilotProfileResponse } from "types/admin";
import { DroneInput, Insurance } from "types/drone";
import { Pilot, TDroneItemResponse, TDroneResponse } from "types/pilot";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { SettingIconNotSelected, SettingIconSelected } from "components/templates/Icon";
import Loading from "components/templates/Loading/Loading";

import DroneForm from "../AdminAddDronePage/DroneForm/DroneForm";
import AdminEditDronePageStyled from "./AdminEditDronePage.styled";

const AdminEditDronePage = () => {
  const { t } = useTranslation(["drone", "common"]);

  const navigate = useNavigate();
  const { serialNumber } = useParams();
  const [step, setStep] = useState<string>("Drone");
  const [droneDetail, setDroneDetail] = useState<TDroneResponse>({
    drone: droneDefaultValue,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [isLeased, setIsLeased] = useState<boolean>();
  const [pilotList, setPilotList] = useState<Pilot[]>([]);

  const modal = useSuccessModal({
    title: t`Drone Edited`,
    detail: (
      <>
        <div>{t`Your drone has been successfully edited`}</div>
      </>
    ),
    callBack: () => navigate("/drone-list"),
  });

  const formik = useFormik<any>({
    initialValues: droneDetail,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(addDroneSchema),
    onSubmit: (values) => handleOnUpdateDrone(values),
  });

  const fetchPilotList = async (search?: string) => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage: 1,
          itemsPerPage: 100,
          search,
          searchBy: ["first_name", "lastName", "national_id_no"],
          alias: "user",
        },
      };
      const response: TAdminPilotProfileResponse = await client.request(
        QUERY_ADMIN_PILOT_ALL_LIST,
        variables
      );

      const pilotOptions = response.adminGetPilots.data?.map((item) => {
        return {
          id: item.userId,
          firstName: item.firstName,
          lastName: item.lastName,
          nationalIdNo: decrypt(item.nationalIdNo),
        };
      });

      setPilotList(pilotOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const variables = {
        serialNumber: serialNumber,
      };
      const response: TDroneItemResponse = await client.request(
        QUERY_DRONE_BY_SERIAL_NUMBER,
        variables
      );

      setDroneDetail({
        drone: response.droneBySerialNumber,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([fetchPilotList(), fetchData()]);
      setIsLoading(false);
    };

    loadData();
  }, [serialNumber]);

  useEffect(() => {
    if (droneDetail) {
      const leasing = droneDetail.drone.leasing;

      if (leasing) {
        if (leasing.status === true && leasing.expiryDate) {
          setIsLeased(true);
          formik.setFieldValue("drone.leased.status", true);
          formik.setFieldValue("drone.leased.expiryDate", leasing.expiryDate);
        } else {
          setIsLeased(false);
          formik.setFieldValue("drone.leased.status", false);
          formik.setFieldValue("drone.leased.expiryDate", leasing.expiryDate);
        }
      } else {
        setIsLeased(false);
        formik.setFieldValue("drone.leased", undefined);
      }
    }
  }, [droneDetail]);

  const handleLeaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsLeased(isChecked);

    if (!isChecked) {
      //if uncheck and it is not leasing
      if (!droneDetail?.drone?.leasing) {
        formik.setFieldValue("drone.leased", undefined);
      }
      //if uncheck and it is leasing
      else {
        formik.setFieldValue("drone.leased.status", false);
        formik.setFieldValue("drone.leased.expiryDate", droneDetail?.drone?.leasing?.expiryDate);
      }
    } else {
      //if checked of both leasing and not leasing condition
      const status = isChecked;
      formik.setFieldValue("drone.leased.status", status);
    }
  };

  const handleOnUpdateDrone = async (values: any) => {
    try {
      setSubmitIsLoading(true);

      const updatedObject: {
        droneInput: DroneInput;
        droneId: string;
      } = {
        droneInput: {
          name: values.drone.name,
          description: values.drone.description,
          droneType: values.drone.droneType,
          brand: values.drone.brand,
          serialNumber: values.drone.serialNumber,
          objective: values.drone.objective || "-",
          model: values.drone.model,
          weight: values.drone.weight,
          extentions: values.drone.extentions,
          images: values.drone.images,
          battery: values.drone.battery,
          insurance: values.drone.insurance?.map((insurance: Insurance) => ({
            id: insurance.id,
            number: insurance.number,
            coverage: insurance.coverage,
            fileList: insurance.fileList,
            expireDate: insurance.expireDate,
            users: insurance.users,
          })),
          payload: values.drone.payload,
          userId: values.drone.userId,
        },
        droneId: values.drone.id,
      };
      // Check if changeLog is an empty string and set it to null
      if (values.drone.config_file) {
        if (values.drone.config_file.changeLogString === "") {
          values.drone.config_file.changeLogString = null;
        }
        updatedObject.droneInput.config_file = {
          fileList: values.drone.config_file.fileList,
          changeLog: values.drone.config_file.changeLogString,
        };
      }
      if (values.drone.leased) {
        updatedObject.droneInput.leased = {
          expiryDate: values.drone.leased.expiryDate,
          status: values.drone.leased.status,
        };
      }

      await client.request(MUTATION_UPDATE_DRONE, updatedObject);
      setSubmitIsLoading(false);

      modal.openModal();
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const handleCreatePilotSuccess = () => {
    fetchPilotList();
  };

  const handleSearchPilot = (value: string) => {
    fetchPilotList(value);
  };

  return (
    <AdminEditDronePageStyled>
      {isLoading && <Loading />}
      <Link to="/drone-list">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon="ChevronLeft" />
          <h3>{t`Drone`}</h3>
        </div>
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-drone-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: t`Drone`,
                    key: "Drone",
                    selectedIcon: <Icon icon="Drone" />,
                    notSelectedIcon: <Icon icon="Drone" />,
                  },
                  {
                    label: t`Insurance`,
                    key: "Insurance",
                    selectedIcon: <InsuranceSelectedIcon />,
                    notSelectedIcon: <InsuranceNotSelectedIcon />,
                  },
                  {
                    label: t`Payload`,
                    key: "Payload",
                    selectedIcon: <PayloadSelectedIcon />,
                    notSelectedIcon: <PayloadNotSelectedIcon />,
                  },
                  {
                    label: t`Battery`,
                    key: "Battery",
                    selectedIcon: <BatterySelectedIcon />,
                    notSelectedIcon: <BatteryNotSelectedIcon />,
                  },
                  {
                    label: t`Config File`,
                    key: "ConfigFile",
                    selectedIcon: <SettingIconSelected />,
                    notSelectedIcon: <SettingIconNotSelected />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>

            <DroneForm
              pilotList={pilotList}
              totalStep={5}
              step={step}
              form={formik}
              isLeased={isLeased}
              handleLeaseChange={handleLeaseChange}
              isEdit={true}
              onCreatePilotSuccess={handleCreatePilotSuccess}
              onSearchPilot={handleSearchPilot}
            />
          </div>
          <div className="footer">
            <div></div>

            <div className="footer-group">
              <Button
                type="button"
                className="cancel-button"
                onClick={() => {
                  window.location.href = "/drone-list";
                }}
              >
                {t(`Cancel`, { ns: "common" })}
              </Button>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t(`Save`, { ns: "common" })}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AdminEditDronePageStyled>
  );
};

export const Component = AdminEditDronePage;
export default AdminEditDronePage;
