import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useMap } from "@ai-and-robotics-ventures/map-library";
import { ReactComponent as SaveIcon } from "assets/svgs/save_icon.svg";
import { client } from "core/gql/fetchgql";
import { QUERY_DISTRICT, QUERY_PROVINCE, QUERY_SUB_DISTRICT } from "core/gql/master";
import { farmTypeList } from "core/utils/constant";
import { get } from "lodash";
import { FC, useEffect, useState } from "react";
import {
  TDistrictItem,
  TDistrictResponse,
  TProvincesItem,
  TProvincesResponse,
  TSubDistrictItem,
  TSubDistrictResponse,
} from "types/master";
import { z } from "zod";

import ASelect from "components/templates/ASelect/ASelect";
import UploadFarmImageList from "components/templates/UploadFarmImageList/UploadFarmImageList";

import MapFarm from "./MapFarm";

interface IAddFarmForm {
  onSaveFarm: (value: any, index: number) => void;
  onCancelForm: (index: number) => void;
  form: any;
  index: number;
}
const addFarmSchema = z.object({
  name: z.string({
    required_error: "name is a required field",
  }),
  farmType: z.string({
    required_error: "farmType is a required field",
  }),
  totalArea: z.number({
    required_error: "totalArea is a required field",
  }),
  imageList: z.string().array().optional().nullable(),
  address: z.string({
    required_error: "address is a required field",
  }),
  provinceCode: z.string({
    required_error: "province is a required field",
  }),
  districtCode: z.string({
    required_error: "district is a required field",
  }),
  subdistrictCode: z.string({
    required_error: "subDistrict is a required field",
  }),
  postcode: z.string({
    required_error: "postcode is a required field",
  }),
  urlList: z.string().array().optional(),

  // completeAddress: z.string({
  //   required_error: "completeAddress is a required field",
  // }),
});
export type TAddFarmForm = z.infer<typeof addFarmSchema>;

const AddFarmForm: FC<IAddFarmForm> = ({ onSaveFarm, onCancelForm, form, index }) => {
  const [provincesList, setProvincesList] = useState<TProvincesItem[]>([]);
  const [districtsList, setDistrictsList] = useState<TDistrictItem[]>([]);
  const [subDistrictsList, setSubDistrictsList] = useState<TSubDistrictItem[]>([]);
  const map = useMap();

  const {
    handleChange,
    values,
    setFieldValue,
    errors,
    validateField,
    setFieldTouched,
    validateForm,
    touched,
  } = form;

  const handleOnChangeMap = (coordinate: [number, number][]) => {
    if (coordinate.length === 0) {
      setFieldValue(`farms[${index}].coordinates`, undefined);
    } else {
      setFieldValue(`farms[${index}].coordinates`, [coordinate]);
    }
  };

  const handleSaveFarm = async (values: any, index: number) => {
    const errorForm = get(errors, `farms[${index}]`);
    if (!errorForm && values?.name) {
      onSaveFarm(values, index);
    } else {
      setFieldTouched(`farms[${index}]`);
    }
  };

  const fetchProvinces = async () => {
    try {
      const response: TProvincesResponse = await client.request(QUERY_PROVINCE);
      setProvincesList(response.provinces);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const variable = {
        provinceCode: get(values, `farms[${index}].provinceCode`),
      };
      const response: TDistrictResponse = await client.request(QUERY_DISTRICT, variable);
      setDistrictsList(response.districtByProvinceCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubDistricts = async () => {
    try {
      const variable = {
        districtCode: get(values, `farms[${index}].districtCode`),
      };
      const response: TSubDistrictResponse = await client.request(QUERY_SUB_DISTRICT, variable);
      setSubDistrictsList(response.subDistrictByDistrictCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  useEffect(() => {
    if (get(values, `farms[${index}].provinceCode`)) {
      fetchDistricts();
    }
  }, [get(values, `farms[${index}].provinceCode`)]);

  useEffect(() => {
    if (get(values, `farms[${index}].districtCode`)) {
      fetchSubDistricts();
    }
  }, [get(values, `farms[${index}].districtCode`)]);

  const handleOnChangeProvince = (value: string) => {
    setFieldValue(`farms[${index}].provinceCode`, value);
    setFieldValue(`farms[${index}].districtCode`, undefined);
    setFieldValue(`farms[${index}].subdistrictCode`, undefined);
    setFieldValue(`farms[${index}].postcode`, "");
    setDistrictsList([]);
    setSubDistrictsList([]);
    setTimeout(() => {
      validateForm();
    }, 100);
  };

  const handleOnChangeDistrict = (value: string) => {
    setFieldValue(`farms[${index}].districtCode`, value);
    setFieldValue(`farms[${index}].subdistrictCode`, undefined);
    setFieldValue(`farms[${index}].postcode`, "");
    setSubDistrictsList([]);
    setTimeout(() => {
      validateForm();
    }, 100);
  };

  const handleOnChangeSubDistrict = (value: string) => {
    setFieldValue(`farms[${index}].subdistrictCode`, value);
    setTimeout(() => {
      validateForm();
    }, 100);
    if (value) {
      const foundPostCode = subDistrictsList.find((item) => item.subDistrictCode === value);
      if (foundPostCode) {
        setFieldValue(`farms[${index}].postcode`, foundPostCode.postCode);
      }
    } else {
      setFieldValue(`farms[${index}].postcode`, "");
    }
  };
  return (
    <div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`farms[${index}].name`}
            label="Farm Name"
            placeholder="Enter Farm Name"
            onChange={handleChange}
            helperSpace
            required
            value={get(values, `farms[${index}].name`)}
            error={get(errors, `farms[${index}].name`)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label="Farm Type"
            required
            placeholder="Select Farm Type"
            options={farmTypeList}
            value={get(values, `farms[${index}].farmType`)}
            onChange={(value) => setFieldValue(`farms[${index}].farmType`, value)}
            error={get(errors, `farms[${index}].farmType`)}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="number"
            name={`farms[${index}].totalArea`}
            label="Total Area"
            placeholder="Enter Total Area"
            helperSpace
            required
            value={get(values, `farms[${index}].totalArea`)}
            onChange={handleChange}
            error={get(errors, `farms[${index}].totalArea`)}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        />
      </div>
      <div>
        <h3>Images</h3>
        <div className="font-300">
          Refer to the sample photos provided, before uploading the Drone and Remote photos.
        </div>
        <div className="font-300">
          Upload only .jpg or .png files with a maximum file size of 5 MB.{" "}
          <span className="font-500">You can upload a maximum of 10 files.</span>
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <h3>Farm</h3>
      </div>
      <div>
        <UploadFarmImageList
          required
          defaultFileUrlList={values.farms[index]?.urlList}
          defaultImageList={values.farms[index]?.imageList}
          onChange={(value, urlList) => {
            setFieldValue(`farms[${index}].imageList`, value);
            setFieldValue(`farms[${index}].urlList`, urlList);
          }}
          error={get(errors, `farms[${index}].imageList`)}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>Location</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name={`farms[${index}].address`}
          label="Location Name"
          placeholder="Enter Location Name"
          helperSpace
          required
          value={get(values, `farms[${index}].address`)}
          onChange={handleChange}
          error={get(errors, `farms[${index}].address`)}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="Province"
            required
            placeholder="Select Province"
            options={provincesList.map((province) => {
              return {
                value: province.provinceCode,
                label: province.name_en,
              };
            })}
            value={get(values, `farms[${index}].provinceCode`)}
            onChange={(value) => handleOnChangeProvince(value)}
            error={get(errors, `farms[${index}].provinceCode`)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label="District"
            required
            placeholder="Select District"
            options={districtsList.map((district) => {
              return {
                value: district.districtCode,
                label: district.name_en,
              };
            })}
            value={get(values, `farms[${index}].districtCode`)}
            onChange={(value) => handleOnChangeDistrict(value)}
            error={get(errors, `farms[${index}].districtCode`)}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="Subdistrict"
            required
            placeholder="Select Subdistrict"
            options={subDistrictsList.map((subDistrict) => {
              return {
                value: subDistrict.subDistrictCode,
                label: subDistrict.name_en,
              };
            })}
            onChange={(value) => handleOnChangeSubDistrict(value)}
            value={get(values, `farms[${index}].subdistrictCode`)}
            error={get(errors, `farms[${index}].subdistrictCode`)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`farms[${index}].postcode`}
            required
            label="Postal Code"
            value={get(values, `farms[${index}].postcode`)}
            onChange={handleChange}
            placeholder="Enter Postal Code"
            error={get(errors, `farms[${index}].postcode`)}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>
          Please draw farm area on map<span style={{ color: "red" }}>*</span>
        </div>
      </div>
      <MapFarm
        map={map}
        defaultCoordinates={get(values, `farms[${index}].coordinates`)}
        onChange={handleOnChangeMap}
      />
      <div className="error-message">
        {get(errors, `farms[${index}].coordinates`) && "Farm area  is a required field"}
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
        <div
          style={{
            display: "flex",
            width: "84px",
            padding: "10px 0px",
            cursor: "pointer",
            justifyContent: "center",
          }}
          onClick={() => onCancelForm(index)}
        >
          Cancel
        </div>
        <button
          type="button"
          onClick={() => handleSaveFarm(values.farms[index], index)}
          className="save-button"
        >
          <SaveIcon />
          Save
        </button>
      </div>
    </div>
  );
};

export default AddFarmForm;
