import { client } from "core/gql/fetchgql";
import { QUERY_MY_DRONE } from "core/gql/pilot";
import { useState } from "react";
import { IMeta } from "types/pagination";
import { IDroneDetail, TDroneListResponse } from "types/pilot";

import DroneListTable from "components/templates/DroneListTable/DroneListTable";

import AdminDroneListPage from "../AdminDroneListPage/AdminDroneListPage";
import { useAuth } from "../AuthPage/store/auth";

const DroneListPage = () => {
  const [droneList, setDroneList] = useState<IDroneDetail[]>([]);
  const [meta, setMeta] = useState<IMeta>();

  const fetchDroneList = async ({
    currentPage,
    pageSize,
    searchName,
  }: {
    currentPage: number;
    pageSize: number;
    searchName: string;
  }) => {
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
        },
      };
      const response: TDroneListResponse = await client.request(QUERY_MY_DRONE, variables);
      setDroneList(response.myDrone.data);
      setMeta(response.myDrone.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return <DroneListTable droneList={droneList} meta={meta} fetchData={fetchDroneList} />;
};

export const Component = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes("ADMIN");
  const isAdminServiceProvider = user?.roles.includes("ADMIN_SERVICE_PROVIDER");

  if (isAdmin || isAdminServiceProvider) {
    return <AdminDroneListPage />;
  } else {
    return <DroneListPage />;
  }
};
export default Component;
