import { Button } from "@ai-and-robotics-ventures/cumulus-ui";
import { Input, Select, Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayjs from "core/configs/dayjs";
import useCalendarPage from "core/domains/CalendarPage/useCalendarPage";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { PageTitle } from "components/templates";

import { useAuth } from "../AuthPage/store/auth";
import { FilterIcon } from "../MissionTemplatePage/components/Filter/StyledFilter";
import CalendarNavigator from "./components/CalendarNavigator";
import EventCard from "./components/EventCard";
import UpcomingMission from "./components/UpcomingMission";

export async function loader() {
  return null;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .fc .fc-timegrid-col.fc-day-today {
    background-color: #1e1e1e;
  }
  .fc .fc-scrollgrid table {
    border-top-style: unset;
  }
  .fc .fc-scroller-liquid-absolute {
    padding-top: 16px;
  }
  .fc-direction-ltr {
    overflow: auto;
    padding-top: 16px;
  }
  .fc-timegrid-event .fc-event-main {
    padding: 0 4px;
  }
  .fc .fc-timegrid-slot-minor {
    border-top: 0 !important;
  }
  .fc .fc-timegrid-slot {
    border-top: 1px solid #757c87;
    border-top-style: dashed;
  }
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 0;
  }
  .fc-timegrid-event-harness {
    margin-left: 20px;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
  }
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    position: relative;
  }
  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    position: absolute;
    top: -22px;
    left: 0;
    padding-right: 16px;
    background-color: #1e1e1e;
  }
  .fc .fc-timegrid-now-indicator-line {
    border-color: #3549ff;
    border-width: 4px 0 0;
  }
  .fc-timegrid-col-frame {
    padding-left: 20px;
  }
  .fc .fc-timegrid-now-indicator-container {
    overflow: inherit;
  }
  .fc .fc-timegrid-now-indicator-arrow {
    background-color: #3549ff;
    margin-top: -10px;
    padding: 4px 16px;
    border-radius: 16px;
    border: 0;
  }
`;

const CalendarWrapper = styled.div`
  width: 100%;
  background-color: #1e1e1e;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
`;

const UpcomingMissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 392px;
  background-color: #1e1e1e;
  padding: 76px 32px 32px 0;
`;

const FilterSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const FilterSelectWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Calendar = () => {
  const theme = useTheme();
  const { accessControl } = useAuth();
  const {
    search,
    selectedDate,
    setSelectedDate,
    setSearch,
    setMission,
    setDevice,
    setMember,

    mission,
    missionOptions,

    device,
    deviceOptions,

    member,
    memberOptions,

    events,
    todayEvents,
    tomorrowEvents,

    getCardSizeByPeriod,
    filterEvents,
  } = useCalendarPage();
  const navigate = useNavigate();
  const calendarRef = useRef<FullCalendar>(null!);

  useEffect(() => {
    // Change calendar date
    calendarRef.current.getApi().gotoDate(selectedDate);

    // Move scroll calendar to now
    const now = dayjs();
    calendarRef.current.getApi().scrollToTime({
      hour: now.hour(),
      minute: now.minute() - 5,
    });
  }, [selectedDate]);

  return (
    <Wrapper>
      <CalendarWrapper>
        <PageTitle title={`Mission Planner`} />
        <FilterSearchWrapper>
          <Input
            css={{ width: "100%", maxWidth: "360px" }}
            suffix={<Icon icon="Magnify" />}
            placeholder="Search Mission, Device, Member"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          {accessControl["TEMPLATE_CREATE"] && (
            <Button
              color="primary"
              onClick={() => navigate("/create-mission-planner")}
              style={{ whiteSpace: "nowrap" }}
            >
              <Icon icon="Plus" /> Cre"ate Mission Planner
            </Button>
          )}
        </FilterSearchWrapper>
        <FilterSelectWrapper>
          <FilterIcon>
            <Icon size={24} icon="Filter" />
          </FilterIcon>
          <Select name="views" options={[{ value: "Week", label: "Week" }]} value={"Week"} />
          <Select name="missions" options={missionOptions} onChange={setMission} value={mission} />
          <Select name="devices" options={deviceOptions} onChange={setDevice} value={device} />
          <Select name="members" options={memberOptions} onChange={setMember} value={member} />
        </FilterSelectWrapper>
        <CalendarNavigator selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          headerToolbar={false}
          allDaySlot={false}
          slotEventOverlap={false}
          expandRows={true}
          nowIndicator={true}
          dayHeaders={false}
          eventBackgroundColor={"transparent"}
          eventBorderColor={"transparent"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            hour12: false,
          }}
          views={{
            timeGridOneDay: {
              type: "timeGrid",
              duration: { days: 1 },
            },
          }}
          slotDuration={"00:01:30"}
          events={filterEvents(events)}
          nowIndicatorContent={(now: any) => {
            if (!now.isAxis) return null;
            return <Text variant="body3">{dayjs(now.date).format("HH:mm")}</Text>;
          }}
          slotLabelContent={(label: any) => (
            <Text variant="body3" css={{ color: theme.color.grayPrimary[600] }}>
              {label.text}
            </Text>
          )}
          eventContent={(events: any) => {
            return (
              <EventCard
                title={events.event.title}
                description={events.event.extendedProps.description}
                image={events.event.extendedProps.image}
                avatars={events.event.extendedProps.avatars}
                size={getCardSizeByPeriod(events.event.start, events.event.end)}
                border={!events.isFuture && !events.isPast}
                status={events.isPast ? "cancelled" : undefined}
              />
            );
          }}
          initialView="timeGridOneDay"
        />
      </CalendarWrapper>
      <UpcomingMissionWrapper>
        <PageTitle title={`My Upcoming Planner ${todayEvents.length + tomorrowEvents.length}`} />
        <UpcomingMission title={`Today (${todayEvents.length})`} events={todayEvents} />
        <UpcomingMission
          title={`Tomorrow • ${dayjs().add(1, "day").format("ddd, DD MMM YYYY")} (${
            tomorrowEvents.length
          })`}
          events={tomorrowEvents}
        />
      </UpcomingMissionWrapper>
    </Wrapper>
  );
};

export const Component = () => {
  const { accessControl } = useAuth();
  if (!accessControl["TEMPLATE_SEARCH"]) return null;

  return <Calendar />;
};
export default Component;
