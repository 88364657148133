import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import ConfigurationFileForm from "./ConfigurationFileForm";

interface ConfigurationFileStepProps {
  noStep: number;
  totalStep: number;
  isReadOnly: boolean;
  form: any;
  isEdit?: boolean;
}

const ConfigurationFileStep: React.FC<ConfigurationFileStepProps> = ({
  form,
  noStep,
  totalStep,
  isEdit,
}) => {
  const { t } = useTranslation(["drone", "common"]);
  const theme = useTheme();

  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} {noStep}/{totalStep}
      </div>
      <h1>{t`Configuration File`}</h1>
      <hr />

      <ConfigurationFileForm form={form} isEdit={isEdit} />
    </div>
  );
};

export default ConfigurationFileStep;
