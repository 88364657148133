import * as turf from "@turf/turf";

export const transformProjectToView = (project?: Project.Item): ProjectView.Item => {
  return {
    id: project?.id || "N/A",
    name: project?.projectName || "N/A",
    description: project?.operationType || "N/A",
    location: "PTIC Wang-Chan Rayong" || "N/A",
    area: project?.status || "N/A",
    totalDevice: project?.devices?.length || 0,
    totalOnMission:
      project?.missions?.filter((mission) => true || mission.status === "ACTIVATED").length || 0,
    totalMember: project?.members?.length || 0,
    imageUrl: project?.projectImagePath,
  };
};

export const transformMissionStatusToView = (status: Mission.Status): MissionView.Status => {
  return (
    {
      PENDING: "Pre-Flight",
      APPROVED: "Pre-Flight",
      REJECTED: "Pre-Flight",
      REVIEWING: "Pre-Flight",
      ACTIVATED: "In-Flight",
      COMPLETED: "Post-Flight",
      EMERGENCY: "Post-Flight",
      ABORTED: "Post-Flight",
    }[status] || "Pre-Flight"
  );
};

export const transformMissionRequestStatusToView = (
  status: Mission.Status
): MissionView.RequestStatus => {
  // [ACTIVATED, COMPLETED, EMERGENCY, ABORTED] don't show in flight request page
  return (
    {
      PENDING: "Request", // TODO: redefine wording with designer to "In progress"
      APPROVED: "Accept",
      REJECTED: "Reject",
      REVIEWING: "Request",
      ACTIVATED: status,
      COMPLETED: status,
      EMERGENCY: status,
      ABORTED: status,
    }[status] || status
  );
};

export const getCenter = (coordinates: number[][]) => {
  try {
    return turf.center(turf.polygon([coordinates]).geometry).geometry.coordinates;
  } catch (err) {
    return coordinates[0];
  }
};

export const numberToAlphabet = (number: number) => String.fromCharCode(64 + number);
