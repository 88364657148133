import { z } from "zod";

export const addPilotSchema = z
  .object({
    firstName: z.string({
      required_error: "firstName is a required field",
    }),
    lastName: z.string({
      required_error: "lastName is a required field",
    }),
    nationalIdNo: z.string({
      required_error: "nationalIdNo is a required field",
    }),
    nationality: z.string({
      required_error: "nationality is a required field",
    }),
    phoneNo: z
      .string({
        required_error: "phoneNo is a required field",
      })
      .min(9, "Phone No must have at least 9 characters")
      .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
    email: z
      .string({
        required_error: "email is a required field",
      })
      .email("Email is not valid"),
    password: z
      .string({
        required_error: "password is a required field",
      })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z.string({
      required_error: "confirmPassword is a required field",
    }),
    license: z.object({
      caatLicenseNo: z.string({
        required_error: "License Number is a required field",
      }),
      caatObjective: z.string({
        required_error: "License Purpose is a required field",
      }),
      caatExpireDate: z.string({
        required_error: "caatExpireDate is a required field",
      }),
      nbtcLicenseNo: z.string({
        required_error: "License Issue Date is a required field",
      }),
      nbtcObjective: z.string({
        required_error: "License Expiry Date is a required field",
      }),
      nbtcExpireDate: z.string({
        required_error: "nbtcExpireDate is a required field",
      }),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const editPilotSchema = z.object({
  firstName: z.string({
    required_error: "firstName is a required field",
  }),
  lastName: z.string({
    required_error: "lastName is a required field",
  }),
  nationalIdNo: z.string({
    required_error: "nationalIdNo is a required field",
  }),
  nationality: z.string({
    required_error: "nationality is a required field",
  }),
  phoneNo: z
    .string({
      required_error: "phoneNo is a required field",
    })
    .min(9, "Phone No must have at least 9 characters")
    .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
  email: z
    .string({
      required_error: "email is a required field",
    })
    .email("Email is not valid"),

  license: z.object({
    caatLicenseNo: z.string({
      required_error: "License Number is a required field",
    }),
    caatObjective: z.string({
      required_error: "License Purpose is a required field",
    }),
    caatExpireDate: z.string({
      required_error: "caatExpireDate is a required field",
    }),
    nbtcLicenseNo: z.string({
      required_error: "License Issue Date is a required field",
    }),
    nbtcObjective: z.string({
      required_error: "License Expiry Date is a required field",
    }),
    nbtcExpireDate: z.string({
      required_error: "nbtcExpireDate is a required field",
    }),
  }),
});

export const addPilotDefaultValue = {
  firstName: "",
  lastName: "",
  nationalIdNo: "",
  nationality: "",
  email: "",
  phoneNo: "",
  license: {
    caatLicenseNo: "",
    caatObjective: "",
    caatExpireDate: "",
    // caatFileList: [],
    nbtcLicenseNo: "",
    nbtcObjective: "",
    nbtcExpireDate: "",
    // nbtcFileList: [],
  },
  password: "",
  confirmPassword: "",
};

export const adminAddFarmerSchema = z
  .object({
    firstName: z.string({
      required_error: "First Name is a required field",
    }),
    lastName: z.string({
      required_error: "LastName is a required field",
    }),
    nationalIdNo: z.string({
      required_error: "National Id is a required field",
    }),
    nationality: z.string({
      required_error: "Nationality is a required field",
    }),
    phoneNo: z
      .string({
        required_error: "Phone No is a required field",
      })
      .min(9, "Phone No must have at least 9 characters")
      .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
    address: z.string({
      required_error: "Address is a required field",
    }),
    // completeAddress: z.string().optional().nullable(),
    password: z
      .string({
        required_error: "password is a required field",
      })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z.string({
      required_error: "confirmPassword is a required field",
    }),
    email: z
      .string({
        required_error: "email is a required field",
      })
      .email("Email is not valid"),
    provinceCode: z.string({
      required_error: "province is a required field",
    }),
    districtCode: z.string({
      required_error: "district is a required field",
    }),
    subdistrictCode: z.string({
      required_error: "subDistrict is a required field",
    }),
    postcode: z.string({
      required_error: "postCode is a required field",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const addFarmerDefaultValue = {
  id: "",
  firstName: "",
  lastName: "",
  nationalIdNo: "",
  email: "",
  nationality: "",
  phoneNo: "",
  address: "",
  provinceCode: "",
  districtCode: "",
  subdistrictCode: "",
  postcode: "",
  // completeAddress: "",
  password: "",
  confirmPassword: "",
};

export const addAdminSchema = z
  .object({
    firstName: z.string({
      required_error: "firstName is a required field",
    }),
    lastName: z.string({
      required_error: "lastName is a required field",
    }),
    nationalIdNo: z.string({
      required_error: "nationalIdNo is a required field",
    }),
    nationality: z.string({
      required_error: "nationality is a required field",
    }),
    phoneNo: z
      .string({
        required_error: "phoneNo is a required field",
      })
      .min(9, "Phone No must have at least 9 characters")
      .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
    email: z
      .string({
        required_error: "email is a required field",
      })
      .email("Email is not valid"),
    password: z
      .string({
        required_error: "password is a required field",
      })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z.string({
      required_error: "confirmPassword is a required field",
    }),
    serviceProvider: z.object({
      name: z.string({
        required_error: "Service provider name is a required field",
      }),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const addAdminDefaultValue = {
  firstName: "",
  lastName: "",
  nationalIdNo: "",
  nationality: "",
  email: "",
  phoneNo: "",
  password: "",
  confirmPassword: "",
  serviceProvider: {
    name: "",
  },
};
export const editAdminSchema = z.object({
  firstName: z.string({
    required_error: "firstName is a required field",
  }),
  lastName: z.string({
    required_error: "lastName is a required field",
  }),
  nationalIdNo: z.string({
    required_error: "nationalIdNo is a required field",
  }),
  nationality: z.string({
    required_error: "nationality is a required field",
  }),
  phoneNo: z
    .string({
      required_error: "phoneNo is a required field",
    })
    .min(9, "Phone No must have at least 9 characters")
    .refine((val) => /^\d+$/.test(val), "Phone No must be a number"),
  email: z
    .string({
      required_error: "email is a required field",
    })
    .email("Email is not valid"),
  serviceProvider: z.object({
    name: z.string({
      required_error: "Service provider name is a required field",
    }),
  }),
});

export const changePasswordDefaultValue = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const changePasswordSchema = z
  .object({
    currentPassword: z.string({ required_error: "Current Password is a required field" }),
    newPassword: z
      .string({ required_error: "New Password is a required field" })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
    confirmPassword: z
      .string({ required_error: "Confirm Password is a required field" })
      .min(8, "Password must have at least 8 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        "Password must include at least 1 letter, 1 number, and 1 symbol"
      ),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "The password confirmation does not match",
    path: ["confirmPassword"],
  })
  .refine((data) => data.newPassword !== data.currentPassword, {
    message: "New password must be different from the current password",
    path: ["newPassword"],
  });
