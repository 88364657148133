import styled from "@emotion/styled";
import { FC } from "react";

interface IFlightPostSummary {
  totalFlightHour?: string;
  totalDistance?: string;
  totalSprayedArea?: string;
  totalSprayedWater?: string;
  totalFertilizerSprayed?: string;
  totalSprayedQuantity?: string;
  totalSpreadQuantity?: string;
  areaUnitLabel?: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 900px;
  height: 88px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #665c4d;

  .group {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    .label {
      color: #f9fafb;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
    }
    .value-unit {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 8px;
      align-self: stretch;
      .value {
        display: flex;
        height: 31px;
        flex-direction: column;
        flex: 1 0 0;
        text-align: right;

        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      .unit {
        color: #9ca3af;
        text-align: right;

        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
      }
    }
  }
`;
const FlightPostSummary: FC<IFlightPostSummary> = ({
  totalFlightHour,
  totalDistance,
  totalSprayedArea,
  totalSprayedWater,
  totalFertilizerSprayed,
  totalSprayedQuantity,
  totalSpreadQuantity,
  areaUnitLabel,
}) => {
  // const duration = dayjs.duration(data, "seconds");

  return (
    <Wrapper>
      <div className="group">
        <div className="label">Total flight hour</div>
        <div className="value-unit">
          <span className="value">
            {totalFlightHour && totalFlightHour !== "null" ? totalFlightHour : "-"}
          </span>
          <span className="unit">Hours</span>
        </div>
      </div>
      {/* {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(data)} */}
      <div className="group">
        <div className="label">Total distance</div>
        <div className="value-unit">
          <span className="value">
            {totalDistance && totalDistance !== "null"
              ? Intl.NumberFormat("en-US").format(+totalDistance)
              : "0"}
          </span>
          <span className="unit">km</span>
        </div>
      </div>
      <div className="group">
        <div className="label">Total Sprayed Area</div>
        <div className="value-unit">
          <span className="value">
            {totalSprayedArea && totalSprayedArea !== "null"
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(+totalSprayedArea)
              : "0"}
          </span>
          <span className="unit">{areaUnitLabel}</span>
        </div>
      </div>
      <div className="group">
        <div className="label">Total Sprayed Quantity</div>
        <div className="value-unit">
          <span className="value">
            {totalSprayedQuantity && totalSprayedQuantity !== "null"
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                  +totalSprayedQuantity
                )
              : "0"}
          </span>
          <span className="unit">L</span>
        </div>
      </div>
      <div className="group">
        <div className="label">Total Spread Quantity</div>
        <div className="value-unit">
          <span className="value">
            {totalSpreadQuantity && totalSpreadQuantity !== "null"
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                  +totalSpreadQuantity
                )
              : "0"}
          </span>
          <span className="unit">L</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default FlightPostSummary;
