import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { QUERY_FARMER_GET_PROFILE, QUERY_MY_FLIGHT_POST } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TFarmerProfile, TFarmerProfileResponse } from "types/farmer";
import { EPostStatus, TFarmerFlightPostResponse, TFlightPostDetail } from "types/flightPost";
import { IMeta } from "types/pagination";

import ATable from "components/templates/ATable/ATable";
import AiangStatus from "components/templates/AiangStatus/AiangStatus";
import Loading from "components/templates/Loading/Loading";

import { useAuth } from "../AuthPage/store/auth";
import FlightPostListPageStyled from "./FlightPostListPageStyled.styled";
import FlightPostSummary from "./FlightPostSummary";

const FlightPostListPage = () => {
  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [flightPostList, setFlightPostList] = useState<TFlightPostDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState<IMeta>();
  const [profileData, setProfileData] = useState<TFarmerProfile>();
  const [searchName, setSearchName] = useState<string>("");

  const fetchFarmerProfile = async () => {
    try {
      const response: TFarmerProfileResponse = await client.request(QUERY_FARMER_GET_PROFILE);
      setProfileData(response.farmerGetProfile);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFlightPostList = async () => {
    try {
      const variables = {
        options: {
          currentPage: currentPage,
          itemsPerPage: pageSize,
          search: searchName,
        },
      };
      const response: TFarmerFlightPostResponse = await client.request(
        QUERY_MY_FLIGHT_POST,
        variables
      );
      setFlightPostList(response.farmerGetMyFlightPost.data);
      setMeta(response.farmerGetMyFlightPost.meta);
    } catch (error) {
      console.log(error);
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handleSearchName = () => {
    setIsLoading(true);
    fetchFlightPostList();
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFlightPostList();
    fetchFarmerProfile();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchFlightPostList();
  }, [currentPage, pageSize]);
  const successModal = useSuccessModal({
    title: "Farm Disabled",
    detail: (
      <div>
        <div>Your farm has been successfully disabled</div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
  });

  const errorModal = useErrorModal({
    title: "Are you sure you want to disable this farm ?",
    detail: (
      <div>
        <div>This farm will be disabled immediately. </div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
    callBackOnOk() {
      successModal.openModal();
    },
  });

  const columns = [
    {
      title: "Flight Post",
      dataIndex: "name",
      key: "name",
      width: 336,
      render: (data: string, record: TFlightPostDetail) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ objectFit: "cover" }}
              src={record.farm.imageListUrls?.[0]}
              alt={record.farm.name}
              width={104}
              height={60}
            />
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {data}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.farm.name}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.farm.totalArea}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Total Flight",
      dataIndex: "farmType",
      key: "farmType",
      width: 200,
      align: "center" as AlignType,
      render: () => (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "150%",
            color: "#F9FAFB",
          }}
        >
          -
        </div>
      ),
    },
    {
      title: `Area of Operation (${areaUnitLabel})`,
      dataIndex: "totalArea",
      key: "totalArea",
      width: 380,
      align: "center" as AlignType,
      render: (data: number) => {
        return (
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "150%",
              color: "#F9FAFB",
            }}
          >
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(data)}
          </div>
        );
      },
    },
    {
      title: "Sprayed Water (L)",
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      align: "center" as AlignType,
      render: () => (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "150%",
            color: "#F9FAFB",
          }}
        >
          -
        </div>
      ),
    },
    {
      title: "Sprayed Fertilizer (Kg)",
      dataIndex: "status",
      key: "status",
      width: 120,
      align: "center" as AlignType,
      render: () => (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "150%",
            color: "#F9FAFB",
          }}
        >
          -
        </div>
      ),
    },
    {
      title: "Pilot",
      dataIndex: "pilot",
      key: "pilot",
      width: 120,
      align: "center" as AlignType,
      render: (data: string, record: TFlightPostDetail) => {
        if (record.pilot?.firstName) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: "16px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "150%",
                    color: "#F9FAFB",
                  }}
                >
                  {record.pilot.firstName} {record.pilot.lastName}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "150%",
                    color: "#9CA3AF",
                  }}
                >
                  {record.drone?.name}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "150%",
              color: "#F9FAFB",
            }}
          >
            -
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      align: "center" as AlignType,
      render: (data: EPostStatus, record: TFlightPostDetail) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <AiangStatus status={data} />
            <Link to={`/flight-post/${record.id}`}>
              <Icon icon="DotsVertical" style={{ cursor: "pointer" }} />
            </Link>
          </div>
        );
      },
    },
  ];
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  return (
    <FlightPostListPageStyled>
      {isLoading && <Loading />}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="flight-post-page-header">
          <h2>Flight Post</h2>
          <div style={{ width: "300px" }}>
            <InputField
              placeholder="Search flight post "
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
              suffix={
                <Icon icon="Search" onClick={handleSearchName} style={{ cursor: "pointer" }} />
              }
            />
          </div>
        </div>
        <div>
          <FlightPostSummary
            totalFlightHour={profileData?.totalFlightDuration}
            totalDistance={profileData?.totalFlightDistance}
            totalSprayedArea={profileData?.totalFlightArea}
            totalSprayedWater={"0"}
            totalFertilizerSprayed={"0"}
            areaUnitLabel={areaUnitLabel}
          />
        </div>
      </div>
      <ATable
        columns={columns}
        data={flightPostList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </FlightPostListPageStyled>
  );
};
export default FlightPostListPage;
