import { Button, Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as CirclePlusIcon } from "assets/svgs/circle_plus_icon.svg";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import { QUERY_ADMIN_ADMIN_SERVICE_PROVIDER_LIST } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import { decrypt } from "core/utils/crypto";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  TAdminAdminSpProfile,
  TAdminAdminSpProfileListResponse,
  TAdminSpProfile,
  TServiceProviderProfile,
} from "types/admin";
import { IMeta } from "types/pagination";

import ATable from "components/templates/ATable/ATable";

import AdminListPageStyled from "./AdminListPage.styled";

const AdminListPage = () => {
  const { t } = useTranslation("admin");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adminList, setAdminList] = useState<TAdminAdminSpProfile[]>([]);
  const [searchName, setSearchName] = useState<string>("");

  const successModal = useSuccessModal({
    title: "Admin Disabled",
    detail: (
      <div>
        <div>Your admin has been successfully disabled</div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
  });

  const errorModal = useErrorModal({
    title: "Are you sure you want to disable this admin ?",
    detail: (
      <div>
        <div>This admin will be disabled immediately. </div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
    callBackOnOk() {
      successModal.openModal();
    },
  });

  const columns = [
    {
      title: t`Admin`,
      dataIndex: "id",
      key: "id",
      width: 336,
      render: (_: string, record: TAdminAdminSpProfile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar name={`${record.firstName} ${record.lastName}`} round={true} size={"40"} />
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {record.firstName} {record.lastName}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: t`Phone no.`,
      dataIndex: "phoneNo",
      key: "phoneNo",
      width: 200,
      align: "center" as AlignType,
    },
    {
      title: t`Service Provider`,
      dataIndex: "serviceProvider",
      key: "serviceProvider",
      width: 350,
      align: "center" as AlignType,
      render: (serviceProvider: TServiceProviderProfile) => {
        return serviceProvider.name;
      },
    },
    {
      title: t`Action`,
      dataIndex: "",
      key: "action",
      width: 120,
      render: (_: string, record: TAdminSpProfile, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {/* <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={true}
              onChange={() => errorModal.openModal()}
              onColor="#FFEDCD"
              onHandleColor="#FFB32C"
              offHandleColor="#FFB32C"
            /> */}
            <Link to={`/edit-admin-service-provider/${record.id}`}>
              <DetailIcon />
            </Link>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const fetchAdminList = async (currentPage: number, pageSize: number) => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
          searchBy: ["firstName", "lastName"],
        },
      };
      const response: TAdminAdminSpProfileListResponse = await client.request(
        QUERY_ADMIN_ADMIN_SERVICE_PROVIDER_LIST,
        variables
      );

      const adminSpList = response.adminGetAdminServiceProvidersList.data?.map((admin) => {
        return {
          ...admin,
          email: decrypt(admin.email),
          phoneNo: decrypt(admin.phoneNo),
          nationalIdNo: decrypt(admin.nationalIdNo),
        };
      });

      setAdminList(adminSpList);

      setMeta(response.adminGetAdminServiceProvidersList.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handleSearchName = () => {
    setIsLoading(true);
    fetchAdminList(currentPage, pageSize);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAdminList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <AdminListPageStyled>
      <h2>{t`Admin`}</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ width: "300px" }}>
            <InputField
              placeholder={t`Search admin name`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
              suffix={
                <Icon icon="Search" onClick={handleSearchName} style={{ cursor: "pointer" }} />
              }
            />
          </div>
        </div>
        <Link to={"/add-admin-service-provider"}>
          <Button style={{ backgroundColor: "#FFB32C", color: "white" }}>
            <CirclePlusIcon />
            <span style={{ marginLeft: "10px" }}>{t`Add new admin`}</span>
          </Button>
        </Link>
      </div>
      <ATable
        isLoading={isLoading}
        columns={columns}
        data={adminList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </AdminListPageStyled>
  );
};
export default AdminListPage;
