import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

type PropsType = {
  text: string;
  isToday?: boolean;
};

const UpcomingSubtitle: React.FC<PropsType> = (props) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Icon icon="Calendar" color={props.isToday ? theme.color.bluePrimary[400] : undefined} />
      <Text variant="subtitle2">{props.text}</Text>
    </Wrapper>
  );
};

export default UpcomingSubtitle;
