import { Button, Icon, InputField, TextAreaField } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import { MUTATION_ADMIN_ADD_DRONE } from "core/gql/drone";
import { QUERY_MY_FARM_FLIGHT_POST } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import { QUERY_CHECK_DRONE_SERIAL_EXISTS } from "core/gql/pilot";
import { addDroneDefaultValue, addDroneSchema } from "core/schema/drone";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { text } from "stream/consumers";
import {
  TAdminFarmListResponse,
  TAdminFarmerProfile,
  TAdminFarmerProfileListResponse,
} from "types/admin";
import { FormErrors } from "types/base";
import { TFlightPostDetail } from "types/flightPost";
import { IDroneDetail, TCheckDroneSerialResponse } from "types/pilot";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ASelect from "components/templates/ASelect/ASelect";
import Loading from "components/templates/Loading/Loading";

import { IFarmDetail, TFarmListResponse, TUpload } from "../../../../types/farmer";
import DroneFormStyled from "./DroneForm.styled";

const opacityStyle = ({ validate }: { validate: boolean }) => css`
  ${validate ? "opacity: 1" : "opacity: 0.5"}
`;

export interface IValueDroneAdminForm {
  drone: {
    extentions?: string;
    objective?: string;
    description?: string;
    droneType: string;
    brand: string;
    serialNumber: string;
    model: string;
    weight: string;
    name: string;
    images: {
      top: TUpload[];
      left: TUpload[];
      bottom: TUpload[];
      right: TUpload[];
    };
    insurance: {
      number: string;
      coverage: number;
      expireDate: string;
      fileList: TUpload[];
    };
  };
}
interface IFlightPostForm {
  onCancel: () => void;
  onSubmit: (value: IValueDroneAdminForm) => void;
  defaultValue?: IDroneDetail | null;
}
const DroneForm: FC<IFlightPostForm> = ({ defaultValue, onSubmit, onCancel }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validatePilotDrone = async (serialNumber: string) => {
    try {
      const variables = {
        serialNumber,
      };
      const result: TCheckDroneSerialResponse = await client.request(
        QUERY_CHECK_DRONE_SERIAL_EXISTS,
        variables
      );
      return result.checkDroneSerialExists;
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik<any>({
    initialValues: addDroneDefaultValue,
    validationSchema: toFormikValidationSchema(addDroneSchema),
    onSubmit: (values) => handleAddDrone(values),
    validate: async (values) => {
      const errors: FormErrors = {};

      if (values.drone.serialNumber) {
        const droneError = await validatePilotDrone(values.drone.serialNumber);

        if (droneError) {
          errors.drone = {};
          errors.drone.serialNumber = "drone is already added";
        }
      }
      return errors;
    },
  });

  const handleAddDrone = async (values: any) => {
    try {
      const droneInput = {
        name: values.drone.name,
        description: values.drone.description,
        droneType: values.drone.droneType,
        brand: values.drone.brand,
        serialNumber: values.drone.serialNumber,
        objective: values.drone.objective,
        model: values.drone.model,
        weight: values.drone.weight,
        extentions: values.drone.extentions,
        images: values.drone.images,
        battery: values.drone.battery,
        insurance: {
          number: values.drone.insurance.number,
          coverage: values.drone.insurance.coverage,
          fileList: values.drone.insurance.fileList,
          expireDate: values.drone.insurance.expireDate,
        },
        payload: values.drone.payload,
      };

      const variables = {
        droneInput: droneInput,
        pilotEmail: values.drone.pilotEmail,
      };
      await client.request(MUTATION_ADMIN_ADD_DRONE, variables);
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };
  const [farmerList, setFarmerList] = useState<TAdminFarmerProfile[]>([]);

  const [farmList, setFarmList] = useState<IFarmDetail[]>([]);
  // const [totalArea, setTotalArea] = useState<string>(defaultValue?.farm.totalArea || "0");
  const { values, handleChange, setFieldValue, handleSubmit, errors, dirty } = formik;

  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <DroneFormStyled>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit}>
        <div className="add-flight-post-box">
          <div style={{ display: "flex", gap: "8px" }}>
            <Icon size={20} icon="ChevronLeft" style={{ cursor: "pointer" }} onClick={onCancel} />
            <h2>Flight Post</h2>
          </div>
          <ASelect
            label="Farmer"
            value={values.farmerId}
            onChange={(value) => {
              setFieldValue("farmerId", value);
              const farmerSelected = farmerList.find((f) => f.id === value);
              if (farmerSelected) {
                setFarmList(farmerSelected.farms.data);
              }
            }}
            onClear={() => {
              setFarmList([]);
              setFieldValue("farmId", "");
              // setTotalArea("");
            }}
            placeholder="Select Farmer"
            options={farmerList.map((farmer: TAdminFarmerProfile) => {
              return {
                label: `${farmer.firstName} ${farmer.lastName}`,
                value: farmer.id,
              };
            })}
          />
          <ASelect
            label="Farm"
            value={values.farmId}
            onChange={(value) => {
              setFieldValue("farmId", value);
              const farmSelected = farmList.find((f) => f.id === value);
              // if (farmSelected) {
              //   setTotalArea(`${farmSelected.totalArea}`);
              // }
            }}
            placeholder="Select Farm"
            options={farmList.map((farm: IFarmDetail) => {
              return {
                label: farm.name,
                value: farm.id,
              };
            })}
          />
          <div>Area</div>

          {/* {totalArea ? <div>{totalArea}</div> : <div>-</div>} */}

          <InputField
            label="Flight Post Name"
            required
            name="name"
            placeholder="Enter Flight Post Name"
            onChange={handleChange}
            value={values.name}
          />
          <TextAreaField
            required
            name="description"
            label="Flight Post Description"
            rows={4}
            placeholder="Enter description"
            onChange={handleChange}
            value={values.description}
          />
          <h3>Flight Time</h3>
          <InputField
            name="startTime"
            type="datetime-local"
            label="Start Date"
            value={values.startTime}
            onChange={handleChange}
          />
          <InputField
            name="endTime"
            type="datetime-local"
            label="End Date"
            value={values.endTime}
            onChange={handleChange}
          />
        </div>
        <div className="add-footer-flight-post">
          <Button className="cancel-btn" fullWidth onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="add-btn"
            fullWidth
            css={opacityStyle({ validate: dirty && isEmpty(errors) })}
          >
            Save
          </Button>
        </div>
      </form>
    </DroneFormStyled>
  );
};
export default DroneForm;
