import { Button, Icon, InputField, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { IUploadDetail } from "core/domains/services/upload";
import useErrorModal from "core/hooks/useErrorModal";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { IDroneInformation } from "types/droneInformation";
import { EPostStatus, TFlightPostDetail } from "types/flightPost";
import { IDroneDetail } from "types/pilot";

import AiangStatus from "../AiangStatus/AiangStatus";
import UploadFlightLog from "../UploadFlightLog/UploadFlightLog";

const DroneListItemStyled = styled.div`
  width: 292px;
  border-radius: 10px;
  background: #665c4d;
  padding: 8px;
  cursor: pointer;
  
  .item-container {
    display: flex;
    position: relative;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
  .sub-title {
    color: #9ca3af;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
  .edit-flight-post-btn {
    position: absolute;
    right: 20px;
    bottom: 0;
    cursor: pointer;
  }

  .btn {
    width: 100%;
    font-weight: 500;
    font-family: Kanit;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }

  .on-mission-btn,
  .on-mission-btn:hover {
    background-color: #e9d8fd;
    background #e9d8fd;
    color: #322659;
  }
  .available-btn,
  .available-btn:hover {
    background-color: #85dda5;
    background #85dda5;
    color: #ffffff;
  }
  .start-btn,
  .start-btn:hover {
    background-color: #ffb32c;
    background #ffb32c;
    color: #ffffff;
  }
  .cancel-btn,
  .cancel-btn:hover {
    background-color: #ef7979;
    background #ef7979;
    color: #ffffff;
  }
`;

const borderStyled = ({ selected }: { selected: boolean }) => css`
  ${selected === true
    ? `
    border: 1px solid #ffb32c;
    border-radius: 10px;
    background: #665c4d;
  `
    : ``}
`;
export interface IFlightPostItem extends TFlightPostDetail {
  setSelectedEditFlightPost?: (id: string) => void;
  isPilot?: boolean;
  selected: boolean;
  index: number;
  onStartFlightPost?: (index: string) => void;
  onAcceptFlightPost?: (index: string) => void;
  onDeleteFlightPost?: (index: string) => void;
  toStepCreateFlightPlan?: (index: string) => void;
  onCancelFlightPost?: (index: string, reason: string) => void;
  onFinishFlightPost?: (id: string, flightLog: IUploadDetail) => void;
}

// export interface IDroneDetailItem extends IDroneDetail {
//   index: number;
// }

export interface IDroneDetailItem extends IDroneInformation {
  index: number;
}

const DroneListItem: FC<IDroneDetailItem> = ({
  id,
  // name,
  serialNumber,
  flightInformation,
  // imageTopUrls,
  // isActive,
}) => {
  const [reasonText, setReasonText] = useState<string>("");
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [flightLogFile, setFlightLogFile] = useState<IUploadDetail | null>(null);

  return (
    <DroneListItemStyled css={borderStyled({ selected: false })}>
      <div className="item-container">
        <img
          style={{
            borderRadius: "5px",
          }}
          alt={id}
          width={57}
          height={57}
          // src={farm.imageListUrls?.[0]}
          src={require("../../../assets/images/UAV.png")}
        />
        <div className="ml-8">
          <div className="title">{serialNumber}</div>
          {/* <div className="sub-title">{}</div> */}
          <div className="sub-title">
            {dayjs(flightInformation?.createdAt).format("DD MMM YYYY")}
          </div>
          {/* <div className="sub-title">
            {dayjs(startTime).format("DD MMM YYYY")} - {dayjs(endTime).format("DD MMM YYYY")}
          </div> */}
        </div>
        {/* <div>
          <div className="title">ACTIVE</div>
          <div className="sub-title">{isActive.toString()}</div>
        </div> */}
      </div>
    </DroneListItemStyled>
  );
};

export default DroneListItem;
