import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_FARMER_REGISTER } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { farmerSignSchema, farmerSignupDefaultValue } from "core/schema/farmer";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  FarmIconSelected,
  FarmIconWhite,
  FarmerIconNotSelectedWhite,
  FarmerIconSelected,
} from "components/templates/Icon";

import AddFarmerPageStyled from "../AddFarmerPage/AddFarmerPage.styled";
import FarmStep from "../FarmerSignupPage/FarmStep/FarmStep";
import FarmerStep from "../FarmerSignupPage/FarmerStep/FarmerStep";

type TFarmerSignSchema = z.infer<typeof farmerSignSchema>;

const AdminAddFarmerPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Farmer");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableChangeStep, setDisableChangeStep] = useState(false);

  const modal = useSuccessModal({
    title: "Registration Successful",
    detail: (
      <>
        <div>Your registration form has been</div>
        <div>submitted to the system</div>
      </>
    ),
    callBack: () => navigate("/login"),
  });
  const formik = useFormik<TFarmerSignSchema>({
    initialValues: farmerSignupDefaultValue,
    validationSchema: toFormikValidationSchema(farmerSignSchema),
    onSubmit: (values) => handleSubmitForm(values),
  });

  const renderStep = (step: string) => {
    switch (step) {
      case "Farmer":
        return <FarmerStep form={formik} />;
      case "Farm":
        return (
          <FarmStep
            form={formik}
            noStep="2"
            totalStep="2"
            setDisableChangeStep={setDisableChangeStep}
          />
        );
    }
  };

  const handleChangeStep = (value: string) => {
    if (!disableChangeStep) {
      setStep(value);
    }
  };
  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);
      const farmList = values.farms.map((f: any) => {
        return {
          address: f.address,
          coordinates: f.coordinates,
          districtCode: f.districtCode,
          farmType: f.farmType,
          imageList: f.imageList,
          name: f.name,
          postcode: f.postcode,
          provinceCode: f.provinceCode,
          subdistrictCode: f.subdistrictCode,
          totalArea: f.totalArea,
        };
      });
      const farmerInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        email: values.email,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        address: values.address,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        subdistrictCode: values.subdistrictCode,
        postcode: values.postcode,
        password: values.password,
        farms: farmList,
      };
      const variables = {
        farmerInput: farmerInput,
      };
      await client.request(MUTATION_FARMER_REGISTER, variables);
      modal.openModal();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating item:", error);
    }
  };
  const handleClickCancel = () => {
    formik.resetForm();
    navigate("/farmer-list");
  };

  return (
    <AddFarmerPageStyled>
      <div
        style={{ display: "flex", alignItems: "center", paddingBottom: "32px" }}
        onClick={handleClickCancel}
      >
        <Icon icon="ChevronLeft" />
        <h3>Add Farmer</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-farm-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Farmer",
                    selectedIcon: <FarmerIconSelected />,
                    notSelectedIcon: <FarmerIconNotSelectedWhite />,
                  },
                  {
                    label: "Farm",
                    selectedIcon: <FarmIconSelected />,
                    notSelectedIcon: <FarmIconWhite />,
                  },
                ]}
                selected={step}
                onClick={handleChangeStep}
              />
            </div>
            {renderStep(step)}
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/farmer-list"}>
                <Button className="cancel-button">Cancel</Button>
              </Link>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                Register
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AddFarmerPageStyled>
  );
};

export const Component = AdminAddFarmerPage;
export default AdminAddFarmerPage;
