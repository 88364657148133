import { Avatar, Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const UserDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  .indentity {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
`;

export type User = {
  name: string;
  position: string;
};

type UserProps = {
  user: User;
};

const UserDetail: React.FC<UserProps> = ({ user, ...props }) => {
  const theme = useTheme();
  return (
    <UserDetailStyled {...props}>
      <Avatar size="sm" />
      <div className="identity">
        <Text variant="body3">{user.name}</Text>
        <Text
          variant="body4"
          style={{
            color: theme.color.grayPrimary[500],
          }}
        >
          {user.position}
        </Text>
      </div>
    </UserDetailStyled>
  );
};

export default UserDetail;
