/**
 * It's possible to use a middleware to pre-process any request or handle raw response.
 */
import storage from "core/domains/utils/storage";
import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from "graphql-request";

const endpoint = `${process.env.REACT_APP_AUTH_URL}/graphql/`;

const getAccessToken = () => Promise.resolve(`some special token here`);

const requestMiddleware: RequestMiddleware = async (request) => {
  const access_token = storage.get("access_token");
  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const responseMiddleware = (response: any) => {
  if (response.status === 200) {
  } else if (response.response.errors[0].extensions.code === "FORBIDDEN") {
    storage.clear();
    window.location.href = "/login";
  }
  // if (!(response instanceof Error) && response.errors) {
  //   console.log("response", response);
  //   console.log("response.status", response.status);
  //   console.log("response.errors", response.errors);
  // }
};

export const client = new GraphQLClient(endpoint, { requestMiddleware, responseMiddleware });
// {
//   /**
//    * It's also possible to use an async function as a request middleware. The resolved data will be passed to the request:
//    */

//   const requestMiddleware: RequestMiddleware = async (request) => {
//     const token = await getAccessToken();
//     return {
//       ...request,
//       headers: { ...request.headers, "x-auth-token": token },
//     };
//   };

//   const _client = new GraphQLClient(endpoint, { requestMiddleware });
// }
// {
//   /**
//    * Response middleware example (log request trace id if error caused):
//    */

//   const _client = new GraphQLClient(endpoint, { responseMiddleware });
// }
