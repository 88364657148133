import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as BatteryNotSelectedIcon } from "assets/svgs/battery_icon_not_selected.svg";
import { ReactComponent as BatterySelectedIcon } from "assets/svgs/battery_icon_selected.svg";
import { ReactComponent as InsuranceNotSelectedIcon } from "assets/svgs/insurance_icon_not_selected.svg";
import { ReactComponent as InsuranceSelectedIcon } from "assets/svgs/insurance_icon_selected.svg";
import { ReactComponent as PayloadNotSelectedIcon } from "assets/svgs/payload_icon_not _selected.svg";
import { ReactComponent as PayloadSelectedIcon } from "assets/svgs/payload_icon_selected.svg";
import { client } from "core/gql/fetchgql";
import { MUTATION_UPDATE_DRONE, QUERY_DRONE_BY_SERIAL_NUMBER } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addDroneSchema } from "core/schema/drone";
import { droneDefaultValue } from "core/schema/pilot";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { DroneInput, Insurance } from "types/drone";
import { TDroneItemResponse, TDroneResponse } from "types/pilot";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { SettingIconNotSelected, SettingIconSelected } from "components/templates/Icon";
import Loading from "components/templates/Loading/Loading";

import DroneForm from "../AddDronePage/DroneForm/DroneForm";
import AdminEditDronePage from "../AdminEditDronePage/AdminEditDronePage";
import { useAuth } from "../AuthPage/store/auth";
import EditDronePageStyled from "./EditDronePage.styled";

const EditDronePage = () => {
  const { t } = useTranslation(["drone", "common"]);
  const navigate = useNavigate();
  const { serialNumber } = useParams();
  const [step, setStep] = useState<string>("Drone");
  const [droneDetail, setDroneDetail] = useState<TDroneResponse>({
    drone: droneDefaultValue,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);

  const modal = useSuccessModal({
    title: t`Drone Edited`,
    detail: (
      <>
        <div>{t`Your drone has been successfully edited`}</div>
      </>
    ),
    callBack: () => navigate("/drone-list"),
  });
  const formik = useFormik<any>({
    initialValues: droneDetail,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(addDroneSchema),
    onSubmit: (values) => handleOnUpdateDrone(values),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          serialNumber: serialNumber,
        };
        const response: TDroneItemResponse = await client.request(
          QUERY_DRONE_BY_SERIAL_NUMBER,
          variables
        );

        setDroneDetail({
          drone: response.droneBySerialNumber,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();
  }, [serialNumber]);

  useEffect(() => {
    if (droneDetail) {
      const leasing = droneDetail.drone.leasing;

      if (leasing) {
        if (leasing.status === true && leasing.expiryDate) {
          formik.setFieldValue("drone.leased.status", true);
          formik.setFieldValue("drone.leased.expiryDate", leasing.expiryDate);
        } else {
          formik.setFieldValue("drone.leased.status", false);
          formik.setFieldValue("drone.leased.expiryDate", leasing.expiryDate);
        }
      } else {
        formik.setFieldValue("drone.leased", undefined);
      }
    }
  }, [droneDetail]);

  const handleOnUpdateDrone = async (values: any) => {
    try {
      setSubmitIsLoading(true);

      const updatedObject: {
        droneInput: DroneInput;
        droneId: string;
      } = {
        droneInput: {
          name: values.drone.name,
          description: values.drone.description,
          droneType: values.drone.droneType,
          brand: values.drone.brand,
          serialNumber: values.drone.serialNumber,
          objective: values.drone.objective || "-",
          model: values.drone.model,
          weight: values.drone.weight,
          extentions: values.drone.extentions,
          images: values.drone.images,
          battery: values.drone.battery,
          insurance: values.drone.insurance?.map((insurance: Insurance) => ({
            id: insurance.id,
            number: insurance.number,
            coverage: insurance.coverage,
            fileList: insurance.fileList,
            expireDate: insurance.expireDate,
            users: insurance.users,
          })),
          payload: values.drone.payload,
          userId: values.drone.userId,
        },
        droneId: values.drone.id,
      };
      // Check if changeLog is an empty string and set it to null
      if (values.drone.config_file) {
        if (values.drone.config_file.changeLogString === "") {
          values.drone.config_file.changeLogString = null;
        }
        updatedObject.droneInput.config_file = {
          fileList: values.drone.config_file.fileList,
          changeLog: values.drone.config_file.changeLogString,
        };
      }

      await client.request(MUTATION_UPDATE_DRONE, updatedObject);
      setSubmitIsLoading(false);

      modal.openModal();
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  return (
    <EditDronePageStyled>
      {isLoading && <Loading />}
      <Link to="/drone-list">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon="ChevronLeft" />
          <h3>{t`Drone`}</h3>
        </div>
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-drone-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: t`Drone`,
                    key: "Drone",
                    selectedIcon: <Icon icon="Drone" />,
                    notSelectedIcon: <Icon icon="Drone" />,
                  },
                  {
                    label: t`Insurance`,
                    key: "Insurance",
                    selectedIcon: <InsuranceSelectedIcon />,
                    notSelectedIcon: <InsuranceNotSelectedIcon />,
                  },
                  {
                    label: t`Payload`,
                    key: "Payload",
                    selectedIcon: <PayloadSelectedIcon />,
                    notSelectedIcon: <PayloadNotSelectedIcon />,
                  },
                  {
                    label: t`Battery`,
                    key: "Battery",
                    selectedIcon: <BatterySelectedIcon />,
                    notSelectedIcon: <BatteryNotSelectedIcon />,
                  },
                  {
                    label: t`Config File`,
                    key: "ConfigFile",
                    selectedIcon: <SettingIconSelected />,
                    notSelectedIcon: <SettingIconNotSelected />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>

            <DroneForm
              totalStep={5}
              step={step}
              form={formik}
              isEdit
              isLeased={droneDetail?.drone?.leasing?.status}
            />
          </div>
          <div className="footer">
            <div></div>

            <div className="footer-group">
              <Button
                type="button"
                className="cancel-button"
                onClick={() => {
                  window.location.href = "/drone-list";
                }}
              >
                {t(`Cancel`, { ns: "common" })}
              </Button>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t(`Save`, { ns: "common" })}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </EditDronePageStyled>
  );
};

export const Component = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes("ADMIN");
  const isAdminServiceProvider = user?.roles.includes("ADMIN_SERVICE_PROVIDER");

  if (isAdmin || isAdminServiceProvider) {
    return <AdminEditDronePage />;
  } else {
    return <EditDronePage />;
  }
};
export default Component;
