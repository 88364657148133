import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ReactComponent as AltiMeterIcon } from "assets/svgs/altimeter_icon.svg";
import { ReactComponent as GrommetIcon } from "assets/svgs/grommet_icons_waypoint.svg";
import { ReactComponent as SpeedMeterIcon } from "assets/svgs/speedmeter_icon.svg";
import { FC } from "react";

const Wrapper = styled.div`
  .header {
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 24px;
      left: 12px;
      width: 1px;
      height: 88px;
      background-color: #ffb32c;
    }
  }
  .row-waypoint-item {
    display: flex;
    margin-left: 8px;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
    justify-content: space-evenly;
    div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .text-input-component {
      height: 36px !important;
      input {
        padding: 8px 0 8px 16px;
      }
    }
  }
`;

interface IWaypointItem {
  index: number;
  form: any;
}
const WaypointItem: FC<IWaypointItem> = ({ index, form }) => {
  return (
    <Wrapper>
      <div className="header">
        <GrommetIcon /> Point {index + 1}
      </div>
      <div className="row-waypoint-item">
        <div>
          <AltiMeterIcon /> Altitude (m)
        </div>
        <div style={{ width: "64px" }}>
          <InputField
            name={`waypoints[${index}].altitude`}
            onChange={form.handleChange}
            type="number"
            step="0.1"
            max={100}
            min={0}
            placeholder="0.00"
          />
        </div>
      </div>
      <div className="row-waypoint-item">
        <div>
          <SpeedMeterIcon />
          Speed (kmph)
        </div>
        <div style={{ width: "64px" }}>
          <InputField
            name={`waypoints[${index}].speed`}
            onChange={form.handleChange}
            type="number"
            step="0.1"
            max={100}
            min={0}
            placeholder="0.00"
          />
        </div>
      </div>
    </Wrapper>
  );
};
export default WaypointItem;
