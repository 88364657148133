import styled from "@emotion/styled";
import BG_CHART from "assets/images/bg-altitude-chart.png";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { FC, useMemo } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      min: 0,
      max: 150,
      ticks: {
        stepSize: 30,
        color: "white",
      },
      grid: {
        color: "white",
      },
      border: {
        color: "white",
      },
    },
    x: {
      display: false,
    },
  },
};

interface IAltitudeChart {
  data: any;
  length: number;
}

const Wrapper = styled.div`
  position: absolute;
  left: 360px;
  bottom: 16px;
  background-image: url(${BG_CHART});
  width: calc(100vw - 450px);
  height: 248px;
  padding: 16px 24px;
  border-radius: 10px;
  .altitude-title {
    color: #f9fafb;
    font-family: Kanit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    text-decoration-line: underline;
  }
`;
const AltitudeChart: FC<IAltitudeChart> = ({ data, length }) => {
  const chartValue = useMemo(() => {
    const altitudeList = [];
    // const altitudeList = data.waypoints.map((i: any) => i.altitude).filter((j: any) => j);
    // if (altitudeList.length === data.coordinates.length - 1) {
    //   return altitudeList;
    // }
    for (let i = 0; i < data.coordinates.length - 1; i++) {
      if (data.waypoints[i] && data.waypoints[i].altitude) {
        altitudeList.push(data.waypoints[i].altitude);
      } else {
        altitudeList.push(0);
      }
    }
    return altitudeList;
  }, [data]);

  const labels = Array(length).fill("");

  const chartData = {
    labels,
    datasets: [
      {
        label: "Altitude",
        data: chartValue,
        borderColor: "#FFB32C",
        backgroundColor: "white",
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
        pointBorderWidth: 4,
      },
    ],
  };
  return (
    <Wrapper>
      <div className="altitude-title">Altitude</div>
      <Line options={options} data={chartData} />
    </Wrapper>
  );
};
export default AltitudeChart;
