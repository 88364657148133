import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ReactComponent as BackIcon } from "assets/svgs/arrow-left-long.svg";
import { PropsWithChildren, ReactNode, memo } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "components/pages/AuthPage/store/auth";

import Notification from "../Notification/Notification";

const PageTitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  gap: 16px;

  .back {
    cursor: pointer;
  }

  .name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    .location {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        display: inline-block;
      }
    }
  }
`;

type PageTitleProps = {
  title?: string;
  description?: string;
  icon?: ReactNode;
  withBack?: boolean;
  onBack?: () => void;
  withNotification?: boolean;
} & PropsWithChildren;

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  icon,
  withBack,
  onBack,
  children,
  withNotification,
}) => {
  const navigate = useNavigate();
  const { accessControl } = useAuth();

  return (
    <PageTitleStyled>
      {withBack && <BackIcon className="back" onClick={() => (onBack ? onBack() : navigate(-1))} />}
      <div className="name">
        {title && <Text variant="h6">{title}</Text>}
        <span className="location">
          {icon}
          {description && <Text variant="body3">{description}</Text>}
        </span>
      </div>
      {children}
      {withNotification && accessControl["NOTI"] && <Notification />}
    </PageTitleStyled>
  );
};

export default memo(PageTitle);
