import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { pilotBatteryDefaultValue } from "core/schema/pilot";
import { batteryBrandList, batteryModelList } from "core/utils/constant";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ASelect from "components/templates/ASelect/ASelect";

interface IBatteryForm {
  index: number;
  form: any;
  batteryList: any[];
  setBatteryList: (list: any[]) => void;
}

const BatteryForm: React.FC<IBatteryForm> = ({ form, index, batteryList, setBatteryList }) => {
  const { t } = useTranslation(["form", "drone", "common"]);
  const theme = useTheme();

  const handleBatteryChange = (field: string, value: any) => {
    const newList = [...batteryList];
    newList[index][field] = value;
    setBatteryList(newList);
    form.setFieldValue(`drone.battery[${index}].${field}`, value);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            required
            label={t`Battery Brand`}
            placeholder={t`Select Battery Brand`}
            options={batteryBrandList}
            value={batteryList[index].brand}
            onChange={(value) => handleBatteryChange("brand", value)}
            error={
              get(form, `touched.drone.battery[${index}].brand`) &&
              get(form, `errors.drone.battery[${index}].brand`)
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            required
            label={t`Model`}
            placeholder={t`Select Battery Model`}
            options={batteryModelList}
            value={batteryList[index].model}
            onChange={(value) => handleBatteryChange("model", value)}
            error={
              get(form, `touched.drone.battery[${index}].model`) &&
              get(form, `errors.drone.battery[${index}].model`)
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.battery[${index}].capacity`}
            label={t`Battery Capacity (mAh)`}
            placeholder={t`Enter Battery Capacity`}
            helperSpace
            required
            value={batteryList[index].capacity}
            onChange={(e: { target: { value: any } }) =>
              handleBatteryChange("capacity", e.target.value)
            }
            error={
              get(form, `touched.drone.battery[${index}].capacity`) &&
              get(form, `errors.drone.battery[${index}].capacity`)
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name={`drone.battery[${index}].serialNumber`}
            label={t`Serial Number of Battery`}
            placeholder={t`Enter Serial Number`}
            helperSpace
            required
            value={batteryList[index].serialNumber}
            onChange={(e: { target: { value: any } }) =>
              handleBatteryChange("serialNumber", e.target.value)
            }
            error={
              get(form, `touched.drone.battery[${index}].serialNumber`) &&
              get(form, `errors.drone.battery[${index}].serialNumber`)
            }
          />
        </div>
      </div>
      {batteryList.length !== 1 && batteryList.length !== index + 1 && <hr />}
    </div>
  );
};

export default BatteryForm;
