import { Button, Loading } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_ADMIN_UPDATE_PILOT, QUERY_ADMIN_GET_PILOT_BY_ID } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { editPilotSchema } from "core/schema/admin";
import { decrypt } from "core/utils/crypto";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminPilotByIdResponse } from "types/admin";
import { TPilotProfile } from "types/pilot";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  LicenseIconSelected,
  LicenseIconWhite,
  PilotIconSelected,
  PilotIconWhite,
} from "components/templates/Icon";

import EditPilotProfilePageStyled from "../EditPilotProfilePage/EditPilotProfilePage.styled";
import PilotForm from "../EditPilotProfilePage/Pilot/PilotForm";

const AdminEditPilotProfilePage = () => {
  const { t } = useTranslation(["pilot", "common"]);

  const { id } = useParams();
  const navigate = useNavigate();

  const [step, setStep] = useState<string>("Pilot");
  const [profileData, setProfileData] = useState<TPilotProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);

  const formik = useFormik<any>({
    initialValues: profileData || {},
    validationSchema: toFormikValidationSchema(editPilotSchema),
    enableReinitialize: true,
    onSubmit: (values) => handleUpdateProfile(values),
  });

  const dataStep = [
    {
      label: t`Pilot`,
      key: "Pilot",
      selectedIcon: <PilotIconSelected />,
      notSelectedIcon: <PilotIconWhite />,
    },
    {
      label: t`License`,
      key: "License",
      selectedIcon: <LicenseIconSelected />,
      notSelectedIcon: <LicenseIconWhite />,
    },
  ];

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const variables = {
            userId: id,
          };
          const response: TAdminPilotByIdResponse = await client.request(
            QUERY_ADMIN_GET_PILOT_BY_ID,
            variables
          );

          const pilot = {
            ...response.adminGetPilotById,
            email: decrypt(response.adminGetPilotById.email),
            phoneNo: decrypt(response.adminGetPilotById.phoneNo),
            nationalIdNo: decrypt(response.adminGetPilotById.nationalIdNo),
          };

          setProfileData(pilot);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };
      setIsLoading(true);
      fetchData();
    } else {
      navigate("/pilot-list");
    }
  }, []);
  const handleUpdateProfile = async (values: any) => {
    try {
      setSubmitIsLoading(true);
      const pilotInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        license: values.license,
      };
      const variables = {
        pilotProfile: pilotInput,
        pilotId: profileData?.id,
      };
      await client.request(MUTATION_ADMIN_UPDATE_PILOT, variables);
      successModal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const successModal = useSuccessModal({
    title: t`Pilot Edited`,
    detail: (
      <div>
        <div>{t`Your pilot has been successfully edited`}</div>
      </div>
    ),
    callBack: () => navigate("/pilot-list"),
  });

  return (
    <EditPilotProfilePageStyled>
      {isLoading && <Loading />}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>{t`Profile`}</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-profile-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>
            <PilotForm form={formik} step={step} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/pilot-list"}>
                <Button className="cancel-button">{t(`Cancel`, { ns: "common" })}</Button>
              </Link>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t(`Save`, { ns: "common" })}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <successModal.SuccessModal />
    </EditPilotProfilePageStyled>
  );
};
export default AdminEditPilotProfilePage;
