import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ReactComponent as DoubleLeftIcon } from "assets/svgs/double_left_icon.svg";
import { ReactComponent as DoubleRightIcon } from "assets/svgs/double_right_icon.svg";
import Pagination from "rc-pagination";
import { ChangeEvent, FC } from "react";

interface IAPagination {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onChange: (page: number) => void;
  onChangePageSize: (event: ChangeEvent<HTMLSelectElement>) => void;
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .page-size-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-left: 20px;
    margin-right: 20px;
    color: #9ca3af;
  }
  .page-size-selector {
    background-color: transparent;
    border: none;
    font-family: kanit;
    color: #9ca3af;
  }
`;
const APagination: FC<IAPagination> = ({
  totalItems,
  currentPage,
  pageSize,
  onChange,
  onChangePageSize,
}) => {
  const handleFirstPage = () => {
    onChange(1);
  };

  const handleLastPage = () => {
    const lastPage = Math.ceil(totalItems / pageSize);
    console.log("handleLastPage", lastPage);
    onChange(lastPage);
  };

  const handleNextPage = () => {
    const lastPage = Math.ceil(totalItems / pageSize);
    if (currentPage < lastPage) {
      const nextPage = currentPage + 1;
      console.log("handleNextPage", nextPage);
      onChange(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      const prevPage = currentPage - 1;
      onChange(prevPage);
    }
  };

  const itemRender = (current: number, type: string, element: React.ReactNode) => {
    if (type === "prev") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <DoubleLeftIcon onClick={handleFirstPage} />
          <Icon size={16} icon="ChevronLeft" color="white" onClick={handlePrevPage} />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Icon size={16} icon="ChevronRight" color="white" onClick={handleNextPage} />
          <DoubleRightIcon onClick={handleLastPage} />
        </div>
      );
    }
    if (type === "page") {
      return <div onClick={() => onChange(current)}>{current}</div>;
    }
    return element;
  };
  // console.log("currentPage", currentPage);
  return (
    <Wrapper>
      <Pagination
        style={{ display: "flex", alignItems: "center" }}
        total={totalItems}
        current={currentPage}
        pageSize={pageSize}
        itemRender={itemRender}
        showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} records`}
      />
      <span className="page-size-label">Rows per page:</span>
      <select className="page-size-selector" value={pageSize} onChange={onChangePageSize}>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </Wrapper>
  );
};

export default APagination;
