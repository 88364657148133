import {
  Avatar,
  Icon,
  InputField,
  Text,
  TextArea,
  TextAreaField,
} from "@ai-and-robotics-ventures/cumulus-ui";
import { css, useTheme } from "@emotion/react";
import { pilotPayloadDefaultValue } from "core/schema/pilot";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import PayloadForm from "./PayloadForm";

interface IPayloadStep {
  // selected: string;
  // data: IDataButtonGroup[];
  noStep: number;
  totalStep: number;
  isReadOnly: boolean;
  form: any;
}

const PayloadStep: React.FC<IPayloadStep> = ({ form, noStep, totalStep }) => {
  const { t } = useTranslation(["drone", "common"]);

  const theme = useTheme();
  const [payloadList, setPayloadList] = useState(form.values.drone.payload);
  const handleAddPayload = () => {
    payloadList.push(pilotPayloadDefaultValue);
    setPayloadList([...payloadList]);
    console.log();
  };
  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} {noStep}/{totalStep}
      </div>
      <h1>{t`Payload Information`}</h1>
      <hr />
      {payloadList.map((payload: any, index: number) => {
        return (
          <>
            <PayloadForm form={form} key={index} index={index} />
            {payloadList.length !== 1 && payloadList.length !== index + 1 && <hr />}
          </>
        );
      })}
      {payloadList.length ? <hr /> : <></>}
      <div className="add-more-payload-btn" onClick={handleAddPayload}>
        <Icon icon="Plus" size={24} /> {t`Add Payload`}
      </div>
    </div>
  );
};

export default PayloadStep;
