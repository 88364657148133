import { Button, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import {
  MUTATION_ADMIN_REGISTER,
  QUERY_ADMIN_SERVICE_PROVIDER_LIST,
  QUERY_CHECK_ADMIN_MAIL_EXISTS,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addAdminDefaultValue, addAdminSchema } from "core/schema/admin";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  TAdminSpProfileListResponse,
  TCheckAdminEmailResponse,
  TServiceProviderProfile,
} from "types/admin";
import { FormErrors } from "types/base";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { UserIcon, UserIconSelected } from "components/templates/Icon";

import AdminSignupPageStyled from "./AdminSignupPage.styled";
import AdminStep from "./AdminStep/AdminStep";

type TAddAdminSchema = z.infer<typeof addAdminSchema>;

const AddAdminServiceProviderPage = () => {
  const { t } = useTranslation(["admin", "common"]);
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("admin");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceProviderList, setServiceProviderList] = useState<TServiceProviderProfile[]>([]);

  const modal = useSuccessModal({
    title: t`New Admin Service Provider Added`,
    detail: (
      <>
        <div>{t`Your Admin service provider has been successfully`}</div>
        <div>{t`added to the platform`}</div>
      </>
    ),
    callBack: () => navigate("/admin-service-provider-list"),
  });

  const validateAdminEmail = async (email: string) => {
    try {
      const variables = {
        email,
      };
      const result: TCheckAdminEmailResponse = await client.request(
        QUERY_CHECK_ADMIN_MAIL_EXISTS,
        variables
      );
      return result.checkAdminEmailExists;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchServiceProviderList = async () => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
      };
      const response: TAdminSpProfileListResponse = await client.request(
        QUERY_ADMIN_SERVICE_PROVIDER_LIST,
        variables
      );
      setServiceProviderList(response.adminGetServiceProvidersList.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchServiceProviderList();
  }, []);

  const formik = useFormik<TAddAdminSchema>({
    initialValues: addAdminDefaultValue,
    validationSchema: toFormikValidationSchema(addAdminSchema),
    onSubmit: (values) => handleSubmitForm(values),
    validate: async (values) => {
      const errors: FormErrors = {};
      if (values.email) {
        const asyncError = await validateAdminEmail(values.email);
        if (asyncError) {
          errors.email = "email is already exists";
        }
      }
      return errors;
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);
      const AdminInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        email: values.email,
        phoneNo: values.phoneNo,
        password: values.password,
        serviceProvider: values.serviceProvider.name,
      };
      const variables = {
        AdminInput: AdminInput,
      };
      await client.request(MUTATION_ADMIN_REGISTER, variables);
      modal.openModal();
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating item:", error);
      toast({
        title: "",
        description:
          "Registration Error: Unable to process your registration at the moment. Please try again",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <AdminSignupPageStyled>
      <form onSubmit={formik.handleSubmit}>
        <div className="">
          <h1
            style={{
              padding: "32px 16px",
              fontWeight: "bold",
              marginTop: 0,
            }}
          >
            {t`Admin Registration`}
          </h1>
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: t`Admin`,
                    key: "admin",
                    selectedIcon: <UserIconSelected />,
                    notSelectedIcon: <UserIcon />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <AdminStep form={formik} serviceProviderList={serviceProviderList} isEdit={false} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/admin-service-provider-list"}>
                <Button className="cancel-button">{t(`Cancel`, { ns: "common" })}</Button>
              </Link>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                <span style={{ marginLeft: "8px" }}>{t`Add Admin`}</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AdminSignupPageStyled>
  );
};

export const Component = AddAdminServiceProviderPage;
export default AddAdminServiceProviderPage;
