import MainProvider from "core/providers";
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import AddAdminServiceProviderPage from "components/pages/AddAdminServiceProviderPage/AddAdminServiceProviderPage";
import AddDronePage from "components/pages/AddDronePage/AddDronePage";
import AddFarmPage from "components/pages/AddFarmPage/AddFarmPage";
import AddFarmerPage from "components/pages/AddFarmerPage/AddFarmerPage";
import AddPilotPage from "components/pages/AddPilotPage/AddPilotPage";
import AdminDroneFlightPostPage from "components/pages/AdminDroneFlightPostPage/AdminDroneFlightPostPage";
import AdminEditAdminServiceProviderProfilePage from "components/pages/AdminEditAdminServiceProviderProfilePage /AdminEditAdminServiceProviderProfilePage";
import AdminEditFarmerProfilePage from "components/pages/AdminEditFarmerProfilePage/AdminEditFarmerProfilePage";
import AdminEditPilotProfilePage from "components/pages/AdminEditPilotProfilePage/AdminEditPilotProfilePage";
import AdminFlightDetailPage from "components/pages/AdminFlightDetailPage/AdminFlightDetailPage";
import AdminListPage from "components/pages/AdminListPage/AdminListPage";
import LoginPage from "components/pages/AuthPage/LoginPage";
import LogoutPage from "components/pages/AuthPage/LogoutPage";
import PrivateProvider from "components/pages/AuthPage/PrivateProvider";
import Calendar from "components/pages/Calendar/Calendar";
import ChangePasswordPage from "components/pages/ChangePasswordPage/ChangePasswordPage";
import CreateMissionPlannerPage from "components/pages/CreateMissionPlannerPage/CreateMissionPlannerPage";
import CreateMissionTemplatePage from "components/pages/CreateMissionTemplatePage/CreateMissionTemplatePage";
import CreateProjectPage from "components/pages/CreateProjectPage/CreateProjectPage";
import DroneListPage from "components/pages/DroneListPage/DroneListPage";
import EditDronePage from "components/pages/EditDronePage/EditDronePage";
import EditFarmPage from "components/pages/EditFarmPage/EditFarmPage";
import EditFarmerProfilePage from "components/pages/EditFarmerProfilePage/EditFarmerProfilePage";
import EditPilotProfilePage from "components/pages/EditPilotProfilePage/EditPilotProfilePage";
import EditProfilePage from "components/pages/EditProfilePage/EditProfilePage";
import FarmListPage from "components/pages/FarmListPage/FarmListPage";
import FarmerListPage from "components/pages/FarmerListPage/FarmerListPage";
import FarmerReportPage from "components/pages/FarmerReportPage/FarmerReportPage";
import FarmerSignupPage from "components/pages/FarmerSignupPage/FarmerSignupPage";
import FleetPage from "components/pages/FleetPage/FleetPage";
import FlightLogDetailPage from "components/pages/FlightLogDetailPage/FlightLogDetailPage";
import FlightPlanPage from "components/pages/FlightPlanPage/FlightPlanPage";
import FlightPostDetailPage from "components/pages/FlightPostDetailPage/FlightPostDetailPage";
import FlightPostListPage from "components/pages/FlightPostListPage/FlightPostListPage";
import FlightPostPage from "components/pages/FlightPostPage/FlightPostPage";
import FlightRecordDetailPage from "components/pages/FlightRecordDetailPage/FlightRecordDetailPage";
import FlightRequestPage from "components/pages/FlightRequestPage/FlightRequestPage";
import HomePage from "components/pages/HomePage/HomePage";
import LeasingListPage from "components/pages/LeasingListPage/LeasingListPage";
import MissionDetailsPage from "components/pages/MissionDetailsPage/MissionDetailsPage";
import MissionTemplatePage from "components/pages/MissionTemplatePage/MissionTemplatePage";
import NewDroneListPage from "components/pages/NewDroneListPage/NewDroneListPage";
import Page404 from "components/pages/Page404/Page404";
import PilotListPage from "components/pages/PilotListPage/PilotListPage";
import PilotReportPage from "components/pages/PilotReportPage/PilotReportPage";
import PilotSignupPage from "components/pages/PilotSignupPage/PilotSignupPage";
import ProjectPage from "components/pages/ProjectPage/ProjectPage";
import ReportPage from "components/pages/ReportPage/ReportPage";
import RoleManagementPage from "components/pages/RoleManagementPage/RoleManagementPage";
import UserManagementPage from "components/pages/UserManagementPage/UserManagementPage";
import UserPreference from "components/pages/UserPreference/UserPreference";
import { MapLayout } from "components/templates";
import AMapLayout from "components/templates/AMapLayout/AMapLayout";
import NavigationLayout from "components/templates/NavigationLayout/NavigationLayout";

const router = createBrowserRouter([
  {
    path: "/",
    // lazy: async () => import("components/pages/AuthPage/LoginPage"),
    element: <LoginPage />,
  },
  {
    path: "/",
    element: (
      // <PrivateProvider>
      <AMapLayout />
      // </PrivateProvider>
    ),
    children: [
      {
        path: "flight-post",
        // element: <FlightPostPage />,
        element: <NewDroneListPage />,
      },
      {
        path: "flight-plan",
        element: <FlightPlanPage />,
      },
      {
        path: "drone/flight-detail/:id",
        element: <AdminFlightDetailPage />,
      },
    ],
  },
  {
    path: "/",
    element: (
      // <PrivateProvider>
      <NavigationLayout />
      // </PrivateProvider>
    ),
    errorElement: <Page404 />,
    children: [
      {
        path: "calendar",
        // lazy: async () => import("components/pages/Calendar/Calendar"),
        element: <Calendar />,
      },
      // {
      //   path: "/user-management",
      //   // lazy: async () => import("components/pages/AuthPage/LogoutPage"),
      //   element: <UserManagementPage />,
      // },
      {
        path: "/role-management",
        // lazy: async () => import("components/pages/AuthPage/LogoutPage"),
        element: <RoleManagementPage />,
      },
      {
        path: "farm-list",
        element: <FarmListPage />,
      },
      {
        path: "add-farm",
        element: <AddFarmPage />,
      },
      // {
      //   path: "farmer-report",
      //   element: <FarmerReportPage />,
      // },
      // {
      //   path: "pilot-report",
      //   element: <PilotReportPage />,
      // },
      {
        path: "report",
        element: <ReportPage />,
      },
      {
        path: "flight-post-list",
        element: <FlightPostListPage />,
      },
      {
        path: "flight-post/:id",
        element: <FlightPostDetailPage />,
      },
      {
        path: "drone-list",
        element: <DroneListPage />,
      },
      {
        path: "add-drone",
        element: <AddDronePage />,
      },
      {
        path: "edit-drone/:serialNumber",
        element: <EditDronePage />,
      },
      {
        path: "flight-record/:serialNumber",
        element: <FlightRecordDetailPage />,
      },
      {
        path: "flight-log/:id",
        element: <FlightLogDetailPage />,
      },
      {
        path: "drone/:id",
        element: <EditDronePage />,
      },
      {
        path: "farmer-list",
        element: <FarmerListPage />,
      },
      {
        path: "pilot-list",
        element: <PilotListPage />,
      },
      {
        path: "add-farmer",
        element: <AddFarmerPage />,
      },
      {
        path: "add-pilot",
        element: <AddPilotPage />,
      },
      {
        path: "/admin-service-provider-list",
        element: <AdminListPage />,
      },
      {
        path: "add-admin-service-provider",
        element: <AddAdminServiceProviderPage />,
      },
      {
        path: "farm/:id",
        element: <EditFarmPage />,
      },
      // {
      //   path: "edit-pilot-profile",
      //   element: <EditPilotProfilePage />,
      // },
      // {
      //   path: "edit-farmer-profile",
      //   element: <EditFarmerProfilePage />,
      // },
      {
        path: "edit-farmer/:id",
        element: <AdminEditFarmerProfilePage />,
      },
      {
        path: "edit-pilot/:id",
        element: <AdminEditPilotProfilePage />,
      },
      {
        path: "drone/flight-post/:serialNumber",
        element: <AdminDroneFlightPostPage />,
      },
      {
        path: "edit-admin-service-provider/:id",
        element: <AdminEditAdminServiceProviderProfilePage />,
      },
      {
        path: "edit-profile/:id",
        element: <EditProfilePage />,
      },
      {
        path: "leasing-list",
        element: <LeasingListPage />,
      },
      {
        path: "setting",
        element: <UserPreference />,
      },
      {
        path: "change-password/:id",
        element: <ChangePasswordPage />,
      },
    ],
  },
  {
    path: "/login",
    // lazy: async () => import("components/pages/AuthPage/LoginPage"),
    element: <LoginPage />,
  },
  {
    path: "/farmer-register",
    // lazy: async () => import("components/pages/AuthPage/LoginPage"),
    element: <FarmerSignupPage />,
  },
  {
    path: "/pilot-register", // lazy: async () => import("components/pages/AuthPage/LoginPage"),
    element: <PilotSignupPage />,
  },
  {
    path: "/logout",
    // lazy: async () => import("components/pages/AuthPage/LogoutPage"),
    element: <LogoutPage />,
  },
]);

const AppRouter: React.FC = (props) => {
  return (
    <React.Suspense fallback="loading...">
      <MainProvider>
        <RouterProvider router={router} />
      </MainProvider>
    </React.Suspense>
  );
};

export default AppRouter;
