import { FC } from "react";

import LicenseForm from "./LicenseForm";
import ProfilePilotForm from "./ProfilePilotForm";

interface IPilotForm {
  form: any;
  step: string;
}

const PilotForm: FC<IPilotForm> = ({ form, step }) => {
  const renderStep = (step: string) => {
    switch (step) {
      case "Pilot":
        return <ProfilePilotForm form={form} />;
      case "License":
        return <LicenseForm form={form} />;
    }
  };
  return <div className="step-container">{renderStep(step)}</div>;
};
export default PilotForm;
