import { alert } from "@ai-and-robotics-ventures/cumulus-ui";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  authRequestInterceptor,
  authResponseInterceptorError,
  authResponseInterceptorSuccess,
} from "./authFetch";

export enum MASTER_PLAN {
  POST_CREATE_PROJECT = "api/v1/masterplan/plan/project",
  POST_CREATE_MISSION = "api/v1/masterplan/plan/mission",

  GET_ALL_PROJECT = "api/v1/project/gets",
  GET_PROJECT_INFO = "api/v1/project/project-information",
  GET_DEVICE = "api/v1/project/get-devices",
  GET_MEMBER = "api/v1/project/get-member",
  GET_ALL_PROJECT_NAME = "api/v1/project/projectnames/get",

  GET_MISSION = "api/v1/mission/get",
  GET_ALL_MISSION = "api/v1/mission/search",
  GET_WAYPOINTS = "api/v1/mission/waypoint/retrieve",
  GET_BOX_BY_PROJECT_ID = "api/v1/mission/get-boxes",
  GET_DEVICE_BY_PROJECT_ID = "api/v1/mission/get-devices",
  GET_MEMBER_BY_PROJECT_ID = "api/v1/mission/get-members",
  GET_ALL_MISSION_TYPE = "api/v1/mission-template/missiontypes/get",
  GET_MISSION_TEMPLATE = "api/v1/mission-template/search",
  GET_MISSION_TEMPLATE_BY_ID = "api/v1/mission-template/get",
  GET_MISSION_TEMPLATE_BY_PROJECT_ID = "api/v1/mission-template/get/project",
  POST_CREATE_MISSION_TEMPLATE = "api/v1/mission-template/create",
  DELETE_MISSION_TEMPLATE_BY_ID = "api/v1/mission-template/delete",

  POST_MISSION_TAKEOFF = "api/v1/mission/takeoff",
  POST_MISSION_LANDING = "api/v1/mission/landing",

  GET_BOUNDARY_AREA = "api/v1/masterplan/boundary",
  GET_ALL_PUBLIC_NOFLYZONE = "api/v1/flyzone/public/restricted/latlng/get",
  GET_ALL_PROJECT_NOFLYZONE = "api/v1/flyzone/project/restricted/all/get",
  GET_ALL_PROJECT_NOFLYZONE_BY_ID = "api/v1/flyzone/project/restricted/get",

  POST_PLAN_SURVEY_PATH = "/api/v1/masterplan/plan/survey-path",

  GET_ALL_NOTIFICATION = "api/v1/notification/get/all",
  POST_NOTIFICATION_READ = "api/v1/notification/read",
  POST_NOTIFICATION_READ_ALL = "api/v1/notification/read/all",
  POST_NOTIFICATION_ACKNOWLEDGE = "api/v1/notification/acknowledge",

  GET_ALL_TEAM = "/api/v1/authentication/get/team",
  GET_MEMBER_BY_TEAM_ID = "/api/v1/authentication/get/users",
}

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    // baseURL: 'http://localhost:3000',
    // baseURL: "https://aluto-dev.arv.co.th",
    baseURL: process.env.REACT_APP_MASTER_PLAN_URL || "https://aluto-dev.arv.co.th/master-plan", // docker.for.mac.
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      // 'Access-Control-Allow-Credentials': true,
      // 'Content-Type': 'multipart/form-data',
    },
  });

  return instance;
};

const masterplan = axiosInstance();
const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  return config;
};

const responseInterceptorSuccess = (res: AxiosResponse<any, any>) => {
  return res;
};

const responseInterceptorError = (error: any) => {
  if (error?.statusCode === 401) {
    alert("Unauthorized", {
      icon: "error",
      titleText: error?.error,
      confirmButtonText: "Confirm",
    });
  }
  return Promise.resolve(error);
};

masterplan.interceptors.request.use(authRequestInterceptor as any);
masterplan.interceptors.response.use(authResponseInterceptorSuccess, authResponseInterceptorError);

masterplan.interceptors.request.use(requestInterceptor as any);
masterplan.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);

export default masterplan;
