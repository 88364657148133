import { toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { useInfiniteQuery } from "@tanstack/react-query";
import masterplan, { MASTER_PLAN } from "core/domains/services/masterplanFetch";
import { queryClient } from "core/providers/react-query";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql, useSubscription } from "urql";

export const NOTIFICATION_RECIEVED = gql`
  subscription getNotification {
    data: notificationRecieved {
      id
      title
      description
      tableName
      recordId
      recordStatus
      createdAt
    }
  }
`;

const notifyToast = (noti: NotificationLog.Item) => {
  // Refetch mission detail page & fleet page on mission status changed
  queryClient.invalidateQueries({
    predicate: (query) =>
      [MASTER_PLAN.GET_MISSION, MASTER_PLAN.GET_PROJECT_INFO].includes((query?.queryKey as any)[0]),
  });

  if (["REJECTED", "EMERGENCY", "ABORTED"].includes(noti?.recordStatus)) {
    toast({
      type: "error",
      iconName: "Warning",
      title: noti?.title,
      description: noti?.description,
      autoClose: false,
    });
  }
};

const useNotification = () => {
  const prevNotification = useRef<any[]>([]);
  const [unread, setUnread] = useState(false);

  const notificationParams = { order: "DESC", take: 10 };
  const notificationQuery = useInfiniteQuery(
    [MASTER_PLAN.GET_ALL_NOTIFICATION, notificationParams],
    ({ pageParam = 1 }) =>
      masterplan
        .get(MASTER_PLAN.GET_ALL_NOTIFICATION, {
          params: { ...notificationParams, page: pageParam },
        })
        .then(({ data }) => data),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => {
        return lastPage?.rslt?.meta?.hasNextPage && lastPage?.rslt?.meta.page + 1;
      },
      getPreviousPageParam: (lastPage: any) => {
        return lastPage?.rslt?.meta?.hasPreviousPage && lastPage?.rslt?.meta.page - 1;
      },
    }
  );

  const [notificationLog] = useSubscription(
    { query: NOTIFICATION_RECIEVED },
    (messages = [], response) => {
      prevNotification.current = messages;
      const noti = response?.data;
      notifyToast(noti);
      return [{ ...noti, status: "NEW" }, ...messages];
    }
  );

  const notiQueryData: NotificationLog.Item[] = useMemo(
    () => _.flatten(notificationQuery.data?.pages.map((page) => page?.result?.data || [])),
    [notificationQuery.data]
  );

  const notifications: NotificationLog.Item[] = useMemo(
    () => [...(notificationLog.data || []), ...notiQueryData],
    [notiQueryData, notificationLog.data]
  );

  const hasNewNoti = useMemo(
    () => !!notifications.find((notification) => notification.status === "NEW"),
    [notifications]
  );

  useEffect(() => {
    setUnread(hasNewNoti);
  }, [hasNewNoti]);

  const handleReadAll = useCallback(async () => {
    prevNotification.current = notificationLog.data;
    setUnread(false);

    await masterplan.get(MASTER_PLAN.POST_NOTIFICATION_READ_ALL);

    notificationLog.data = [];
    notificationQuery.refetch();
  }, [notificationLog, notificationQuery]);

  const handleAcknowledge = useCallback(() => {
    masterplan.post(MASTER_PLAN.POST_NOTIFICATION_ACKNOWLEDGE);
    setUnread(false);
  }, []);

  return {
    notificationLog: notifications,
    unread,
    handleReadAll,
    notificationQuery,
    handleAcknowledge,
  };
};

export default useNotification;
