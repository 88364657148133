import { Icon, InputField, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { dayjs } from "core/configs";
import { QUERY_GET_DRONE_LEASING_LIST } from "core/gql/drone";
import { client } from "core/gql/fetchgql";
import { debounce } from "lodash";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TGetDroneLeasingListResponse } from "types/drone";
import { IMeta } from "types/pagination";
import { IDroneDetail } from "types/pilot";

import ATable from "components/templates/ATable/ATable";

import LeasingForm from "./components/LeasingForm";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  .border-top {
    border-top: 1px solid #6b7280;
  }

  .header {
    display: flex;
    gap: 8px;

    .search-wrapper {
      width: 300px;
    }
  }
`;

const LeasingListPage = () => {
  const { t } = useTranslation("drone");

  const [searchName, setSearchName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [droneLeasing, setDroneLeasing] = useState<IDroneDetail[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<IDroneDetail | undefined>();

  const fetchDroneLeasingList = async ({
    currentPage,
    pageSize,
    searchName,
  }: {
    currentPage: number;
    pageSize: number;
    searchName: string;
  }) => {
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
        },
      };
      const response: TGetDroneLeasingListResponse = await client.request(
        QUERY_GET_DRONE_LEASING_LIST,
        variables
      );
      setDroneLeasing(response.getDroneLeasing.data);
      setMeta(response.getDroneLeasing.meta);
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(async (page, size) => {
      setIsLoading(true);

      await fetchDroneLeasingList({ currentPage: page, pageSize: size, searchName });

      setIsLoading(false);
    }, 300);

    debouncedFetch(currentPage, pageSize);

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchName]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchDroneLeasingList({ currentPage, pageSize, searchName });

      setIsLoading(false);
    };

    fetchData();
  }, [currentPage, pageSize]);

  const handleEditDrone = (drone: IDroneDetail) => {
    setEditingItem(drone);
    setIsOpen(true);
  };

  const handleClose = () => {
    setEditingItem(undefined);
    setIsOpen(false);
  };

  const columns = [
    {
      title: t`Drone Name`,
      dataIndex: "name",
      key: "name",
      align: "center" as AlignType,
      width: 200,
    },
    {
      title: t`Serial No.`,
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center" as AlignType,
      width: 200,
    },
    {
      title: t`Service provider`,
      dataIndex: "serviceProvider",
      key: "serviceProvider",
      align: "center" as AlignType,
      width: 200,
      render: (row: any, record: IDroneDetail) => (
        <div>{record?.droneServiceProvider?.serviceProvider?.name}</div>
      ),
    },
    {
      title: t`Expiry Date`,
      dataIndex: "expiryDate",
      key: "expiryDate",
      align: "center" as AlignType,
      width: 200,
      render: (row: any, record: IDroneDetail) => {
        return <div>{dayjs(record?.leasing?.expiryDate).utc().format("DD MMM YYYY")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: 50,
      align: "center" as AlignType,
      render: (row: any, record: IDroneDetail) => (
        <Icon icon="Edit" onClick={() => handleEditDrone(record)} />
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const handleSuccess = () => {
    setIsOpen(false);
    setEditingItem(undefined);

    fetchDroneLeasingList({ currentPage, pageSize, searchName });
  };

  const modalStyle = {
    content: {
      width: 600,
      height: "max-content",
      padding: 24,
      borderRadius: 10,
      boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)",
      background: "rgb(65, 66, 33)",
    },
  };

  return (
    <StyledWrapper>
      <h2>{t`Leasing Drone`}</h2>

      <div className="header">
        <div className="search-wrapper">
          <InputField
            placeholder={t`Search drone serial number`}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
            suffix={<Icon icon="Search" />}
          />
        </div>
      </div>

      <ATable
        isLoading={isLoading}
        columns={columns}
        data={droneLeasing}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <Modal isOpen={isOpen} onRequestClose={handleClose} style={modalStyle}>
        <LeasingForm
          droneId={editingItem?.id}
          leasing={editingItem?.leasing}
          onClose={handleClose}
          onSuccess={handleSuccess}
        />
      </Modal>
    </StyledWrapper>
  );
};

export default LeasingListPage;
