import { Button, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_FARMER_REGISTER } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { farmerSignSchema, farmerSignupDefaultValue } from "core/schema/farmer";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  FarmIconSelected,
  FarmIconWhite,
  FarmerIconNotSelectedWhite,
  FarmerIconSelected,
} from "components/templates/Icon";

import { useAuth } from "../AuthPage/store/auth";
import FarmStep from "./FarmStep/FarmStep";
import FarmerSignupPageStyled from "./FarmerSignupPage.styled";
import FarmerStep from "./FarmerStep/FarmerStep";

type TFarmerSignSchema = z.infer<typeof farmerSignSchema>;

const FarmerSignupPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Farmer");
  const {
    user,
    actions: { logout },
  } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableChangeStep, setDisableChangeStep] = useState(false);
  const modal = useSuccessModal({
    title: "Registration Successful",
    detail: (
      <>
        <div>Your registration form has been</div>
        <div>submitted to the system</div>
      </>
    ),
    callBack: () => navigate("/login"),
  });
  const formik = useFormik<TFarmerSignSchema>({
    initialValues: farmerSignupDefaultValue,
    validationSchema: toFormikValidationSchema(farmerSignSchema),
    onSubmit: (values) => handleSubmitForm(values),
  });
  const renderStep = (step: string) => {
    switch (step) {
      case "Farmer":
        return <FarmerStep form={formik} />;
      case "Farm":
        return (
          <FarmStep
            form={formik}
            noStep="2"
            totalStep="2"
            setDisableChangeStep={setDisableChangeStep}
          />
        );
    }
  };

  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);

      const farmList = values.farms.map((f: any) => {
        return {
          address: f.address,
          coordinates: [f.coordinates[0]],
          districtCode: f.districtCode,
          farmType: f.farmType,
          imageList: f.imageList,
          name: f.name,
          postcode: f.postcode,
          provinceCode: f.provinceCode,
          subdistrictCode: f.subdistrictCode,
          totalArea: f.totalArea,
        };
      });
      const farmerInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        email: values.email,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        address: values.address,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        subdistrictCode: values.subdistrictCode,
        postcode: values.postcode,
        password: values.password,
        farms: farmList,
      };
      const variables = {
        farmerInput: farmerInput,
      };
      await client.request(MUTATION_FARMER_REGISTER, variables);
      modal.openModal();

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response?.errors[0]?.message === "COORDINATE") {
        toast({
          title: "",
          description:
            "Registration Error: This user is already registered. Please choose a different email address.",
          type: "error",
        });
      } else if (error.response?.errors[0]?.message) {
        toast({
          title: "",
          description:
            "Registration Error: Please define the farm area before proceeding with registration.",
          type: "error",
        });
      } else {
        toast({
          title: "",
          description:
            "Registration Error: Unable to process your registration at the moment. Please try again",
          type: "error",
        });
      }
    }
  };
  const handleChangeStep = (value: string) => {
    if (!disableChangeStep) {
      setStep(value);
    }
  };
  // const errors = formik.errors;

  useEffect(() => {
    if (user) {
      if (user.exp > Date.now() / 1000) {
        if (user.roles.includes("FARMER")) {
          navigate("/farm-list");
        }
        if (user.roles.includes("PILOT")) {
          navigate("/report");
        }
        if (user.roles.includes("ADMIN")) {
          navigate("/admin-service-provider-list");
        }
        if (user.roles.includes("ADMIN_SERVICE_PROVIDER")) {
          navigate("/flight-post");
        }
        if (user.roles.includes("LEASING")) {
          navigate("/leasing-list");
        }
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [user]);
  return (
    <FarmerSignupPageStyled>
      <form onSubmit={formik.handleSubmit}>
        <div className="container">
          <h1
            style={{
              padding: "32px 16px",
              fontWeight: "bold",
              marginTop: 0,
            }}
          >
            Farmer Registration
          </h1>
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Farmer",
                    selectedIcon: <FarmerIconSelected />,
                    notSelectedIcon: <FarmerIconNotSelectedWhite />,
                    // isError: checkIsError(errors, [
                    //   "firstName",
                    //   "lastName",
                    //   "nationalIdNo",
                    //   "email",
                    //   "phoneNo",
                    //   "address",
                    //   "nationality",
                    //   "password",
                    //   "confirmPassword",
                    //   "postcode",
                    //   "provinceCode",
                    //   "subdistrictCode",
                    //   "districtCode",
                    // ]),
                  },
                  {
                    label: "Farm",
                    selectedIcon: <FarmIconSelected />,
                    notSelectedIcon: <FarmIconWhite />,
                    // isError: checkIsError(errors, [
                    //   "firstName",
                    //   "lastName",
                    //   "nationalIdNo",
                    //   "email",
                    //   "phoneNo",
                    //   "license",
                    //   "password",
                    //   "confirmPassword",
                    // ]),
                  },
                ]}
                selected={step}
                onClick={handleChangeStep}
              />
            </div>
            {renderStep(step)}
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/login"}>
                <Button className="cancel-button">Cancel</Button>
              </Link>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                <span style={{ marginLeft: "8px" }}>Register</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </FarmerSignupPageStyled>
  );
};

export const Component = FarmerSignupPage;
export default FarmerSignupPage;
