import storage, { STORAGE_PREFIX } from "core/domains/utils/storage";
import { client } from "core/gql/fetchgql";
import { QUERY_USER_INFO } from "core/gql/user";
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import jwtDecode from "jwt-decode";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { TUserInfoResponse } from "types/user";

export type User = User.User;
export const initialAccessControl = {
  DOCUMENT: false,
  DRAFT_ABLE: false,
  FLIGHT_WATCHER: false,
  FLYZONE_MANAGER: false,
  FLYZONE_WATCHER: false,
  MISSION_TYPES_GET_ALL: false,
  MISSION_WATCHER: false,
  NOTI: false,
  OCCUPANCY_WATCHER: false,
  OI_WATCHER: false,
  OV_WATCHER: false,
  PATH_FINDER_WATCHER: false,
  PLAN_ABLE: false,
  RESERVED_MUTATION: false,
  RESERVED_WATCHER: false,
  TEMPLATE_CREATE: false,
  TEMPLATE_DELETE: false,
  TEMPLATE_GET: false,
  TEMPLATE_GET_BY_OI: false,
  TEMPLATE_SEARCH: false,
  TEST: false,
  UTM_WATCHER: false,
  WAYPOINT_ACTIONS_GET_ALL: false,
};

export const userAtom = atomWithStorage<User | undefined>(`${STORAGE_PREFIX}user`, undefined);
export const permissionsAtom = atom<User.Permission[]>([]);
export const accessControlAtom = atom(initialAccessControl);

export const useAuth = () => {
  const [user, setUser] = useAtom(userAtom);
  const [permissions, setPermissions] = useAtom(permissionsAtom);
  const [accessControl, setAccessControl] = useAtom(accessControlAtom);

  const actions = useMemo(
    () => ({
      login: async (accessToken: string) => {
        const userToken: User = jwtDecode(accessToken);

        storage.set("access_token", accessToken);

        const userInfo: TUserInfoResponse = await client.request(QUERY_USER_INFO);

        let user = userToken;

        if (userInfo?.getUserInfo) {
          user = {
            ...userToken,
            ...{
              roles: [userInfo?.getUserInfo?.role?.name as User.Role],
              userId: userInfo?.getUserInfo?.id,
              firstName: userInfo?.getUserInfo?.firstName,
              lastName: userInfo?.getUserInfo?.lastName,
              preference: userInfo?.getUserInfo?.userPreference,
            },
          };
        }

        setUser(user);

        // console.log()
        // storage.set("refresh_token", refreshToken);
        // origin && storage.set("origin", origin);
      },
      setUser: (user: User) => {
        setUser(user);
      },
      setPermission: (permissions: User.Permission[]) => {
        const accessControl = permissions.reduce((access = initialAccessControl, permission) => {
          access[permission?.topic] = permission?.value === "true";
          return access;
        }, initialAccessControl);
        setPermissions(permissions);
        setAccessControl(accessControl);
      },
      logout: () => {
        setUser(undefined);
        storage.remove("access_token");
        storage.remove("refresh_token");
      },
    }),
    [setAccessControl, setPermissions, setUser]
  );

  return {
    user,
    permissions,
    actions,
    accessControl,
  };
};
