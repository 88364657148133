import { gql } from "graphql-request";

export const QUERY_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      id
      firstName
      lastName
      email
      telNo
      role {
        name
      }
      userPreference {
        language
        unit {
          area
        }
      }
    }
  }
`;

export const SAVE_USER_PREFERENCE = gql`
  mutation saveUserPreference($preferenceInput: UserPreferenceInput!) {
    saveUserPreference(preferenceInput: $preferenceInput)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(changePasswordInput: $input) {
      success
    }
  }
`;
