import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

const Wrapper = styled.div`
  border-radius: 16px;
  background: #665c4d;
  padding: 24px;
  flex: 1;
  .header-section {
    display: flex;
    gap: 16px;
    align-items: center;
    .report-card-icon-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 10px;
      background-color: #64cff6;
      display: flex;
      justify-content: center;
    }
    .report-card-title {
      color: #f9fafb;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .report-card-value {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: space-between;
    }
    .percent-change-value {
      padding: 4px 10px;
      border-radius: 10px;
      background: #9dfac4;
      color: #219653;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

interface IReportCard {
  title: string;
  value: string | number;
  children: ReactNode;
}
const ReportCard: FC<IReportCard> = ({ title, value, children }) => {
  return (
    <Wrapper>
      <div className="header-section">
        <div className="report-card-icon-wrapper">
          <Icon icon="File" size={24} />
        </div>
        <div style={{ width: "100% " }}>
          <div className="report-card-title">{title}</div>
          <div className="report-card-value">
            <span>{value}</span>
          </div>
        </div>
      </div>
      {children}
    </Wrapper>
  );
};
export default ReportCard;
