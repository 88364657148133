import { FC } from "react";

import AddressFarmerForm from "./AddressFarmerForm";
import ProfileFarmerForm from "./ProfileFarmerForm";

interface IFarmerForm {
  form: any;
  step: string;
}

const FarmerForm: FC<IFarmerForm> = ({ form, step }) => {
  const renderStep = (step: string) => {
    switch (step) {
      case "Farmer":
        return <ProfileFarmerForm form={form} />;
      case "Address":
        return <AddressFarmerForm form={form} />;
    }
  };
  return <div className="step-container">{renderStep(step)}</div>;
};
export default FarmerForm;
