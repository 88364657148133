import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "polished";

import Avatar from "./Avatar";

const EventWrapper = styled.div`
  padding: 16px 32px 16px 16px;
  background-color: ${({ theme }) => theme.color.graySecondary[900]};
  border-radius: 20px;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const BlackDrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.color.graySecondary[800], 0.8)};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 2;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const StyledImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 14px;
`;

export type CardSize = "sm" | "md" | "lg";
export type CardStatus = "cancelled";

export type Event = {
  title: string;
  description: string;
  image: string;
  avatars: string[];
  start?: Date;
  end?: Date;
  size?: CardSize;
  border?: boolean;
  status?: CardStatus;
};

const EventCard: React.FC<Event> = (props) => {
  const theme = useTheme();
  return (
    <EventWrapper
      style={{
        height: props.size === "lg" ? "100%" : "auto",
        border: props.border ? `1px solid ${theme.color.bluePrimary[400]}` : "0",
        alignItems: props.size === "lg" ? "flex-start" : "center",
        backgroundColor: props.status === "cancelled" ? "" : "",
      }}
    >
      <BlackDrop hidden={props.status !== "cancelled"} />
      <Wrapper>
        <StyledImage src={props.image} hidden={props.size === "sm"} />
        <FullWidth>
          <TitleWrapper>
            <Text variant="subtitle1">{props.title}</Text>
            {props.avatars && <Avatar avatars={props.avatars} />}
          </TitleWrapper>
          <Text
            variant="body4"
            css={{ color: theme.color.grayPrimary[600] }}
            hidden={props.size === "sm"}
          >
            {props.description}
          </Text>
        </FullWidth>
      </Wrapper>
    </EventWrapper>
  );
};

export default EventCard;
