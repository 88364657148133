import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import {
  QUERY_DRONE_BY_SERIAL_NUMBER,
  QUERY_FLIGHT_POST_BY_DRONE_SERIAL_NUMBER,
} from "core/gql/pilot";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import { droneBrandList } from "core/utils/constant";
import { getLabelFromConstant } from "core/utils/form";
import dayjs from "dayjs";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TFlightPostByDroneResponse, TFlightPostDetail } from "types/flightPost";
import { IMeta } from "types/pagination";
import { IDroneDetail, TDroneItemResponse } from "types/pilot";

import ATable from "components/templates/ATable/ATable";
import Badge from "components/templates/Badge/Badge";

import { useAuth } from "../AuthPage/store/auth";
import FlightPostSummary from "../FlightPostListPage/FlightPostSummary";
import FlightRecordDetailPageStyled from "./FlightRecordDetailPage.styled";

const columns = [
  {
    title: "Flight",
    dataIndex: "name",
    key: "name",
    width: 436,
    render: (data: string, record: TFlightPostDetail) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ objectFit: "cover" }}
            src={record.farm.imageListUrls[0]}
            alt={record.name}
            width={104}
            height={60}
          />
          <div style={{ marginLeft: "16px" }}>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "150%",
                color: "#F9FAFB",
              }}
            >
              {data}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "300",
                lineHeight: "150%",
                color: "#9CA3AF",
              }}
            >
              {record.farm.name}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "300",
                lineHeight: "150%",
                color: "#9CA3AF",
              }}
            >
              {dayjs(record.startTime).format("DD MMM YYYY")} -{" "}
              {dayjs(record.endTime).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    title: "Total Flight Hour",
    dataIndex: "flightDuration",
    key: "flightDuration",
    width: 200,
    align: "center" as AlignType,
    render: (data: number) => {
      if (data) {
        const duration = dayjs.duration(data, "seconds");
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${hours} H ${minutes} M`;
      }
      return <div>-</div>;
    },
  },
  {
    title: "Area of Operation",
    dataIndex: "totalArea",
    key: "totalArea",
    width: 380,
    align: "center" as AlignType,
    render: (data: number) => {
      return Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(data);
    },
  },
  {
    title: "Farmer",
    dataIndex: "createDate",
    key: "createDate",
    width: 150,
    align: "center" as AlignType,
    render: (data: string, record: TFlightPostDetail) => {
      if (record.pilot?.firstName) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {record.farmer.firstName} {record.farmer.lastName}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "150%",
            color: "#F9FAFB",
          }}
        >
          -
        </div>
      );
    },
  },
  {
    title: "Pilot",
    dataIndex: "Pilot",
    key: "Pilot",
    width: 150,
    align: "center" as AlignType,
    render: (_: string, record: TFlightPostDetail) => {
      if (record.pilot?.firstName) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {record.pilot.firstName} {record.pilot.lastName}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.drone?.name}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "150%",
            color: "#F9FAFB",
          }}
        >
          -
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
    align: "center" as AlignType,
    render: (data: string, record: TFlightPostDetail) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          <Badge label={data} type="success" />
          <Link to={"/flight-log/" + record.id}>
            <Icon icon="DotsVertical" />
          </Link>
        </div>
      );
    },
  },
];

const FlightRecordDetailPage = () => {
  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [flightPostList, setFlightPostList] = useState<TFlightPostDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [droneDetail, setDroneDetail] = useState<IDroneDetail>();
  const { serialNumber } = useParams();
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  // const fetchFlightPostList = async (currentPage: number, pageSize: number) => {
  //   try {
  //     const variables = {
  //       options: {
  //         currentPage,
  //         itemsPerPage: pageSize,
  //       },
  //       droneSerial: serialNumber,
  //     };
  //     const response: TFlightPostByDroneResponse = await client.request(
  //       QUERY_FLIGHT_POST_BY_DRONE_SERIAL_NUMBER,
  //       variables
  //     );
  //     console.log("response", response);
  //     setFlightPostList(response.flightPostByDroneSerialNumber.data);
  //     setMeta(response.flightPostByDroneSerialNumber.meta);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   setIsLoading(false);
  // };

  const fetchDroneData = async () => {
    try {
      const variables = {
        serialNumber: serialNumber,
      };
      const response: TDroneItemResponse = await client.request(
        QUERY_DRONE_BY_SERIAL_NUMBER,
        variables
      );
      setDroneDetail(response.droneBySerialNumber);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // fetchFlightPostList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchDroneData();
  }, []);

  return (
    <FlightRecordDetailPageStyled>
      <div className="flight-record-detail-header">
        <Link to={"/drone-list"}>
          <div className="back-button">
            <Icon icon="ArrowBackward" size={24} />
            <div className="back-button-label">Back to Drone List</div>
          </div>
        </Link>
        <div className="flight-record-detail-body">
          <div className="flight-record-title">
            <span>Flight Record</span>
          </div>
          <div className="flight-record-detail-other">
            <div className="flight-record-detail-box">
              <span className="label">Drone :</span>
              <span className="value">{droneDetail?.name}</span>
            </div>
            <div className="flight-record-detail-box">
              <span className="label">Model :</span>
              <span className="value">
                {getLabelFromConstant(droneBrandList, droneDetail?.model || "")}
              </span>
            </div>
            <div className="flight-record-detail-box">
              <span className="label">Serial :</span>
              <span className="value">{serialNumber}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="action-header">
        <FlightPostSummary
          totalFlightHour={droneDetail?.totalFlightDuration}
          totalDistance={droneDetail?.totalFlightDistance}
          totalSprayedArea={droneDetail?.totalFlightArea}
          totalSprayedWater={"0"}
          totalFertilizerSprayed={"0"}
          areaUnitLabel={areaUnitLabel}
        />
        {/* <div className="action-box">
          <SearchInput />
          <div className="wrapper-btn">
            <Button>
              <UploadFlightLogIcon style={{ marginRight: "10px" }} />
              Upload flight log
            </Button>
            <Button>
              <AddFlightPostIcon style={{ marginRight: "10px" }} />
              Upload flight plan
            </Button>
          </div>
        </div> */}
      </div>
      <ATable
        isLoading={isLoading}
        columns={columns}
        data={flightPostList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </FlightRecordDetailPageStyled>
  );
};
export default FlightRecordDetailPage;
