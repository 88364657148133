import styled from "@emotion/styled";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

export const options = {
  responsive: true,
  interaction: {
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart - Multi Axis",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: false,
      position: "left" as const,
    },
    x: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [123, 451, 3, 154, 123, 41],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      fill: true,
      tension: 0.4,
    },
  ],
};

const Wrapper = styled.div`
  border-radius: 15px;
  padding: 16px;
  border: 1px solid #6b7a99;
  display: flex;
  justify-content: space-between;
  font-family: Poppins;

  .small-text {
    color: #f9fafb;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    .gray {
      color: #b5b2b2;
    }
  }
  .value {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
  }
`;

interface LineChartProps {
  title: string;
  value: string | number;
  desc?: string | React.ReactNode;
  showChart?: boolean;
}

const LineChart: React.FC<LineChartProps> = ({ showChart, title, value, desc }) => {
  return (
    <Wrapper>
      <div>
        <div className="small-text">{title}</div>
        <div className="value">{value}</div>
        {desc && <div className="small-text">{desc}</div>}
      </div>

      {showChart && (
        <div style={{ height: "70px", width: "150px" }}>
          <Line options={options} data={data} />
        </div>
      )}
    </Wrapper>
  );
};
export default LineChart;
