import styled from "@emotion/styled";

const FlightPlantListStepStyled = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  .flight-post-box {
    width: 332px;
    backdrop-filter: blur(5px);
    background: rgba(102, 92, 77, 0.9);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: scroll;
    padding-bottom: 100px;

    .selected {
      border: 1px solid #ffb32c;
      border-radius: 10px;
      background: #665c4d;
    }
    .flight-post-filter-list {
      display: flex;
      gap: 8px;
    }
  }
  .footer-flight-post {
    padding: 24px 16px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(102, 92, 77, 0.6) 0%,
      rgba(102, 92, 77, 0.53) 45.71%,
      rgba(102, 92, 77, 0) 100%
    );
    backdrop-filter: blur(10px);
    width: 100%;

    button {
      background-color: #ffb32c;
      color: white;
    }
  }
`;

export default FlightPlantListStepStyled;
