import ColorSwatch from "./colors";

export const aliasColor = {
  default: {
    text: {
      disabled: ColorSwatch.grayPrimary[50],
    },
    bg: {
      disabled: ColorSwatch.grayPrimary[900],
    },
    border: {
      disabled: ColorSwatch.grayPrimary[900],
    },
  },
  primary: {
    text: {
      default: ColorSwatch.bluePrimary[50],
      disabled: ColorSwatch.bluePrimary[50],
    },
    bg: {
      default: ColorSwatch.bluePrimary.base,
      hover: ColorSwatch.bluePrimary[700],
      active: ColorSwatch.bluePrimary[900],
    },
    border: {
      default: ColorSwatch.bluePrimary.base,
      hover: ColorSwatch.bluePrimary[700],
      focus: ColorSwatch.bluePrimary[300],
      active: ColorSwatch.bluePrimary[900],
    },
  },
  secondary: {
    text: {
      default: ColorSwatch.bluePrimary[50],
      disabled: ColorSwatch.bluePrimary[50],
    },
    bg: {
      default: ColorSwatch.bluePrimary.base,
      hover: ColorSwatch.bluePrimary[700],
      active: ColorSwatch.bluePrimary[900],
    },
    border: {
      default: ColorSwatch.grayPrimary[800],
      hover: ColorSwatch.bluePrimary[700],
      focus: ColorSwatch.grayPrimary[800],
      active: ColorSwatch.grayPrimary[900],
    },
  },
  error: {
    text: {},
    bg: {
      default: ColorSwatch?.error?.base,
      hover: ColorSwatch?.error?.[700],
      focus: ColorSwatch?.error?.base,
      active: ColorSwatch?.error?.[900],
    },
    border: {
      default: ColorSwatch?.error?.base,
      hover: ColorSwatch?.error?.[700],
      focus: ColorSwatch?.error?.[300],
      active: ColorSwatch?.error?.[900],
    },
  },
};
