import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import Select, { Option, SelectProps } from "rc-select";
import { useEffect, useState } from "react";

const StyledWrapper = styled.div<{ isMultipleHasValue: boolean }>`
  .rc-select-selector {
    align-items: center;

    .rc-select-selection-overflow {
      padding-left: 16px;
      width: ${({ isMultipleHasValue }) => (isMultipleHasValue ? "100%" : 0)};
    }

    .rc-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export interface ISelectOption {
  value: string;
  label: string;
}
interface IASelectProps {
  options: ISelectOption[];
  actionOption?: React.ReactNode;
  placeholder: string;
  label?: string;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  disabled?: boolean;
  onClear?: () => void;
  onSearch?: (value: string) => void;
}

const ASelect: React.FC<IASelectProps & SelectProps> = ({
  options,
  placeholder = "",
  label,
  value,
  required,
  onChange,
  onClear,
  onSearch,
  error,
  disabled = false,
  actionOption,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [isMultipleHasValue, setIsMultipleHasValue] = useState<boolean>(false);

  useEffect(() => {
    setIsMultipleHasValue(!isEmpty(value) && props.mode === "multiple");
  }, [props.mode, value]);

  const handleActionOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const handleSearch = (inputValue: string) => {
    if (!inputValue) {
      setIsMultipleHasValue(false);
    } else {
      setIsMultipleHasValue(true);
    }

    if (onSearch) {
      onSearch(inputValue);
    }
  };

  return (
    <StyledWrapper isMultipleHasValue={isMultipleHasValue}>
      {label && (
        <div style={{ fontWeight: 400, lineHeight: "150%", fontSize: "16px", marginBottom: "4px" }}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </div>
      )}
      <Select
        allowClear
        showSearch
        suffixIcon={<Icon icon="ChevronDown" />}
        value={value === "" ? null : value}
        placeholder={placeholder}
        onChange={onChange}
        onClear={onClear && onClear}
        onSearch={handleSearch}
        open={open}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        filterOption={(inputValue, option) => {
          if (!inputValue) {
            return true;
          }

          return (
            (option?.label as string)
              .toLocaleLowerCase()
              .includes(inputValue.toLocaleLowerCase()) ||
            (option?.label as string).toLocaleLowerCase().includes("action option")
          );
        }}
        {...props}
      >
        {options.map((op) => {
          return (
            <Option value={op.value} key={op.value} label={op.label}>
              {op.label}
            </Option>
          );
        })}

        {actionOption && (
          <Option value="action-option" key="action-option" label="action option">
            <div onClick={handleActionOptionClick}>{actionOption}</div>
          </Option>
        )}
      </Select>

      {error && (
        <div>
          <span className="error-message">{error}</span>
        </div>
      )}
    </StyledWrapper>
  );
};

export default ASelect;
