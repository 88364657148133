import styled from "@emotion/styled";

const EditDronePageStyled = styled.div`
  .title {
    color: #fff;

    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  .value {
    color: #9ca3af;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */
  }

  .edit-drone-container {
    .form {
      border-radius: 12px;
      background: #414221;
      min-height: calc(100vh - 164px);
      padding: 40px 16px 20px 0;
      height: 100%;
      position: relative;
      .step-section {
        position: absolute;
        left: 100px;
        top: 0;
      }
    }
    .footer {
      display: flex;
      width: 100%;
      height: 100px;
      padding: 8px 24px;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      position: sticky;
      bottom: 0;
      left: 0;
      background: #29371c;
      flex-direction: row;
      .disable-btn {
        background: transparent;
        color: white;
        border: 1px solid #db5959;
        width: 110px;
      }

      .footer-group {
        display: flex;
        width: 360px;
        align-items: flex-start;
        gap: 16px;
        .cancel-button {
          background: #fff;
        }
      }
    }
  }
`;

export default EditDronePageStyled;
