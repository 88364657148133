import { css } from "@emotion/react";

const styles = ({ selected }: { selected: boolean }) => css`
  display: flex;
  width: 62px;
  padding: 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: ${selected ? "#1A1A1A" : "transparent"};
  cursor: pointer;
  .value {
    color: ${selected ? "#FFB32C" : "white"};
    font-family: Kanit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .label {
    color: ${selected ? "#FFB32C" : "#9CA3AF"};
    font-family: Kanit;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
`;

interface IFPFilterItem {
  value: string;
  label: string;
  selected: boolean;
  onClick: (value: string) => void;
}
const FPFilterItem = ({ value, label, selected, onClick }: IFPFilterItem) => {
  return (
    <div css={styles({ selected })} onClick={() => onClick(label)}>
      <div className="value">{value}</div>
      <div className="label">{label}</div>
    </div>
  );
};

export default FPFilterItem;
