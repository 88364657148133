import styled from "@emotion/styled";

const FlightLogDetailPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .flight-record-detail-header {
    display: inline-flex;
    align-items: center;
    gap: 16px;

    .back-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      align-self: stretch;

      .back-button-label {
        color: #9ca3af;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .flight-record-detail-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .flight-record-title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .flight-record-detail-other {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .flight-record-detail-box {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          .label {
            color: #9ca3af;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
          }
          .value {
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
          }
        }
      }
    }
  }
  .action-header {
    display: flex;
    align-items: center;
    gap: 16px;
    .action-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .wrapper-btn {
        display: flex;
        gap: 16px;
        button {
          color: white;
          background: #ffb32c;
        }
      }
    }
  }
`;

export default FlightLogDetailPageStyled;
