import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import masterplan, { MASTER_PLAN } from "../../domains/services/masterplanFetch";
import { MissionType } from "./enums";

type GetAllMissionTemplateTypeResult = {
  name: string;
  value: MissionType;
};

const useGetAllMissionTypeQuery = () =>
  useQuery<AxiosResponse<Query.QueryResult.Response<GetAllMissionTemplateTypeResult[]>>>(
    [MASTER_PLAN.GET_ALL_MISSION_TYPE],
    async () => await masterplan.get(MASTER_PLAN.GET_ALL_MISSION_TYPE)
  );

export default useGetAllMissionTypeQuery;
