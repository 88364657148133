import styled from "@emotion/styled";

const FarmStepStyled = styled.div`
  margin-left: 300px;

  button.save-button {
    width: 84px;
    border-radius: 10px;
    border: 1px solid #ffc256;
    opacity: 0.5;
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: transparent;
    background-color: transparent !important;
    color: #ffc256;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .add-more-farm-btn {
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #ffc256;
    cursor: pointer;
  }
`;

export default FarmStepStyled;
