import { gql } from "graphql-request";

export const MUTATION_FARMER_REGISTER = gql`
  mutation registerFarmer($farmerInput: RegisterFarmerInput!) {
    registerFarmer(farmerRegisterInput: $farmerInput)
  }
`;

export const MUTATION_UPDATE_FARM = gql`
  mutation updateFarm($farmId: String!, $farm: UpdateFarmInput!) {
    updateFarm(farmId: $farmId, farm: $farm)
  }
`;

export const MUTATION_ADD_FARM = gql`
  mutation addFarm($farm: [FarmInput!]!) {
    addFarm(farm: $farm)
  }
`;

export const QUERY_MY_FARM = gql`
  query myFarms($options: PaginationInput!) {
    myFarms(options: $options) {
      data {
        id
        name
        farmType
        totalArea
        address
        createdAt
        isActive
        provinceCode
        districtCode
        subdistrictCode
        postcode
        imageListUrls
        geom {
          coordinates
        }
        imageList {
          ext
          uid
          file_name
          unique_id
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
export const QUERY_MY_FARM_FLIGHT_POST = gql`
  query myFarms($options: PaginationInput!) {
    myFarms(options: $options) {
      data {
        id
        name
        totalArea
      }
    }
  }
`;

export const QUERY_FARM_BY_ID = gql`
  query farmId($input: String!) {
    farmById(farmId: $input) {
      name
      farmType
      totalArea
      address
      createdAt
      isActive
      provinceCode
      districtCode
      subdistrictCode
      postcode
      geom {
        coordinates
      }
      imageList {
        ext
        uid
        file_name
        unique_id
      }
      imageListUrls
    }
  }
`;

export const QUERY_FARMER_GET_PROFILE = gql`
  query farmerGetProfile {
    farmerGetProfile {
      email
      id
      firstName
      lastName
      nationalIdNo
      nationality
      phoneNo
      address
      provinceCode
      districtCode
      subdistrictCode
      postcode
      createdAt
      totalFlightDuration
      totalFlightDistance
      totalFlightArea
    }
  }
`;

export const MUTATION_UPDATE_FARMER_PROFILE = gql`
  mutation updateFarmerProfile($farmer: UpdateFarmerInput!) {
    updateFarmerProfile(farmer: $farmer)
  }
`;

export const QUERY_MY_FLIGHT_POST = gql`
  query farmerGetMyFlightPost($options: PaginationInput!, $flightStatus: [EPostStatus]) {
    farmerGetMyFlightPost(options: $options, flightStatus: $flightStatus) {
      data {
        id
        name
        description
        startTime
        endTime
        status
        farmId
        farmerId
        createdAt
        averageSpeed
        totalSprayWater
        totalSprayFertilizer
        totalDistance
        totalArea
        flightDuration
        pilot {
          firstName
          lastName
        }
        drone {
          name
        }
        farm {
          name
          totalArea
          imageListUrls
          geom {
            coordinates
          }
        }
      }
    }
  }
`;

export const QUERY_COUNT_FLIGHT_POST = gql`
  query countMyFlightPost {
    countMyFlightPost {
      role
      all
      pending
      accepted
      ready
      inFlight
      cancel
      finish
    }
  }
`;
