import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { IUploadDetail } from "core/domains/services/upload";
import { QUERY_COUNT_FLIGHT_POST } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import {
  MUTATION_ACCEPT_FLIGHT_POST,
  MUTATION_CANCEL_FLIGHT_POST,
  MUTATION_DECLINE_FLIGHT_POST,
  MUTATION_FINISH_FLIGHT_POST,
  MUTATION_READY_FLIGHT_POST,
  MUTATION_START_FLIGHT_POST,
  QUERY_AVAILABLE_FLIGHT_POST,
  QUERY_MY_PILOT_FLIGHT_POST,
} from "core/gql/pilot";
import useErrorModalOnly from "core/hooks/useErrorModalOnly";
import { useEffect, useState } from "react";
import {
  EPostStatus,
  IAddFlightPlanData,
  TAvailableFlightPostResponse,
  TCountFlightPost,
  TCountFlightPostResponse,
  TFlightPostDetail,
  TPilotMYFlightPostResponse,
} from "types/flightPost";

import Loading from "components/templates/Loading/Loading";

import AddFlightPlanStep from "./AddFlightPlanStep/AddFlightPlanStep";
import FlightPlantListStep from "./FlightPlantListStep/FlightPlantListStep";

const FlightPlanPage = (props: any) => {
  const [step, setStep] = useState<string>("list");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterSelected, setFilterSelected] = useState<string>("ALL");
  const [count, setCount] = useState<TCountFlightPost>();

  const [selectedFlightPost, setSelectedFlightPost] =
    useState<TFlightPostDetail | undefined>(undefined);
  const [flightPostList, setFlightPostList] = useState<TFlightPostDetail[]>([]);
  const errorAcceptFlightPostModal = useErrorModalOnly({
    title: "Error Accept Flight Post",
    detail: (
      <div>
        <div>By accepting this flight</div>
        <div>It appears that the flight post has already been accepted by another user</div>
      </div>
    ),
    icon: <Icon icon="Warning" size={40} style={{ color: "#EB3434" }} />,
    callBackOnOk: () => {
      handleRefetch();
    },
  });
  const handleAcceptFlightPost = async (id: string) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setIsLoading(true);
      setSelectedFlightPost(found);
      try {
        const variables = {
          flightPostId: found.id,
        };
        await client.request(MUTATION_ACCEPT_FLIGHT_POST, variables);
        setStep("create");
        handleRefetch();
      } catch (error) {
        errorAcceptFlightPostModal.openModal();
        setIsLoading(false);
      }
    }
  };

  const toStepCreateFlightPlan = async (id: string) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setSelectedFlightPost(found);
      setStep("create");
    }
  };
  const handleDeclineFlightPost = async (id: string) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setIsLoading(true);
      setSelectedFlightPost(found);
      try {
        const variables = {
          flightPostId: found.id,
        };
        await client.request(MUTATION_DECLINE_FLIGHT_POST, variables);
        handleRefetch();
        setStep("list");
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const handleCancelFlightPost = async (id: string, reason: string) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setIsLoading(true);
      setSelectedFlightPost(found);
      try {
        const variables = {
          flightPostId: found.id,
          cancelReason: reason,
        };
        await client.request(MUTATION_CANCEL_FLIGHT_POST, variables);
        handleRefetch();
        setStep("list");
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const handleFinishFlightPost = async (id: string, flightLog: IUploadDetail) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setIsLoading(true);
      setSelectedFlightPost(found);
      try {
        const variables = {
          flightPostId: found.id,
          flightLog: flightLog,
        };
        await client.request(MUTATION_FINISH_FLIGHT_POST, variables);
        handleRefetch();
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const handleOnChangeFilter = (filter: string) => {
    setFilterSelected(filter);
  };

  const handleStartFlightPost = async (id: string) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setIsLoading(true);
      setSelectedFlightPost(found);
      try {
        const variables = {
          flightPostId: found.id,
        };
        await client.request(MUTATION_START_FLIGHT_POST, variables);
        handleRefetch();
        setStep("list");
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const fetchAvailableFlightPost = async () => {
    try {
      const variables = {
        options: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
      };
      const response: TAvailableFlightPostResponse = await client.request(
        QUERY_AVAILABLE_FLIGHT_POST,
        variables
      );
      setFlightPostList(response.availableFlightPost.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchMyFlightPost = async (status?: EPostStatus | EPostStatus[]) => {
    try {
      const variables = {
        options: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
        flightStatus: status,
      };
      const response: TPilotMYFlightPostResponse = await client.request(
        QUERY_MY_PILOT_FLIGHT_POST,
        variables
      );
      setFlightPostList(response.pilotGetMyFlightPost.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleReadyFlightPost = async (values: IAddFlightPlanData) => {
    try {
      setIsLoading(true);
      const waypoints = values.coordinates.map((cb, index) => {
        if (values.waypoints[index]) {
          return {
            waypoint: cb,
            altitude: values.waypoints[index].altitude,
            speed: values.waypoints[index].speed,
          };
        } else {
          return {
            waypoint: cb,
            altitude: 0,
            speed: 0,
          };
        }
      });

      const variables = {
        waypoints,
        flightPostId: selectedFlightPost?.id,
        droneSerial: values.droneSerialNumber,
      };
      await client.request(MUTATION_READY_FLIGHT_POST, variables);
      handleRefetch();
      setStep("list");
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchCountFlightPost = async () => {
    try {
      const response: TCountFlightPostResponse = await client.request(QUERY_COUNT_FLIGHT_POST);
      setCount(response.countMyFlightPost);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchMyFlightPost();
    fetchCountFlightPost();
  }, []);

  useEffect(() => {
    if (filterSelected) {
      handleRefetch();
    }
  }, [filterSelected]);

  const handleRefetch = () => {
    setIsLoading(true);
    fetchCountFlightPost();
    if (filterSelected) {
      switch (filterSelected) {
        case "AVAILABLE":
          fetchAvailableFlightPost();
          break;
        case "ACCEPTED":
          fetchMyFlightPost(EPostStatus.ACCEPTED);
          break;
        case "READY":
          fetchMyFlightPost(EPostStatus.READY);
          break;
        case "PENDING":
          fetchMyFlightPost(EPostStatus.PENDING);
          break;
        case "IN FLIGHT":
          fetchMyFlightPost(EPostStatus.IN_FLIGHT);
          break;

        default:
          // all
          fetchMyFlightPost();
          break;
      }
    }
  };

  const renderStep = (step: string) => {
    switch (step) {
      case "list":
        return (
          <FlightPlantListStep
            count={count}
            onChangeFilter={handleOnChangeFilter}
            filter={filterSelected}
            data={flightPostList}
            onAcceptFlightPost={handleAcceptFlightPost}
            onStartFlightPost={handleStartFlightPost}
            onDeleteFlightPost={handleDeclineFlightPost}
            toStepCreateFlightPlan={toStepCreateFlightPlan}
            onCancelFlightPost={handleCancelFlightPost}
            onFinishFlightPost={handleFinishFlightPost}
          />
        );
      case "create":
        return (
          <AddFlightPlanStep
            onBack={(value: string) => setStep(value)}
            data={selectedFlightPost}
            handleReadyFlightPost={handleReadyFlightPost}
          />
        );
    }
  };

  return (
    <div>
      <errorAcceptFlightPostModal.ErrorModal />

      {isLoading && <Loading />}
      {renderStep(step)}
    </div>
  );
};

export default FlightPlanPage;
