import { z } from "zod";

export const farmSchema = z.object({
  farms: z
    .object({
      name: z.string({
        required_error: "name is a required field",
      }),
      farmType: z.string({
        required_error: "farmType is a required field",
      }),
      totalArea: z.number({
        required_error: "totalArea is a required field",
      }),
      imageList: z
        .array(
          z.object({
            ext: z.string(),
            uid: z.string(),
            file_name: z.string(),
            unique_id: z.string(),
          })
        )
        .refine((array) => array.length > 0, {
          message: "Please upload image",
        }),
      address: z.string({
        required_error: "address is a required field",
      }),
      provinceCode: z.string({
        required_error: "province is a required field",
      }),
      districtCode: z.string({
        required_error: "district is a required field",
      }),
      subdistrictCode: z.string({
        required_error: "subDistrict is a required field",
      }),
      postcode: z.string({
        required_error: "postcode is a required field",
      }),
      coordinates: z.array(z.array(z.array(z.number()))),

      // coordinates: z
      // .array(
      //   z.object({
      //     number1: z.number(),
      //     number2: z.number(),
      //   })
      // )
      // .refine((array) => array.length > 0, {
      //   message: "Please upload image",
      // }),
      // completeAddress: z.string({
      //   required_error: "completeAddress is a required field",
      // }),
    })
    .array()
    .min(1, "At least 1 farm is required"),
});

export const farmDefaultValue = {
  farms: [],
};

export const farmSchemaForEdit = z.object({
  name: z.string({
    required_error: "name is a required field",
  }),
  farmType: z.string({
    required_error: "farmType is a required field",
  }),
  totalArea: z.number({
    required_error: "totalArea is a required field",
  }),
  imageList: z
    .array(
      z.object({
        ext: z.string(),
        uid: z.string(),
        file_name: z.string(),
        unique_id: z.string(),
      })
    )
    .refine((array) => array.length > 0, {
      message: "Please upload image",
    }),
  address: z.string({
    required_error: "address is a required field",
  }),
  provinceCode: z.string({
    required_error: "province is a required field",
  }),
  districtCode: z.string({
    required_error: "district is a required field",
  }),
  subdistrictCode: z.string({
    required_error: "subDistrict is a required field",
  }),
  postcode: z.string({
    required_error: "postcode is a required field",
  }),
  coordinates: z.array(z.array(z.array(z.number()))),
  // completeAddress: z.string({
  //   required_error: "completeAddress is a required field",
  // }),
});

// farmName: "",
// farmType: "",
// totalArea: "",
// farmImage: "",
// locationName: "",
// province: "",
// district: "",
// subDistrict: "",
// postCode: "",
// completeAddress: "",
