import { Component } from "@ai-and-robotics-ventures/cumulus-ui";
import { useQuery } from "@tanstack/react-query";
import ImageAvatarDefault from "assets/images/person-default.png";
import dayjs from "core/configs/dayjs";
import { objectToSearch } from "core/domains/utils/searchParams";
import { MissionType } from "core/queries/missionTemplate/enums";
import useGetAllMissionTypeQuery from "core/queries/missionTemplate/useGetAllMissionTypeQuery";
import useMissionTemplatesQuery from "core/queries/missionTemplate/useMissionTemplatesQuery";
import { useMemo, useState } from "react";

import { CardSize } from "components/pages/Calendar/components/EventCard";

import DefaultMisionIcon from "../../../assets/images/default_waypoint.png";
import planner, { PLANNER } from "../services/plannerFetch";

const transformEvent = (events: any[]) => (task: any) => {
  const mission: any = events.find((event) => event.id === task.missionTemplateId) || {};
  const start = dayjs(task.startTime);
  const end = dayjs(task.endTime);
  return {
    title: mission?.name || "Untitle",
    image: mission?.imagePath || DefaultMisionIcon,
    start: start.toDate(),
    end: end.toDate(),
    description: `HORRUS • ${start.format("HH:mm")} - ${end.format("HH:mm")} • ${
      mission?.missionType
    }`,
    avatars: [ImageAvatarDefault],
    member: task.memberId,
    device: task.deviceId,
    type: mission?.missionType,
  };
};

const defaultMissionType = [
  { value: MissionType.Waypoints, name: MissionType.Waypoints },
  { value: MissionType.Survey, name: MissionType.Survey },
  { value: MissionType.ROI, name: MissionType.ROI },
];

const useCalendarPage = () => {
  // ========== LOGIC ==========

  // SEARCH
  const [search, setSearch] = useState<string>("");
  const [member, setMember] = useState<string>("All members");
  const [device, setDevice] = useState<string>("All devices");
  const [mission, setMission] = useState<string>("All missions");
  const [selectedDate, setSelectedDate] = useState<Date>(dayjs().toDate());

  // MISSIONS
  const getAllMissionTypeQuery = useGetAllMissionTypeQuery();

  // TASKS
  const taskQuery = useQuery([PLANNER.GET_TASKS, selectedDate], () => {
    const startTime = dayjs(selectedDate).hour(0).minute(0).second(0).format();
    const endTime = dayjs(selectedDate).hour(23).minute(59).second(59).format();
    const params = objectToSearch({ startTime, endTime });
    return planner.get(`${PLANNER.GET_TASKS}?${params}`);
  });
  const tasks: any[] = useMemo(() => taskQuery.data?.data?.rslt || [], [taskQuery.data]);

  const eventsQuery = useMissionTemplatesQuery({
    templateIds: tasks.map((task) => task.missionTemplateId),
  });
  const events: any[] = useMemo(() => {
    const events = eventsQuery.data?.data?.rslt?.data || [];
    return tasks.map(transformEvent(events));
  }, [tasks, eventsQuery.data]);

  // UPCOMING TASKS
  const upcomingTaskQuery = useQuery([PLANNER.GET_TASKS], () => {
    const startTime = dayjs(selectedDate).hour(0).minute(0).second(0).format();
    const endTime = dayjs(selectedDate).add(1, "day").hour(23).minute(59).second(59).format();
    const params = objectToSearch({ startTime, endTime });
    return planner.get(`${PLANNER.GET_TASKS}?${params}`);
  });
  const upcomingTasks: any[] = useMemo(
    () => upcomingTaskQuery.data?.data?.rslt || [],
    [upcomingTaskQuery.data]
  );

  // TODAY EVENTS
  const todayTasks = useMemo(
    () =>
      upcomingTasks.filter(
        (task) => dayjs(task.startTime).format() <= dayjs().endOf("date").format()
      ),
    [upcomingTasks]
  );

  const todayEventsQuery = useMissionTemplatesQuery({
    templateIds: todayTasks.map((task) => task.missionTemplateId),
  });

  const todayEvents: any[] = useMemo(() => {
    const events = todayEventsQuery.data?.data?.rslt?.data || [];
    return todayTasks.map(transformEvent(events));
  }, [todayTasks, todayEventsQuery.data]);

  // TOMORROW EVENTS
  const tomorrowTasks = useMemo(
    () =>
      upcomingTasks.filter(
        (task) => dayjs(task.startTime).format() > dayjs().endOf("date").format()
      ),
    [upcomingTasks]
  );
  const tomorrowEventsQuery = useMissionTemplatesQuery({
    templateIds: tomorrowTasks.map((task) => task.missionTemplateId),
  });
  const tomorrowEvents: any[] = useMemo(() => {
    const events = tomorrowEventsQuery.data?.data?.rslt?.data || [];
    return tomorrowTasks.map(transformEvent(events));
  }, [tomorrowTasks, tomorrowEventsQuery.data]);

  // ========== TRANSFORM ==========

  // MISSIONS
  const missionOptions = useMemo(() => {
    const defaultOptions = [{ value: "All missions", label: "All missions" }];
    const options =
      getAllMissionTypeQuery.data?.data?.rslt?.map((mt) => ({ value: mt.name, name: mt.name })) ??
      defaultMissionType;
    return [...defaultOptions, ...options];
  }, [getAllMissionTypeQuery.data?.data]);

  // DEVICES
  const deviceOptions: Component.Select.Option[] = useMemo(() => {
    const defaultOptions = [{ value: "All devices", label: "All devices" }];
    const options = [...new Set(tasks.map((task) => task.deviceId))].map((device) => {
      return {
        value: device,
        label: device,
      };
    });
    return [...defaultOptions, ...options];
  }, [tasks]);

  // MEMBERS
  const memberOptions: Component.Select.Option[] = useMemo(() => {
    const defaultOption = [{ value: "All members", label: "All members" }];
    const options = [...new Set(tasks.map((task) => task.memberId))].map((member) => {
      return {
        value: member,
        label: member,
      };
    });
    return [...defaultOption, ...options];
  }, [tasks]);

  // ========== UI ==========
  const getCardSizeByPeriod = (start: Date | null, end: Date | null): CardSize => {
    if (start === null || end === null) return "md";

    const diff = dayjs(end).diff(start, "minutes");
    if (diff <= 2) return "sm";
    if (diff <= 5) return "md";
    return "lg";
  };

  const filterEvents = (events: any) => {
    return events.filter((item: any) => {
      return (
        (member === item.member || member === "All members") &&
        (device === item.device || device === "All devices") &&
        (mission === item.type || mission === "All missions")
      );
    });
  };

  return {
    search,
    selectedDate,
    setSelectedDate,
    setSearch,
    setMission,
    setDevice,
    setMember,

    mission,
    missionOptions,

    device,
    deviceOptions,

    member,
    memberOptions,

    events,
    todayEvents,
    tomorrowEvents,

    getCardSizeByPeriod,
    filterEvents,
  };
};

export default useCalendarPage;
