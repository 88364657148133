import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { CAATLicenseObjectiveList, NBTCLicenseObjectiveList } from "core/utils/constant";
import dayjs from "dayjs";
import { get } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import ASelect from "components/templates/ASelect/ASelect";

interface ILicenseForm {
  form: any;
}
const LicenseForm: FC<ILicenseForm> = ({ form }) => {
  const theme = useTheme();

  const { t } = useTranslation(["form", "common", "pilot"]);

  return (
    <div className="">
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} 2/2
      </div>
      <h1>{t(`License`, { ns: "pilot" })}</h1>
      <hr />

      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>{t(`CAAT License`, { ns: "pilot" })}</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="license.caatLicenseNo"
            label={t`CAAT License Number`}
            placeholder={t`Enter CAAT License Number`}
            helperSpace
            required
            onChange={form.handleChange}
            value={form.values.license.caatLicenseNo}
            error={
              get(form, "touched.license.caatLicenseNo") &&
              get(form, "errors.license.caatLicenseNo")
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            options={CAATLicenseObjectiveList}
            label={t`CAAT License Objective`}
            placeholder={t`Enter CAAT License Objective`}
            required
            value={form.values.license.caatObjective}
            onChange={(value) => form.setFieldValue("license.caatObjective", value)}
            error={
              get(form, "touched.license.caatObjective") &&
              get(form, "errors.license.caatObjective")
            }
            // onChange={form.handleChange}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="date"
            name="license.caatExpireDate"
            label={t`CAAT License Expiry Date`}
            placeholder={t`Enter CAAT License Expiry Date`}
            helperSpace
            value={dayjs(form.values.license.caatExpireDate).format("YYYY-MM-DD")}
            required
            error={
              get(form, "touched.license.caatExpireDate") &&
              get(form, "errors.license.caatExpireDate")
            }
            onChange={form.handleChange}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>{t(`NBTC License`, { ns: "pilot" })}</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="license.nbtcLicenseNo"
            label={t`NBTC License Number`}
            placeholder={t`Enter License Number`}
            helperSpace
            required
            onChange={form.handleChange}
            value={form.values.license.nbtcLicenseNo}
            error={
              get(form, "touched.license.nbtcLicenseNo") &&
              get(form, "errors.license.nbtcLicenseNo")
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label={t`NBTC License Objective`}
            placeholder={t`Enter NBTC License Objective`}
            options={NBTCLicenseObjectiveList}
            required
            value={form.values.license.nbtcObjective}
            error={
              get(form, "touched.license.nbtcObjective") &&
              get(form, "errors.license.nbtcObjective")
            }
            onChange={(value) => form.setFieldValue("license.nbtcObjective", value)}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="date"
            name="license.nbtcExpireDate"
            label={t`NBTC License Expiry Date`}
            placeholder={t`Enter License Expiry Date`}
            helperSpace
            value={dayjs(form.values.license.nbtcExpireDate).format("YYYY-MM-DD")}
            required
            error={
              get(form, "touched.license.nbtcExpireDate") &&
              get(form, "errors.license.nbtcExpireDate")
            }
            onChange={form.handleChange}
          />
        </div>
      </div>
    </div>
  );
};
export default LicenseForm;
