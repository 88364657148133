import { Button, Icon, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useSuccessModal = ({
  title,
  detail,
  callBack,
}: {
  title: string;
  detail?: React.ReactNode;
  callBack?: () => void;
}) => {
  const { t } = useTranslation("common");

  const [isOpen, setIsOpen] = useState(false);
  const SuccessModal = () => {
    return isOpen ? (
      <Modal
        isOpen={isOpen}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "336px",
            width: "360px",
            borderRadius: "20px",
            border: "1px solid #3E4754",
            background: "rgba(255, 255, 255, 0.80)",
            boxShadow: "4px 4px 50px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1A1A1A",
            flexDirection: "column",
          },
        }}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "80px",
            backgroundColor: "rgba(145, 202, 163, 0.20)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Icon icon="CheckMark" color="#91CAA3" size={40} />
        </div>
        <div style={{ marginTop: "24px" }}>{title}</div>
        <div
          className="font-300"
          style={{
            fontSize: "14px",
            lineHeight: "22px",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          {detail}
        </div>
        {/* <Link to={"/login"} style={{ textDecoration: "none" }}> */}
        <Button
          type="submit"
          style={{ backgroundColor: "#FFB32C", marginTop: "40px" }}
          onClick={() => {
            setIsOpen(false);
            if (callBack) {
              callBack();
            }
          }}
        >
          {t`Ok`}
        </Button>
        {/* </Link> */}
      </Modal>
    ) : null;
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    SuccessModal,
    openModal,
    closeModal,
  };
};

export default useSuccessModal;
