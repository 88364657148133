import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { DeviceOption } from "core/domains/Map/useDeviceSubscription";
import { getCenter } from "core/domains/utils";
import { useAtomValue, useSetAtom } from "jotai";
import _ from "lodash";
import { useEffect, useMemo } from "react";

import { currentTimeAtom } from "../MissionDetailsPage/components/TrackingChart";
import {
  Dashline,
  HeatPosition,
  Line,
  Marker,
  Volume,
  centerAtom,
  dashlinesAtom,
  dronePathAtom,
  heatPositionsAtom,
  linesAtom,
  markersAtom,
  polygonAtom,
  volumesAtom,
  zoomAtom,
} from "./Map";

export const createVolumeId = (project_id?: string, mission_id?: string, suffix?: string) => {
  return [project_id, mission_id, suffix].join("_");
};
export const getVolumeId = (id: string) => {
  const [project_id, mission_id, suffix] = id.split("_");
  return { project_id, mission_id, suffix };
};

export const useDisplayProjects = (
  projects: Project.Item[],
  options?: {
    selectedProjectId?: string;
    showOutline?: boolean;
  }
) => {
  const theme = useTheme();
  const _project = useMemo(() => projects.filter((project) => !_.isEmpty(project)), [projects]);
  const volumes: Volume[] = useMemo(
    () =>
      _project.map((project) => {
        let color = theme.color.success[500];
        let opacity = 0.5;
        let outlineColor = theme.color.success[500];
        let ceiling = 0.5;
        const isSelectedProject = options?.selectedProjectId === project.id;
        if (isSelectedProject) {
          color = theme.color.bluePrimary[500];
          opacity = 0.3;
          outlineColor = theme.color.bluePrimary[400];
          ceiling = 1;
        }

        return {
          id: project.id,
          coordinates: project.area?.map((coor) => coor.map((s) => Number(s))),
          color,
          opacity,
          outlineColor,
          type: "missionArea",
          showOutline: options?.showOutline,
          ceiling,
        };
      }),
    [_project, options?.selectedProjectId, options?.showOutline, theme]
  );
  useDisplayVolumes(volumes);
};

export const useDisplayMissions = (
  missions: Mission.Item[],
  options?: { selectedMissionId?: string }
) => {
  const theme = useTheme();
  const _missions = useMemo(() => missions.filter((mission) => !_.isEmpty(mission)), [missions]);
  const volumes: Volume[] = useMemo(
    () => [
      ..._missions.map((mission) => ({
        id: createVolumeId(undefined, mission.id, "operationVolumeArea"),
        coordinates: mission?.operationVolumeArea?.map((coor) => coor.map(Number)),
        color: theme.color.error[500],
        type: "operationVolumeArea",
        ceiling: 1,
        floor: 0,
      })),
      ..._missions.map((mission) => ({
        id: createVolumeId(undefined, mission.id, "conformanceVolumeArea"),
        coordinates: mission?.conformanceVolumeArea?.map((coor) => coor.map(Number)),
        color: theme.color.warning[500],
        type: "conformanceVolumeArea",
        ceiling: 2,
        floor: 0,
      })),
      ..._missions.map((mission) => ({
        id: createVolumeId(undefined, mission.id, "flightGeographyArea"),
        coordinates: mission?.flightGeographyArea?.map((coor) => coor.map(Number)),
        color:
          options?.selectedMissionId === mission.id
            ? theme.color.bluePrimary[500]
            : theme.color.success[500],
        type: "flightGeographyArea",
        ceiling: mission.ceiling,
        floor: mission.floor,
        showOutline: options?.selectedMissionId === mission.id,
      })),
      ..._missions
        .filter((mission) => mission.operationalArea)
        .map((mission) => ({
          id: createVolumeId(undefined, mission.id, "operationalArea"),
          coordinates: mission?.operationalArea?.map((coor) => coor.map(Number)),
          color: theme.color.success[500],
          type: "operationalArea",
          ceiling: 3,
          floor: 0,
        })),
    ],
    [_missions, options?.selectedMissionId, theme]
  );
  useDisplayVolumes(volumes);
};

export const useDisplayVolumes = (volumes: Volume[]) => {
  const setVolumes = useSetAtom(volumesAtom);
  useEffect(
    () => setVolumes((prevVolumes) => _.unionBy(volumes, prevVolumes, "id")),
    [setVolumes, volumes]
  );
};

export const useDisplayDevices = (
  devices: DeviceOption[],
  options?: { selectedDeviceId?: string }
) => {
  const theme = useTheme();
  const currentTime = useAtomValue(currentTimeAtom);

  const _devices = useMemo(
    () =>
      devices.map((device) => {
        const isSelected = device.id === options?.selectedDeviceId;
        let logs = device.logs;
        let coordinates = device.coordinates;
        if (isSelected && currentTime && logs.length && currentTime > logs[0]?.timestamp) {
          logs = logs.filter((log) => log.timestamp <= currentTime);
          coordinates = logs.map((log) => [log.longitude, log.latitude, log.altitude]);
        }
        return {
          ...device,
          logs,
          coordinates,
        };
      }),
    [currentTime, devices, options?.selectedDeviceId]
  );

  const markers = useMemo(
    () =>
      _devices.map((device) => {
        const isSelected = device.id === options?.selectedDeviceId;
        return {
          id: device.id,
          color: isSelected ? theme.color.bluePrimary[900] : theme.color.bluePrimary.base,
          borderColor: theme.color.bluePrimary.base,
          center: device.coordinates[device.coordinates.length - 1],
          icon: <Icon icon="Drone" />,
        };
      }),
    [_devices, options?.selectedDeviceId, theme]
  );
  useDisplayMarker(markers);

  const lines = useMemo(
    () =>
      _devices
        .filter((device) => device.id === options?.selectedDeviceId)
        .map((device) => ({
          id: device.id,
          coordinates: device.coordinates,
        })),
    [_devices, options?.selectedDeviceId]
  );
  useDisplayLine(lines);
};

export const useDisplayMarker = (markers: Marker[]) => {
  const setMarkers = useSetAtom(markersAtom);
  useEffect(() => {
    setMarkers(markers);
    // setMarkers((prevMarkers) => _.unionBy(markers, prevMarkers, "id"));
  }, [markers, setMarkers]);
};

export const useDisplayLine = (lines: Line[]) => {
  const setLines = useSetAtom(linesAtom);
  useEffect(() => {
    setLines([]);
    setLines((prevLines) => _.unionBy(lines, prevLines, "id"));
  }, [lines, setLines]);
  // useEffect(() => setLines(lines), [lines, setLines]);
};

export const useDisplayDashline = (dashLines: Dashline[]) => {
  const setDashLines = useSetAtom(dashlinesAtom);
  useEffect(
    () => setDashLines((prevDashLines) => _.unionBy(dashLines, prevDashLines, "id")),
    [dashLines, setDashLines]
  );
};

export const useDisplayDronePath = (path: [number, number][]) => {
  const setDronePath = useSetAtom(dronePathAtom);
  useEffect(() => setDronePath(path), [path, setDronePath]);
};

export const useDisplayPolygon = (polygon: [number, number][]) => {
  const setPolygon = useSetAtom(polygonAtom);
  useEffect(() => setPolygon(polygon), [polygon, setPolygon]);
};

export const useToCenter = (polygon: [number, number][]) => {
  const center = getCenter(polygon);
  const setCenter = useSetAtom(centerAtom);
  useEffect(() => {
    if (center) {
      setCenter(center);
    }
  }, [polygon, setCenter]);
};

export const useZoom = (zoomLevel: number) => {
  const setZoom = useSetAtom(zoomAtom);
  useEffect(() => {
    setZoom(zoomLevel);
  }, [zoomLevel, setZoom]);
};

export const useDisplayHeatPositions = (heatPositions: HeatPosition[]) => {
  const setHeatPositions = useSetAtom(heatPositionsAtom);
  useEffect(() => {
    setHeatPositions(() => {
      return heatPositions;
    });
  }, [heatPositions, setHeatPositions]);
};
