import UploadFarm from "assets/images/UploadFarm.png";

const UploadFarmImage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${UploadFarm})`,
        backgroundSize: "cover",
        width: "294px",
        height: "180px",
      }}
    />
  );
};
export default UploadFarmImage;
