import storage from "core/domains/utils/storage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "./store/auth";

export async function loader() {
  return null;
}

const LogoutPage = () => {
  const navigate = useNavigate();
  const {
    actions: { logout },
  } = useAuth();

  useEffect(() => {
    (async () => {
      // await authFetch.post(AUTH.POST_LOGOUT);
      logout();
      const origin = storage.get("origin");
      if (origin) {
        window.location.href = origin;
        storage.remove("origin");
      } else {
        navigate("/login");
      }
    })();
  }, [logout, navigate]);

  return null;
};

export const Component = LogoutPage;
export default LogoutPage;
