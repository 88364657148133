import { Button, Radio } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { client } from "core/gql/fetchgql";
import { SAVE_USER_PREFERENCE } from "core/gql/user";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import useSuccessModal from "core/hooks/useSuccessModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";

import { useAuth } from "../AuthPage/store/auth";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  .lang-wrapper {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }

  .footer-wrapper {
    margin-top: 20px;
  }
`;

const UserPreference = () => {
  const { t } = useTranslation(["preference", "common"]);
  const { user, actions } = useAuth();

  const isLeasing = user?.roles?.includes("LEASING");

  const { areaUnits } = useAreaConvert();

  const [currentLang, setCurrentLang] = useState<string>(user?.preference?.language || "en");
  const [currentUnitArea, setCurrentUnitArea] = useState<string>(
    user?.preference?.unit?.area || "rai"
  );
  const [isSubmitting, setIsSubmmitting] = useState<boolean>(false);

  const modal = useSuccessModal({
    title: t`User preference updated`,
    detail: (
      <>
        <div>{t`User preference updated successfully`}</div>
      </>
    ),
  });

  const language = [
    { label: t`English`, value: "en" },
    { label: t`Thai`, value: "th" },
  ];

  const handleSave = async () => {
    try {
      setIsSubmmitting(true);
      const variables = {
        preferenceInput: {
          language: currentLang,
          unit: { area: currentUnitArea },
        },
      };

      await client.request(SAVE_USER_PREFERENCE, variables);

      actions.setUser({
        ...user,
        preference: {
          language: currentLang,
          unit: { area: currentUnitArea },
        },
      } as User.User);

      setIsSubmmitting(false);
      modal.openModal();
    } catch (error) {
      setIsSubmmitting(false);

      console.error("Error creating item:", error);
    }
  };

  return (
    <StyledWrapper>
      <h2>{t`User Preference`}</h2>

      <div>
        <div>{t`Language`}</div>
        <div className="lang-wrapper">
          {language?.map((item, index) => {
            return (
              <Radio
                key={index}
                id={item.value}
                checked={item.value === currentLang}
                onChange={() => setCurrentLang(item.value)}
              >
                {item.label}
              </Radio>
            );
          })}
        </div>
      </div>

      {!isLeasing && (
        <div>
          <div>{t`Unit`}</div>
          <div className="lang-wrapper">
            {areaUnits?.map((item, index) => {
              return (
                <Radio
                  key={index}
                  id={item.value}
                  checked={item.value === currentUnitArea}
                  onChange={() => setCurrentUnitArea(item.value)}
                >
                  {item.label}
                </Radio>
              );
            })}
          </div>
        </div>
      )}

      <div className="footer-wrapper">
        <Button className="register-button-active" onClick={handleSave}>
          {isSubmitting && <ClipLoader size={16} color="#36d7b7" />}
          {t(`Save`, { ns: "common" })}
        </Button>
      </div>

      <modal.SuccessModal />
    </StyledWrapper>
  );
};

export default UserPreference;
