export const STORAGE_PREFIX = `uas_${process.env.NODE_ENV}:`;

export const get = (key: string) => localStorage.getItem(`${STORAGE_PREFIX}${key}`);

export const set = (key: string, value: string) =>
  localStorage.setItem(`${STORAGE_PREFIX}${key}`, value);

export const remove = (key: string) => localStorage.removeItem(`${STORAGE_PREFIX}${key}`);

export const clear = () => localStorage.clear();

const storage = { get, set, remove, clear };

export default storage;
