import { Dropdown, Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as CheckMarkIcon } from "assets/svgs/checkmark.svg";
import { ReactComponent as ClockNotificationIcon } from "assets/svgs/clock-notification.svg";
import { ReactComponent as WarningNotificationIcon } from "assets/svgs/warning-notification.svg";
import { ReactComponent as XNotificationIcon } from "assets/svgs/x-notification.svg";
import masterplan, { MASTER_PLAN } from "core/domains/services/masterplanFetch";
import { objectToSearch } from "core/domains/utils/searchParams";
import useNotification from "core/hooks/useNotification";
import moment from "moment";
import { useCallback, useMemo } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Badge,
  BadgeWrapper,
  NotificationIcon,
  NotificationItem,
  NotificationItemContent,
  NotificationItemDescription,
  NotificationItemIcon,
  NotificationItemTitle,
  NotificationModal,
  NotificationModalContent,
  NotificationModalHead,
  NotificationModalReadAll,
} from "./Notification.styled";

export type NotificationView = {
  preIcon?: JSX.Element;
  postIcon?: JSX.Element;
  title?: string;
  description?: string;
  onDelete?: (id: number) => void;
  variant?: "info" | "success" | "error" | "warning";
  bottomText?: string;
  onClick?: () => void;
  onClickGoTo?: () => void;
  status?: NotificationLog.Status;
};

const Notification = () => {
  const navigate = useNavigate();

  const { notificationLog, handleReadAll, unread, notificationQuery, handleAcknowledge } =
    useNotification();

  const handleRedirectPage = useCallback(
    (noti: NotificationLog.Item) => {
      if (noti.tableName === "Mission") {
        navigate({
          pathname: "/project-mission",
          search: objectToSearch({ mission_id: noti.recordId }),
        });
      }
    },
    [navigate]
  );

  const handleReadNotification = useCallback(
    async (noti: NotificationLog.Item) => {
      if (noti.status !== "READ") {
        masterplan
          .get(`${MASTER_PLAN.POST_NOTIFICATION_READ}/${noti.id}`)
          .then(() => notificationQuery.refetch());
      }
    },
    [notificationQuery]
  );

  const notifications = useMemo(
    () =>
      notificationLog?.map((noti: NotificationLog.Item): NotificationView => {
        let icon = WarningNotificationIcon;
        if (["PENDING", "REVIEWING"].includes(noti.recordStatus)) {
          icon = ClockNotificationIcon;
        } else if (["REJECTED", "EMERGENCY", "ABORTED"].includes(noti.recordStatus)) {
          icon = XNotificationIcon;
        } else if (["ACTIVATED"].includes(noti.recordStatus)) {
          icon = WarningNotificationIcon;
        } else if (["APPROVED", "COMPLETED"].includes(noti.recordStatus)) {
          icon = WarningNotificationIcon;
        }
        return {
          preIcon: React.createElement(icon, { className: noti.recordStatus }),
          title: noti.title,
          description: noti.description,
          bottomText: noti?.createdAt ? moment(noti.createdAt).fromNow() : "",
          onClick: () => handleReadNotification(noti),
          onClickGoTo: () => handleRedirectPage(noti),
          status: noti.status,
        };
      }) ?? [],
    [handleReadNotification, handleRedirectPage, notificationLog]
  );

  const unreadNotifications = useMemo(
    () => notifications.filter((notification) => notification.status === "NEW"),
    [notifications]
  );

  return (
    <>
      <Dropdown
        mask={
          <NotificationIcon>
            <Icon icon="Bell" onClick={handleAcknowledge} />
            {unread && (
              <BadgeWrapper>
                <Badge>
                  {unreadNotifications.length > 99 ? "99+" : unreadNotifications.length}
                </Badge>
              </BadgeWrapper>
            )}
          </NotificationIcon>
        }
      >
        <NotificationModal>
          <NotificationModalHead>
            <Text variant="body1">Notifications</Text>
            <NotificationModalReadAll onClick={handleReadAll}>
              Mark all as read &nbsp;
              <CheckMarkIcon className="icon" />
            </NotificationModalReadAll>
          </NotificationModalHead>
          <NotificationModalContent>
            {notifications.map((notification, index) => {
              return (
                <NotificationItem
                  key={`notification-${index}`}
                  onClick={notification.onClick}
                  className={notification.status}
                >
                  <NotificationItemIcon>{notification.preIcon}</NotificationItemIcon>
                  <NotificationItemContent>
                    <NotificationItemTitle onClick={notification.onClickGoTo}>
                      {notification.title}
                    </NotificationItemTitle>
                    <NotificationItemDescription
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: `${notification.description}` }}
                    ></NotificationItemDescription>
                    <Text variant="body3">{notification.bottomText}</Text>
                  </NotificationItemContent>
                </NotificationItem>
              );
            })}
          </NotificationModalContent>
        </NotificationModal>
      </Dropdown>
    </>
  );
};

export default Notification;
