import {
  Avatar,
  Datepicker,
  InputField,
  Text,
  TextArea,
  TextAreaField,
} from "@ai-and-robotics-ventures/cumulus-ui";
import { css, useTheme } from "@emotion/react";
import { client } from "core/gql/fetchgql";
import { QUERY_NATIONALITIES } from "core/gql/master";
import { CAATLicenseObjectiveList, NBTCLicenseObjectiveList } from "core/utils/constant";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { TNationalityItem, TNationalityResponse } from "types/master";

import ASelect from "components/templates/ASelect/ASelect";

import PilotStepStyled from "./PilotStep.styled";

interface IPilotStep {
  // selected: string;
  // data: IDataButtonGroup[];
  form: any;
  // form.handleChange: (e: React.ChangeEvent<any>) => void;
}
const PilotStep: React.FC<IPilotStep> = ({ form }) => {
  const theme = useTheme();
  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchNationality();
  }, []);

  return (
    <PilotStepStyled>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        Part 1/5
      </div>
      <h1>Pilot Details</h1>
      <hr />
      {/* <h3>Profile Picture</h3>
      <Avatar /> */}
      <h3>Pilot Information</h3>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="firstName"
          label="First Name"
          placeholder="Enter First Name"
          onChange={form.handleChange}
          helperSpace
          required
          value={form.values.firstName}
          error={get(form, "touched.firstName") && get(form, "errors.firstName")}
        />
        <InputField
          type="text"
          name="lastName"
          onChange={form.handleChange}
          label="Last Name"
          placeholder="Enter Last Name"
          helperSpace
          required
          value={form.values.lastName}
          error={get(form, "touched.lastName") && get(form, "errors.lastName")}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="nationalIdNo"
            label="National ID Number"
            placeholder="Enter National ID Number"
            onChange={form.handleChange}
            helperSpace
            required
            value={form.values.nationalIdNo}
            error={get(form, "touched.nationalIdNo") && get(form, "errors.nationalIdNo")}
          />
        </div>
        <div
          style={{ flex: 1, display: "flex", justifyContent: "flex-end", flexDirection: "column" }}
        >
          <ASelect
            label="Nationality"
            placeholder="Select Nationality"
            required
            options={nationalityList.map((nation) => {
              return {
                value: nation.nationalityCode,
                label: nation.nationality,
              };
            })}
            value={form.values.nationality}
            // @ts-ignore:
            onChange={(value) => form.setFieldValue("nationality", value)}
            error={get(form, "touched.nationality") && get(form, "errors.nationality")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="email"
          label="Email"
          placeholder="Enter Email"
          helperSpace
          required
          value={form.values.email}
          onChange={form.handleChange}
          error={get(form, "touched.email") && get(form, "errors.email")}
        />
        <InputField
          type="text"
          name="phoneNo"
          label="Phone Number"
          placeholder="Enter Phone Number"
          helperSpace
          onChange={form.handleChange}
          required
          value={form.values.phoneNo}
          error={get(form, "touched.phoneNo") && get(form, "errors.phoneNo")}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>CAAT License</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        {/* <ASelect
          label="CAAT License Number"
          placeholder="Enter CAAT License Number"
          required
          options={CAATLicenseObjectiveList}
          value={form.values.license.caatLicenseNo}
          onChange={(value) => form.setFieldValue("license.caatLicenseNo", value)}
          error={
            get(form, "touched.license.caatLicenseNo") && get(form, "errors.license.caatLicenseNo")
          }
        /> */}
        <InputField
          type="text"
          name="license.caatLicenseNo"
          label="CAAT License Number"
          placeholder="Enter CAAT License Number"
          helperSpace
          required
          onChange={form.handleChange}
          value={form.values.license.caatLicenseNo}
          error={
            get(form, "touched.license.caatLicenseNo") && get(form, "errors.license.caatLicenseNo")
          }
        />
        <InputField
          type="date"
          name="license.caatExpireDate"
          label="CAAT License Expiry Date"
          placeholder="Enter CAAT License Expiry Date"
          helperSpace
          value={form.values.license.caatExpireDate}
          required
          error={
            get(form, "touched.license.caatExpireDate") &&
            get(form, "errors.license.caatExpireDate")
          }
          onChange={form.handleChange}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="CAAT License Objective"
            placeholder="Enter CAAT License Objective"
            required
            options={CAATLicenseObjectiveList}
            value={form.values.license.caatObjective}
            onChange={(value) => form.setFieldValue("license.caatObjective", value)}
            error={
              get(form, "touched.license.caatObjective") &&
              get(form, "errors.license.caatObjective")
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>NBTC License</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="license.nbtcLicenseNo"
          label="NBTC License Number"
          placeholder="Enter License Number"
          helperSpace
          required
          onChange={form.handleChange}
          value={form.values.license.nbtcLicenseNo}
          error={
            get(form, "touched.license.nbtcLicenseNo") && get(form, "errors.license.nbtcLicenseNo")
          }
        />

        <InputField
          type="date"
          name="license.nbtcExpireDate"
          label="NBTC License Expiry Date"
          placeholder="Enter License Expiry Date"
          helperSpace
          value={form.values.license.nbtcExpireDate}
          required
          error={
            get(form, "touched.license.nbtcExpireDate") &&
            get(form, "errors.license.nbtcExpireDate")
          }
          onChange={form.handleChange}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="NBTC License Objective"
            placeholder="Enter NBTC License Objective"
            required
            options={NBTCLicenseObjectiveList}
            value={form.values.license.nbtcObjective}
            onChange={(value) => form.setFieldValue("license.nbtcObjective", value)}
            error={
              get(form, "touched.license.nbtcObjective") &&
              get(form, "errors.license.nbtcObjective")
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>Password</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="password"
          name="password"
          label="Password"
          placeholder="Enter Password"
          onChange={form.handleChange}
          helperSpace
          required
          value={form.values.password}
          error={get(form, "touched.password") && get(form, "errors.password")}
        />
        <InputField
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Enter Password"
          helperSpace
          required
          value={form.values.confirmPassword}
          error={get(form, "touched.confirmPassword") && get(form, "errors.confirmPassword")}
          onChange={form.handleChange}
        />
      </div>
    </PilotStepStyled>
  );
};

export default PilotStep;
