import { FC } from "react";
import { EPostStatus } from "types/flightPost";

import Badge from "components/templates/Badge/Badge";

interface IAiangStatus {
  status: EPostStatus;
  isPilot?: boolean;
}
const AiangStatus: FC<IAiangStatus> = ({ status, isPilot = false }) => {
  if (isPilot) {
    switch (status) {
      case EPostStatus.PENDING:
        return <Badge label="Available" type="success" />;
      case EPostStatus.READY:
        return <Badge label="Ready" type="success" />;
      case EPostStatus.IN_FLIGHT:
        return <Badge label="In Flight" type="inprogress" />;
      case EPostStatus.ACCEPTED:
        return <Badge label="Accepted" type="warning" />;
      default:
        return null;
    }
  } else {
    switch (status) {
      case EPostStatus.CANCEL:
        return <Badge label="Cancel" type="normal" />;
      case EPostStatus.PENDING:
        return <Badge label="Pending" type="warning" />;
      case EPostStatus.IN_FLIGHT:
        return <Badge label="In Flight" type="inprogress" />;
      case EPostStatus.ACCEPTED:
        return <Badge label="Accepted" type="success" />;
      case EPostStatus.READY:
        return <Badge label="Ready" type="success" />;
      case EPostStatus.FINISH:
        return <Badge label="Done" type="success" />;
      default:
        return null;
    }
  }
};
export default AiangStatus;
