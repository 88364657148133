import { UIMarker } from "@ai-and-robotics-ventures/map-library";
import { useTheme } from "@emotion/react";
import WaypointPin from "assets/svgs/WaypointPin";
import { useMemo } from "react";
import ReactDOMServer from "react-dom/server";

type WayPointMarkerProps = {
  id: string | number;
  coordinate: number[];
  text: string;
  color?: string;
};
const WayPointMarker: React.FC<WayPointMarkerProps> = ({
  id,
  coordinate,
  text,
  color = "#101993",
  ...rest
}) => {
  const theme = useTheme();
  const content = useMemo(() => {
    return ReactDOMServer.renderToString(
      <div style={{ position: "relative" }}>
        <WaypointPin
          style={{
            position: "absolute",
            zIndex: 1,
            transform: "translate(-50%, -100%)",
            color,
            borderColor: theme.color.grayPrimary[500],
          }}
        />
        <span
          style={{
            position: "absolute",
            zIndex: 2,
            transform: "translate(-50%)",
            bottom: 8,
            color: theme.color.grayPrimary[50],
          }}
        >
          {text}
        </span>
      </div>
    );
  }, [color, theme, text]);
  return (
    <UIMarker
      id={`waypoint-marker-${id}`}
      coordinate={coordinate}
      options={{ content }}
      isVisible={true}
      {...rest}
    />
  );
};

export default WayPointMarker;
