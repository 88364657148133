import styled from "@emotion/styled";

const AddFlightPlanStepStyled = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  .add-flight-plan-box {
    width: 346px;
    backdrop-filter: blur(5px);
    background: rgba(102, 92, 77, 0.9);
    padding: 16px;
    overflow: scroll;
    padding-bottom: 100px;
    .flight-plan-filter-list {
      display: flex;
      gap: 8px;
    }

    .operation-area-select {
      margin-top: 24px;
      display: flex;
      height: 48px;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-bottom: 8px;

      border-radius: 12px;
      border: 1px solid #ffb32c;
      background: rgba(102, 92, 77, 0.7);
    }
    .operation-area-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 18px;
      background: #665c4d;
      margin-top: 8px;
      .operation-area-title {
        color: var(--gray-primary-50, #ececef);
        font-family: Kanit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        .red {
          color: var(--error-500, #db5959);
          font-family: Kanit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }

      .icon-box {
        cursor: pointer;
        border-radius: 12px;
        border: 1px solid #ffb32c;
        background: rgba(102, 92, 77, 0.7);
        display: flex;
        padding: 44px 24px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        .label {
          color: #ececef;
          text-align: center;
          font-family: Kanit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
  .text-area-component {
    background-color: white;
    textarea {
      color: black;
    }
  }

  .add-footer-flight-plan {
    display: flex;
    gap: 16px;
    padding: 24px 16px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(102, 92, 77, 0.6) 0%,
      rgba(102, 92, 77, 0.53) 45.71%,
      rgba(102, 92, 77, 0) 100%
    );
    backdrop-filter: blur(10px);
    width: 100%;

    .cancel-btn {
      background-color: white;
      color: black;
    }

    .add-btn {
      color: #1a1a1a;
      background-color: #ffc256;
    }
  }
`;

export default AddFlightPlanStepStyled;
