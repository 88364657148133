import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "polished";

const TabStyled = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) =>
    isActive
      ? css`
          background: ${rgba(theme.color.graySecondary[900], 0.7)};
          color: ${theme.color.bluePrimary[400]};
          backdrop-filter: blur(10px);
        `
      : css`
          background: transparent;
          color: ${theme.color.grayPrimary[600]};
        `}

  border-radius: 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  justify-content: center;
  padding: 7.5px 0;
  cursor: pointer;
  p {
    white-space: nowrap;
  }
`;

type Tab = {
  label: string;
  value: string;
  count: number;
};

type TabProps = {
  data: Tab;
  isActive: boolean;
};

const TabComponent: React.FC<TabProps & React.HTMLAttributes<HTMLDivElement>> = ({
  data,
  isActive,
  ...props
}) => {
  return (
    <TabStyled isActive={isActive} {...props}>
      <Text variant="h5">{data.count}</Text>
      <Text variant="body3">{data.label}</Text>
    </TabStyled>
  );
};

export default TabComponent;
