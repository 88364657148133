import { gql } from "graphql-request";

export const QUERY_PROVINCE = gql`
  query provinces {
    provinces {
      provinceCode
      name_th
      name_en
    }
  }
`;

export const QUERY_DISTRICT = gql`
  query districtByProvinceCode($provinceCode: String!) {
    districtByProvinceCode(provinceCode: $provinceCode) {
      districtCode
      name_th
      name_en
    }
  }
`;

export const QUERY_SUB_DISTRICT = gql`
  query subDistrictByDistrictCode($districtCode: String!) {
    subDistrictByDistrictCode(districtCode: $districtCode) {
      subDistrictCode
      name_th
      name_en
      postCode
    }
  }
`;

export const QUERY_NATIONALITIES = gql`
  query nationalities {
    nationalities {
      nationalityCode
      country
      nationality
    }
  }
`;
