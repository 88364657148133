import { Button, Loading } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_ADMIN_UPDATE_FARMER, QUERY_ADMIN_GET_FARMER_BY_ID } from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminFarmerByIdResponse } from "types/admin";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  AddressIconSelected,
  AddressIconWhite,
  FarmerIconNotSelectedWhite,
  FarmerIconSelected,
  PasswordIconSelected,
  PasswordIconWhite,
} from "components/templates/Icon";

import { TFarmerProfile } from "../../../types/farmer";
import EditFarmerProfilePageStyled from "./EditFarmerProfilePage.styled";
import FarmerForm from "./Farmer/FarmerForm";

const EditFarmerProfilePage = () => {
  const { t } = useTranslation("common");

  const { id } = useParams();
  const [step, setStep] = useState<string>("Farmer");
  const [profileData, setProfileData] = useState<TFarmerProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const formik = useFormik<any>({
    initialValues: profileData || {},
    enableReinitialize: true,
    onSubmit: (values) => handleUpdateProfile(values),
  });

  const fetchFarmerProfile = async () => {
    try {
      const variables = {
        userId: id,
      };
      const response: TAdminFarmerByIdResponse = await client.request(
        QUERY_ADMIN_GET_FARMER_BY_ID,
        variables
      );
      setProfileData(response.adminGetFarmerById);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const dataStep = [
    {
      label: t`Farmer`,
      key: "Farmer",
      selectedIcon: <FarmerIconSelected />,
      notSelectedIcon: <FarmerIconNotSelectedWhite />,
    },
    {
      label: t`Address`,
      key: "Address",
      selectedIcon: <AddressIconSelected />,
      notSelectedIcon: <AddressIconWhite />,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchFarmerProfile();
  }, []);

  const handleUpdateProfile = async (values: any) => {
    try {
      setSubmitIsLoading(true);
      const farmerInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        phoneNo: values.phoneNo,
        address: values.address,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        subdistrictCode: values.subdistrictCode,
        postcode: values.postcode,
      };
      const variables = {
        farmer: farmerInput,
        userId: id,
      };
      await client.request(MUTATION_ADMIN_UPDATE_FARMER, variables);
      successModal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  const successModal = useSuccessModal({
    title: t`Profile Edited`,
    detail: (
      <div>
        <div>{t`Your profile has been successfully edited`}</div>
      </div>
    ),
  });
  return (
    <EditFarmerProfilePageStyled>
      {isLoading && <Loading />}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>{t`Profile`}</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-profile-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup data={dataStep} selected={step} onClick={setStep} />
            </div>
            <FarmerForm form={formik} step={step} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/farm-list"}>
                <Button className="cancel-button">{t`Cancel`}</Button>
              </Link>
              <Button type="submit" className="register-button-active">
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t`Save`}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <successModal.SuccessModal />
    </EditFarmerProfilePageStyled>
  );
};
export default EditFarmerProfilePage;
