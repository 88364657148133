import styled from "@emotion/styled";

const FarmerSignupPageStyled = styled.div`
  background: #29371c;
  backdrop-filter: blur(10px);

  .container {
    min-height: 100vh;
    padding: 0 32px;

    .form {
      border-radius: 12px;
      background: #414221;
      min-height: calc(100vh - 216px);
      height: 100%;
      padding: 40px;
      position: relative;
      .step-section {
        position: absolute;
        left: 100px;
        top: 0;
      }
    }
  }
`;

export default FarmerSignupPageStyled;
