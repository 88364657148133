import { Button, Icon, InputField, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { IUploadDetail } from "core/domains/services/upload";
import useErrorModal from "core/hooks/useErrorModal";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { EPostStatus, TFlightPostDetail } from "types/flightPost";

import AiangStatus from "../AiangStatus/AiangStatus";
import UploadFlightLog from "../UploadFlightLog/UploadFlightLog";

const FlightPostItemStyled = styled.div`
  width: 292px;
  border-radius: 10px;
  background: #665c4d;
  padding: 8px;
  cursor: pointer;
  
  .item-container {
    display: flex;
    position: relative;
    justify-content: space-evenly;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
  .sub-title {
    color: #9ca3af;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
  .edit-flight-post-btn {
    position: absolute;
    right: 20px;
    bottom: 0;
    cursor: pointer;
  }

  .btn {
    width: 100%;
    font-weight: 500;
    font-family: Kanit;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }

  .on-mission-btn,
  .on-mission-btn:hover {
    background-color: #e9d8fd;
    background #e9d8fd;
    color: #322659;
  }
  .available-btn,
  .available-btn:hover {
    background-color: #85dda5;
    background #85dda5;
    color: #ffffff;
  }
  .start-btn,
  .start-btn:hover {
    background-color: #ffb32c;
    background #ffb32c;
    color: #ffffff;
  }
  .cancel-btn,
  .cancel-btn:hover {
    background-color: #ef7979;
    background #ef7979;
    color: #ffffff;
  }
`;

const borderStyled = ({ selected }: { selected: boolean }) => css`
  ${selected === true
    ? `
    border: 1px solid #ffb32c;
    border-radius: 10px;
    background: #665c4d;
  `
    : ``}
`;
export interface IFlightPostItem extends TFlightPostDetail {
  setSelectedEditFlightPost?: (id: string) => void;
  isPilot?: boolean;
  selected: boolean;
  index: number;
  onStartFlightPost?: (index: string) => void;
  onAcceptFlightPost?: (index: string) => void;
  onDeleteFlightPost?: (index: string) => void;
  toStepCreateFlightPlan?: (index: string) => void;
  onCancelFlightPost?: (index: string, reason: string) => void;
  onFinishFlightPost?: (id: string, flightLog: IUploadDetail) => void;
}

const FlightPostItem: FC<IFlightPostItem> = ({
  id,
  name,
  startTime,
  endTime,
  status,
  description,
  setSelectedEditFlightPost,
  isPilot = false,
  selected,
  index,
  farm,
  onAcceptFlightPost,
  onStartFlightPost,
  onDeleteFlightPost,
  toStepCreateFlightPlan,
  onCancelFlightPost,
  onFinishFlightPost,
}) => {
  const [reasonText, setReasonText] = useState<string>("");
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [flightLogFile, setFlightLogFile] = useState<IUploadDetail | null>(null);
  const acceptFlightPostModal = useErrorModal({
    title: "Accept Flight Post",
    detail: (
      <div>
        <div>By accepting this flight, you must complete</div>
        <div>the flight plan before the next day</div>
      </div>
    ),
    icon: <Icon icon="Warning" size={40} style={{ color: "#EB3434" }} />,
    callBackOnOk: () => {
      if (onAcceptFlightPost) {
        onAcceptFlightPost(id);
      }
    },
  });
  const renderActionButton = (status: EPostStatus) => {
    if (isPilot) {
      switch (status) {
        case EPostStatus.IN_FLIGHT:
          return (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setShowFinishModal(true);
              }}
              fullWidth
              className="mt-8 on-mission-btn btn"
            >
              End Mission
            </Button>
          );
        case EPostStatus.PENDING: // available for pilot
          return (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                acceptFlightPostModal.openModal();
              }}
              fullWidth
              className="mt-8 available-btn btn"
            >
              Accept
            </Button>
          );
        case EPostStatus.ACCEPTED:
          return (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                if (toStepCreateFlightPlan) {
                  toStepCreateFlightPlan(id);
                }
              }}
              fullWidth
              className="mt-8 start-btn btn"
            >
              Create Flight Post
            </Button>
          );
        case EPostStatus.READY:
          return (
            <div style={{ display: "flex", gap: "16px", marginTop: "8px" }}>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  setShowCancelModal(true);
                }}
                fullWidth
                className="cancel-btn btn"
              >
                Cancel
              </Button>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  if (onStartFlightPost) {
                    onStartFlightPost(id);
                  }
                }}
                fullWidth
                className="start-btn btn"
              >
                Start
              </Button>
            </div>
          );
        default:
          return null;
      }
    }
  };

  return (
    <FlightPostItemStyled css={borderStyled({ selected })}>
      <div className="item-container">
        <img
          style={{
            borderRadius: "5px",
          }}
          alt={name}
          width={57}
          height={57}
          src={farm.imageListUrls?.[0]}
        />
        <div className="ml-8">
          <div className="title">{name}</div>
          {/* <div className="sub-title">{location}</div> */}
          <div className="sub-title">
            {dayjs(startTime).format("DD MMM YYYY")} - {dayjs(endTime).format("DD MMM YYYY")}
          </div>
        </div>
        <div>
          <AiangStatus status={status} isPilot={isPilot} />
        </div>

        {!isPilot && status === EPostStatus.PENDING && (
          <div
            className="edit-flight-post-btn"
            onClick={(e) => {
              e.stopPropagation();
              if (setSelectedEditFlightPost) {
                setSelectedEditFlightPost(id);
              }
            }}
          >
            <Icon icon="Edit" />
          </div>
        )}
      </div>
      {renderActionButton(status || "")}

      <acceptFlightPostModal.ErrorModal />
      <Modal
        isOpen={showCancelModal}
        ariaHideApp={false}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "336px",
            width: "400px",
            borderRadius: "20px",
            border: "1px solid #3E4754",
            background: "rgba(255, 255, 255, 0.80)",
            boxShadow: "4px 4px 50px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1A1A1A",
            flexDirection: "column",
            padding: "24px",
            overflow: "hidden",
          },
        }}
      >
        <div style={{ display: "flex", height: "100%", gap: "16px" }}>
          <div
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "80px",
              backgroundColor: "rgba(235, 52, 52, 0.20)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Icon icon="Warning" size={40} style={{ color: "#EB3434" }} />
          </div>
          <div
            style={{
              width: "260px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <div style={{ fontSize: "24px" }}>{"Cancel Flight Post"}</div>
              <div
                className="font-300"
                style={{
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <div>
                  <div>Enter reason why you are canceling this post</div>
                </div>
              </div>

              <div>
                <InputField
                  name="reason"
                  label="Reason of Cancellation"
                  placeholder="Enter Reason"
                  value={reasonText}
                  onChange={(e: any) => setReasonText(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                style={{
                  backgroundColor: "white",
                  color: "#1F2937",
                  width: "100px",
                  minWidth: "100px",
                }}
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ width: "100px", minWidth: "100px" }}
                className={reasonText ? "register-button-active" : "register-button"}
                onClick={() => {
                  if (onCancelFlightPost && reasonText) {
                    onCancelFlightPost(id, reasonText);
                  }
                  setShowCancelModal(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showFinishModal}
        ariaHideApp={false}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "400px",
            width: "500px",
            borderRadius: "20px",
            border: "1px solid #3E4754",
            background: "rgba(255, 255, 255, 0.80)",
            boxShadow: "4px 4px 50px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1A1A1A",
            flexDirection: "column",
            padding: "24px",
            overflow: "hidden",
          },
        }}
      >
        <div style={{ display: "flex", height: "100%", gap: "16px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <div style={{ fontSize: "24px" }}>End Mission</div>
              <div
                className="font-300"
                style={{
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <div>
                  <div>Please Upload Flight Log Before End Mission</div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <UploadFlightLog
                  onChange={(value, urlList) => {
                    setFlightLogFile(value[0]);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "space-evenly",
                  marginTop: "16px",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "white",
                    color: "#1F2937",
                  }}
                  onClick={() => {
                    setShowFinishModal(false);
                    setFlightLogFile(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={flightLogFile ? "register-button-active" : "register-button"}
                  disabled={!flightLogFile}
                  onClick={() => {
                    if (onFinishFlightPost && flightLogFile) {
                      onFinishFlightPost(id, flightLogFile);
                    }
                    setShowFinishModal(false);
                    setFlightLogFile(null);
                  }}
                >
                  End Mission
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </FlightPostItemStyled>
  );
};

export default FlightPostItem;
