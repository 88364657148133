import { Icon, IconButton } from "@ai-and-robotics-ventures/cumulus-ui";
import { ARVMap, Component, Constants, useDraw } from "@ai-and-robotics-ventures/map-library";
import { useTheme } from "@emotion/react";
import { get, groupBy } from "lodash";
import { FC, useCallback, useEffect } from "react";

import MapSettings, { MapSettingStyled } from "components/pages/Map/MapSettings";

interface IMapFarm {
  onChange: (coordinate: [number, number][]) => void;
  defaultCoordinates?: [number, number][][];
  error?: string;
  map: any;
}
const MapFarm: FC<IMapFarm> = ({ onChange, defaultCoordinates, error, map }) => {
  const theme = useTheme();
  const handleClearProjectArea = useCallback(() => {
    onChange([]);
  }, []);
  const onDrawingChange = (e: any) => {
    if (e.collection?.features?.length === 0) return handleClearProjectArea();
    const polygonGroup = groupBy(
      e?.collection?.features,
      (feature) => feature?.properties?.lineNumber
    );
    const points = Object.values(polygonGroup)[0];
    if (!points.length) return handleClearProjectArea();
    const coordinates = points.map((point) => point.geometry.coordinates);
    onChange(coordinates);
  };
  const handleOnDrawing = (e: any) => {
    console.log(e.collection?.features);

    const polygonGroup = groupBy(
      e?.collection?.features,
      (feature) => feature?.properties?.lineNumber
    );
    if (Object.values(polygonGroup).length > 1) {
      onDelete({
        lineNumber: get(Object.values(polygonGroup), "[1].properties.lineNumber"),
        pointNumber: get(Object.values(polygonGroup), "[1].properties.pointNumber"),
      });
      return false;
    }
  };
  const { setMode, mode, onDelete, handleSetDrawData, handleDrawClear } = useDraw({
    onDrawCreate: onDrawingChange,
    onDrawUpdate: onDrawingChange,
    onDrawDelete: onDrawingChange,
    onDrawing: handleOnDrawing,
    mode: Constants.Modes.Normal,
    drawModes: [Constants.Modes.LineString],
    map: map?.map,
    isMapLoaded: map?.isMapLoaded,
  });
  console.log("mode", mode);
  useEffect(() => {
    if (defaultCoordinates && map) {
      handleSetDrawData(defaultCoordinates);
    }
  }, [defaultCoordinates, map]);

  return (
    <div style={{ height: "500px", position: "relative" }}>
      <div
        style={{
          right: 0,
          zIndex: 10,
          padding: 10,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <MapSettings mapRef={map} />
        <MapSettingStyled>
          <IconButton
            css={{
              color:
                mode === Constants.Modes.LineString
                  ? theme.color.bluePrimary[400]
                  : theme.color.grayPrimary[50],
            }}
            fullHeight
            fullWidth
            // disabled={!isDrawable}
            onClick={() => {
              setMode(
                mode === Constants.Modes.LineString
                  ? Constants.Modes.Normal
                  : Constants.Modes.LineString
              );
            }}
          >
            <Icon size={15} icon="VectorPath" />
          </IconButton>
          <IconButton fullHeight fullWidth onClick={() => onDelete()}>
            <Icon size={15} icon="Trash" />
          </IconButton>
        </MapSettingStyled>
      </div>
      <ARVMap
        {...map}
        zoom={14}
        token={
          process.env.REACT_APP_MAPBOXGL_TOKEN ||
          "pk.eyJ1IjoiYWx0ZWFyIiwiYSI6ImNsMDlmZXFwMjA5dXkzY3JxYnZndWJ4eWkifQ.9XiPDHd4pE1N4wLt2t9KTQ"
        }
        dragRotate={false}
        enableAltitude={false}
        tileType="satellite"
        lngLat={[101.44340296804398, 12.994216191296942]}
        style={{ width: "100%", height: "100%", position: "absolute" }}
      />
      <div className="error-message">{error && "Farm area  is a required field"}</div>
    </div>
  );
};
export default MapFarm;
