import { ChartData, ChartOptions } from "chart.js";
import { dayjs } from "core/configs";
import {
  QUERY_ADMIN_GET_DASHBOARD_SUMMARY,
  QUERY_ADMIN_GET_FLIGHT_INFORMATION_DASHBOARD,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TAdminFlightInformationDashboardTableData,
  TAdminGetFlightInformationDashboardResponse,
  TAdminGetSummaryDashboardResponse,
  TSummaryDashboard,
} from "types/admin";

import { useAuth } from "components/pages/AuthPage/store/auth";

export interface FlightStat {
  title: string;
  value: number;
  desc?: string | React.ReactNode;
}

type GetFlightInformationQuery = {
  options: {
    currentPage: number;
    itemsPerPage: number;
  };
  filters?: {
    startDate: string;
    endDate: string;
  };
};

export type FlightInformation = {
  data: TAdminFlightInformationDashboardTableData[];
  totalItems: number;
};

const defaultChartData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: [],
      borderWidth: 1,
    },
  ],
};

export const chartOption: ChartOptions<"pie"> = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "white", // Change the legend text color to red
      },
    },
  },
  aspectRatio: 1,
};

const useReport = ({
  startDate,
  endDate,
  currentPage,
  pageSize,
}: {
  startDate: Date | null;
  endDate: Date | null;
  currentPage: number;
  pageSize: number;
}) => {
  const { t } = useTranslation(["report", "common"]);

  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);

  const [summaryDashboard, setSummaryDashboard] = useState<TSummaryDashboard>();
  const [flightStats, setFlightStats] = useState<FlightStat[]>([]);
  const [totalSprayedChartData, setTotalSprayedChartData] = useState<ChartData>(defaultChartData);

  const [flightInformationTableLoading, setFlightInformationTableLoading] =
    useState<boolean>(false);
  const [flightInformation, setFlightInformation] = useState<FlightInformation>({
    data: [],
    totalItems: 0,
  });

  const fetchSummaryDashboard = useCallback(async () => {
    try {
      let variables: { filters: { startDate: string; endDate: string } } | undefined = undefined;

      if (startDate && endDate) {
        variables = {
          filters: {
            startDate: dayjs(startDate).utc(true).endOf("day").toISOString(),
            endDate: dayjs(endDate).utc(true).endOf("day").toISOString(),
          },
        };
      }

      const query: TAdminGetSummaryDashboardResponse = await client.request(
        QUERY_ADMIN_GET_DASHBOARD_SUMMARY,
        variables
      );

      setSummaryDashboard(query.getSummaryDashboard);

      const totalFlight = query?.getSummaryDashboard?.totalFlightInformation;
      const totalStatSprayedArea = query?.getSummaryDashboard?.totalStatSprayedArea;
      const totalStatSprayedQuantity = query?.getSummaryDashboard?.totalStatSprayedQuantity;
      const totalStatSpreadQuantity = query?.getSummaryDashboard?.totalStatSpreadQuantity;

      const flightStats = [
        {
          title: `${t`Total Sprayed Area`} (${areaUnitLabel})`,
          value: totalStatSprayedArea !== null ? parseFloat(totalStatSprayedArea.toFixed(2)) : 0,
          desc: (
            <>
              <span className="gray">{t`Average Sprayed Area per Flight :`}</span>{" "}
              {totalStatSprayedArea !== null && totalStatSprayedArea
                ? (totalStatSprayedArea / totalFlight).toFixed(2)
                : 0}
            </>
          ),
        },
        {
          title: `${t`Total Sprayed Quantity`} (${t(`L`, { ns: "common" })})`,
          value:
            totalStatSprayedQuantity !== null ? parseFloat(totalStatSprayedQuantity.toFixed(2)) : 0,
          desc: (
            <>
              <span className="gray">{t`Average Sprayed Quantity per Flight :`}</span>{" "}
              {totalStatSprayedQuantity !== null && totalStatSprayedQuantity
                ? (totalStatSprayedQuantity / totalFlight).toFixed(2)
                : 0}
            </>
          ),
        },
        {
          title: `${t`Total Spread Quantity`} (${t(`Kg`, { ns: "common" })})`,
          value:
            totalStatSpreadQuantity !== null ? parseFloat(totalStatSpreadQuantity.toFixed(2)) : 0,
          desc: (
            <>
              <span className="gray">{t`Average Spread Quantity per Flight :`}</span>{" "}
              {totalStatSpreadQuantity !== null && totalStatSpreadQuantity
                ? (totalStatSpreadQuantity / totalFlight).toFixed(2)
                : 0}
            </>
          ),
        },
      ];

      setFlightStats(flightStats);

      const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);
      const totalSprayedChartLabels = query?.getSummaryDashboard?.fieldType.map(
        (type) => type.farmType
      );
      const totalSprayedChartCount = query?.getSummaryDashboard?.fieldType.map(
        (type) => type.count
      );
      const totalSprayedChartBackgroundColor = Array(totalSprayedChartLabels.length)
        .fill(0)
        .map((_) => `#${randomColor()}`);

      const totalSprayedChartData = {
        labels: totalSprayedChartLabels,
        datasets: [
          {
            label: t`# of Sprayed Area`,
            data: totalSprayedChartCount,
            backgroundColor: totalSprayedChartBackgroundColor,
            borderWidth: 1,
          },
        ],
      };

      setTotalSprayedChartData(totalSprayedChartData);
    } catch (e) {
      console.error("Error fetching data", e);
    }
  }, [endDate, startDate]);

  const fetchFlightInformationDashboard = useCallback(
    async (currentPage: number, itemsPerPage: number) => {
      try {
        setFlightInformationTableLoading(true);

        let variables: GetFlightInformationQuery = {
          options: {
            currentPage,
            itemsPerPage,
          },
        };

        if (startDate && endDate) {
          variables = {
            ...variables,
            filters: {
              startDate: dayjs(startDate).utc(true).endOf("day").toISOString(),
              endDate: dayjs(endDate).utc(true).endOf("day").toISOString(),
            },
          };
        }

        const query: TAdminGetFlightInformationDashboardResponse = await client.request(
          QUERY_ADMIN_GET_FLIGHT_INFORMATION_DASHBOARD,
          variables
        );

        const data = query.getFlightInformationDashboard.data.map((item) => {
          const {
            serviced_recipient_field_type,
            serviced_recipient_firstname,
            serviced_recipient_lastname,
          } = item.field || {};

          const sprayedArea = item.stat_sprayed_area
            ? parseFloat(item.stat_sprayed_area.toFixed(2))
            : 0;

          const sprayedQuantity = item.stat_sprayed_quantity
            ? parseFloat(item.stat_sprayed_quantity.toFixed(2))
            : 0;

          return {
            id: item.id,
            area: item.field
              ? `${serviced_recipient_firstname} ${serviced_recipient_lastname} ${serviced_recipient_field_type}`
              : "-",
            createDate: dayjs(item.createdAt).format("DD/MM/YYYY"),
            sprayedArea: sprayedArea || "-",
            sprayedQuantity: sprayedQuantity || "-",
            spreadQuantity: item.stat_spread_quantity || "-",
            droneId: item?.droneInformation?.serialNumber || "-",
            totalDistance: item.stat_flight_length || "-",
          };
        });

        setFlightInformation({
          data,
          totalItems: query.getFlightInformationDashboard.meta.totalItems,
        });
      } catch (e) {
        console.error("Error fetching data", e);
      } finally {
        setFlightInformationTableLoading(false);
      }
    },
    [endDate, startDate]
  );

  useEffect(() => {
    if (startDate && endDate) {
      fetchSummaryDashboard();
    }
  }, [endDate, fetchSummaryDashboard, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchFlightInformationDashboard(currentPage, pageSize);
    }
  }, [currentPage, pageSize, startDate, endDate, fetchFlightInformationDashboard]);

  return {
    summaryDashboard,
    flightStats,
    totalSprayedChartData,
    flightInformationTableLoading,
    flightInformation,
  };
};

export default useReport;
