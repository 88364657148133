import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import React from "react";
import { IDroneInformation } from "types/droneInformation";

import "./DroneMarker.css";

const DroneMarker: React.FC<{ drone: IDroneInformation }> = ({ drone }) => {
  return (
    <div style={{ position: "relative", zIndex: 1000 }}>
      <div className="marker" title={drone.serialNumber}>
        <span className="serial-number">{drone.serialNumber}</span>
      </div>
      <div style={{ position: "relative", top: "2px" }}>
        <Icon icon="Drone" />
      </div>
    </div>
  );
};

export default DroneMarker;
