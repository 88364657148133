import styled from "@emotion/styled";
import { HashLoader } from "react-spinners";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
`;
const Loading = () => (
  <Wrapper>
    <HashLoader color="#32c95b" />
  </Wrapper>
);

export default Loading;
