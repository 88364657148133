import { Icon, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import DocumentImg from "assets/images/document.png";
import { IUploadDetail, handleUploadFlightLog } from "core/domains/services/upload";
import { FC, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

import Loading from "../Loading/Loading";
import { IUploadFile } from "../UploadFarmImageList/UploadFarmImageList";
import UploadFlightLogImage from "./UploadFlightLogImage";

interface IUploadFlightLog {
  onChange: (data: IUploadDetail[], tempUrl: string[]) => void;
  require?: boolean;
  error?: string;
  defaultFileUrlList?: string[];
  defaultImageList?: IUploadDetail[];
}
const UploadFlightLog: FC<IUploadFlightLog> = ({
  require = false,
  onChange,
  error,
  defaultFileUrlList,
  defaultImageList,
}) => {
  const theme = useTheme();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadList, setUploadList] = useState<IUploadDetail[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    if (defaultImageList) {
      setUploadList(defaultImageList);
      const formatFileList = defaultImageList.map((item: IUploadDetail, index: number) => {
        return {
          path: item?.file_name,
          name: item?.unique_id,
          type: `image/${item.ext}`,
          webkitRelativePath: "",
          preview: defaultFileUrlList?.[index] as string,
        };
      });
      setFileList(formatFileList);
    }
  }, [defaultFileUrlList]);

  const handleDeleteImage = (e: any, index: number) => {
    e.stopPropagation();
    setFileList([]);
    setUploadList([]);
    onChange([], []);
  };

  const onDrop = async (acceptedFiles: any) => {
    setIsUploading(true);
    setShowError(false);
    try {
      const updatedFiles: IUploadFile[] = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      console.log(updatedFiles);
      setFileList(updatedFiles);
      const result = await handleUploadFlightLog(acceptedFiles[0]);
      if (result) {
        const urlList = updatedFiles.map((tf) => tf.preview);
        onChange([result], urlList);
      } else {
        setShowError(true);
      }
      setIsUploading(false);
    } catch (error) {
      console.log("in", error);
      setIsUploading(false);
      setShowError(true);
    }
  };

  const { getRootProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const renderPreview = () => {
    return fileList.map((file: any, index: number) => (
      <div key={file.name} style={{ position: "relative", width: "180px", margin: "auto" }}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => handleDeleteImage(e, index)}
        >
          {
            <Icon
              style={{ cursor: "pointer" }}
              icon="XMark"
              size={14}
              color={theme.color.gray[400]}
            />
          }
        </div>
        <div
          style={{
            backgroundImage: `url(${DocumentImg})`,
            backgroundSize: "cover",
            width: "180px",
            height: "180px",
            cursor: "pointer",
          }}
        />
        {isUploading && <Loading />}
      </div>
    ));
  };
  return (
    <div>
      <div {...getRootProps()}>
        {fileList.length !== 0 ? renderPreview() : <UploadFlightLogImage />}
      </div>
      {showError && <div className="error-message">Invalid file format</div>}
      <div className="error-message">{error && error}</div>
    </div>
  );
};
export default UploadFlightLog;
