import { Button } from "@ai-and-robotics-ventures/cumulus-ui";
import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";

const Page404 = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const error = useRouteError();
  console.error("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥", (error as any)?.message);

  const errorMessage = "Internal server error!!!";

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        height: 100vh;
      `}
    >
      <Text variant="h1" color="warning">
        404
      </Text>
      <Text variant="body1" color="info">
        {errorMessage}
      </Text>
      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <Button variant="outlined" onClick={() => navigate(location.pathname)}>
          Refresh
        </Button>
        <Button variant="outlined" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default Page404;
