import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || "";

export const decrypt = (ciphertext: string): string => {
  const key = CryptoJS.enc.Hex.parse(secretKey);

  const [ivHex, encryptedHex] = ciphertext.split(":");
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encrypted,
    iv: iv,
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    iv: iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
