import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import UploadFarmImg from "assets/images/UploadFarm.png";
import { farmTypeList } from "core/utils/constant";
import { getLabelFromConstant } from "core/utils/form";
import { FC } from "react";

import { TAddFarmForm } from "./AddFarmForm";

interface IFarmCardList {
  farmList: TAddFarmForm[];
  selectedEditFarm: (index: number) => void;
  onDeleteFarm: (index: number) => void;
  errorList: any[];
}
interface IFarmCardItem {
  farmInfo: TAddFarmForm;
  selectedEditFarm: (index: number) => void;
  onDeleteFarm: (index: number) => void;
  index: number;
  isError: boolean;
}
const WrapperCardItem = styled.div`
  position: relative;
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin: 8px 0;
  .item-row {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    .item-box {
      display: flex;
      width: 280px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .item-label {
        color: #f9fafb;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      .item-value {
        color: #f9fafb;

        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
      }
    }
  }
  .edit-btn {
    position: absolute;
    right: 16px;
    position: absolute;
    right: 16px;
    display: flex;
    padding: 5px 8px;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .delete-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
  }
`;
const styles = ({ isError }: { isError: boolean }) => css`
  ${isError === true ? `border: 1px solid #863232; border-radius: 8px;` : ``}
`;
const FarmCardItem: FC<IFarmCardItem> = ({
  farmInfo,
  selectedEditFarm,
  index,
  onDeleteFarm,
  isError,
}) => {
  const { name, totalArea, farmType, imageList, urlList } = farmInfo;
  return (
    <WrapperCardItem css={styles({ isError })}>
      <img
        src={urlList?.[0] || UploadFarmImg}
        width={248}
        height={152}
        alt={name}
        style={{ objectFit: "cover" }}
      />
      <div className="item-row">
        <div className="item-box">
          <div className="item-label">Farm Name</div>
          <div className="item-value">{name}</div>
        </div>
        <div className="item-box">
          <div className="item-label">Farm Type</div>
          <div className="item-value">{getLabelFromConstant(farmTypeList, farmType)}</div>
        </div>
      </div>
      <div className="item-row">
        <div className="item-box">
          <div className="item-label">Total Area</div>
          <div className="item-value">{totalArea}</div>
        </div>

        {/* <div className="item-box">
          <div className="item-label">Farmer</div>
          <div className="item-value">{farmType.value}</div>
        </div> */}
      </div>
      <div className="edit-btn" onClick={() => selectedEditFarm(index)}>
        <Icon icon="Edit" />
        Edit
      </div>
      <Icon
        className="delete-btn"
        icon="Trash"
        color="#B56060"
        size={24}
        onClick={() => onDeleteFarm(index)}
      />
    </WrapperCardItem>
  );
};
const FarmCardList: FC<IFarmCardList> = ({
  farmList,
  selectedEditFarm,
  onDeleteFarm,
  errorList,
}) => {
  return (
    <div>
      {farmList.map((item, index) => (
        <FarmCardItem
          isError={!!errorList?.[index]}
          farmInfo={item}
          key={item.name}
          selectedEditFarm={selectedEditFarm}
          index={index}
          onDeleteFarm={onDeleteFarm}
        />
      ))}
    </div>
  );
};
export default FarmCardList;
