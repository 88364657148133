import styled from "@emotion/styled";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import dayjs from "dayjs";
import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { IDroneInformation } from "types/droneInformation";
import { IFlightInformation, IFlightInformationAndLocation } from "types/flightInformation";

import { useAuth } from "components/pages/AuthPage/store/auth";
import { useDisplayPolygon } from "components/pages/Map/useDisplay";
import AiangStatus from "components/templates/AiangStatus/AiangStatus";

interface IProps {
  flightInformation?: IFlightInformationAndLocation;
  droneInformation?: IDroneInformation;
  areaUnitLabel?: string;
}

export enum ArgiMode {
  Spray = "Spray mode",
  Spread = "Spread mode",
}

const Wrapper = styled.div`
  position: absolute;
  width: calc(100vw - 240px);
  padding: 16px 24px;
  left: 140px;
  bottom: 24px;
  border-radius: 10px;
  background: rgba(26, 26, 26, 0.9);
  backdrop-filter: blur(5px);
  .title {
    color: #f9fafb;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
    margin-bottom: 16px;
  }
  .flight-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 8px;
  }
  .flight-label {
    color: #9ca3af;
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
    flex: 1;
  }
  .flight-value {
    color: #d1d5db;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    flex: 2;
  }

  .flex-border {
    flex: 1;
    border-left: 1px solid #6b7280;
    padding-left: 24px;
    height: 100%;
  }

  .chart-wrapper {
    height: 224px;
    display: flex;
    align-items: center;
  }
`;

const FlightDetail = ({ flightInformation, droneInformation }: IProps) => {
  const { t } = useTranslation(["drone", "common"]);

  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);

  const flightPostDetail = {
    farmer: {
      firstName: "mock-first-name",
      lastName: "mock-last-name",
    },
    pilot: {
      firstName: "mock-pilot-first-name",
      lastName: "mock-pilot-last-name",
    },
    farm: {
      name: "mock-farm-name",
      totalArea: "mock-total-area",
    },
    drone: {
      name: "",
      model: "",
    },
    name: "mock-post-name",
    pilotEmail: "mock-pilot-email",
    startTime: new Date(),
    endTime: new Date(),
    status: "READY",
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        display: false,
      },
    },
  };

  const chartData = useMemo(() => {
    return {
      labels: Array.from(flightInformation?.batteryVoltages || [], () => ""),
      datasets: [
        {
          label: t`Battery Voltages`,
          data: flightInformation?.batteryVoltages || [],
          borderColor: "#d39523",
          backgroundColor: "#d39523",
          borderWidth: 1,
          pointRadius: 0,
          fill: false,
        },
      ],
    };
  }, [flightInformation?.batteryVoltages]);

  return (
    <Wrapper>
      <div style={{ display: "flex", alignItems: "flex-start", gap: "24px" }}>
        <div
          style={{
            flex: 1.5,
            // gap: "16px",
            flexDirection: "column",
          }}
        >
          <div className="title">{t`Flight Detail`}</div>
          <div className="flight-name">{flightInformation?.id}</div>
          <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Farmer :`}</span>
                <span className="flight-value">
                  {flightInformation?.field?.serviced_recipient_firstname}{" "}
                  {flightInformation?.field?.serviced_recipient_lastname}
                </span>
              </div>
              {/* <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Pilot :</span>
                <span className="flight-value">
                  {flightPostDetail.pilot?.firstName ?? "-"}{" "}
                  {flightPostDetail.pilot?.lastName ?? ""}
                </span>
              </div> */}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Location :`}</span>
                <span className="flight-value">
                  {flightInformation?.field?.serviced_recipient_province}{" "}
                  {flightInformation?.field?.serviced_recipient_region}
                </span>
              </div>
              {/* <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Pilot Email :</span>
                <span className="flight-value">{flightPostDetail.pilotEmail ?? "-"}</span>
              </div> */}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Area :`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_sprayed_area}{" "}
                  <span style={{ fontWeight: "Thin" }}>{areaUnitLabel}</span>
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Drone :`}</span>
                <span className="flight-value">{droneInformation?.serialNumber}</span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Time :`}</span>
                <span className="flight-value">
                  <div>
                    {dayjs(flightInformation?.start_flight_at).format("DD MMMM YYYY")} -{" "}
                    {dayjs(flightInformation?.end_flight_at).format("DD MMMM YYYY")}
                  </div>
                  <div>
                    {dayjs(flightInformation?.start_flight_at).format("HH:mm")} -{" "}
                    {dayjs(flightInformation?.end_flight_at).format("HH:mm")}
                  </div>
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Version :`}</span>
                <span className="flight-value">{droneInformation?.versionConf ?? "-"}</span>
              </div>
            </div>
            {/* <div style={{ display: "flex" }}>
              <span className="flight-label" style={{ flex: 1 }}>
                Status :
              </span>
              <div className="flight-value" style={{ flex: 5 }}>
                <AiangStatus status={flightPostDetail.status} />

                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "8px" }}
                >
                  <DropWaterIcon />
                  <span>678.35 L</span>
                  <GrassIcon />
                  <span>456.78 kg</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            height: "264px",
            borderLeft: "1px solid #6B7280",
            paddingLeft: "24px",
          }}
        >
          <div className="title">{t`Flight Information Stats`}</div>
          <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Sprayed area:`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_sprayed_area &&
                  flightInformation?.stat_sprayed_area !== null
                    ? flightInformation?.stat_sprayed_area?.toFixed(2)
                    : 0}{" "}
                  {areaUnitLabel}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Sprayed quantity:`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_sprayed_quantity &&
                  flightInformation?.stat_sprayed_quantity !== null
                    ? flightInformation?.stat_sprayed_quantity?.toFixed(2)
                    : 0}{" "}
                  {t(`L`, { ns: "common" })}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Spread quantity:`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_spread_quantity?.toFixed(2) || 0}{" "}
                  {t(`Kg`, { ns: "common" })}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Service mode id :`}</span>
                <span className="flight-value">
                  {flightInformation?.agri_mode === "0" ? ArgiMode.Spray : ""}
                  {flightInformation?.agri_mode === "1" || flightInformation?.agri_mode === "2"
                    ? ArgiMode.Spread
                    : ""}
                  {flightInformation?.agri_mode === "null" ? "-" : null}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Boot count :`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_boot_count && flightInformation?.stat_boot_count !== null
                    ? flightInformation?.stat_boot_count
                    : 0}{" "}
                  {t(`times`, { ns: "common" })}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Flight time :`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_flight_time &&
                  flightInformation?.stat_flight_time !== "null"
                    ? flightInformation?.stat_flight_time
                    : "0"}{" "}
                  {t(`minutes`, { ns: "common" })}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">{t`Flight length :`}</span>
                <span className="flight-value">
                  {flightInformation?.stat_flight_length &&
                  flightInformation?.stat_flight_length !== null
                    ? flightInformation?.stat_flight_length?.toFixed(2)
                    : 0}{" "}
                  {t(`Km`, { ns: "common" })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-border">
          <div className="title">{t`Battery Voltages`}</div>
          <div className="chart-wrapper">
            <Line height={200} options={chartOptions} data={chartData} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default FlightDetail;
