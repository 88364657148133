import { Button, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import { MUTATION_PILOT_REGISTER, QUERY_CHECK_PILOT_MAIL_EXISTS } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addPilotDefaultValue, addPilotSchema } from "core/schema/admin";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FormErrors } from "types/base";
import { TCheckPilotEmailResponse } from "types/pilot";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { PilotIconSelected, PilotIconWhite } from "components/templates/Icon";

import PilotStep from "../PilotSignupPage/PilotStep/PilotStep";
import AddPilotPageStyled from "./AddPilotPage.styled";

type TAddPilotSchema = z.infer<typeof addPilotSchema>;

const AddPilotPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Pilot");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modal = useSuccessModal({
    title: "New Pilot Added",
    detail: (
      <>
        <div>Your pilot has been successfully </div>
        <div>added to the platform</div>
      </>
    ),
    callBack: () => navigate("/pilot-list"),
  });
  const validatePilotEmail = async (email: string) => {
    try {
      const variables = {
        email,
      };
      const result: TCheckPilotEmailResponse = await client.request(
        QUERY_CHECK_PILOT_MAIL_EXISTS,
        variables
      );
      return result.checkPilotEmailExists;
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik<TAddPilotSchema>({
    initialValues: addPilotDefaultValue,
    validationSchema: toFormikValidationSchema(addPilotSchema),
    onSubmit: (values) => handleSubmitForm(values),
    validate: async (values) => {
      const errors: FormErrors = {};
      if (values.email) {
        const asyncError = await validatePilotEmail(values.email);
        if (asyncError) {
          errors.email = "email is already exists";
        }
      }
      return errors;
    },
  });
  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);
      const pilotInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        email: values.email,
        phoneNo: values.phoneNo,
        password: values.password,
        license: values.license,
      };
      const variables = {
        pilotInput: pilotInput,
      };
      await client.request(MUTATION_PILOT_REGISTER, variables);
      modal.openModal();
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating item:", error);
      toast({
        title: "",
        description:
          "Registration Error: Unable to process your registration at the moment. Please try again",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <AddPilotPageStyled>
      <form onSubmit={formik.handleSubmit}>
        <div className="">
          <h1
            style={{
              padding: "32px 16px",
              fontWeight: "bold",
              marginTop: 0,
            }}
          >
            Pilot Registration
          </h1>
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Pilot",
                    selectedIcon: <PilotIconSelected />,
                    notSelectedIcon: <PilotIconWhite />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <PilotStep form={formik} />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/pilot-list"}>
                <Button className="cancel-button">Cancel</Button>
              </Link>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                <span style={{ marginLeft: "8px" }}>Add Pilot</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AddPilotPageStyled>
  );
};

export const Component = AddPilotPage;
export default AddPilotPage;
