import {
  ARVMap,
  BoxLayer,
  BoxSource,
  Layer,
  LineString,
  Mesh,
  ThreeLayer,
  UIMarker,
  mapboxgl,
  maptalks,
  useMap,
  usePinContent,
} from "@ai-and-robotics-ventures/map-library";
import { MapboxOverlay } from "@deck.gl/mapbox";
import { useTheme } from "@emotion/react";
import { CesiumIonLoader } from "@loaders.gl/3d-tiles";
import * as turf from "@turf/turf";
import { Position } from "@turf/turf";
import { Tile3DLayer } from "deck.gl";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { Coordinate } from "maptalks";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import WangChanModel from "../../../assets/gltf/scene_mesh_textured.gltf";
import PTICImage from "../../../assets/images/ptic2.png";
import droneSVG from "../../../assets/svgs/drone.svg";
import { getCenter } from "../../../core/domains/utils";
// import { paramsToObject } from "core/domains/utils/searchParams";
import { paramsToObject } from "../../../core/domains/utils/searchParams";
import MapSettings from "./MapSettings";
import WayPointMarker from "./components/WayPointMarker";
import { getVolumeId, useDisplayMarker } from "./useDisplay";
import useNoFlyZone from "./useNoFlyZone";

const checkUUID = (id: string) => id.split("-").length === 5;
const scrollToId = (id: string) => {
  const element = window.document.getElementById(id);
  element?.scrollIntoView({ behavior: "smooth" });
};

const tileset_id = [1601302];

// export const mapAtom = atom<Component.ARVMap.UseMap | undefined>(undefined);
export const preMapSettingsAtom = atom(<></>);

const mapbox_token = process.env.REACT_APP_MAPBOXGL_TOKEN || "";
const CESIUM_ION_TOKEN = process.env.REACT_APP_CESIUM_ION_TOKEN || "";

export type HeatPosition = {
  id: string;
  coordinates: [number, number, number];
};

export type Volume = {
  id: string;
  coordinates: number[][];
  color?: string;
  opacity?: number;
  type?: string;
  ceiling?: number;
  floor?: number;
  showOutline?: boolean;
  outlineColor?: string;
};
export type Marker = {
  id: string;
  color: string;
  borderColor: string;
  center: number[];
  icon: ReactNode;
};
export type WaypointGroup = {
  groupId: string; // mission id, group id
  waypoints: {
    id: string; // waypoint id
    text?: string;
    color?: string;
    coordinate: number[];
  }[];
};
export type Dashline = {
  id: string;
  coordinates: number[][];
};
export type Line = {
  id: string;
  coordinates: number[][];
};

export const ARVmapPropsAtom = atom<any>(undefined);
export const polygonAtom = atom<[number, number][]>([]);
export const centerAtom = atom<number[]>([101.445062498716, 12.99441220336984]);
export const zoomAtom = atom<number>(7);
export const volumesAtom = atom<Volume[]>([]);
export const markersAtom = atom<Marker[]>([]);
export const dronePathAtom = atom<[number, number][]>([]);

export const heatPositionsAtom = atom<HeatPosition[]>([]);
export const waypointsAtom = atom<WaypointGroup[]>([]);
export const dashlinesAtom = atom<Dashline[]>([]);
export const linesAtom = atom<Line[]>([]);
export const projectAreaAtom = atom<Position[]>([]);
export const isEnableAltitudeAtom = atom<boolean>(false);
export const isEnableNoFlyZoneAtom = atom<boolean>(false);
export const extentAtom = atom<{
  ymin: number;
  xmin: number;
  ymax: number;
  xmax: number;
}>({ ymin: 0, xmin: 0, ymax: 0, xmax: 0 });

export const resetMapAtom = atom(null, (get, set) => {
  set(volumesAtom, []);
  set(markersAtom, []);
  set(waypointsAtom, []);
  set(dashlinesAtom, []);
  set(linesAtom, []);
  set(projectAreaAtom, []);
  set(polygonAtom, []);
  set(dronePathAtom, []);
  set(heatPositionsAtom, []);
  //set back to initial state when route changes
  set(centerAtom, [101.445062498716, 12.99441220336984]);
});

const MapComponent = () => {
  const theme = useTheme();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { project_id, mission_id } = paramsToObject(searchParams);
  const layersSet = useMemo<any[]>(() => {
    const layers: any[] = [];
    tileset_id.forEach((t) => {
      layers.push(
        new Tile3DLayer({
          id: "tile-3d-layer-" + t,
          data: "https://assets.ion.cesium.com/" + t + "/tileset.json",
          loader: CesiumIonLoader,
          loadOptions: {
            tileset: {
              throttleRequests: false,
            },
            "cesium-ion": {
              accessToken: CESIUM_ION_TOKEN,
            },
          },
        })
      );
    });
    return layers;
  }, []);
  const setARVmapProps = useSetAtom(ARVmapPropsAtom);
  const center = useAtomValue(centerAtom);
  const zoom = useAtomValue(zoomAtom);
  const volumes = useAtomValue(volumesAtom);
  const markers = useAtomValue(markersAtom);
  const waypoints = useAtomValue(waypointsAtom);
  const dashlines = useAtomValue(dashlinesAtom);
  const lines = useAtomValue(linesAtom);
  const projectArea = useAtomValue(projectAreaAtom);
  const isEnableAltitude = useAtomValue(isEnableAltitudeAtom);
  const isEnableNoFlyZone = useAtomValue(isEnableNoFlyZoneAtom);
  const pos = useAtomValue(polygonAtom);
  const dronePath = useAtomValue(dronePathAtom);
  const [extent, setExtent] = useAtom(extentAtom);
  const heatPositions = useAtomValue(heatPositionsAtom);
  // const setMap = useSetAtom(mapAtom);
  const resetMap = useSetAtom(resetMapAtom);

  // INITIAL MAP
  const mapProps = useMap();
  const renderer = useRef<THREE.WebGLRenderer>();
  const camera = useRef<THREE.Camera>(new THREE.Camera());
  const scene = useRef<THREE.Scene>(new THREE.Scene());
  const modelOrigin = useMemo(() => [101.4525507242, 12.9771107141], []);
  const modelAltitude = useMemo(() => 240, []);
  const modelRotate = useMemo(() => [0, 0, 0.002], []);
  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    modelOrigin as mapboxgl.LngLatLike,
    modelAltitude
  );
  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z ?? 0,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    /* Since the 3D model is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };
  const customLayer = useMemo<mapboxgl.CustomLayerInterface>(() => {
    return {
      id: "wang-chan-map",
      type: "custom",
      renderingMode: "3d",
      onAdd: function (map: mapboxgl.Map, gl: WebGLRenderingContext) {
        // scene.current = new THREE.Scene();
        if (scene.current.children.length === 0) {
          const directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -70, 100).normalize();
          scene.current.add(directionalLight);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff);
          directionalLight2.position.set(0, 70, 100).normalize();
          scene.current.add(directionalLight2);
          // use the three.js GLTF loader to add the 3D model to the three.js scene
          THREE.Cache.enabled = true;
          const loader = new GLTFLoader();
          // const loadingManager = new THREE.LoadingManager().setURLModifier;

          loader.load("3D/scene_mesh_textured.gltf", (gltf) => {
            // console.log("gltf;", gltf);
            scene.current.add(gltf.scene);
          });

          // use the Mapbox GL JS map canvas for three.js
          renderer.current = new THREE.WebGLRenderer({
            canvas: mapProps.map.mapbox.current?.getCanvas(),
            context: gl,
            antialias: true,
          });
          renderer.current.autoClear = false;
        }
      },
      render: function (_gl: WebGLRenderingContext, matrix: number[]) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(1, 0, 0),
          modelTransform.rotateX
        );
        const rotationY = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 1, 0),
          modelTransform.rotateY
        );
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 0, 1),
          modelTransform.rotateZ
        );
        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ ?? 0
          )
          .scale(
            new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale)
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);
        camera.current.projectionMatrix = m.multiply(l);
        if (renderer.current) {
          renderer.current.resetState();
          renderer.current.render(scene.current, camera.current);
        }
        mapProps.map.mapbox.current?.triggerRepaint();
      },
    };
  }, []);

  useEffect(() => {
    if (mapProps.isMapLoaded) {
      const wc = mapProps.map.mapbox.current?.getLayer("wang-chan-map");
      if (!wc && isEnableAltitude) {
        mapProps.map.mapbox.current?.addLayer(customLayer);
      } else if (wc) {
        mapProps.map.mapbox.current?.removeLayer("wang-chan-map");
      }
    }
  }, [customLayer, isEnableAltitude, mapProps.isMapLoaded, mapProps.map.mapbox]);

  // const customLayer = useWangChan3D(mapProps);
  useEffect(() => setARVmapProps(mapProps), [setARVmapProps, mapProps]);

  // useEffect(() => {
  //   setMap(mapProps);
  // }, [mapProps, setMap]);

  // reset map on change page
  useEffect(() => resetMap(), [location.pathname, resetMap]);

  // Set selected volume
  const [selectedVolumeId, setSelectedVolumeId] = useState(mission_id || project_id);
  useEffect(() => {
    if (mission_id) {
      setSelectedVolumeId(mission_id);
      scrollToId(`mission_${mission_id}`);
    } else if (project_id) {
      setSelectedVolumeId(project_id);
      scrollToId(`project_${project_id}`);
    } else setSelectedVolumeId("");
  }, [mission_id, project_id]);
  const selectedVolume = useMemo(
    () => volumes.find((volume) => volume.id?.includes(selectedVolumeId)),
    [volumes, selectedVolumeId]
  );

  // Go to selected volumes on changed selectedVolumeId
  useEffect(() => {
    if (!selectedVolume?.coordinates?.length) return;
    mapProps?.map?.maptalk?.current?.animateTo(
      { center: getCenter(selectedVolume.coordinates) },
      { duration: 300 }
    );
  }, [mapProps.isMapLoaded, selectedVolumeId]);

  useEffect(() => {
    if (mapProps.map) {
      mapProps.map.mapbox.current?.once("load", () => {
        if (!mapProps.map.mapbox.current?.getSource("radar")) {
          mapProps.map.mapbox.current?.addSource("radar", {
            type: "image",
            url: PTICImage,
            coordinates: [
              [101.44165070214643, 12.996894648923387],
              [101.45046683268197, 12.996816027959582],
              [101.45030858153893, 12.992349476258227],
              [101.44188075983698, 12.992584232772131],
            ],
          });
          mapProps.map.mapbox.current?.addLayer({
            id: "radar-layer",
            type: "raster",
            source: "radar",
            paint: {
              "raster-fade-duration": 0,
            },
          });
        }
      });
      if (layersSet) {
        // mapProps.map.mapbox.current?.once("load", () => {
        // const tile3DOverlay = new MapboxOverlay({
        //   layers: layersSet,
        // });
        // mapProps.map.mapbox?.current?.addControl(tile3DOverlay);
      }
    }
  }, []);

  const handleClickPolygon = (geo: any[]) => {
    const polygon = geo[0];
    if (!polygon) return;
    const { project_id, mission_id } = getVolumeId(polygon.id);
    setSearchParams((params) => {
      checkUUID(project_id) && params.set("project_id", project_id);
      checkUUID(mission_id) && params.set("mission_id", mission_id);
      return params;
    });
  };

  const updateExtent = useCallback(
    (e: any) => {
      if (!e?.target?.getExtent) return;
      setExtent(e.target.getExtent());
    },
    [setExtent]
  );
  const handleMoveEnd = useCallback(
    (e: maptalks.EvenableHandlerFun) => {
      updateExtent(e);
    },
    [updateExtent]
  );

  // No fly zone
  const isShowAllProjectNFZ = useMemo(() => location.pathname !== "/project", [location.pathname]);
  const { volumes: noFlyZoneVolumes } = useNoFlyZone({
    isEnabled: isEnableNoFlyZone,
    minLatLng: [extent.ymin, extent.xmin],
    maxLatLng: [extent.ymax, extent.xmax],
    projectId: isShowAllProjectNFZ ? undefined : project_id,
  });

  // MAP RENDER COMPONENTS

  // map volume to mesh
  const renderVolumes = useMemo(
    () =>
      volumes
        .filter((volume) => volume.id)
        .filter((volume) => volume.coordinates.length)
        .map((volume, index) => {
          try {
            let height = volume.ceiling || 1;
            if (!isEnableAltitude) height = height > 3 ? 3 : height;
            const properties = {
              id: volume.id,
              base_height: volume.floor || 0, // height,
              height: height,
              color: volume.color || theme.color.success[500],
              opacity: volume.opacity || 0.5,
              type: volume.type || "",
            };
            const polygon = turf.polygon([volume.coordinates], properties, { id: index });
            return (
              <Mesh
                outline={volume.showOutline}
                outlineColor={volume.outlineColor || theme.color.success[500]}
                key={`${volume.id}_area_${location.pathname}`}
                id={`${volume.id}_area_${location.pathname}`}
                geoJSON={turf.featureCollection([polygon])}
              />
            );
          } catch (err) {
            console.error("Erorr create volume", volume.id, err);
            return null;
          }
        }),
    [isEnableAltitude, location.pathname, theme, volumes]
  );

  const renderMarkers = useMemo(() => {
    return markers.map((pin, index) => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const content = usePinContent({
          children: pin.icon,
          borderColor: pin.borderColor,
          color: pin.color,
          radar: true,
        });

        return (
          <UIMarker
            key={`ui_marker ${index}`}
            id="test-marker"
            options={{ content: content }}
            coordinate={pin.center}
          />
        );
      } catch (err) {
        console.error("Error create marker", pin.id, err);
        return null;
      }
    });
  }, [markers]);

  // map noflyzone to mesh
  const renderNoFlyZones = useMemo(
    () =>
      noFlyZoneVolumes
        .filter((volume) => volume.id && volume.coordinates?.length > 3)
        .map((volume, index) => {
          try {
            const properties = {
              id: volume.id,
              base_height: volume.floor || 0, // height,
              height: volume.ceiling || 1,
              color: volume.color || theme.color.success[500],
              opacity: volume.opacity || 0.5,
              type: volume.type || "",
            };
            const polygon = turf.polygon([volume.coordinates], properties, { id: index });
            return (
              <Mesh
                outline={volume.showOutline}
                outlineColor={volume.outlineColor || theme.color.success[500]}
                key={`${volume.id}_nofly_area`}
                id={`${volume.id}_nofly_area`}
                geoJSON={turf.featureCollection([polygon])}
                isVisible={false}
              />
            );
          } catch (err) {
            console.error("Erorr create noFlyZone", volume.id, volume.coordinates, err);
            return null;
          }
        }),
    [noFlyZoneVolumes, theme]
  );

  useEffect(() => {
    const fitMapToBounds = async () => {
      const mapboxMap = mapProps?.map?.mapbox?.current;
      const maptalkMap = mapProps?.map?.maptalk?.current;

      if (markers.length > 0 && mapboxMap) {
        const markerPositions = markers.map((marker) => [marker.center]);
        const bounds = turf.bbox(
          turf.featureCollection(markerPositions.map((pos) => turf.point(pos[0])))
        );

        const map = mapboxMap.fitBounds(
          [
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]],
          ],
          {
            maxZoom: 17,
            maxDuration: 0,
            animate: false,
          }
        );

        if (maptalkMap) {
          const center = [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2];
          maptalkMap.setCenter(new maptalks.Coordinate(center));
          maptalkMap.setZoom(map.getZoom());
        }
      }
    };
    fitMapToBounds();
  }, [markers]);

  useEffect(() => {
    mapProps?.map?.maptalk?.current?.animateTo({ center: center }, { duration: 300 });
  }, [center]);

  const HeatPositions = useMemo(() => {
    const features: turf.Feature<turf.Point, { id: string }>[] = heatPositions.map((position) => {
      const feature: turf.Feature<turf.Point, { id: string }> = {
        type: "Feature",
        properties: {
          id: position.id,
        },
        geometry: {
          type: "Point",
          coordinates: position.coordinates,
        },
      };

      return feature;
    });

    const featureCollection: turf.FeatureCollection<turf.Point> = {
      type: "FeatureCollection",
      features: features,
    };

    return (
      <BoxSource
        id="heatmap"
        source={{
          type: "geojson",
          data: featureCollection,
        }}
      >
        <BoxLayer
          layer={{
            id: "heatPositions-heat",
            type: "heatmap",
            source: "heatmap",
            paint: {
              "heatmap-weight": ["interpolate", ["linear"], ["get", "mag"], 0, 0, 6, 1],

              "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],

              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(33,102,172,0)",
                0.2,
                "rgb(103,169,207)",
                0.4,
                "rgb(209,229,240)",
                0.6,
                "rgb(253,219,199)",
                0.8,
                "rgb(239,138,98)",
                0.9,
                "rgb(178,24,43)",
              ],
              "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 7, 12],
            },
          }}
        />
      </BoxSource>
    );
  }, [heatPositions]);

  const Polygon = useMemo(
    () => (
      <BoxSource
        id="boundary"
        source={{
          type: "geojson",
          data: {
            features:
              pos?.length > 3
                ? [
                    turf.polygon([pos], {
                      color: "#6b5be2",
                    }),
                  ]
                : [],
            type: "FeatureCollection",
          },
        }}
      >
        <BoxLayer
          layer={{
            id: "paths",
            type: "fill",
            source: "boundary",
            paint: {
              "fill-color": ["get", "color"],
              "fill-opacity": 0.5,
            },
          }}
        />
        <BoxLayer
          layer={{
            id: "paths2",
            type: "line",
            source: "boundary",
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": ["get", "color"],
              "line-width": 4,
            },
          }}
        />
      </BoxSource>
    ),
    [pos]
  );

  return (
    <>
      <div
        style={{
          right: 0,
          zIndex: 10,
          padding: 10,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <MapSettings mapRef={mapProps} />
      </div>
      <ARVMap
        {...mapProps}
        token={mapbox_token}
        dragRotate={false}
        enableAltitude={isEnableAltitude}
        tileType="satellite"
        zoom={zoom}
        lngLat={center}
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClickPolygon={handleClickPolygon}
        onMoveEnd={handleMoveEnd}
      >
        <ThreeLayer
          id={"VOLUMES"}
          options={{ forceRenderOnMoving: true, forceRenderOnRotating: true, zIndex: 2 }}
          config={{ animation: true }}
        >
          {renderVolumes}
        </ThreeLayer>

        <ThreeLayer
          id={"NO_FLY_ZONE"}
          options={{ forceRenderOnMoving: true, forceRenderOnRotating: true, zIndex: 2 }}
          config={{ animation: true }}
        >
          {renderNoFlyZones.map(
            (mesh) => mesh && React.cloneElement(mesh, { isVisible: isEnableNoFlyZone })
          )}
        </ThreeLayer>

        {/* Heat map */}
        {HeatPositions}

        <Layer id={"LAYER.MARKERS"} options={{ enableAltitude: false, zIndex: 3 }}>
          {renderMarkers}
        </Layer>

        {/* Waypoints */}
        <Layer id="waypoint_layer" options={{ zIndex: 5 }}>
          {waypoints.map((waypointGroup, i) => {
            return waypointGroup.waypoints.map((waypoint, j) => (
              <WayPointMarker
                id={waypointGroup.groupId + i + j}
                coordinate={waypoint.coordinate}
                text={waypoint.text || String(j + 1)}
                color={waypoint.color || theme.color.grayPrimary[800]}
              />
            ));
          })}
        </Layer>

        {/* Line with distance */}
        {/* TODO: Fix removelayer in map lib before use this */}
        {/* {renderWaypoints.map((waypointGroup, i) => (
          <LineWithDistanceLayer
            key={i}
            id={i}
            coordinates={waypointGroup.waypoints.map(({ coordinate }) => coordinate)}
          />
        ))} */}

        {/* Dash line */}
        <Layer id="line-waypoint-route" options={{ zIndex: 4 }}>
          {waypoints.map((waypointGroup) => (
            <LineString
              key={`${waypointGroup.groupId}_path`}
              id={`${waypointGroup.groupId}_path`}
              coordinates={waypointGroup?.waypoints?.map(({ coordinate }) => coordinate)}
              options={{
                symbol: {
                  lineWidth: 4,
                  lineColor: "white",
                  lineDasharray: [4, 12],
                  lineCap: "round",
                  lineJoin: "round",
                },
              }}
            />
          ))}
          {dashlines.map((dashline) => (
            <LineString
              key={`${dashline.id}_dash-line`}
              id={`${dashline.id}_dash-line`}
              coordinates={dashline.coordinates}
              options={{
                symbol: {
                  lineWidth: 4,
                  lineColor: "white",
                  lineDasharray: [4, 12],
                  lineCap: "round",
                  lineJoin: "round",
                },
              }}
            />
          ))}
        </Layer>

        {/* Route line */}
        <Layer id="line-route" options={{ zIndex: 5 }}>
          {lines.map((line) => (
            <LineString
              key={`${line.id}_route`}
              id={`${line.id}_route`}
              coordinates={line.coordinates}
              options={{
                properties: {
                  altitude: line.coordinates.map(([log, lat, altitude]) => altitude),
                },
                symbol: {
                  lineWidth: 4,
                  lineColor: {
                    type: "radial",
                    colorStops: [
                      [0.0, "#FFFFFF"],
                      [0.5, "#6F85FF"],
                      [1.0, "#4059E3"],
                    ],
                  },
                  lineCap: "round",
                  lineJoin: "round",
                },
              }}
            />
          ))}
        </Layer>

        {/* <ThreeLayer
          id="operational_volume"
          options={{ forceRenderOnMoving: true, forceRenderOnRotating: true, zIndex: 2 }}
          config={{ animation: true }}
        >
          {renderVolumes}
        </ThreeLayer> */}

        {projectArea.length > 0 && (
          <Layer id="project_area_path" options={{ zIndex: 3 }}>
            {/* {renderWaypoints.map((waypointGroup, i) => ( */}
            <LineString
              key="project_area_path"
              id="project_area"
              coordinates={projectArea}
              options={{
                symbol: {
                  lineWidth: 4,
                  lineColor: "white",
                  lineDasharray: [4, 12],
                  lineCap: "round",
                  lineJoin: "round",
                },
              }}
            />
            {/* ))} */}
          </Layer>
        )}

        {/* {Polygon} */}

        {/* Drone path */}
        {
          <Layer id="drone_path" options={{ zIndex: 6 }}>
            <LineString
              id={"drone-path-line"}
              coordinates={dronePath}
              options={{
                symbol: {
                  lineColor: "black",
                  lineWidth: 2,
                  lineDasharray: [10, 10],
                  markerFile: droneSVG,
                  markerPlacement: "vertex-first", //vertex, point, vertex-first, vertex-last, center
                  markerVerticalAlignment: "middle",
                  markerWidth: 25,
                  markerHeight: 25,
                },
                arrowPlacement: "point",
                arrowStyle: "classic",
                enableSimplify: true,
                simplifyTolerance: 20,
              }}
            />
          </Layer>
        }
      </ARVMap>
    </>
  );
};

export default MapComponent;
