import styled from "@emotion/styled";
import { FC } from "react";

export type TBadgeType = "normal" | "success" | "warning" | "inprogress";
interface IBadge {
  type: TBadgeType;
  label: string;
}

const Wrapper = styled.div`
  display: flex;
  div {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #edf2f7;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
    }
  }
  .normal {
    color: black;
    background-color: white;
  }
  .success {
    background-color: #b2f5ea;
    color: #1d4044;
  }
  .warning {
    background-color: #feebc8;
    color: #652b19;
  }
  .inprogress {
    background-color: #e9d8fd;
    color: #322659;
  }
`;
const Badge: FC<IBadge> = ({ type = "normal", label }) => {
  return (
    <Wrapper>
      <div className={type}>
        <span>{label}</span>
      </div>
    </Wrapper>
  );
};
export default Badge;
