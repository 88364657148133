import { get } from "lodash";

export const setTouchAllFields = (form: any, fields: string[]) => {
  const touchedFields = fields.reduce((acc, field) => {
    acc[field] = true;
    return acc;
  }, {});

  form.setTouched(touchedFields);
};
export const registerAllFields = (form: any, fields: string[]) => {
  fields.forEach((f) => {
    form.registerField(f);
  });
};

export const checkIsError = (errors: Object, fields: string[]) => {
  const result = fields.some((f) => get(errors, f, false));
  return result;
};

interface IItem {
  label: string;
  value: string;
}
export const getLabelFromConstant = (data: IItem[], value: string) => {
  const found = data.find((d) => d.value === value);
  if (found) {
    return found.label;
  }
  return value;
};
