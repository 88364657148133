import { IconButton } from "@ai-and-robotics-ventures/cumulus-ui";
import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { Component, useDraw, useZoom } from "@ai-and-robotics-ventures/map-library";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useAtom, useAtomValue } from "jotai";
import { rgba } from "polished";
import React from "react";

import { useAuth } from "../AuthPage/store/auth";
import { isEnableAltitudeAtom, isEnableNoFlyZoneAtom, preMapSettingsAtom } from "./Map";

const Wrapper = styled.div`
  > div {
    margin-bottom: 8px;
  }
`;

export const MapSettingStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34px;
  /* height: 60px; */

  *:not(:last-child) {
    padding-bottom: 9.5px;
  }
  > :last-child {
    padding-bottom: 8px;
  }
  > :first-of-type {
    padding-top: 8px;
  }
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: ${({ theme }) => rgba(theme.color.graySecondary[900], 0.6)};
`;

type PropsType = {
  mapRef: Component.ARVMap.UseMap;
};

const MapSettings: React.FC<PropsType> = (props) => {
  const theme = useTheme();
  const { mapRef } = props;
  const { zoomIn, zoomOut } = useZoom(mapRef.map);
  const preMapSettings = useAtomValue(preMapSettingsAtom);
  const [isEnableNoFlyZone, setEnableNoFlyZone] = useAtom(isEnableNoFlyZoneAtom);
  const [isEnableAltitude, setEnableAltitude] = useAtom(isEnableAltitudeAtom);
  const { accessControl } = useAuth();

  const handleGoToCurrentLocation = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition((position) => {
      const coordinate = [position.coords.longitude, position.coords.latitude];
      mapRef?.map?.maptalk?.current?.animateTo({ center: coordinate }, { duration: 300 });
    });
  };

  return (
    <Wrapper>
      <MapSettingStyled>
        {/* <IconButton
          fullHeight
          fullWidth
          onClick={() => setEnableAltitude((isEnableAltitude) => !isEnableAltitude)}
        >
          <Icon
            size={15}
            icon="ThreeD"
            color={isEnableAltitude ? theme.color.bluePrimary[500] : theme.color.grayPrimary[50]}
          />
        </IconButton> */}
        <IconButton fullHeight fullWidth onClick={handleGoToCurrentLocation}>
          <Icon size={15} icon="CurrentLocation" />
        </IconButton>
        {accessControl["FLYZONE_MANAGER"] && (
          <IconButton
            fullHeight
            fullWidth
            onClick={() => setEnableNoFlyZone((isEnableNoFlyZone) => !isEnableNoFlyZone)}
          >
            <Icon
              size={15}
              icon="NoEntry"
              color={isEnableNoFlyZone ? theme.color.bluePrimary[500] : theme.color.grayPrimary[50]}
            />
          </IconButton>
        )}
      </MapSettingStyled>
      <MapSettingStyled>
        <IconButton fullHeight fullWidth onClick={() => zoomIn()}>
          <Icon size={15} icon="Plus" />
        </IconButton>
        <IconButton fullHeight fullWidth onClick={() => zoomOut()}>
          <Icon size={15} icon="Minus" />
        </IconButton>
      </MapSettingStyled>
      {preMapSettings}
    </Wrapper>
  );
};

export default MapSettings;
