import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { IUploadDetail } from "core/domains/services/upload";
import { FC, useEffect, useState } from "react";
import { TCountFlightPost, TFlightPostDetail } from "types/flightPost";

import FlightDetail from "components/pages/FlightPostPage/FlightPostListStep/FlightDetail";
import { useDisplayPolygon, useToCenter } from "components/pages/Map/useDisplay";
import FPFilterItem from "components/templates/FPFilterItem/FPFilterItem";
import FlightPostItem from "components/templates/FlightPostItem/FlightPostItem";

import FlightPlantListStepStyled from "./FlightPlantListStepStyled.styled";

// const flightPostCount = {
//   all: 9,
//   available: 2,
//   accepted: 1,
//   ready: 3,
// };
const flightPostTab = {
  all: "ALL",
  pending: "AVAILABLE",
  accepted: "ACCEPTED",
  ready: "READY",
};

interface IFlightPlantListStep {
  // onClick: (value: string) => void;
  data: TFlightPostDetail[];
  onAcceptFlightPost: (id: string) => void;
  onDeleteFlightPost: (id: string) => void;
  onStartFlightPost: (id: string) => void;
  onCancelFlightPost: (index: string, reason: string) => void;
  onChangeFilter: (filter: string) => void;
  filter: string;
  count?: TCountFlightPost;
  toStepCreateFlightPlan: (id: string) => void;
  onFinishFlightPost: (id: string, flightLog: IUploadDetail) => void;
}

const FlightPlantListStep: FC<IFlightPlantListStep> = ({
  onAcceptFlightPost,
  data,
  onStartFlightPost,
  onChangeFilter,
  onDeleteFlightPost,
  onCancelFlightPost,
  toStepCreateFlightPlan,
  onFinishFlightPost,
  filter,
  count,
}) => {
  const [areaLine, setAreaLine] = useState<[number, number][]>([]);
  const [selectedFlightPost, setSelectedFlightPost] = useState<TFlightPostDetail | null>(null);
  const [filterFlightPost, setFilterFlightPost] = useState<TFlightPostDetail[]>(data);
  const keyFilter = Object.keys(flightPostTab);

  const handleOnSelectItem = (fp: TFlightPostDetail) => {
    const found = data.find((flightPost) => flightPost.id === fp.id);
    if (found) {
      setSelectedFlightPost(found);
      setAreaLine(fp.farm.geom.coordinates[0]);
    }
  };

  const handleOnCloseDetail = () => {
    setSelectedFlightPost(null);
    setAreaLine([]);
  };

  const handleOnFilterFlightPost = (event: any) => {
    const filtered = data.filter((fp) => fp.name.includes(event.target.value));
    setFilterFlightPost(filtered);
  };

  useEffect(() => {
    setFilterFlightPost(data);
  }, [data]);

  useDisplayPolygon(areaLine);
  useToCenter(areaLine);
  return (
    <FlightPlantListStepStyled>
      <div className="flight-post-box">
        <h2>Flight Post</h2>
        <div className="flight-post-filter-list">
          {keyFilter.map((key) => {
            return (
              <FPFilterItem
                onClick={(value: string) => onChangeFilter(value)}
                key={key}
                label={flightPostTab[key]}
                value={count && count[key]}
                selected={filter === flightPostTab[key]}
              />
            );
          })}
        </div>
        <div>
          <InputField placeholder="Search" onChange={handleOnFilterFlightPost} />
        </div>
        {filterFlightPost.map((fp, index) => (
          <div key={fp.id} onClick={() => handleOnSelectItem(fp)}>
            <FlightPostItem
              {...fp}
              index={index}
              selected={fp.id === selectedFlightPost?.id}
              isPilot
              onAcceptFlightPost={onAcceptFlightPost}
              onStartFlightPost={onStartFlightPost}
              onDeleteFlightPost={onDeleteFlightPost}
              toStepCreateFlightPlan={toStepCreateFlightPlan}
              onCancelFlightPost={onCancelFlightPost}
              onFinishFlightPost={onFinishFlightPost}
            />
          </div>
        ))}
      </div>

      {selectedFlightPost && (
        <FlightDetail data={selectedFlightPost} onClose={() => handleOnCloseDetail()} />
      )}
    </FlightPlantListStepStyled>
  );
};
export default FlightPlantListStep;
