import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { MUTATION_UPDATE_FARM, QUERY_FARM_BY_ID } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import useSuccessModal from "core/hooks/useSuccessModal";
import { farmSchemaForEdit } from "core/schema/farm";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { FarmIconSelected, FarmIconWhite } from "components/templates/Icon";
import Loading from "components/templates/Loading/Loading";

import { IFarmDetail, TFarmItemResponse } from "../../../types/farmer";
import EditFarmPageStyled from "./EditFarmPage.styled";
import EditFarmStep from "./EditFarmStep";

const EditFarmPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Farm");
  const [farmDetail, setFarmDetail] = useState<IFarmDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);

  const formik = useFormik<any>({
    initialValues: { ...farmDetail, coordinates: farmDetail?.geom.coordinates } || {},
    validationSchema: toFormikValidationSchema(farmSchemaForEdit),
    onSubmit: (values) => handleOnUpdateFarm(values),
    enableReinitialize: true,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          input: id,
        };
        const response: TFarmItemResponse = await client.request(QUERY_FARM_BY_ID, variables);
        setFarmDetail(response.farmById);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();
  }, []);

  const modal = useSuccessModal({
    title: "Farm Edited",
    detail: (
      <>
        <div>Your farm has been successfully edited</div>
      </>
    ),
    callBack: () => navigate("/farm-list"),
  });

  const handleClickCancel = () => {
    formik.resetForm();
    navigate("/farm-list");
  };

  const handleOnUpdateFarm = async (values: IFarmDetail) => {
    try {
      setSubmitIsLoading(true);
      const updatedObject: Partial<IFarmDetail> = {
        ...values,
      };
      delete updatedObject.createdAt;
      delete updatedObject.id;
      delete updatedObject.geom;
      delete updatedObject.isActive;
      delete updatedObject.imageListUrls;
      const variables = {
        farmId: id,
        farm: updatedObject,
      };
      await client.request(MUTATION_UPDATE_FARM, variables);
      modal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
      console.error("Error creating item:", error);
    }
  };

  return (
    <EditFarmPageStyled>
      {isLoading && <Loading />}
      <div style={{ display: "flex", alignItems: "center" }} onClick={handleClickCancel}>
        <Icon icon="ChevronLeft" />
        <h3>Edit Farm</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="edit-farm-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Farm",
                    selectedIcon: <FarmIconSelected />,
                    notSelectedIcon: <FarmIconWhite />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <EditFarmStep form={formik} totalStep="1" noStep="1" />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Button className="cancel-button" onClick={handleClickCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                className={isEmpty(formik.errors) ? "register-button-active" : "register-button"}
              >
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                Save Farm
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </EditFarmPageStyled>
  );
};

export const Component = EditFarmPage;
export default EditFarmPage;
