import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { client } from "core/gql/fetchgql";
import { QUERY_NATIONALITIES } from "core/gql/master";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TNationalityItem, TNationalityResponse } from "types/master";

import ASelect from "components/templates/ASelect/ASelect";

interface IProfilePilotForm {
  form: any;
}
const ProfilePilotForm: FC<IProfilePilotForm> = ({ form }) => {
  const { t } = useTranslation(["form", "common", "pilot"]);

  const theme = useTheme();
  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNationality();
  }, []);

  return (
    <div className="">
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} 1/2
      </div>
      <h1>{t(`Pilot`, { ns: "pilot" })}</h1>
      <hr />
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="firstName"
          value={form.values.firstName}
          label={t`First Name`}
          placeholder={t`Enter First Name`}
          onChange={form.handleChange}
          helperSpace
          error={form.touched.firstName && form.errors.firstName}
          required
        />
        <InputField
          type="text"
          name="lastName"
          value={form.values.lastName}
          onChange={form.handleChange}
          label={t`Last Name`}
          placeholder={t`Enter Last Name`}
          helperSpace
          error={form.touched.lastName && form.errors.lastName}
          required
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="nationalIdNo"
            value={form.values.nationalIdNo}
            label={t`National ID Number`}
            placeholder={t`Enter National ID Number`}
            onChange={form.handleChange}
            helperSpace
            error={form.touched.nationalIdNo && form.errors.nationalIdNo}
            required
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <ASelect
            label={t`Nationality`}
            placeholder={t`Select Nationality`}
            required
            options={nationalityList.map((nation) => {
              return {
                value: nation.nationalityCode,
                label: nation.nationality,
              };
            })}
            value={form.values.nationality}
            onChange={(value) => form.setFieldValue("nationality", value)}
            error={form.touched.nationality && form.errors.nationality}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="email"
          label={t`Email`}
          placeholder={t`Enter Email`}
          helperSpace
          required
          value={form.values.email}
          onChange={form.handleChange}
          disabled
          // error={get(form, "touched.email") && get(form, "errors.email")}
        />
        <InputField
          type="text"
          name="phoneNo"
          label={t`Phone Number`}
          placeholder={t`Enter Phone Number`}
          helperSpace
          onChange={form.handleChange}
          required
          value={form.values.phoneNo}
          // error={get(form, "touched.phoneNo") && get(form, "errors.phoneNo")}
        />
      </div>
    </div>
  );
};
export default ProfilePilotForm;
