import { Button } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { rgba } from "polished";
import { PropsWithChildren, memo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "components/pages/AuthPage/store/auth";
import Map from "components/pages/Map/Map";

import Navigation from "../Navigation/Navigation";

const MapLayoutStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grayPrimary[50]};
  z-index: 1;

  .layout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    > .container::before {
      backdrop-filter: blur(10px);
    }
    > .container {
      width: 408px;
      backdrop-filter: blur(10px);
      background: ${({ theme }) => rgba(theme.color.graySecondary[900], 0.6)};
      /* backdrop-filter: blur(10px); */
      /* padding: 0px 24px 24px; */
      /* overflow: auto; */

      .page-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .create-button {
    position: absolute;
    bottom: 32px;
    right: 32px;
  }
`;

type MapLayoutProps = {
  withNavigation?: boolean;
} & PropsWithChildren;

const MapLayout: React.FC<MapLayoutProps> = ({ withNavigation = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { accessControl } = useAuth();

  return (
    <MapLayoutStyled>
      <div className="layout">
        {withNavigation && <Navigation />}
        <div className="container">
          <Outlet />
        </div>
      </div>
      <div>
        <Map />
      </div>
      {location.pathname === "/project" && accessControl["PLAN_ABLE"] && (
        <Button
          className="create-button"
          color="primary"
          onClick={() => navigate("/project-create")}
        >
          Create Project
        </Button>
      )}
      {location.pathname === "/mission-template" && accessControl["PLAN_ABLE"] && (
        <Button
          className="create-button"
          color="primary"
          onClick={() => navigate("/create-mission-template")}
        >
          Create Mission
        </Button>
      )}
    </MapLayoutStyled>
  );
};

export default memo(MapLayout);
