import {
  QUERY_ADMIN_GET_DRONE_INFORMATION_BY_FLIGHT_INFORMATION_ID,
  QUERY_ADMIN_GET_FLIGHT_INFORMATION_AND_LOCATION,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TAdminGetDroneInformationByFlightInformationId,
  TAdminGetFlightInformationAndLocationResponse,
} from "types/admin";
import { IDroneInformation } from "types/droneInformation";
import { IFlightInformationAndLocation } from "types/flightInformation";

import { useDisplayDronePath, useToCenter } from "../Map/useDisplay";
import FlightDetail from "./FlightDetail/FlightDetail";

const polygon = [
  ["101.44554837885799", "12.995372740803914"],
  ["101.445542614239", "12.995372670685848"],
  ["101.4455264367377", "12.995370919219932"],
  ["101.44528766139867", "12.995353216801185"],
  ["101.44526420982204", "12.995350889787902"],
  ["101.44427715516022", "12.99522805419944"],
  ["101.44418390107691", "12.99520668128929"],
  ["101.44415079815323", "12.995191536341043"],
  ["101.44397747484808", "12.99518709196503"],
  ["101.44388224874443", "12.99517490837327"],
  ["101.44379167317086", "12.995143782469889"],
  ["101.44370967136804", "12.995095062460372"],
  ["101.44363979520206", "12.995030858630564"],
  ["101.44358507131594", "12.994953951939307"],
  ["101.44354787003223", "12.994867673561158"],
  ["101.44352980268542", "12.994775760596923"],
  ["101.44347884178563", "12.994192944957513"],
  ["101.4434797518363", "12.994107327988203"],
  ["101.44349730047492", "12.994023431227342"],
  ["101.44353085141665", "12.993944296555458"],
  ["101.44357918817744", "12.993872793189192"],
  ["101.44364055818215", "12.993811513651641"],
  ["101.44371273630969", "12.99376267977513"],
  ["101.44379310557014", "12.993728062144399"],
  ["101.44387875198942", "12.9937089159008"],
  ["101.44396657026088", "12.993705935234956"],
  ["101.44403425442157", "12.993709932266269"],
  ["101.44410196778699", "12.993707962740187"],
  ["101.44444518433504", "12.9935586269707"],
  ["101.44451928027416", "12.993533503741826"],
  ["101.44459667291171", "12.993520973350652"],
  ["101.44614430473719", "12.993400751265977"],
  ["101.44623904383758", "12.993402902517479"],
  ["101.44633133443435", "12.99342386489724"],
  ["101.44641728471676", "12.99346275444097"],
  ["101.44649327023535", "12.993517931211775"],
  ["101.44655608674007", "12.993587068453802"],
  ["101.44660308530035", "12.993667250708281"],
  ["101.446632284009", "12.99375509675462"],
  ["101.44664245155967", "12.99384690219265"],
  ["101.44664781807128", "12.994973330597269"],
  ["101.4466383464094", "12.99506607667245"],
  ["101.44660945505174", "12.995154927714166"],
  ["101.44656238525948", "12.995236066348456"],
  ["101.4464991593129", "12.995306006551763"],
  ["101.44642249362862", "12.995361743424908"],
  ["101.44633568205289", "12.995400882296424"],
  ["101.44624245434476", "12.995421741608181"],
  ["101.44614681592944", "12.995423425162706"],
  ["101.44555404667541", "12.995373768243732"],
  ["101.44554837885799", "12.995372740803914"],
];

const AdminFlightDetailPage = () => {
  const [dronePath, setDronePath] = useState<[number, number][]>([]);
  const [flightInformation, setFlightInformation] = useState<IFlightInformationAndLocation>();
  const [droneInformation, setDroneInformation] = useState<IDroneInformation>();
  const { id } = useParams();

  const fetchFlightInformationDetail = async () => {
    try {
      const variables = {
        flightInformationId: id,
      };

      const response: TAdminGetFlightInformationAndLocationResponse = await client.request(
        QUERY_ADMIN_GET_FLIGHT_INFORMATION_AND_LOCATION,
        variables
      );
      setFlightInformation(response.getFlightInformationAndLocation);
      setDronePath(
        response.getFlightInformationAndLocation.location
          ? response.getFlightInformationAndLocation.location
          : []
      );
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const fetchDroneInformation = async () => {
    const variables = {
      flightInformationId: id,
    };
    const response: TAdminGetDroneInformationByFlightInformationId = await client.request(
      QUERY_ADMIN_GET_DRONE_INFORMATION_BY_FLIGHT_INFORMATION_ID,
      variables
    );
    setDroneInformation(response.adminGetDroneInformationByFlightInformationId);
  };

  useEffect(() => {
    fetchFlightInformationDetail();
    fetchDroneInformation();
  }, []);

  useDisplayDronePath(dronePath);
  useToCenter(dronePath);

  return <FlightDetail flightInformation={flightInformation} droneInformation={droneInformation} />;
};

export default AdminFlightDetailPage;
