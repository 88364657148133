import styled from "@emotion/styled";

const DroneFormStyled = styled.div`
  margin-left: 300px;
  .add-more-payload-btn {
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #ffc256;
    cursor: pointer;
  }
`;

export default DroneFormStyled;
