import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { FC } from "react";

interface ISearchInput extends React.InputHTMLAttributes<HTMLInputElement> {
  onClick?: () => void;
}
const SearchInput: FC<ISearchInput> = ({ onClick, placeholder }) => {
  return (
    <InputField
      placeholder={placeholder}
      suffix={<Icon icon="Search" onClick={onClick} style={{ cursor: "pointer" }} />}
    />
  );
};
export default SearchInput;
