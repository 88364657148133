import { Button, InputField, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { client } from "core/gql/fetchgql";
import { QUERY_NATIONALITIES } from "core/gql/master";
import { MUTATION_PILOT_REGISTER, QUERY_CHECK_PILOT_MAIL_EXISTS } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addPilotDefaultValue, addPilotSchema } from "core/schema/admin";
import { CAATLicenseObjectiveList, NBTCLicenseObjectiveList } from "core/utils/constant";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { FormErrors } from "types/base";
import { TNationalityItem, TNationalityResponse } from "types/master";
import { TCheckPilotEmailResponse } from "types/pilot";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ASelect from "../ASelect/ASelect";

type TAddPilotSchema = z.infer<typeof addPilotSchema>;

const StyledWrapper = styled.div`
  background: #29371c;
  backdrop-filter: blur(10px);

  .header {
    padding: 32px;
    font-weight: bold;
    margin-top: 0;
  }

  .form {
    border-radius: 12px;
    background: #414221;
    height: 100%;
    padding: 40px 16px 20px 16px;
    min-height: calc(100vh - 210px);

    .form-item {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
    }
  }
`;

interface PilotFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

const PilotForm: React.FC<PilotFormProps> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation(["form", "pilot", "common"]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNationality();
  }, []);

  const modal = useSuccessModal({
    title: t(`New Pilot Added`, { ns: "pilot" }),
    detail: (
      <>
        <div>{t(`Your pilot has been successfully`, { ns: "pilot" })}</div>
        <div>{t(`added to the platform`, { ns: "pilot" })}</div>
      </>
    ),
    callBack: () => onSuccess(),
  });

  const validatePilotEmail = async (email: string) => {
    try {
      const variables = {
        email,
      };
      const result: TCheckPilotEmailResponse = await client.request(
        QUERY_CHECK_PILOT_MAIL_EXISTS,
        variables
      );
      return result.checkPilotEmailExists;
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik<TAddPilotSchema>({
    initialValues: addPilotDefaultValue,
    validationSchema: toFormikValidationSchema(addPilotSchema),
    onSubmit: (values) => handleSubmitForm(values),
    validate: async (values) => {
      const errors: FormErrors = {};
      if (values.email) {
        const asyncError = await validatePilotEmail(values.email);
        if (asyncError) {
          errors.email = "email is already exists";
        }
      }
      return errors;
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);
      const pilotInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        email: values.email,
        phoneNo: values.phoneNo,
        password: values.password,
        license: values.license,
      };
      const variables = {
        pilotInput: pilotInput,
      };
      await client.request(MUTATION_PILOT_REGISTER, variables);
      modal.openModal();
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating item:", error);
      toast({
        title: "",
        description:
          "Registration Error: Unable to process your registration at the moment. Please try again",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <StyledWrapper>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <h1 className="header">{t(`New Pilot`, { ns: "pilot" })}</h1>
          <div className="form">
            <h1>{t(`Pilot Details`, { ns: "pilot" })}</h1>
            <hr />
            <h3>{t(`Pilot Information`, { ns: "pilot" })}</h3>

            <div className="form-item">
              <InputField
                type="text"
                name="firstName"
                label={t`First Name`}
                placeholder={t`Enter First Name`}
                onChange={formik.handleChange}
                helperSpace
                required
                value={formik.values.firstName}
                error={get(formik, "touched.firstName") && get(formik, "errors.firstName")}
              />
              <InputField
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                label={t`Last Name`}
                placeholder={t`Enter Last Name`}
                helperSpace
                required
                value={formik.values.lastName}
                error={get(formik, "touched.lastName") && get(formik, "errors.lastName")}
              />
            </div>

            <div className="form-item">
              <div style={{ flex: 1 }}>
                <InputField
                  type="text"
                  name="nationalIdNo"
                  label={t`National ID Number`}
                  placeholder={t`Enter National ID Number`}
                  onChange={formik.handleChange}
                  helperSpace
                  required
                  value={formik.values.nationalIdNo}
                  error={get(formik, "touched.nationalIdNo") && get(formik, "errors.nationalIdNo")}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <ASelect
                  label={t`Nationality`}
                  placeholder={t`Select Nationality`}
                  required
                  options={nationalityList.map((nation) => {
                    return {
                      value: nation.nationalityCode,
                      label: nation.nationality,
                    };
                  })}
                  value={formik.values.nationality}
                  onChange={(value: string) => formik.setFieldValue("nationality", value)}
                  error={
                    (get(formik, "touched.nationality") &&
                      get(formik, "errors.nationality")) as string
                  }
                />
              </div>
            </div>

            <div className="form-item">
              <InputField
                type="text"
                name="email"
                label={t`Email`}
                placeholder={t`Enter Email`}
                helperSpace
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                error={get(formik, "touched.email") && get(formik, "errors.email")}
              />
              <InputField
                type="text"
                name="phoneNo"
                label={t`Phone Number`}
                placeholder={t`Enter Phone Number`}
                helperSpace
                onChange={formik.handleChange}
                required
                value={formik.values.phoneNo}
                error={get(formik, "touched.phoneNo") && get(formik, "errors.phoneNo")}
              />
            </div>

            <div className="form-item">
              <div>{t(`CAAT License`, { ns: "pilot" })}</div>
            </div>

            <div className="form-item">
              <InputField
                type="text"
                name="license.caatLicenseNo"
                label={t`CAAT License Number`}
                placeholder={t`Enter CAAT License Number`}
                helperSpace
                required
                onChange={formik.handleChange}
                value={formik.values.license.caatLicenseNo}
                error={
                  get(formik, "touched.license.caatLicenseNo") &&
                  get(formik, "errors.license.caatLicenseNo")
                }
              />
              <InputField
                type="date"
                name="license.caatExpireDate"
                label={t`CAAT License Expiry Date`}
                placeholder={t`Enter CAAT License Expiry Date`}
                helperSpace
                value={formik.values.license.caatExpireDate}
                required
                error={
                  get(formik, "touched.license.caatExpireDate") &&
                  get(formik, "errors.license.caatExpireDate")
                }
                onChange={formik.handleChange}
              />
            </div>

            <div className="form-item">
              <div style={{ flex: 1 }}>
                <ASelect
                  label={t`CAAT License Objective`}
                  placeholder={t`Enter CAAT License Objective`}
                  required
                  options={CAATLicenseObjectiveList}
                  value={formik.values.license.caatObjective}
                  onChange={(value) => formik.setFieldValue("license.caatObjective", value)}
                  error={
                    (get(formik, "touched.license.caatObjective") &&
                      get(formik, "errors.license.caatObjective")) as string
                  }
                />
              </div>
            </div>

            <div className="form-item">
              <div>{t(`NBTC License`, { ns: "pilot" })}</div>
            </div>

            <div className="form-item">
              <InputField
                type="text"
                name="license.nbtcLicenseNo"
                label={t`NBTC License Number`}
                placeholder={t`Enter License Number`}
                helperSpace
                required
                onChange={formik.handleChange}
                value={formik.values.license.nbtcLicenseNo}
                error={
                  get(formik, "touched.license.nbtcLicenseNo") &&
                  get(formik, "errors.license.nbtcLicenseNo")
                }
              />

              <InputField
                type="date"
                name="license.nbtcExpireDate"
                label={t`NBTC License Expiry Date`}
                placeholder={t`Enter License Expiry Date`}
                helperSpace
                value={formik.values.license.nbtcExpireDate}
                required
                error={
                  get(formik, "touched.license.nbtcExpireDate") &&
                  get(formik, "errors.license.nbtcExpireDate")
                }
                onChange={formik.handleChange}
              />
            </div>

            <div className="form-item">
              <div style={{ flex: 1 }}>
                <ASelect
                  label={t`NBTC License Objective`}
                  placeholder={t`Enter NBTC License Objective`}
                  required
                  options={NBTCLicenseObjectiveList}
                  value={formik.values.license.nbtcObjective}
                  onChange={(value) => formik.setFieldValue("license.nbtcObjective", value)}
                  error={
                    (get(formik, "touched.license.nbtcObjective") &&
                      get(formik, "errors.license.nbtcObjective")) as string
                  }
                />
              </div>
            </div>

            <div className="form-item">
              <div>{t`Password`}</div>
            </div>

            <div className="form-item">
              <InputField
                type="password"
                name="password"
                label={t`Password`}
                placeholder={t`Enter Password`}
                onChange={formik.handleChange}
                helperSpace
                required
                value={formik.values.password}
                error={get(formik, "touched.password") && get(formik, "errors.password")}
              />
              <InputField
                type="password"
                name="confirmPassword"
                label={t`Confirm Password`}
                placeholder={t`Enter Password`}
                helperSpace
                required
                value={formik.values.confirmPassword}
                error={
                  get(formik, "touched.confirmPassword") && get(formik, "errors.confirmPassword")
                }
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="footer">
            <div className="footer-group">
              <Button className="cancel-button" onClick={onClose}>
                {t(`Cancel`, { ns: "common" })}
              </Button>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                <span style={{ marginLeft: "8px" }}>{t(`Add Pilot`, { ns: "pilot" })}</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </StyledWrapper>
  );
};

export default PilotForm;
