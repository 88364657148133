import { IMeta } from "./pagination";

export enum EPostStatus {
  "PENDING" = "PENDING",
  "ACCEPTED" = "ACCEPTED",
  "READY" = "READY",
  "IN_FLIGHT" = "IN_FLIGHT",
  "CANCEL" = "CANCEL",
  "FINISH" = "FINISH",
}

export type TFarmerFlightPostResponse = {
  farmerGetMyFlightPost: {
    data: TFlightPostDetail[];
    meta: IMeta;
  };
};

export type TFlightPostDetail = {
  id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  status: EPostStatus;
  pilotEmail: string;
  farmId: string;
  farmerId: string;
  createdAt: string;
  drone: {
    name: string;
    model: string;
    serialNumber: string;
  };
  pilot: {
    firstName: string;
    lastName: string;
  };
  farmer: {
    firstName: string;
    lastName: string;
  };
  farm: {
    name: string;
    totalArea: string;
    imageListUrls: string[];
    geom: {
      coordinates: [number, number][][];
    };
  };
  averageSpeed: string;
  totalSprayWater: string;
  totalSprayFertilizer: string;
  totalDistance: string;
  totalArea: string;
  flightDuration: string;
};

export type TFlightPostDetailResponse = {
  flightPostById: TFlightPostDetail;
};

export type TAvailableFlightPostResponse = {
  availableFlightPost: {
    data: TFlightPostDetail[];
  };
};

export type TPilotMYFlightPostResponse = {
  pilotGetMyFlightPost: {
    data: TFlightPostDetail[];
  };
};

export type TFlightPostByDroneResponse = {
  flightPostByDroneSerialNumber: {
    data: TFlightPostDetail[];
    meta: IMeta;
  };
};

export interface IAddFlightPlanData {
  droneSerialNumber: string;
  coordinates: [number][];
  waypoints: {
    altitude: number;
    speed: number;
  }[];
}

export type TCountFlightPostResponse = {
  countMyFlightPost: TCountFlightPost;
};

export type TCountFlightPost = {
  email: string;
  role: string;
  all: string;
  pending: string;
  accepted: string;
  ready: string;
  inFlight: string;
  cancel: string;
  finish: string;
};

export type TFlightLogItem = {
  id: string;
  flightPostId: string;
  logId: string;
  lng: string;
  lat: string;
  heading: string;
  horizontalSpeed: string;
  verticalSpeed: string;
  flightMode: string;
  height: string;
  batteryPercent: string;
  time: string;
  takeoffTime: string;
};

export type TFlightLogByFlightPostIdResponse = {
  flightLogByFlightPostId: {
    data: TFlightLogItem[];
    meta: IMeta;
  };
};
