import styled from "@emotion/styled";

import EventCard, { Event } from "./EventCard";
import UpcomingSubtitle from "./UpcomingSubtitle";

const UpcomingMissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
`;

type PropsType = {
  title: string;
  events: Event[];
};

const renderEventCard = (events: Event[]) => {
  return events.map((event, index) => <EventCard key={index} {...event} />);
};

const UpcomingMission: React.FC<PropsType> = (props) => {
  return (
    <UpcomingMissionContainer>
      <UpcomingSubtitle text={props.title} isToday />
      <Scroll>{renderEventCard(props.events)}</Scroll>
    </UpcomingMissionContainer>
  );
};

export default UpcomingMission;
