import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  authRequestInterceptor,
  authResponseInterceptorError,
  authResponseInterceptorSuccess,
} from "./authFetch";

export enum PLANNER {
  GET_TASKS = "api/v1/task/get",
  POST_CREATE_TASK = "api/v1/task/submit",
}

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    // baseURL: 'http://localhost:3000',
    // baseURL: "https://aluto-dev.arv.co.th",
    baseURL: process.env.REACT_APP_PLANNER_URL || "https://aluto-planner-dev.arv.co.th/", // docker.for.mac.
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      // 'Access-Control-Allow-Credentials': true,
      // 'Content-Type': 'multipart/form-data',
    },
  });

  return instance;
};

const planner = axiosInstance();
const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  return config;
};

const responseInterceptorSuccess = (res: AxiosResponse<any, any>) => {
  return res;
};

const responseInterceptorError = (error: any) => {
  return Promise.resolve(error);
};

planner.interceptors.request.use(authRequestInterceptor as any);
planner.interceptors.response.use(authResponseInterceptorSuccess, authResponseInterceptorError);

planner.interceptors.request.use(requestInterceptor as any);
planner.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);

export default planner;
