import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import masterplan, { MASTER_PLAN } from "../../domains/services/masterplanFetch";
import { MissionType } from "./enums";

type Params = {
  missionType?: MissionType;
  projectIds?: string[];
  searchName?: string;
  page?: number;
  order?: "ASC" | "DESC" | string;
  templateIds?: string[];
  statuses?: string[];
};

type Project = {
  ceiling: number;
  createdAt: Date;
  deletedAt: Date;
  documents?: string;
  endTime: Date;
  floor: number;
  id: string;
  instanceId: string;
  area: string[][];
  operationType: string;
  projectName: string;
  rejectedAt: Date;
  remark?: string;
  serviceType: string;
  specialCondition: string;
  startTime: Date;
  status: string;
  teamId: string;
  type: string;
  updateBy?: string;
  updatedAt: Date;
};

type Action = {
  actionmode: string;
  actiontype: string;
  createdAt: Date;
  deletedAt: Date;
  distance?: number;
  id: string;
  time: number;
  updatedAt: Date;
};

type Waypoint = {
  altitude: number;
  createdAt: Date;
  deletedAt: null;
  id: string;
  operation: string;
  ordering: number;
  speed: number;
  updatedAt: Date;
  waypoint: string[];
  action: Action;
};

export type MissionTemplate = {
  createdAt: Date;
  deletedAt: Date;
  deviceType: "DRONE";
  id: string;
  missionType: MissionType;
  name: string;
  project?: Project;
  status: string;
  updatedAt: Date;
  imagePath?: string;
  waypoints: Waypoint[];
};

const useMissionTemplatesQuery = ({
  missionType,
  searchName = "",
  order = "ASC",
  page = 1,
  projectIds,
  statuses,
  templateIds,
}: Params) => {
  return useQuery<AxiosResponse<Query.QueryResult.ResponseWithPage<MissionTemplate[]>, AxiosError>>(
    {
      queryKey: [
        MASTER_PLAN.GET_MISSION_TEMPLATE,
        {
          missiontype: missionType,
          searchName,
          order,
          page,
          projectIds: projectIds?.length === 0 ? [""] : projectIds,
          statuses,
          templateIds,
        },
      ],
      queryFn: async ({ queryKey }) => {
        const result = await masterplan.get(queryKey[0] as string, {
          params: queryKey[1],
        });
        if (result instanceof AxiosError) {
          throw Error(result.message);
        }
        return result;
      },
    }
  );
};

export default useMissionTemplatesQuery;
