import styled from "@emotion/styled";

const FarmListPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  .flight-post-page-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .border-top {
    border-top: 1px solid #6b7280;
  }
`;

export default FarmListPageStyled;
