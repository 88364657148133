import { gql } from "graphql-request";

export const MUTATION_ADMIN_ADD_DRONE = gql`
  mutation adminAddDrone($droneInput: RegisterDroneInput!, $userId: String!) {
    adminAddDrone(droneInput: $droneInput, userId: $userId)
  }
`;
export const QUERY_GET_DRONE_LIST = gql`
  query getDroneList($options: PaginationInput!, $filterByServiceProvider: Boolean!) {
    getDroneList(options: $options, filterByServiceProvider: $filterByServiceProvider) {
      data {
        id
        name
        droneType
        brand
        serialNumber
        model
        weight
        isLock
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const MUTATION_STATUS_DRONE_LEASING = gql`
  mutation updateStatusLeasingDrone($droneId: String!, $droneLeasingStatusInput: Boolean!) {
    updateStatusLeasingDrone(droneId: $droneId, droneLeasingStatusInput: $droneLeasingStatusInput)
  }
`;

export const QUERY_GET_DRONE_LEASING_LIST = gql`
  query getDroneLeasing($options: PaginationInput!) {
    getDroneLeasing(options: $options) {
      data {
        id
        serialNumber
        name
        droneType
        brand
        model
        weight
        leasing {
          status
          expiryDate
        }
        droneServiceProvider {
          serviceProvider {
            name
          }
        }
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const MUTATION_UPDATE_DRONE_LEASING = gql`
  mutation updateDroneLeasing($droneLeasingInput: UpdateDroneLeasingInput!) {
    updateDroneLeasing(droneLeasingInput: $droneLeasingInput)
  }
`;
