import { useTranslation } from "react-i18next";

export const useAreaConvert = () => {
  const { t } = useTranslation("preference");

  const areaUnits = [
    { label: t`Hectare (ha)`, value: "hectare" },
    { label: t`Acre (ac)`, value: "acre" },
    { label: t`Rai`, value: "rai" },
    { label: t`Square Metre (m²)`, value: "square_metre" },
    { label: t`Square Kilometre (km²)`, value: "square_kilometre" },
  ];

  const convertAreaUnits = (value: string) => {
    const unit = areaUnits.find((unit) => unit.value === value);
    return unit ? unit.label : value;
  };

  return { areaUnits, convertAreaUnits };
};
