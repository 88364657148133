import { useAuth } from "../AuthPage/store/auth";
import EditFarmerProfilePage from "../EditFarmerProfilePage/EditFarmerProfilePage";
import EditPilotProfilePage from "../EditPilotProfilePage/EditPilotProfilePage";
import EditAdminServiceProviderProfile from "./EditAdminServiceProviderProfile";

const EditProfilePage = () => {
  const { user } = useAuth();
  const isAdminServiceProvider = user?.roles?.includes("ADMIN_SERVICE_PROVIDER");
  const isPilot = user?.roles?.includes("PILOT");
  const isFarmer = user?.roles?.includes("FARMER");

  if (isAdminServiceProvider) {
    return <EditAdminServiceProviderProfile />;
  }

  if (isPilot) {
    return <EditPilotProfilePage />;
  }

  if (isFarmer) {
    return <EditFarmerProfilePage />;
  }

  return null;
};

export default EditProfilePage;
