import UploadDrone from "assets/images/UploadDrone.png";

const UploadDroneImage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${UploadDrone})`,
        backgroundSize: "cover",
        width: "180px",
        height: "180px",
        cursor: "pointer",
      }}
    />
  );
};
export default UploadDroneImage;
