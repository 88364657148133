import { CumulusProvider } from "@ai-and-robotics-ventures/cumulus-ui";
import { compose } from "core/hoc";
import { LightTheme } from "core/styles/theme";
import React, { PropsWithChildren } from "react";

import QueryProvider from "./react-query";
import URQLProvider from "./urql";

const MainProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const Provider = compose(QueryProvider, URQLProvider);
  return (
    <CumulusProvider theme={LightTheme}>
      <Provider>{children}</Provider>
    </CumulusProvider>
  );
};

export default MainProvider;
