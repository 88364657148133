import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

type ButtonStepProps = {
  selected: boolean;
  label: string;
  selectedIcon: React.ReactNode;
  notSelectedIcon: React.ReactNode;
  onClick: (value: string) => void;
  isError?: boolean;
};

const StyledButton = styled.div<{ selected: boolean }>`
  height: 48px;
  width: 160px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 16px;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          background-color: rgba(255, 237, 205, 0.9);
          color: #ffb32c;

          &::before {
            content: "";
            position: absolute;
            top: -50px;
            right: 24px;
            width: 1px;
            height: 50px;
            background-color: #f6d0af;
          }
        `
      : css`
          background-color: transparent;
          color: white;
          &::before {
            content: "";
            position: absolute;
            top: -50px;
            right: 24px;
            width: 1px;
            height: 50px;
            background-color: #6b7280;
          }
        `}
`;

const StyledIcon = styled.div<{ selected: boolean; isError: boolean }>`
  border-radius: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  ${({ selected }) =>
    selected
      ? css`
          background-color: #ffedcd;
          color: #ffb32c;
        `
      : css`
          background-color: black;
          color: white;
        `}

  ${({ isError }) =>
    isError &&
    css`
      border: 2px solid #db5959;
    `}

  .flex-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
  }
`;

const ButtonStep: React.FC<ButtonStepProps> = ({
  selected,
  isError = false,
  label,
  selectedIcon,
  notSelectedIcon,
  onClick,
}) => {
  return (
    <StyledButton selected={selected} onClick={() => onClick(label)}>
      <div style={{ fontSize: "16px", fontWeight: 500, lineHeight: "150%" }}>{label}</div>
      <StyledIcon selected={selected} isError={isError}>
        <div className="flex-icon">{selected ? selectedIcon : notSelectedIcon}</div>
      </StyledIcon>
    </StyledButton>
  );
};

export default ButtonStep;
