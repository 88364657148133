import { useMutation } from "@tanstack/react-query";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  authRequestInterceptor,
  authResponseInterceptorError,
  authResponseInterceptorSuccess,
} from "./authFetch";

export const UPLOAD = "api/v1/upload";
export const UPLOAD_FLIGHT_LOG = "upload-flight-log";
export interface IUploadDetail {
  ext: string;
  file_name: string;
  uid: string;
  unique_id: string;
}
const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    // baseURL: 'http://localhost:3000',
    // baseURL: "https://aluto-dev.arv.co.th",
    baseURL: process.env.REACT_APP_AUTH_URL || "http://localhost:3005/",
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      // 'Access-Control-Allow-Credentials': true,
      // 'Content-Type': 'multipart/form-data',
    },
  });

  return instance;
};
const uploadInstance = axiosInstance();
const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  return config;
};

const responseInterceptorSuccess = (res: AxiosResponse<any, any>) => {
  return res;
};

const responseInterceptorError = (error: any) => {
  return Promise.resolve(error);
};

uploadInstance.interceptors.request.use(authRequestInterceptor as any);
uploadInstance.interceptors.response.use(
  authResponseInterceptorSuccess,
  authResponseInterceptorError
);

uploadInstance.interceptors.request.use(requestInterceptor as any);
uploadInstance.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);

export default uploadInstance;

export enum UploadType {
  DRONE = "DRONE",
  INSURANCE = "INSURANCE",
  CONFIG_FILE = "CONFIG_FILE",
}
export const handleUploadConfigFile = async (file: File, uploadType?: UploadType) => {
  const formData = new FormData();
  let uploadFile: File;
  if (!file.type) {
    const fileName = file.name.replace(/\.[^/.]+$/, "");
    uploadFile = new File([file], fileName, { type: "text/x-config" });
  } else {
    uploadFile = file;
  }
  formData.append("file", uploadFile);
  if (uploadType !== undefined) {
    formData.append("uploadType", uploadType);
  }
  const result = await uploadInstance.post<IUploadDetail>(UPLOAD, formData);
  return result.data;
};

export const handleUploadFile = async (file: File, uploadType?: UploadType) => {
  const formData = new FormData();
  formData.append("file", file);
  if (uploadType !== undefined) {
    formData.append("uploadType", uploadType);
  }
  const result = await uploadInstance.post<IUploadDetail>(UPLOAD, formData);

  return result.data;
};

export const handleUploadFlightLog = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = await uploadInstance.post<IUploadDetail>(UPLOAD_FLIGHT_LOG, formData);

  return result.data;
};
