import { FC } from "react";

import ConfigurationFileStep from "components/pages/AdminAddDronePage/DroneForm/ConfigurationFileStep/ConfigurationFileStep";

import InsuranceStep from "../../AdminAddDronePage/DroneForm/InsuranceStep/InsuranceStep";
import BatteryStep from "./BatteryStep/BatteryStep";
import DroneFormStyled from "./DroneForm.styled";
import DroneStep from "./DroneStep/DroneStep";
import PayloadStep from "./PayloadStep/PayloadStep";

interface IDroneForm {
  step: string;
  form: any;
  totalStep: number;
  isEdit?: boolean;
  isReadOnly?: boolean;
  handleLeaseChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLeased?: boolean;
  leaseable?: boolean;
  showDroneOwner?: boolean;
}

const DroneForm: FC<IDroneForm> = ({
  step,
  form,
  totalStep,
  isReadOnly = false,
  isEdit = false,
  handleLeaseChange,
  isLeased,
  leaseable,
  showDroneOwner = false,
}) => {
  const renderStep = (step: string) => {
    switch (step) {
      case "Drone":
        return (
          <DroneStep
            isEdit={isEdit}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 4}
            handleLeaseChange={handleLeaseChange}
            isLeased={isLeased}
            leaseable={leaseable}
            showDroneOwner={showDroneOwner}
          />
        );
      case "Payload":
        return (
          <PayloadStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 2}
          />
        );
      case "Battery":
        return (
          <BatteryStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep - 1}
            noStep={totalStep}
          />
        );
      case "Insurance":
        return (
          <InsuranceStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep - 3}
            showDronePilot={false}
          />
        );
      case "ConfigFile":
        return (
          <ConfigurationFileStep
            isReadOnly={isReadOnly}
            form={form}
            totalStep={totalStep}
            noStep={totalStep}
            isEdit={isEdit}
          />
        );
    }
  };
  return <DroneFormStyled>{renderStep(step)}</DroneFormStyled>;
};

export default DroneForm;
