import { gql } from "graphql-request";

export const MUTATION_PILOT_REGISTER = gql`
  mutation registerPilot($pilotInput: RegisterPilotInput!) {
    registerPilot(pilotRegisterInput: $pilotInput)
  }
`;

export const QUERY_MY_DRONE = gql`
  query myDrone($options: PaginationInput!) {
    myDrone(options: $options) {
      data {
        id
        name
        droneType
        brand
        serialNumber
        model
        weight
        isLock
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const MUTATION_ADD_DRONE = gql`
  mutation adminAddDrone($droneInput: RegisterDroneInput!, $userId: String!) {
    adminAddDrone(droneInput: $droneInput, userId: $userId)
  }
`;

export const MUTATION_UPDATE_DRONE = gql`
  mutation updateDrone($droneInput: UpdateDroneInput!, $droneId: String!) {
    updateDrone(droneInput: $droneInput, droneId: $droneId)
  }
`;

export const QUERY_DRONE_BY_SERIAL_NUMBER = gql`
  query droneBySerialNumber($serialNumber: String!) {
    droneBySerialNumber(serialNumber: $serialNumber) {
      id
      name
      description
      droneType
      brand
      serialNumber
      model
      weight
      isActive
      extentions
      userId
      battery {
        brand
        model
        serialNumber
        capacity
      }
      insurance {
        id
        number
        coverage
        fileList {
          ext
          uid
          file_name
          unique_id
        }
        expireDate
        fileUrls
        users
      }
      payload {
        name
        type
        brand
        model
        specification
      }
      images {
        top {
          ext
          uid
          file_name
          unique_id
        }
        left {
          ext
          uid
          file_name
          unique_id
        }
        bottom {
          ext
          uid
          file_name
          unique_id
        }
        right {
          ext
          uid
          file_name
          unique_id
        }
      }
      leasing {
        expiryDate
        status
      }
      imagesUrls {
        top
        left
        right
        bottom
      }
      config_file {
        fileList {
          ext
          uid
          file_name
          unique_id
        }
        changeLog
      }
      configFileUrls
    }
  }
`;

export const QUERY_PILOT_GET_PROFILE = gql`
  query pilotGetProfile {
    pilotGetProfile {
      id
      firstName
      lastName
      nationalIdNo
      nationality
      email
      phoneNo
      license {
        caatLicenseNo
        caatExpireDate
        caatObjective
        nbtcLicenseNo
        nbtcObjective
        nbtcExpireDate
      }
    }
  }
`;

export const MUTATION_UPDATE_PILOT_PROFILE = gql`
  mutation updatePilotProfile($pilotProfile: UpdatePilotInput!, $pilotId: String!) {
    updatePilotProfile(pilotId: $pilotId, pilotProfile: $pilotProfile)
  }
`;

export const QUERY_AVAILABLE_FLIGHT_POST = gql`
  query availableFlightPost($options: PaginationInput!) {
    availableFlightPost(options: $options) {
      data {
        id
        name
        description
        startTime
        endTime
        status
        farmId
        farmerId
        createdAt
        pilot {
          firstName
          lastName
        }
        drone {
          name
        }
        farm {
          name
          totalArea
          imageListUrls
          geom {
            coordinates
          }
        }
      }
    }
  }
`;

export const QUERY_MY_PILOT_FLIGHT_POST = gql`
  query pilotGetMyFlightPost($options: PaginationInput!, $flightStatus: [EPostStatus]) {
    pilotGetMyFlightPost(options: $options, flightStatus: $flightStatus) {
      data {
        id
        name
        description
        startTime
        endTime
        status
        farmId
        farmerId
        createdAt
        pilot {
          firstName
          lastName
        }
        drone {
          name
        }
        farm {
          name
          totalArea
          imageListUrls
          geom {
            coordinates
          }
        }
      }
    }
  }
`;

export const MUTATION_ACCEPT_FLIGHT_POST = gql`
  mutation acceptFlightPost($flightPostId: String!) {
    acceptFlightPost(flightPostId: $flightPostId)
  }
`;

export const MUTATION_READY_FLIGHT_POST = gql`
  mutation readyFlightPost(
    $flightPostId: String!
    $waypoints: [WayPointInput!]!
    $droneSerial: String!
  ) {
    readyFlightPost(flightPostId: $flightPostId, waypoints: $waypoints, droneSerial: $droneSerial)
  }
`;

export const MUTATION_DECLINE_FLIGHT_POST = gql`
  mutation declineFlightPost($flightPostId: String!) {
    declineFlightPost(flightPostId: $flightPostId)
  }
`;

export const MUTATION_START_FLIGHT_POST = gql`
  mutation startFlightPost($flightPostId: String!) {
    startFlightPost(flightPostId: $flightPostId)
  }
`;
export const MUTATION_CANCEL_FLIGHT_POST = gql`
  mutation cancelFlightPost($flightPostId: String!, $cancelReason: String!) {
    cancelFlightPost(flightPostId: $flightPostId, cancelReason: $cancelReason)
  }
`;
export const QUERY_CHECK_PILOT_MAIL_EXISTS = gql`
  query checkPilotEmailExists($email: String!) {
    checkPilotEmailExists(email: $email)
  }
`;

export const QUERY_CHECK_DRONE_SERIAL_EXISTS = gql`
  query checkDroneSerialExists($serialNumber: String!) {
    checkDroneSerialExists(serialNumber: $serialNumber)
  }
`;

export const MUTATION_FINISH_FLIGHT_POST = gql`
  mutation finishFlightPost($flightPostId: String!, $flightLog: ImageInput!) {
    finishFlightPost(flightPostId: $flightPostId, flightLog: $flightLog)
  }
`;

export const QUERY_FLIGHT_POST_BY_DRONE_SERIAL_NUMBER = gql`
  query flightPostByDroneSerialNumber($options: PaginationInput!, $droneSerial: String!) {
    flightPostByDroneSerialNumber(options: $options, droneSerial: $droneSerial) {
      data {
        id
        name
        description
        startTime
        endTime
        status
        farmId
        farmerId
        createdAt
        averageSpeed
        totalSprayWater
        totalSprayFertilizer
        totalDistance
        totalArea
        flightDuration
        pilot {
          firstName
          lastName
        }
        drone {
          name
        }
        farm {
          name
          totalArea
          imageListUrls
          geom {
            coordinates
          }
        }
        farmer {
          firstName
          lastName
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
