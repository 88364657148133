import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as BatteryNotSelectedIcon } from "assets/svgs/battery_icon_not_selected.svg";
import { ReactComponent as BatterySelectedIcon } from "assets/svgs/battery_icon_selected.svg";
import { ReactComponent as InsuranceNotSelectedIcon } from "assets/svgs/insurance_icon_not_selected.svg";
import { ReactComponent as InsuranceSelectedIcon } from "assets/svgs/insurance_icon_selected.svg";
import { ReactComponent as PayloadNotSelectedIcon } from "assets/svgs/payload_icon_not _selected.svg";
import { ReactComponent as PayloadSelectedIcon } from "assets/svgs/payload_icon_selected.svg";
import { QUERY_ADMIN_PILOT_ALL_LIST } from "core/gql/admin";
import { MUTATION_ADMIN_ADD_DRONE } from "core/gql/drone";
import { client } from "core/gql/fetchgql";
import { QUERY_CHECK_DRONE_SERIAL_EXISTS } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addDroneDefaultValue, addDroneSchema } from "core/schema/drone";
import { decrypt } from "core/utils/crypto";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { TAdminPilotProfileResponse } from "types/admin";
import { FormErrors } from "types/base";
import { DroneInput, Insurance } from "types/drone";
import { Pilot, TCheckDroneSerialResponse } from "types/pilot";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { SettingIconNotSelected, SettingIconSelected } from "components/templates/Icon";

import AdminAddDronePageStyled from "./AdminAddDronePage.styled";
import DroneForm from "./DroneForm/DroneForm";

const AdminAddDronePage = () => {
  const { t } = useTranslation(["drone", "common"]);

  const [step, setStep] = useState<string>("Drone");
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [pilotList, setPilotList] = useState<Pilot[]>([]);
  const [isLeased, setIsLeased] = useState<boolean>(true);

  const validatePilotDrone = async (serialNumber: string) => {
    try {
      const variables = {
        serialNumber,
      };
      const result: TCheckDroneSerialResponse = await client.request(
        QUERY_CHECK_DRONE_SERIAL_EXISTS,
        variables
      );
      return result.checkDroneSerialExists;
    } catch (e) {
      console.log(e);
    }
  };
  const modal = useSuccessModal({
    title: t`New Drone Added`,
    detail: (
      <>
        <div>{t`Your drone has been successfully`}</div>
        <div>{t`added to the platform`}</div>
      </>
    ),
    callBack: () => (window.location.href = "/drone-list"),
  });
  const formik = useFormik<any>({
    initialValues: addDroneDefaultValue,
    validationSchema: toFormikValidationSchema(addDroneSchema),
    onSubmit: (values) => handleAddDrone(values),
    validate: async (values) => {
      const errors: FormErrors = {};

      if (values.drone.serialNumber) {
        setSubmitIsLoading(true);
        const droneError = await validatePilotDrone(values.drone.serialNumber);
        setSubmitIsLoading(false);

        if (droneError) {
          errors.drone = {};
          errors.drone.serialNumber = "drone is already added";
        }
      }
      return errors;
    },
  });
  const handleAddDrone = async (values: any) => {
    try {
      setSubmitIsLoading(true);

      const droneInput: DroneInput = {
        name: values.drone.name,
        description: values.drone.description,
        droneType: values.drone.droneType,
        brand: values.drone.brand,
        serialNumber: values.drone.serialNumber,
        objective: values.drone.objective,
        model: values.drone.model,
        weight: values.drone.weight,
        extentions: values.drone.extentions,
        images: values.drone.images,
        battery: values.drone.battery,
        insurance: values.drone.insurance?.map((insurance: Insurance) => ({
          number: insurance.number,
          coverage: insurance.coverage,
          fileList: insurance.fileList,
          expireDate: insurance.expireDate,
          users: insurance.users,
        })),
        payload: values.drone.payload,
      };
      // Check if changeLog is an empty string and set it to null
      if (values.drone.config_file) {
        if (values.drone.config_file.changeLogString === "") {
          values.drone.config_file.changeLogString = null;
        }
        droneInput.config_file = {
          fileList: values.drone.config_file.fileList,
          changeLog: values.drone.config_file.changeLogString,
        };
      }
      if (values.drone.leased) {
        droneInput.leased = {
          expiryDate: values.drone.leased.expiryDate,
          status: true,
        };
      }

      const variables = {
        droneInput: droneInput,
        userId: values.drone.userId,
      };

      await client.request(MUTATION_ADMIN_ADD_DRONE, variables);
      modal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);

      console.error("Error creating item:", error);
    }
  };

  const fetchPilotList = async (search?: string) => {
    try {
      const variables = {
        optionsAdmin: {
          currentPage: 1,
          itemsPerPage: 100,
          search,
          searchBy: ["first_name", "lastName", "national_id_no"],
          alias: "user",
        },
      };
      const response: TAdminPilotProfileResponse = await client.request(
        QUERY_ADMIN_PILOT_ALL_LIST,
        variables
      );

      const pilotOptions = response.adminGetPilots.data?.map((item) => {
        return {
          id: item.userId,
          firstName: item.firstName,
          lastName: item.lastName,
          nationalIdNo: decrypt(item.nationalIdNo),
        };
      });

      setPilotList(pilotOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPilotList();
  }, []);

  const handleLeaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsLeased(isChecked);
    if (!isChecked) {
      formik.setFieldValue("drone.leased", undefined);
    }
  };

  const handleCreatePilotSuccess = () => {
    fetchPilotList();
  };

  const handleSearchPilot = (value: string) => {
    fetchPilotList(value);
  };

  return (
    <AdminAddDronePageStyled>
      <Link to="/drone-list">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon="ChevronLeft" />
          <h3>{t`Drone`}</h3>
        </div>
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-drone-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: t`Drone`,
                    key: "Drone",
                    selectedIcon: <Icon icon="Drone" />,
                    notSelectedIcon: <Icon icon="Drone" />,
                  },
                  {
                    label: t`Insurance`,
                    key: "Insurance",
                    selectedIcon: <InsuranceSelectedIcon />,
                    notSelectedIcon: <InsuranceNotSelectedIcon />,
                  },
                  {
                    label: t`Payload`,
                    key: "Payload",
                    selectedIcon: <PayloadSelectedIcon />,
                    notSelectedIcon: <PayloadNotSelectedIcon />,
                  },
                  {
                    label: t`Battery`,
                    key: "Battery",
                    selectedIcon: <BatterySelectedIcon />,
                    notSelectedIcon: <BatteryNotSelectedIcon />,
                  },
                  {
                    label: t`Config File`,
                    key: "ConfigFile",
                    selectedIcon: <SettingIconSelected />,
                    notSelectedIcon: <SettingIconNotSelected />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <DroneForm
              pilotList={pilotList}
              totalStep={5}
              step={step}
              form={formik}
              isLeased={isLeased}
              handleLeaseChange={handleLeaseChange}
              onCreatePilotSuccess={handleCreatePilotSuccess}
              onSearchPilot={handleSearchPilot}
            />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Button
                type="button"
                className="cancel-button"
                onClick={() => {
                  window.location.href = "/drone-list";
                }}
              >
                {t(`Cancel`, { ns: "common" })}
              </Button>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t`Add Drone`}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AdminAddDronePageStyled>
  );
};

export const Component = AdminAddDronePage;
export default AdminAddDronePage;
