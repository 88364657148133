import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IChangePasswordForm {
  form: any;
}

const ChangePasswordForm: FC<IChangePasswordForm> = ({ form }) => {
  const theme = useTheme();

  const { t } = useTranslation(["form", "common"]);

  return (
    <div>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        {t(`Part`, { ns: "common" })} 1/1
      </div>

      <h1>{t(`Password`, { ns: "common" })}</h1>
      <hr />

      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="password"
            name="currentPassword"
            value={form.values.currentPassword}
            label={t`Current Password`}
            placeholder={t`Enter Current Password`}
            helperSpace
            error={form.touched.currentPassword && form.errors.currentPassword}
            required
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>

      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="password"
            name="newPassword"
            value={form.values.newPassword}
            label={t`New Password`}
            placeholder={t`Enter New Password`}
            helperSpace
            error={form.touched.newPassword && form.errors.newPassword}
            required
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>

      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="password"
            name="confirmPassword"
            value={form.values.confirmPassword}
            label={t`Confirm Password`}
            placeholder={t`Enter confirm Password`}
            helperSpace
            error={form.touched.confirmPassword && form.errors.confirmPassword}
            required
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};
export default ChangePasswordForm;
