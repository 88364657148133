import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as BatteryNotSelectedIcon } from "assets/svgs/battery_icon_not_selected.svg";
import { ReactComponent as BatterySelectedIcon } from "assets/svgs/battery_icon_selected.svg";
import { ReactComponent as InsuranceNotSelectedIcon } from "assets/svgs/insurance_icon_not_selected.svg";
import { ReactComponent as InsuranceSelectedIcon } from "assets/svgs/insurance_icon_selected.svg";
import { ReactComponent as PayloadNotSelectedIcon } from "assets/svgs/payload_icon_not _selected.svg";
import { ReactComponent as PayloadSelectedIcon } from "assets/svgs/payload_icon_selected.svg";
import { client } from "core/gql/fetchgql";
import { MUTATION_ADD_DRONE, QUERY_CHECK_DRONE_SERIAL_EXISTS } from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { addDroneDefaultValue, addDroneSchema } from "core/schema/drone";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FormErrors } from "types/base";
import { DroneInput, Insurance } from "types/drone";
import { TCheckDroneSerialResponse } from "types/pilot";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import { SettingIconNotSelected, SettingIconSelected } from "components/templates/Icon";

import AdminAddDronePage from "../AdminAddDronePage/AdminAddDronePage";
import { useAuth } from "../AuthPage/store/auth";
import AddDronePageStyled from "./AddDronePage.styled";
import DroneForm from "./DroneForm/DroneForm";

const AddDronePage = () => {
  const { t } = useTranslation(["drone", "common"]);

  const { user } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState<string>("Drone");
  const [isSubmitLoading, setSubmitIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      formik.setFieldValue("drone.userId", user?.userId);
      formik.setFieldValue("drone.userFullname", `${user?.firstName} ${user?.lastName}`);

      formik?.values?.drone?.insurance?.map((insurance: any, index: number) => {
        formik.setFieldValue(`drone.insurance[${index}].users`, [user?.userId]);

        return insurance;
      });
    }
  }, [user]);

  const validatePilotDrone = async (serialNumber: string) => {
    try {
      const variables = {
        serialNumber,
      };
      const result: TCheckDroneSerialResponse = await client.request(
        QUERY_CHECK_DRONE_SERIAL_EXISTS,
        variables
      );
      return result.checkDroneSerialExists;
    } catch (e) {
      console.log(e);
    }
  };
  const modal = useSuccessModal({
    title: t`New Drone Added`,
    detail: (
      <>
        <div>{t`Your drone has been successfully`}</div>
        <div>{t`added to the platform`}</div>
      </>
    ),
    callBack: () => navigate("/drone-list"),
  });

  const formik = useFormik<any>({
    initialValues: addDroneDefaultValue,
    validationSchema: toFormikValidationSchema(addDroneSchema),
    onSubmit: (values) => handleAddDrone(values),
    validate: async (values) => {
      const errors: FormErrors = {};

      if (values.drone.serialNumber) {
        setSubmitIsLoading(true);
        const droneError = await validatePilotDrone(values.drone.serialNumber);
        setSubmitIsLoading(false);

        if (droneError) {
          errors.drone = {};
          errors.drone.serialNumber = "drone is already added";
        }
      }
      return errors;
    },
  });

  const handleAddDrone = async (values: any) => {
    try {
      setSubmitIsLoading(true);

      const droneInput: DroneInput = {
        name: values.drone.name,
        description: values.drone.description,
        droneType: values.drone.droneType,
        brand: values.drone.brand,
        serialNumber: values.drone.serialNumber,
        objective: values.drone.objective,
        model: values.drone.model,
        weight: values.drone.weight,
        extentions: values.drone.extentions,
        images: values.drone.images,
        battery: values.drone.battery,
        insurance: values.drone.insurance?.map((insurance: Insurance) => ({
          number: insurance.number,
          coverage: insurance.coverage,
          fileList: insurance.fileList,
          expireDate: insurance.expireDate,
          users: insurance.users,
        })),
        payload: values.drone.payload,
      };
      // Check if changeLog is an empty string and set it to null
      if (values.drone.config_file) {
        if (values.drone.config_file.changeLogString === "") {
          values.drone.config_file.changeLogString = null;
        }
        droneInput.config_file = {
          fileList: values.drone.config_file.fileList,
          changeLog: values.drone.config_file.changeLogString,
        };
      }
      const variables = {
        droneInput: droneInput,
        userId: values.drone.userId,
      };

      await client.request(MUTATION_ADD_DRONE, variables);
      modal.openModal();
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);

      console.error("Error creating item:", error);
    }
  };

  return (
    <AddDronePageStyled>
      <Link to="/drone-list">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon icon="ChevronLeft" />
          <h3>{t`Drone`}</h3>
        </div>
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <div className="add-drone-container">
          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: t`Drone`,
                    key: "Drone",
                    selectedIcon: <Icon icon="Drone" />,
                    notSelectedIcon: <Icon icon="Drone" />,
                  },
                  {
                    label: t`Insurance`,
                    key: "Insurance",
                    selectedIcon: <InsuranceSelectedIcon />,
                    notSelectedIcon: <InsuranceNotSelectedIcon />,
                  },
                  {
                    label: t`Payload`,
                    key: "Payload",
                    selectedIcon: <PayloadSelectedIcon />,
                    notSelectedIcon: <PayloadNotSelectedIcon />,
                  },
                  {
                    label: t`Battery`,
                    key: "Battery",
                    selectedIcon: <BatterySelectedIcon />,
                    notSelectedIcon: <BatteryNotSelectedIcon />,
                  },
                  {
                    label: t`Config File`,
                    key: "ConfigFile",
                    selectedIcon: <SettingIconSelected />,
                    notSelectedIcon: <SettingIconNotSelected />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            <DroneForm totalStep={5} step={step} form={formik} showDroneOwner />
          </div>
          <div className="footer">
            <div className="footer-group">
              <Button className="cancel-button" onClick={() => navigate("/drone-list")}>
                {t(`Cancel`, { ns: "common" })}
              </Button>
              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isSubmitLoading && <ClipLoader size={16} color="#36d7b7" />}
                {t`Add Drone`}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </AddDronePageStyled>
  );
};

export const Component = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes("ADMIN");
  const isAdminServiceProvider = user?.roles.includes("ADMIN_SERVICE_PROVIDER");

  if (isAdmin || isAdminServiceProvider) {
    return <AdminAddDronePage />;
  } else {
    return <AddDronePage />;
  }
};
export default Component;
