import { css } from "@emotion/react";
import { Link } from "react-router-dom";

type NavIconProps = {
  selected: boolean;
  label: string;
  selectedIcon: React.ReactNode;
  notSelectedIcon: React.ReactNode;
  link: string;
};

const labelStyles = ({ selected }: { selected: boolean }) => css`
  ${selected === true ? "color: #FFB32C;" : "color: #9CA3AF;"}
`;

const NavIcon: React.FC<NavIconProps> = ({
  selected,
  label,
  selectedIcon,
  notSelectedIcon,
  link,
}) => {
  return (
    <Link to={link}>
      <div
        style={{
          height: "60px",
          width: "48px",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            borderRadius: "48px",
            width: "48px",
            display: "flex",
            justifyContent: "center",
            height: "48px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {selected ? selectedIcon : notSelectedIcon}
          </div>
        </div>
        <div
          style={{ fontSize: "10px", fontWeight: 400, lineHeight: "150%" }}
          css={labelStyles({ selected })}
        >
          {label}
        </div>
      </div>
    </Link>
  );
};

export default NavIcon;
