import styled from "@emotion/styled";
import i18n from "core/configs/i18n";
import { PropsWithChildren, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "components/pages/AuthPage/store/auth";

import Navigation from "../Navigation/Navigation";

const NavigationLayoutStyled = styled.div`
  color: ${({ theme }) => theme.color.grayPrimary[50]};
  .layout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;

    .container-layout {
      width: 100%;
      background: #29371c;
      backdrop-filter: blur(25px);
      overflow: auto;
      padding: 32px 24px 0;
    }
  }
`;

type NavigationLayoutProps = PropsWithChildren;

const NavigationLayout: React.FC<NavigationLayoutProps> = () => {
  const { user } = useAuth();

  useEffect(() => {
    i18n.changeLanguage(user?.preference?.language || "en");
  }, [user]);

  return (
    <NavigationLayoutStyled>
      <div className="layout">
        <Navigation />
        <div className="container-layout">
          <Outlet />
        </div>
      </div>
    </NavigationLayoutStyled>
  );
};

export default NavigationLayout;
