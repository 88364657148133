import { Tag } from "@ai-and-robotics-ventures/cumulus-ui";
import { Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "polished";

const statusColor = (theme: Theme) => ({
  "On mission": css`
    color: ${theme.color.bluePrimary[400]};
    border-color: ${theme.color.bluePrimary[400]};
    background-color: ${rgba(theme.color.bluePrimary[400], 0.2)};
  `,
  Available: css`
    color: ${theme.color.blueSecondary[400]};
    border-color: ${theme.color.blueSecondary[400]};
    background-color: ${rgba(theme.color.blueSecondary[400], 0.2)};
  `,
  Offline: css`
    color: ${theme.color.grayPrimary[700]};
    border-color: ${theme.color.grayPrimary[700]};
    background-color: ${rgba(theme.color.grayPrimary[700], 0.2)};
  `,
  Busy: css`
    color: ${theme.color.grayPrimary[50]};
    border-color: ${theme.color.grayPrimary[50]};
    background-color: ${rgba(theme.color.grayPrimary[700], 0.2)};
  `,
  Completed: css`
    color: ${theme.color.success[500]};
    border-color: ${theme.color.success[500]};
    background-color: ${rgba(theme.color.success[500], 0.2)};
  `,
  Default: css`
    color: ${theme.color.grayPrimary[50]};
    border-color: ${theme.color.grayPrimary[50]};
    background-color: ${rgba(theme.color.grayPrimary[700], 0.2)};
  `,
});

const TagStyled = styled(Tag)<{ status: MissionView.Status }>`
  ${({ theme, status }) => statusColor(theme)[status] || statusColor(theme).Default}
`;

type TagDeviceStatusProps = {
  status: MissionView.Status;
};

const TagDeviceStatus: React.FC<TagDeviceStatusProps> = ({ status }) => {
  return <TagStyled status={status}>{status}</TagStyled>;
};
export default TagDeviceStatus;
