import UploadFarm from "assets/images/UploadFlightLogImage.png";

const UploadFlightLogImage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${UploadFarm})`,
        backgroundSize: "cover",
        width: "450px",
        height: "180px",
        cursor: "pointer",
      }}
    />
  );
};
export default UploadFlightLogImage;
