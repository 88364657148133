import styled from "@emotion/styled";
import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IFlightPostSummary {
  totalFlightHour?: string;
  totalDistance?: string;
  totalSprayedArea?: string;
  totalSprayedWater?: string;
  totalFertilizerSprayed?: string;
  totalSprayedQuantity?: string;
  totalSpreadQuantity?: string;
  areaUnitLabel?: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #665c4d;
  flex-direction: column;

  .group {
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    .label {
      color: #f9fafb;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
    }
    .value-unit {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .value {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }
      .unit {
        color: #9ca3af;
        text-align: right;
        flex: 1 0 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
      }
    }
  }
`;
const FlightPostSummaryAdmin: FC<IFlightPostSummary> = ({
  totalFlightHour,
  totalDistance,
  totalSprayedArea,
  totalSprayedWater,
  totalFertilizerSprayed,
  totalSprayedQuantity,
  totalSpreadQuantity,
  areaUnitLabel,
}) => {
  const { t } = useTranslation(["drone", "common"]);

  return (
    <Wrapper>
      <div className="group">
        <div className="label">{t`Total flight hour :`}</div>
        <div className="value-unit">
          <span className="value">
            {totalFlightHour && totalFlightHour !== "null" ? totalFlightHour : "-"}
          </span>
          <span className="unit">{t(`Hours`, { ns: "common" })}</span>
        </div>
      </div>
      {/* {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(data)} */}
      {/* <div className="group">
        <div className="label">Total distance : </div>
        <div className="value-unit">
          <span className="value">
            {totalDistance && totalDistance !== "null"
              ? Intl.NumberFormat("en-US").format(totalDistance === "null" ? 0 : +totalDistance)
              : "0"}
          </span>
          <span className="unit">KM</span>
        </div>
      </div> */}
      <div className="group">
        <div className="label">{t`Total Sprayed Area :`}</div>
        <div className="value-unit">
          <span className="value">
            {totalSprayedArea
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                  totalSprayedArea === "null" ? 0 : +totalSprayedArea
                )
              : "0"}
          </span>
          <span className="unit">{areaUnitLabel}</span>
        </div>
      </div>
      <div className="group">
        <div className="label">{t`Total Sprayed Quantity :`}</div>
        <div className="value-unit">
          <span className="value">
            {totalSprayedQuantity && totalSprayedQuantity !== "null"
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                  totalSprayedQuantity === "null" ? 0 : +totalSprayedQuantity
                )
              : "0"}
          </span>
          <span className="unit">{t(`L`, { ns: "common" })}</span>
        </div>
      </div>
      <div className="group">
        <div className="label">{t`Total Spread Quantity :`}</div>
        <div className="value-unit">
          <span className="value">
            {totalSpreadQuantity && totalSpreadQuantity !== "null"
              ? Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                  totalSpreadQuantity === "null" ? 0 : +totalSpreadQuantity
                )
              : "0"}
          </span>
          <span className="unit">{t(`Kg`, { ns: "common" })}</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default FlightPostSummaryAdmin;
