import { Button, Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { ReactComponent as CirclePlusIcon } from "assets/svgs/circle_plus_icon.svg";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import { MUTATION_UPDATE_FARM, QUERY_MY_FARM } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import useWarningModal from "core/hooks/useWarningModal";
import { farmTypeList } from "core/utils/constant";
import { getLabelFromConstant } from "core/utils/form";
import dayjs from "dayjs";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import { IMeta } from "types/pagination";

import ATable from "components/templates/ATable/ATable";
import Badge from "components/templates/Badge/Badge";

import { IFarmDetail, TFarmListResponse } from "../../../types/farmer";
import AdminFarmListPage from "../AdminFarmListPage/AdminFarmListPage";
import { useAuth } from "../AuthPage/store/auth";
import FarmListPageStyled from "./FarmListPageStyled.styled";

const FarmListPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>();
  const [farmList, setFarmList] = useState<IFarmDetail[]>([]);
  const [dataDelete, setDataDelete] = useState<IFarmDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");
  const onToggleSwitch = (detail: IFarmDetail) => {
    setDataDelete(detail);
    if (detail.isActive) {
      errorModal.openModal();
    } else {
      enableModal.openModal();
    }
  };

  const fetchFarmList = async (currentPage: number, pageSize: number) => {
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
          search: searchName,
        },
      };
      const response: TFarmListResponse = await client.request(QUERY_MY_FARM, variables);
      setFarmList(response.myFarms.data);
      setMeta(response.myFarms.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  const handleToggleSwitch = async () => {
    if (dataDelete && dataDelete.id) {
      try {
        setIsLoading(true);
        const updatedObject: Partial<IFarmDetail> = {
          ...dataDelete,
          isActive: !dataDelete.isActive,
          coordinates: dataDelete.geom.coordinates,
          totalArea: +dataDelete.totalArea,
        };
        delete updatedObject.createdAt;
        delete updatedObject.id;
        delete updatedObject.geom;
        delete updatedObject.imageListUrls;
        const variables = {
          farmId: dataDelete.id,
          farm: updatedObject,
        };
        await client.request(MUTATION_UPDATE_FARM, variables);
        setDataDelete(undefined);
        await fetchFarmList(currentPage, pageSize);
        setIsLoading(false);

        successModal.openModal();
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleSearchName = () => {
    setIsLoading(true);
    fetchFarmList(currentPage, pageSize);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchFarmList(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const successModal = useSuccessModal({
    title: dataDelete?.isActive ? "Farm Disabled" : "Farm Enabled",
    detail: dataDelete?.isActive ? (
      <div>
        <div>Your farm has been successfully disabled</div>
        <div>You can re-enable again at anytime</div>
      </div>
    ) : (
      <div>
        <div>Your farm has been successfully enabled</div>
        <div>You can re-disable again at anytime</div>
      </div>
    ),
  });

  const enableModal = useWarningModal({
    title: "Are you sure you want to enable this farm ?",
    detail: (
      <div>
        <div>This farm will be enable immediately. </div>
        <div>You can re-disable again at anytime</div>
      </div>
    ),
    callBackOnOk() {
      handleToggleSwitch();
    },
  });
  const errorModal = useErrorModal({
    title: "Are you sure you want to disable this farm ?",
    detail: (
      <div>
        <div>This farm will be disabled immediately. </div>
        <div>You can re-enable again at anytime</div>
      </div>
    ),
    callBackOnOk() {
      handleToggleSwitch();
    },
  });
  const columns = [
    {
      title: "Farm",
      dataIndex: "name",
      key: "name",
      width: 336,
      render: (data: string, record: IFarmDetail) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ objectFit: "cover" }}
              src={record.imageListUrls?.[0] || "https://picsum.photos/id/3/200"}
              alt={record.name}
              width={104}
              height={60}
            />
            <div style={{ marginLeft: "16px" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#F9FAFB",
                }}
              >
                {data}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "150%",
                  color: "#9CA3AF",
                }}
              >
                {record.totalArea} Rai
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Farm Type",
      dataIndex: "farmType",
      key: "farmType",
      width: 300,
      align: "center" as AlignType,
      render: (data: string) => {
        return <div>{getLabelFromConstant(farmTypeList, data)}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      width: 380,
      align: "center" as AlignType,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      align: "center" as AlignType,
      render: (data: string) => {
        return <div>{dayjs(data).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 150,
      align: "center" as AlignType,
      render: (data: boolean) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Badge label={data ? "Active" : "Inactive"} type={data ? "success" : "normal"} />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "isActive",
      key: "action",
      width: 120,
      render: (isActive: boolean, record: IFarmDetail, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={isActive}
              onChange={() => onToggleSwitch(record)}
              onColor="#FFEDCD"
              onHandleColor="#FFB32C"
              offHandleColor="#FFB32C"
            />
            <Link to={"/farm/" + record.id}>
              <Icon icon="Edit" />
            </Link>
          </div>
        );
      },
    },
  ];
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  return (
    <FarmListPageStyled>
      <h2>Farm</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "300px" }}>
          <InputField
            placeholder="Search farm"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
            suffix={<Icon icon="Search" onClick={handleSearchName} style={{ cursor: "pointer" }} />}
          />
        </div>
        <Link to={"/add-farm"}>
          <Button style={{ backgroundColor: "#FFB32C", color: "white" }}>
            <CirclePlusIcon />
            <span style={{ marginLeft: "10px" }}>Add new farm</span>
          </Button>
        </Link>
      </div>
      <ATable
        isLoading={isLoading}
        columns={columns}
        data={farmList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      <enableModal.WarningModal />
      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </FarmListPageStyled>
  );
};

export const Component = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes("ADMIN");
  const isAdminServiceProvider = user?.roles.includes("ADMIN_SERVICE_PROVIDER");

  if (isAdmin || isAdminServiceProvider) {
    return <AdminFarmListPage />;
  } else {
    return <FarmListPage />;
  }
};
export default Component;
