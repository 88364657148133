import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useMap } from "@ai-and-robotics-ventures/map-library";
import { useTheme } from "@emotion/react";
import { client } from "core/gql/fetchgql";
import { QUERY_DISTRICT, QUERY_PROVINCE, QUERY_SUB_DISTRICT } from "core/gql/master";
import { farmTypeList } from "core/utils/constant";
import { get, pullAt } from "lodash";
import { useEffect, useState } from "react";
import {
  TDistrictItem,
  TDistrictResponse,
  TProvincesItem,
  TProvincesResponse,
  TSubDistrictItem,
  TSubDistrictResponse,
} from "types/master";

import ASelect from "components/templates/ASelect/ASelect";
import UploadFarmImageList from "components/templates/UploadFarmImageList/UploadFarmImageList";

import MapFarm from "../FarmerSignupPage/FarmStep/MapFarm";
import EditFarmStepStyled from "./EditFarmStep.styled";

interface IEditFarmStep {
  noStep: string;
  totalStep: string;
  form: any;
}
const EditFarmStep: React.FC<IEditFarmStep> = ({ form, noStep, totalStep }) => {
  const theme = useTheme();
  const [provincesList, setProvincesList] = useState<TProvincesItem[]>([]);
  const [districtsList, setDistrictsList] = useState<TDistrictItem[]>([]);
  const [subDistrictsList, setSubDistrictsList] = useState<TSubDistrictItem[]>([]);
  const map = useMap();

  const { values, touched, errors, setFieldValue, handleChange, validateForm } = form;

  const handleOnChangeMap = (coordinate: [number, number][]) => {
    if (coordinate.length === 0) {
      setFieldValue("coordinates", undefined);
    } else {
      setFieldValue("coordinates", [coordinate]);
    }
  };

  const fetchProvinces = async () => {
    try {
      const response: TProvincesResponse = await client.request(QUERY_PROVINCE);
      setProvincesList(response.provinces);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const variable = {
        provinceCode: values.provinceCode,
      };
      const response: TDistrictResponse = await client.request(QUERY_DISTRICT, variable);
      setDistrictsList(response.districtByProvinceCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubDistricts = async () => {
    try {
      const variable = {
        districtCode: values.districtCode,
      };
      const response: TSubDistrictResponse = await client.request(QUERY_SUB_DISTRICT, variable);
      setSubDistrictsList(response.subDistrictByDistrictCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  useEffect(() => {
    if (values.provinceCode) {
      fetchDistricts();
    }
  }, [values.provinceCode]);

  useEffect(() => {
    if (values.districtCode) {
      fetchSubDistricts();
    }
  }, [values.districtCode]);

  const handleOnChangeProvince = (value: string) => {
    setFieldValue(`provinceCode`, value);
    setFieldValue("districtCode", undefined);
    setFieldValue("subdistrictCode", undefined);

    setFieldValue("postcode", "");
    setDistrictsList([]);
    setSubDistrictsList([]);
    setTimeout(() => {
      validateForm();
    }, 100);
  };

  const handleOnChangeDistrict = (value: string) => {
    setFieldValue("districtCode", value);
    setFieldValue("subdistrictCode", undefined);
    setFieldValue("postcode", "");
    setSubDistrictsList([]);
    setTimeout(() => {
      validateForm();
    }, 100);
  };

  const handleOnChangeSubDistrict = (value: string) => {
    setFieldValue("subdistrictCode", value);
    setTimeout(() => {
      validateForm();
    }, 100);
    if (value) {
      const foundPostCode = subDistrictsList.find((item) => item.subDistrictCode === value);
      if (foundPostCode) {
        setFieldValue("postcode", foundPostCode.postCode);
      }
    }
  };

  return (
    <EditFarmStepStyled>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        Part {noStep}/{totalStep}
      </div>
      <h1>Farm Information</h1>
      <hr />
      <div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <InputField
              type="text"
              name={`name`}
              label="Farm Name"
              placeholder="Enter Farm Name"
              onChange={handleChange}
              helperSpace
              required
              value={get(values, `name`)}
              error={get(touched, `name`) && get(errors, `name`)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <ASelect
              label="Farm Type"
              required
              placeholder="Select Farm Type"
              options={farmTypeList}
              value={get(values, `farmType`)}
              onChange={(value) => setFieldValue(`farmType`, value)}
              error={get(touched, `farmType`) && get(errors, `farmType`)}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <InputField
              type="number"
              name={`totalArea`}
              label="Total Area"
              placeholder="Enter Total Area"
              helperSpace
              required
              value={get(values, `totalArea`)}
              onChange={handleChange}
              error={get(touched, `totalArea`) && get(errors, `totalArea`)}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          />
        </div>
        <div>
          <h3>Images</h3>
          <div className="font-300">
            Refer to the sample photos provided, before uploading the Drone and Remote photos.
          </div>
          <div className="font-300">
            Upload only .jpg or .png files with a maximum file size of 5 MB.{" "}
            <span className="font-500">You can upload a maximum of 10 files.</span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <h3>Farm</h3>
        </div>
        <div>
          <UploadFarmImageList
            required
            defaultFileUrlList={values.imageListUrls}
            defaultImageList={values.imageList}
            onChange={(value, urlList) => {
              console.log("value, urlList", value, urlList);
              setFieldValue(`imageList`, value);
              setFieldValue(`imageListUrls`, urlList);
            }}
            error={errors?.imageList}
          />
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div>Location</div>
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <InputField
            type="text"
            name={`address`}
            label="Location Name"
            placeholder="Enter Location Name"
            helperSpace
            required
            value={get(values, `address`)}
            onChange={handleChange}
            error={get(touched, `address`) && get(errors, `address`)}
          />
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <ASelect
              label="Province"
              required
              placeholder="Select Province"
              options={provincesList.map((province) => {
                return {
                  value: province.provinceCode,
                  label: province.name_en,
                };
              })}
              value={get(values, `provinceCode`)}
              onChange={(value) => handleOnChangeProvince(value)}
              error={get(touched, `provinceCode`) && get(errors, `provinceCode`)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <ASelect
              label="District"
              required
              placeholder="Select District"
              options={districtsList.map((district) => {
                return {
                  value: district.districtCode,
                  label: district.name_en,
                };
              })}
              value={get(values, `districtCode`)}
              onChange={(value) => handleOnChangeDistrict(value)}
              error={get(touched, `districtCode`) && get(errors, `districtCode`)}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <ASelect
              label="Subdistrict"
              required
              placeholder="Select Subdistrict"
              options={subDistrictsList.map((subDistrict) => {
                return {
                  value: subDistrict.subDistrictCode,
                  label: subDistrict.name_en,
                };
              })}
              value={get(values, `subdistrictCode`)}
              onChange={(value) => handleOnChangeSubDistrict(value)}
              error={get(touched, `subdistrictCode`) && get(errors, `subdistrictCode`)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <InputField
              type="text"
              name="postcode"
              value={form.values.postcode}
              label="Postal Code"
              placeholder="Enter Postal Code"
              helperSpace
              error={form.touched.postcode && form.errors.postcode}
              required
              onChange={form.handleChange}
            />
          </div>
        </div>
        {/* <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <TextAreaField
          name={`completeAddress`}
          label="Complete Address"
          required
          placeholder="Enter Address"
          maxLength="255"
          helperSpace
          onChange={handleChange}
          value={get(values, `completeAddress`)}
          rows={4}
          style={{ borderRadius: "12px", background: "white", color: "black" }}
          error={
            get(touched, `completeAddress`) &&
            get(errors, `completeAddress`)
          }
        />
      </div> */}
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div>
            Please draw farm area on map<span style={{ color: "red" }}>*</span>
          </div>
        </div>
        <MapFarm
          map={map}
          onChange={handleOnChangeMap}
          defaultCoordinates={get(values, "geom.coordinates")}
          // error={get(touched, "coordinates") && get(errors, "coordinates")}
        />

        <div className="error-message">
          {get(errors, "coordinates") && "Farm area  is a required field"}
        </div>
      </div>
    </EditFarmStepStyled>
  );
};

export default EditFarmStep;
