import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "polished";

const Wrapper = styled.div`
  display: flex;
`;

const StyledAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.bluePrimary[400]};
`;

const StyledMoreAvatar = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: -8px;
  background-color: ${({ theme }) => rgba(theme.color.bluePrimary[500], 0.2)};
  border: 1px solid ${({ theme }) => theme.color.bluePrimary[400]};
`;

type PropsType = {
  avatars: string[];
};

const Avatar: React.FC<PropsType> = (props) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {props.avatars.length > 0 && <StyledAvatar src={props.avatars[0]} style={{ zIndex: 1 }} />}
      {props.avatars.length > 1 && (
        <StyledAvatar src={props.avatars[1]} style={{ marginLeft: "-8px" }} />
      )}
      {props.avatars.length > 2 && (
        <StyledMoreAvatar>
          <Text variant="body3" css={{ color: theme.color.bluePrimary[400] }}>
            +2
          </Text>
        </StyledMoreAvatar>
      )}
    </Wrapper>
  );
};

export default Avatar;
