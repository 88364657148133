import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { IUploadDetail, UploadType, handleUploadFile } from "core/domains/services/upload";
import { pullAt, some } from "lodash";
import { FC, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

import Loading from "../Loading/Loading";
import UploadDroneImage from "../UploadDroneImage/UploadDroneImage";
import { IUploadFile } from "../UploadFarmImageList/UploadFarmImageList";

interface IUploadDroneItem {
  label: string;
  onChange: (data: IUploadDetail[], tempUrl: string[]) => void;
  require?: boolean;
  error?: string;
  defaultFileUrlList?: string[];
  defaultImageList?: IUploadDetail[];
  uploadType?: UploadType;
}
const UploadDroneItem: FC<IUploadDroneItem> = ({
  label,
  require = false,
  onChange,
  error,
  defaultFileUrlList,
  defaultImageList,
  uploadType,
}) => {
  const theme = useTheme();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadList, setUploadList] = useState<IUploadDetail[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    if (defaultImageList) {
      setUploadList(defaultImageList);
      const formatFileList = defaultImageList.map((item: IUploadDetail, index: number) => {
        return {
          path: item?.file_name,
          name: item?.unique_id,
          type: `image/${item.ext}`,
          webkitRelativePath: "",
          preview: defaultFileUrlList?.[index] as string,
        };
      });
      setFileList(formatFileList);
    }
  }, [defaultFileUrlList, defaultImageList]);

  const handleDeleteImage = (e: any, index: number) => {
    e.stopPropagation();
    setFileList([]);
    setUploadList([]);
    onChange([], []);
  };

  const onDrop = async (acceptedFiles: any) => {
    setIsUploading(true);
    const updatedFiles: IUploadFile[] = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFileList(updatedFiles);
    const result = await handleUploadFile(acceptedFiles[0], uploadType);
    setIsUploading(false);
    const urlList = updatedFiles.map((tf) => tf.preview);
    onChange([result], urlList);
  };

  const { getRootProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
  });
  const renderPreview = () => {
    return fileList.map((file: any, index: number) => (
      <div key={file.name} style={{ position: "relative", width: "180px" }}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => handleDeleteImage(e, index)}
        >
          {
            <Icon
              style={{ cursor: "pointer" }}
              icon="XMark"
              size={14}
              color={theme.color.gray[400]}
            />
          }
        </div>
        <img
          style={{ objectFit: "cover" }}
          alt={file.name}
          src={file.preview}
          width={180}
          height={180}
        />
        {isUploading && <Loading />}
      </div>
    ));
  };
  return (
    <div>
      <div style={{ marginBottom: "4px" }}>
        {label}
        {require && <span style={{ color: "red" }}>*</span>}
      </div>
      <div {...getRootProps()}>
        {fileList.length !== 0 ? renderPreview() : <UploadDroneImage />}
      </div>
      <div className="error-message">{error && error}</div>
    </div>
  );
};
export default UploadDroneItem;
