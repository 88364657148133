import { Button, Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { css } from "@emotion/react";
import { ReactComponent as AddFlightPostIcon } from "assets/svgs/add_flight_post_icon.svg";
import { client } from "core/gql/fetchgql";
import { QUERY_MY_DRONE } from "core/gql/pilot";
import useDrawPolygon from "core/hooks/useDrawPolygon";
import useSuccessModal from "core/hooks/useSuccessModal";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { IAddFlightPlanData, TFlightPostDetail } from "types/flightPost";
import { IDroneDetail, TDroneListResponse } from "types/pilot";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ASelect from "components/templates/ASelect/ASelect";
import Loading from "components/templates/Loading/Loading";

import AddFlightPlanStepStyled from "./AddFlightPlanStepStyled.styled";
import AltitudeChart from "./AltitudeChart";
import WaypointList from "./WaypointList";

const opacityStyle = ({ validate }: { validate: boolean }) => css`
  ${validate ? "opacity: 1" : "opacity: 0.5"}
`;

interface IAddFlightPlanStep {
  onBack: (value: string) => void;
  data?: TFlightPostDetail;
  handleReadyFlightPost: (value: IAddFlightPlanData) => void;
}

const AddFlightPlanStep: FC<IAddFlightPlanStep> = ({ onBack, data, handleReadyFlightPost }) => {
  const [droneList, setDroneList] = useState<IDroneDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modal = useSuccessModal({
    title: "Flight Post Added",
    detail: (
      <>
        <div>Your flight post has been successfully </div>
        <div>added to the platform</div>
      </>
    ),
    callBack: () => onBack("list"),
  });
  const { pointFlightPlan, distances } = useDrawPolygon({
    coor: data?.farm.geom.coordinates[0] as [number, number][],
  });
  const form = useFormik({
    initialValues: {
      droneSerialNumber: "",
      coordinates: [],
      waypoints: [],
    },
    validationSchema: toFormikValidationSchema(
      z
        .object({
          droneSerialNumber: z.string({
            required_error: "drone is a required field",
          }),
          coordinates: z
            .array(z.tuple([z.number(), z.number()]))
            .refine((array) => array.length > 0, {
              message: "Please draw flight plan",
            }),
          waypoints: z.array(
            z.object({
              altitude: z.number(),
              speed: z.number(),
            })
          ),
        })
        .refine((data) => data.coordinates.length - 1 === data.waypoints.length, {
          message: "waypoints do not match",
          path: ["waypoints"],
        })
    ),
    onSubmit: (values) => handleReadyFlightPost(values),
  });

  const fetchDroneList = async () => {
    try {
      const variables = {
        options: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
      };
      const response: TDroneListResponse = await client.request(QUERY_MY_DRONE, variables);
      setDroneList(response.myDrone.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDroneList();
  }, []);

  useEffect(() => {
    form.setFieldValue("coordinates", pointFlightPlan);
  }, [pointFlightPlan]);
  return (
    <AddFlightPlanStepStyled>
      {isLoading && <Loading />}
      <div className="add-flight-plan-box">
        <div style={{ display: "flex", gap: "8px" }}>
          <Icon
            size={20}
            icon="ChevronLeft"
            onClick={() => onBack("list")}
            style={{ cursor: "pointer" }}
          />
          <h2>Create Flight Plan</h2>
        </div>
        <ASelect
          label="Drone"
          required
          placeholder="Select drone"
          options={droneList.map((drone) => {
            return {
              value: drone.serialNumber,
              label: drone.name,
            };
          })}
          onChange={(value) => form.setFieldValue(`droneSerialNumber`, value)}
          error={
            (get(form, "touched.droneSerialNumber") &&
              get(form, "errors.droneSerialNumber")) as string
          }
        />
        <div className="operation-area-select">
          <span>Operation Area</span>
          <Icon icon="ChevronUp" />
        </div>
        {distances.length > 1 ? (
          <div>
            <WaypointList distances={distances} form={form} />
            <div className="error-message">
              {get(form, "errors.waypoints") && "Waypoint is a required"}
            </div>
          </div>
        ) : (
          <div className="operation-area-section">
            <div className="operation-area-title">
              <span>Operation Area</span>
              <span className="red">*</span>
            </div>
            <div className="icon-box">
              <AddFlightPostIcon />
              <div className="label">Draw the project area on the map</div>
            </div>
            <div className="error-message">
              {get(form, "errors.coordinates") && "Flight Plane is a required"}
            </div>
          </div>
        )}
      </div>
      {distances.length > 1 && <AltitudeChart data={form.values} length={distances.length} />}

      <div className="add-footer-flight-plan">
        <Button className="cancel-btn" fullWidth onClick={() => onBack("list")}>
          Cancel
        </Button>
        <Button
          onClick={() => form.handleSubmit()}
          className="add-btn"
          fullWidth
          css={opacityStyle({ validate: isEmpty(form.errors) })}
        >
          Create
        </Button>
      </div>
      <modal.SuccessModal />
    </AddFlightPlanStepStyled>
  );
};
export default AddFlightPlanStep;
