import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ChartData } from "chart.js";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent } from "react";
import { Line, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TAdminFlightInformationDashboardTableData, TSummaryDashboard } from "types/admin";

import LineChart from "components/pages/FarmerReportPage/LineChart";
import ReportCard from "components/pages/FarmerReportPage/ReportCard";
import ATable from "components/templates/ATable/ATable";

import { FlightInformation, FlightStat, chartOption } from "../hook/useReport";

const StyledWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  .content {
    display: flex;
    gap: 24px;
  }

  .card-content {
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    display: flex;
    .pie-content {
      width: 300px;
      height: 300px;
    }
  }

  .chart-flex {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
  }

  .line-chart {
    margin-top: 20px;
  }
`;

interface PilotReportProps {
  summaryDashboard: TSummaryDashboard;
  flightStats: FlightStat[];
  totalSprayedChartData: ChartData;
  flightInformationTableLoading: boolean;
  flightInformation?: FlightInformation;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  areaUnitLabel?: string;
}

const PilotReport: React.FC<PilotReportProps> = ({
  summaryDashboard,
  flightStats,
  totalSprayedChartData,
  flightInformationTableLoading,
  flightInformation,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
  areaUnitLabel,
}) => {
  const { t } = useTranslation(["report", "common"]);

  const totalFlight = summaryDashboard.totalFlightInformation;

  const totalSprayed = summaryDashboard.fieldType.reduce(
    (previous, current) => previous + current.count,
    0
  );

  const labels = summaryDashboard?.resourceUsage?.map((item) => item.month.split("-").reverse());

  const lineOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        border: { dash: [4, 4] },
        grid: {
          color: "#aaa",
          tickBorderDash: [5, 5],
        },
        ticks: {
          color: "white",
          font: {
            size: 10,
          },
          callback: function (value: string | number, index: number, values: any) {
            console.log("values", values);

            const totalLabels = values.length;
            const maxLabels = 10;

            if (totalLabels > maxLabels) {
              const step = Math.ceil(totalLabels / maxLabels);

              if (index % step !== 0) {
                return ""; // Hide this label
              }
            }

            return labels?.[index]; // Show this label
          },
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          color: "rgb(53, 162, 235)",
          font: {
            size: 10,
          },
          callback: function (value: string | number) {
            return value + " L";
          },
        },
        beginAtZero: true,
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        color: "white",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: "rgb(255, 99, 132)",
          font: {
            size: 10,
          },
          callback: function (value: string | number) {
            return value + " Kg";
          },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "white",
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const lineData = {
    labels,
    datasets: [
      {
        label: t`Water (L)`,
        data: summaryDashboard?.resourceUsage?.map((item) => item.statSprayedQuantity),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y",
        pointRadius: 4,
        pointHoverRadius: 6,
      },
      {
        label: t`Fertilizer (Kg)`,
        data: summaryDashboard?.resourceUsage?.map((item) => item.statSpreadQuantity),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y1",
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  const columns = [
    {
      title: t`Farm / Field`,
      dataIndex: "area",
      key: "area",
      align: "center" as AlignType,
      width: 336,
    },
    {
      title: t`Distance (Km)`,
      dataIndex: "totalDistance",
      key: "totalDistance",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Sprayed Area`} (${areaUnitLabel})`,
      dataIndex: "sprayedArea",
      key: "sprayedArea",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Sprayed Quantity`} (${t(`L`, { ns: "common" })})`,
      dataIndex: "sprayedQuantity",
      key: "sprayedQuantity",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: `${t`Spread Quanitity`} (${t(`Kg`, { ns: "common" })})`,
      dataIndex: "spreadQuantity",
      key: "spreadQuantity",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: t`Drone Id`,
      dataIndex: "droneId",
      key: "droneId",
      width: 200,
      align: "center" as AlignType,
    },
    {
      title: t`Date Created`,
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      align: "center" as AlignType,
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: 50,
      align: "center" as AlignType,
      render: (row: TAdminFlightInformationDashboardTableData) => (
        <Link to={`/drone/flight-detail/${row.id}`}>
          <Icon icon="DotsVertical" />
        </Link>
      ),
    },
  ];

  return (
    <StyledWrapper>
      <div className="content">
        <ReportCard title={t`Total Flight Post`} value={totalFlight}>
          <div className="chart-flex">
            {flightStats.map((stat, index) => {
              return <LineChart key={index} {...stat} />;
            })}
          </div>
        </ReportCard>

        <ReportCard title={t`Total Sprayed Area based on farm type`} value={totalSprayed}>
          <div className="card-content">
            <div className="pie-content">
              <Pie
                key="farm-type"
                data={totalSprayedChartData as ChartData<"pie">}
                options={chartOption}
                width={300}
              />
            </div>
          </div>
        </ReportCard>

        <ReportCard title={t`Resource usage`} value={t`water, fertilizer`}>
          <div className="line-chart">
            <Line key="resource-usage" options={lineOptions} data={lineData} />
          </div>
        </ReportCard>
      </div>

      <ATable
        isLoading={flightInformationTableLoading}
        columns={columns}
        data={flightInformation?.data}
        totalItems={flightInformation?.totalItems || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </StyledWrapper>
  );
};

export default PilotReport;
