import { gql } from "graphql-request";

export const MUTATION_LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(loginInput: $input) {
      token
    }
  }
`;

// const useLoginMutation = () => {
//   const [updateTodoResult, updateTodo] = useMutation(MUTATION_LOGIN);
// };
