import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ReactComponent as WaypointIcon } from "assets/svgs/waypoint_icon.svg";
import { sum } from "lodash";
import { FC, useMemo } from "react";

import WaypointItem from "./WaypointItem";

interface IWaypointList {
  distances: number[];
  form: any;
}

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #ffb32c;
  background: rgba(102, 92, 77, 0.7);
  padding: 16px;
  font-size: 12px;
  .title-waypoint {
    margin-bottom: 24px;
  }
  .row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
`;
const ValueWithUnit = ({ value, unit }: { value: string; unit: string }) => {
  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      <div className="font-size-14">{value}</div>
      <div className="font-size-12">{unit}</div>
    </div>
  );
};

const WaypointList: FC<IWaypointList> = ({ distances, form }) => {
  const totalDistance = sum(distances);

  // speed meter per second
  // const speedList = form.values.waypoints.map((item: any) => item?.speed / 3.6);
  const speedList = form.values.waypoints.map((item: any) => {
    if (item?.speed) {
      return item.speed / 3.6;
    }
    return 0;
  });

  const totalTime = useMemo(() => {
    let overall = 0;
    let lastSpeed = 0;
    if (speedList.length > 0) {
      for (let i = 0; i < distances.length; i++) {
        if (speedList[i]) {
          lastSpeed = speedList[i];
        }
        overall += distances[i] / lastSpeed;
      }
      const hr = overall / 60 / 60;
      const sec = (overall % 60) % 60;
      const min = (overall / 60) % 60;

      return [`${Math.floor(hr)}`, `${Math.floor(min)}`, `${sec.toFixed(0)}`];
    }
    return ["-", "-", "-"];
  }, [distances, speedList]);

  return (
    <Wrapper>
      <div className="font-size-14 title-waypoint">Waypoint List ({distances.length})</div>
      <div className="font-size-12 row">
        <WaypointIcon />
        <span>Total Distance</span>
        <ValueWithUnit value={(totalDistance / 1000).toFixed(2)} unit="km" />
      </div>
      <div className="font-size-12 row">
        <Icon icon="Clock" size={24} />
        <span>Est. Total Time</span>
        <ValueWithUnit value={totalTime[0]} unit="hr" />
        <ValueWithUnit value={totalTime[1]} unit="min" />
        <ValueWithUnit value={totalTime[2]} unit="sec" />
      </div>
      {distances.map((_, index) => (
        <WaypointItem key={index} index={index} form={form} />
      ))}
    </Wrapper>
  );
};

export default WaypointList;
