import { Icon, InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import Loading from "components/templates/Loading/Loading";
import SearchInput from "components/templates/SearchInput/SearchInput";

import { useAuth } from "../AuthPage/store/auth";
import AdminReport from "./component/AdminReport";
import PilotReport from "./component/PilotReport";
import useReport from "./hook/useReport";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;

  .search-wrapper {
    width: 312px;
  }

  .date-wrapper {
    width: 250px;
  }
`;

ChartJS.register(ArcElement, Tooltip, Legend);

const ReportPage = () => {
  const { t } = useTranslation("report");

  // ** Date filter **
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  // ** Table state **
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    // Get the current date
    const currentDate = new Date(new Date().setHours(23, 59, 59, 999));

    // Get the first day of the current year
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);

    setDateRange([firstDayOfYear, currentDate]);
  }, []);

  const {
    summaryDashboard,
    flightStats,
    totalSprayedChartData,
    flightInformationTableLoading,
    flightInformation,
  } = useReport({
    startDate,
    endDate,
    currentPage,
    pageSize,
  });

  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();

  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);
  const isAdmin = user?.roles?.includes("ADMIN") || user?.roles?.includes("ADMIN_SERVICE_PROVIDER");
  const isPilot = user?.roles?.includes("PILOT");
  const isFarmer = user?.roles?.includes("FARMER");

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  const renderContent = () => {
    if (summaryDashboard) {
      if (isAdmin) {
        return (
          <AdminReport
            summaryDashboard={summaryDashboard}
            flightStats={flightStats}
            totalSprayedChartData={totalSprayedChartData}
            currentPage={currentPage}
            pageSize={pageSize}
            flightInformationTableLoading={flightInformationTableLoading}
            flightInformation={flightInformation}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            areaUnitLabel={areaUnitLabel}
          />
        );
      }

      if (isPilot) {
        return (
          <PilotReport
            summaryDashboard={summaryDashboard}
            flightStats={flightStats}
            totalSprayedChartData={totalSprayedChartData}
            currentPage={currentPage}
            pageSize={pageSize}
            flightInformationTableLoading={flightInformationTableLoading}
            flightInformation={flightInformation}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            areaUnitLabel={areaUnitLabel}
          />
        );
      }

      if (isFarmer) {
        // TODO: update when clear requirement
        return (
          <AdminReport
            currentPage={currentPage}
            pageSize={pageSize}
            summaryDashboard={summaryDashboard}
            flightStats={flightStats}
            totalSprayedChartData={totalSprayedChartData}
            flightInformationTableLoading={flightInformationTableLoading}
            flightInformation={flightInformation}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            areaUnitLabel={areaUnitLabel}
          />
        );
      }
    }
  };

  return (
    <StyledWrapper>
      <h2>{t`Progression Reporting Dashboard`}</h2>

      <StyledActions>
        <div className="search-wrapper">
          <SearchInput placeholder={t`Search Flight Post`} />
        </div>

        <div className="date-wrapper">
          <DatePicker
            customInput={<InputField suffix={<Icon icon="Calendar" />} />}
            startDate={startDate}
            endDate={endDate}
            onChange={(value: [Date | null, Date | null]) => {
              value[1]?.setHours(23, 59, 59, 999); //set endDate to be the end of the day ex. 23/01/2024 23:59:59:000z
              setDateRange(value);
            }}
            selectsRange
          />
        </div>
      </StyledActions>

      {summaryDashboard ? renderContent() : <Loading />}
    </StyledWrapper>
  );
};

export default ReportPage;
