import styled from "@emotion/styled";
import { ReactComponent as DropWaterIcon } from "assets/svgs/drop_water_icon.svg";
import { ReactComponent as GrassIcon } from "assets/svgs/grass_icon.svg";
import { client } from "core/gql/fetchgql";
import { QUERY_FLIGHT_POST_BY_ID } from "core/gql/flightPost";
import { useAreaConvert } from "core/hooks/useAreaConvert";
import { droneBrandList } from "core/utils/constant";
import { getLabelFromConstant } from "core/utils/form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { TFlightPostDetail, TFlightPostDetailResponse } from "types/flightPost";

import { useAuth } from "components/pages/AuthPage/store/auth";
import AiangStatus from "components/templates/AiangStatus/AiangStatus";
import Loading from "components/templates/Loading/Loading";

const Wrapper = styled.div`
  position: absolute;
  width: calc(100vw - 440px);
  padding: 16px 24px;
  left: 344px;
  bottom: 24px;
  border-radius: 10px;
  background: rgba(26, 26, 26, 0.9);
  backdrop-filter: blur(5px);
  overflow: scroll;
  .title {
    color: #f9fafb;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
    margin-bottom: 16px;
  }
  .flight-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 8px;
  }
  .flight-label {
    color: #9ca3af;
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
    flex: 1;
  }
  .flight-value {
    color: #d1d5db;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    flex: 2;
  }
`;

interface IFlightDetail {
  onClose: () => void;
  data: TFlightPostDetail;
}
const FlightDetail: FC<IFlightDetail> = ({ onClose, data }) => {
  const { convertAreaUnits } = useAreaConvert();

  const { user } = useAuth();
  const areaUnit = user?.preference?.unit?.area || "rai";
  const areaUnitLabel = convertAreaUnits(areaUnit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flightPostDetail, setFlightPostDetail] = useState<TFlightPostDetail>();
  const fetchFlightPostDetail = async (id: string) => {
    try {
      const variables = {
        flightPostId: id,
      };
      const response: TFlightPostDetailResponse = await client.request(
        QUERY_FLIGHT_POST_BY_ID,
        variables
      );
      setFlightPostDetail(response.flightPostById);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchFlightPostDetail(data.id);
  }, [data]);
  // const renderStatus = () => {
  //   switch (data.status) {
  //     case EPostStatus.PENDING:
  //       return <Badge label="Pending" type="warning" />;
  //     case EPostStatus.IN_FLIGHT:
  //       return <Badge label="In Flight" type="inprogress" />;
  //     case EPostStatus.ACCEPTED:
  //       return <Badge label="Pending" type="success" />;
  //     default:
  //       return null;
  //   }
  // };
  if (!flightPostDetail) {
    return null;
  }

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
        <div
          style={{
            flex: 1,
            gap: "16px",
            flexDirection: "column",
          }}
        >
          <div className="title">Flight Detail</div>
          <div className="flight-name">{flightPostDetail.name}</div>
          <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Farmer :</span>
                <span className="flight-value">
                  {flightPostDetail.farmer.firstName} {flightPostDetail.farmer.lastName}
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Pilot :</span>
                <span className="flight-value">
                  {flightPostDetail.pilot?.firstName ?? "-"}{" "}
                  {flightPostDetail.pilot?.lastName ?? ""}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Location :</span>
                <span className="flight-value">{flightPostDetail.farm.name}</span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Pilot Email :</span>
                <span className="flight-value">{flightPostDetail.pilotEmail ?? "-"}</span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Area :</span>
                <span className="flight-value">
                  {flightPostDetail.farm.totalArea}{" "}
                  <span style={{ fontWeight: "Thin" }}>{areaUnitLabel}</span>
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Drone :</span>
                <span className="flight-value">{flightPostDetail.drone?.name ?? "-"}</span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Time :</span>
                <span className="flight-value">
                  <div>
                    {dayjs(flightPostDetail.startTime).format("DD MMMM YYYY")} -{" "}
                    {dayjs(flightPostDetail.endTime).format("DD MMMM YYYY")}
                  </div>
                  <div>
                    {dayjs(flightPostDetail.startTime).format("HH:mm")} -{" "}
                    {dayjs(flightPostDetail.endTime).format("HH:mm")}
                  </div>
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Model :</span>
                <span className="flight-value">
                  {getLabelFromConstant(droneBrandList, flightPostDetail.drone?.model || "")}
                </span>
              </div>
            </div>
            {/* <div style={{ display: "flex" }}>
              <span className="flight-label" style={{ flex: 1 }}>
                Status :
              </span>
              <div className="flight-value" style={{ flex: 5 }}>
                <AiangStatus status={flightPostDetail.status} />

                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "8px" }}
                >
                  <DropWaterIcon />
                  <span>678.35 L</span>
                  <GrassIcon />
                  <span>456.78 kg</span>
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label"> Status :</span>
                <span className="flight-value">
                  <AiangStatus status={flightPostDetail.status} />

                  <div
                    style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "8px" }}
                  >
                    <DropWaterIcon />
                    <span>678.35 L</span>
                    <GrassIcon />
                    <span>456.78 kg</span>
                  </div>
                </span>
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <span className="flight-label">Flight Post Description :</span>
                <span className="flight-value">{flightPostDetail.description ?? "-"}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            flex: 1,
            height: "264px",
            borderLeft: "1px solid #6B7280",
            paddingLeft: "24px",
          }}
        >
          <div className="title">Area of Operation</div>
        </div> */}
      </div>
      <div
        style={{ position: "absolute", top: "16px", right: "16px", cursor: "pointer" }}
        onClick={() => onClose()}
      >
        X
      </div>
    </Wrapper>
  );
};
export default FlightDetail;
