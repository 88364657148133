import { TextAreaField } from "@ai-and-robotics-ventures/cumulus-ui";
import { UploadType } from "core/domains/services/upload";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import UploadConfigFile from "components/templates/UploadConfigFile/UploadConfigFile";

interface ConfigurationFileFormProps {
  form: any;
  isEdit?: boolean;
}

const ConfigurationFileForm: React.FC<ConfigurationFileFormProps> = ({ form, isEdit }) => {
  const { t } = useTranslation("form");
  const { errors, touched } = form;
  const handleUploadChange = (value: any, urlList: any) => {
    form.setFieldValue("drone.config_file.fileList", value);
    form.setFieldValue("drone.configFileUrls", urlList);
    setTimeout(() => {
      form.validateForm();
    }, 100);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    form.handleChange(e);
  };
  return (
    <div style={{ maxWidth: "80%" }}>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <UploadConfigFile
            label={t`Upload Config File`}
            onChange={handleUploadChange}
            defaultConfigFileUrlList={form.values.drone?.configFileUrls}
            defaultConfigList={form.values.drone.config_file?.fileList}
            error={get(errors, `drone.config_file.fileList`)}
            uploadType={UploadType.CONFIG_FILE}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <TextAreaField
            label={t`Description of Config File Changes`}
            name={`drone.config_file.changeLogString`}
            placeholder={t`Enter description of the config file change`}
            rows={4}
            value={form.values.drone.config_file?.changeLogString || ""}
            defaultValue={form.values.drone.config_file?.changeLogString}
            onChange={handleTextAreaChange}
            error={
              get(touched, `drone.config_file.changeLogString`) &&
              get(errors, `drone.config_file.changeLogString`)
            }
            style={{ borderRadius: "12px", background: "white", color: "black" }}
          />
        </div>
      </div>
      {isEdit && form.values.drone.config_file?.changeLog && (
        <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
          <div style={{ flex: 1 }}>
            <h3>{t`Change Logs`}</h3>
            <div
              style={{
                borderRadius: "12px",
                background: "#f0f0f05b",
                color: "#303030",
                padding: "8px",
                maxHeight: "300px",
                overflowY: "auto",
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
              }}
            >
              {form.values.drone.config_file?.changeLog.map((log: any) => (
                <div>{log}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigurationFileForm;
