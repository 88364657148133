import { Button, Icon, Modal } from "@ai-and-robotics-ventures/cumulus-ui";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const useErrorModal = ({
  title,
  detail,
  icon,
  callBackOnOk,
  callBackOnCancel,
}: {
  title: string;
  detail?: React.ReactNode;
  icon?: React.ReactNode;
  callBackOnOk?: () => void;
  callBackOnCancel?: () => void;
}) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const ErrorModal = () => {
    return isOpen ? (
      <Modal
        isOpen={isOpen}
        style={{
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "336px",
            width: "400px",
            borderRadius: "20px",
            border: "1px solid #3E4754",
            background: "rgba(255, 255, 255, 0.80)",
            boxShadow: "4px 4px 50px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1A1A1A",
            flexDirection: "column",
            padding: "24px",
            overflow: "hidden",
          },
        }}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "80px",
            backgroundColor: "rgba(235, 52, 52, 0.20)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {icon ? icon : <Icon icon="NoEntry" color="#EB3434" size={40} />}
        </div>
        <div style={{ marginTop: "24px" }}>{title}</div>
        <div
          className="font-300"
          style={{
            fontSize: "14px",
            lineHeight: "22px",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          {detail}
        </div>
        <div style={{ marginTop: "40px", display: "flex", gap: "16px" }}>
          <Button
            type="submit"
            style={{ backgroundColor: "white", color: "#1F2937" }}
            onClick={() => {
              setIsOpen(false);
              if (callBackOnCancel) {
                callBackOnCancel();
              }
            }}
          >
            {t`Cancel`}
          </Button>
          <Button
            type="submit"
            style={{ backgroundColor: "#EB3434", color: "white" }}
            onClick={() => {
              setIsOpen(false);
              if (callBackOnOk) {
                callBackOnOk();
              }
            }}
          >
            {t`Ok`}
          </Button>
        </div>
      </Modal>
    ) : null;
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    ErrorModal,
    openModal,
    closeModal,
  };
};

export default useErrorModal;
