import { QUERY_COUNT_FLIGHT_POST, QUERY_MY_FLIGHT_POST } from "core/gql/farmer";
import { client } from "core/gql/fetchgql";
import { QUERY_MY_PILOT_FLIGHT_POST } from "core/gql/pilot";
import { useEffect, useState } from "react";
import {
  EPostStatus,
  TCountFlightPost,
  TCountFlightPostResponse,
  TFarmerFlightPostResponse,
  TFlightPostDetail,
} from "types/flightPost";

import Loading from "components/templates/Loading/Loading";

import AdminNewDroneListPage from "../AdminNewDroneListPage/AdminNewDroneListPage";
import { useAuth } from "../AuthPage/store/auth";
import DroneListStep from "./DroneListStep/DroneListStep";

const DroneListPage = () => {
  const [step, setStep] = useState<string>("list");
  const [selectedEditFlightPost, setSelectedEditFlightPost] =
    useState<TFlightPostDetail | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flightPostList, setFlightPostList] = useState<TFlightPostDetail[]>([]);
  const [count, setCount] = useState<TCountFlightPost>();
  const [filterSelected, setFilterSelected] = useState<string>("ALL");

  const handleSetStepAll = () => {
    setStep("list");
    setSelectedEditFlightPost(null);
  };

  const handleClickEdit = (id: string | null) => {
    const found = flightPostList.find((flightPost) => flightPost.id === id);
    if (found) {
      setSelectedEditFlightPost(found);
      setStep("edit");
    }
  };

  const fetchFlightPostList = async (status?: EPostStatus | EPostStatus[]) => {
    try {
      const variables = {
        options: {
          currentPage: 1,
          itemsPerPage: 1000,
        },
        flightStatus: status,
      };
      const response: TFarmerFlightPostResponse = await client.request(
        QUERY_MY_FLIGHT_POST,
        variables
      );
      setFlightPostList(response.farmerGetMyFlightPost.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchCountFlightPost = async () => {
    try {
      const response: TCountFlightPostResponse = await client.request(QUERY_COUNT_FLIGHT_POST);
      setCount(response.countMyFlightPost);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (filterSelected) {
      setIsLoading(true);
      // switch (filterSelected) {
      //   case "AVAILABLE":
      //     fetchFlightPostList([
      //       EPostStatus.PENDING,
      //       EPostStatus.IN_FLIGHT,
      //       EPostStatus.ACCEPTED,
      //       EPostStatus.READY,
      //     ]);
      //     break;
      //   case "ACCEPTED":
      //     fetchFlightPostList(EPostStatus.ACCEPTED);
      //     break;
      //   case "READY":
      //     fetchFlightPostList(EPostStatus.READY);
      //     break;
      //   default:
      //     // all
      //     fetchFlightPostList([
      //       EPostStatus.PENDING,
      //       EPostStatus.IN_FLIGHT,
      //       EPostStatus.ACCEPTED,
      //       EPostStatus.READY,
      //     ]);
      //     break;
      // }
    }
    setIsLoading(false);
  }, [filterSelected]);
  useEffect(() => {
    setIsLoading(true);
    // fetchFlightPostList([
    //   EPostStatus.PENDING,
    //   EPostStatus.IN_FLIGHT,
    //   EPostStatus.ACCEPTED,
    //   EPostStatus.READY,
    // ]);
    // fetchCountFlightPost();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (filterSelected) {
      handleRefetch();
    }
  }, [filterSelected]);
  const handleRefetch = () => {
    setIsLoading(true);
    // fetchCountFlightPost();
    // if (filterSelected) {
    // switch (filterSelected) {
    //   case "AVAILABLE":
    //     fetchFlightPostList(EPostStatus.PENDING);
    //     break;
    //   case "ACCEPTED":
    //     fetchFlightPostList(EPostStatus.ACCEPTED);
    //     break;
    //   case "READY":
    //     fetchFlightPostList(EPostStatus.READY);
    //     break;
    //   default:
    //     // all
    //     fetchFlightPostList([
    //       EPostStatus.PENDING,
    //       EPostStatus.IN_FLIGHT,
    //       EPostStatus.ACCEPTED,
    //       EPostStatus.READY,
    //     ]);
    //     break;
    // }
    // }
    setIsLoading(false);
  };
  const onSuccess = () => {
    setIsLoading(true);
    handleRefetch();
    setStep("list");
    setSelectedEditFlightPost(null);
  };

  const handleOnChangeFilter = (filter: string) => {
    setFilterSelected(filter);
  };
  const renderStep = (step: string) => {
    return (
      <DroneListStep
        count={count}
        onChangeFilter={handleOnChangeFilter}
        filter={filterSelected}
        flightPostList={flightPostList}
        onSetStep={(value: string) => setStep(value)}
        setSelectedEditFlightPost={handleClickEdit}
      />
    );
  };

  return (
    <div>
      {isLoading && <Loading />}
      {renderStep(step)}
    </div>
  );
};

export const Component = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes("ADMIN");

  // if (isAdmin) {
  return <AdminNewDroneListPage />;
  // } else {
  //   return <DroneListPage />;
  // }
};
export default Component;
