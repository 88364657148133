import styled from "@emotion/styled";

const AdminAddDronePageStyled = styled.div`
  .add-drone-container {
    .form {
      border-radius: 12px;
      background: #414221;
      min-height: calc(100vh - 164px);
      padding: 40px 16px 20px 0;
      height: 100%;
      position: relative;
      .step-section {
        position: absolute;
        left: 100px;
        top: 0;
      }
    }
  }
`;

export default AdminAddDronePageStyled;
