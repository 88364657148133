import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "core/configs/dayjs";
import { rgba } from "polished";
import { Dispatch, SetStateAction } from "react";

const NavigatorWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const NavigatorDateWrapper = styled.div`
  padding: 0 64px;
  width: 100%;
  position: relative;
`;

const DateWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const DateBox = styled.div`
  width: 14%;
  flex-grow: 1;
  text-align: center;
  border-radius: 14px;
  padding: 10px 30px 30px;
  cursor: "pointer";
`;

const ButtonWrapper = styled.div`
  border-radius: 100%;
  background-color: ${({ theme }) => rgba(theme.color.grayPrimary[600], 0.2)};
  position: absolute;
  top: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type PropsType = {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
};

const CalendarNavigator: React.FC<PropsType> = ({ selectedDate, setSelectedDate }) => {
  const theme = useTheme();
  const datepickerRange = [-3, -2, -1, 0, 1, 2, 3];
  const calendarTitle = dayjs(selectedDate).format("MMMM YYYY");
  const dateNavigator = datepickerRange.map((number, index) => {
    const target = dayjs(selectedDate).add(number, "day");
    const date = dayjs(target);
    return (
      <DateBox
        key={index}
        onClick={() => setSelectedDate(date.toDate())}
        style={{
          backgroundColor: index === 3 ? rgba(theme.color.bluePrimary[500], 0.2) : "",
          color: index === 3 ? theme.color.bluePrimary[400] : "",
        }}
      >
        <Text variant="subtitle1">{date.format("D")}</Text>
        <Text variant="body3">{date.format("ddd")}</Text>
      </DateBox>
    );
  });
  const goNextDate = () => setSelectedDate(dayjs(selectedDate).subtract(1, "day").toDate());
  const goPreviousDate = () => setSelectedDate(dayjs(selectedDate).add(1, "day").toDate());

  return (
    <NavigatorWrapper>
      <Text variant="h6">{calendarTitle}</Text>
      <NavigatorDateWrapper>
        <ButtonWrapper onClick={goNextDate} style={{ left: "32px" }}>
          <Icon icon="ChevronLeft" css={{ padding: "2px 0 0 2px" }} />
        </ButtonWrapper>
        <DateWrapper>{dateNavigator}</DateWrapper>
        <ButtonWrapper onClick={goPreviousDate} style={{ right: "32px" }}>
          <Icon icon="ChevronRight" css={{ padding: "2px 0 0 4px" }} />
        </ButtonWrapper>
      </NavigatorDateWrapper>
    </NavigatorWrapper>
  );
};

export default CalendarNavigator;
