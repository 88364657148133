import { Icon } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { IUploadDetail, handleUploadFile } from "core/domains/services/upload";
import { pullAt, some } from "lodash";
import { FC, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

import Loading from "../Loading/Loading";
import UploadFarmImage from "../UploadFarmImage/UploadFarmImage";

export interface IUploadFile {
  path: string;
  lastModified?: number;
  name: string;
  size?: number;
  type: string;
  webkitRelativePath: string;
  preview: string;
}
interface IUploadFarmImageList {
  onChange: (data: IUploadDetail[], tempUrl: string[]) => void;
  error?: string;
  defaultFileUrlList?: string[];
  defaultImageList?: IUploadDetail[];
  required?: boolean;
}
const UploadFarmImageList: FC<IUploadFarmImageList> = ({
  onChange,
  error,
  defaultFileUrlList,
  defaultImageList,
  required,
}) => {
  const theme = useTheme();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadList, setUploadList] = useState<IUploadDetail[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    if (defaultImageList) {
      setUploadList(defaultImageList);
      const formatFileList = defaultImageList.map((item: IUploadDetail, index: number) => {
        return {
          path: item?.file_name,
          name: item?.unique_id,
          type: `image/${item?.ext}`,
          webkitRelativePath: "",
          preview: defaultFileUrlList?.[index] as string,
        };
      });
      setFileList(formatFileList);
    }
  }, [defaultFileUrlList]);
  const handleDeleteImage = (e: any, index: number) => {
    e.stopPropagation();
    pullAt(fileList, index);
    pullAt(uploadList, index);

    const urlList = fileList.map((file) => file.preview);
    setFileList([...fileList]);
    setUploadList([...uploadList]);
    onChange(uploadList, urlList);
  };

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length + fileList.length <= 10) {
      setIsUploading(true);
      const updatedFiles = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      const tempFileList = [...fileList, ...updatedFiles];
      setFileList(tempFileList);
      const result = await Promise.all(acceptedFiles.map((file: File) => handleUploadFile(file)));
      const newResult = [...uploadList, ...result];
      setIsUploading(false);

      const urlList = tempFileList.map((tf) => tf.preview);
      onChange(newResult, urlList);
      setUploadList(urlList);
    }
  };

  const { getRootProps } = useDropzone({
    onDrop,
    disabled: fileList.length >= 10 || isUploading,
    maxFiles: 10,
    maxSize: 5000000,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
  });

  const renderPreview = () => {
    return fileList.map((file: any, index: number) => (
      <div key={file.unique_id} style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "20px",
            height: "20px",
            backgroundColor: "white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={(e) => handleDeleteImage(e, index)}
        >
          {
            <Icon
              style={{ cursor: "pointer" }}
              icon="XMark"
              size={14}
              color={theme.color.gray[400]}
            />
          }
        </div>
        <img
          alt={file.name}
          style={{ objectFit: "cover" }}
          src={file.preview}
          width={294}
          height={180}
        />
        {isUploading && <Loading />}
      </div>
    ));
  };
  return (
    <>
      <div style={{ marginBottom: "4px" }}>
        Farm images
        {required && <span style={{ color: "red" }}>*</span>}
      </div>

      <div style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
        <div
          {...getRootProps()}
          style={{ cursor: fileList.length >= 10 || isUploading ? "not-allowed" : "pointer" }}
        >
          <UploadFarmImage />
        </div>

        {fileList.length > 0 && renderPreview()}
      </div>
      <div className="error-message" style={{ marginBottom: "24px" }}>
        {error && uploadList.length === 0 && error}
      </div>
    </>
  );
};
export default UploadFarmImageList;
