import { InputField } from "@ai-and-robotics-ventures/cumulus-ui";
import { useTheme } from "@emotion/react";
import { client } from "core/gql/fetchgql";
import {
  QUERY_DISTRICT,
  QUERY_NATIONALITIES,
  QUERY_PROVINCE,
  QUERY_SUB_DISTRICT,
} from "core/gql/master";
import { useEffect, useState } from "react";
import {
  TDistrictItem,
  TDistrictResponse,
  TNationalityItem,
  TNationalityResponse,
  TProvincesItem,
  TProvincesResponse,
  TSubDistrictItem,
  TSubDistrictResponse,
} from "types/master";

import ASelect from "components/templates/ASelect/ASelect";

import FarmerStepStyled from "./FarmerStep.styled";

interface IFarmerStep {
  // selected: string;
  // data: IDataButtonGroup[];
  form: any;
  // form.handleChange: (e: React.ChangeEvent<any>) => void;
}
const FarmerStep: React.FC<IFarmerStep> = ({ form }) => {
  const theme = useTheme();
  const [provincesList, setProvincesList] = useState<TProvincesItem[]>([]);
  const [districtsList, setDistrictsList] = useState<TDistrictItem[]>([]);
  const [subDistrictsList, setSubDistrictsList] = useState<TSubDistrictItem[]>([]);
  const [nationalityList, setNationalityList] = useState<TNationalityItem[]>([]);
  const fetchNationality = async () => {
    try {
      const response: TNationalityResponse = await client.request(QUERY_NATIONALITIES);
      setNationalityList(response.nationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchProvinces = async () => {
    try {
      const response: TProvincesResponse = await client.request(QUERY_PROVINCE);
      setProvincesList(response.provinces);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const variable = {
        provinceCode: form.values.provinceCode,
      };
      const response: TDistrictResponse = await client.request(QUERY_DISTRICT, variable);
      setDistrictsList(response.districtByProvinceCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubDistricts = async () => {
    try {
      const variable = {
        districtCode: form.values.districtCode,
      };
      const response: TSubDistrictResponse = await client.request(QUERY_SUB_DISTRICT, variable);
      setSubDistrictsList(response.subDistrictByDistrictCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProvinces();
    fetchNationality();
  }, []);

  useEffect(() => {
    if (form.values.provinceCode) {
      fetchDistricts();
    }
  }, [form.values.provinceCode]);

  useEffect(() => {
    if (form.values.districtCode) {
      fetchSubDistricts();
    }
  }, [form.values.districtCode]);

  const handleOnChangeProvince = (value: string) => {
    form.setFieldValue(`provinceCode`, value);
    form.setFieldValue("districtCode", undefined);
    form.setFieldValue("subdistrictCode", undefined);
    form.setFieldValue("postcode", "");
    setDistrictsList([]);
    setSubDistrictsList([]);
    setTimeout(() => {
      form.validateForm();
    }, 100);
  };

  const handleOnChangeDistrict = (value: string) => {
    form.setFieldValue("districtCode", value);
    form.setFieldValue("subdistrictCode", undefined);
    form.setFieldValue("postcode", "");
    setSubDistrictsList([]);
    setTimeout(() => {
      form.validateForm();
    }, 100);
  };

  const handleOnChangeSubDistrict = (value: string) => {
    form.setFieldValue("subdistrictCode", value);
    setTimeout(() => {
      form.validateForm();
    }, 100);
    if (value) {
      const foundPostCode = subDistrictsList.find((item) => item.subDistrictCode === value);
      if (foundPostCode) {
        form.setFieldValue("postcode", foundPostCode.postCode);
      }
    }
  };
  return (
    <FarmerStepStyled>
      <div
        style={{
          color: theme.color.gray[400],
          fontSize: "12px",
          lineHeight: "150%",
          fontWeight: 300,
        }}
      >
        Part 1/2
      </div>
      <h1>Farmer Information</h1>
      <hr />
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="firstName"
          value={form.values.firstName}
          label="First Name"
          placeholder="Enter First Name"
          onChange={form.handleChange}
          helperSpace
          error={form.touched.firstName && form.errors.firstName}
          required
        />
        <InputField
          type="text"
          name="lastName"
          value={form.values.lastName}
          onChange={form.handleChange}
          label="Last Name"
          placeholder="Enter Last Name"
          helperSpace
          error={form.touched.lastName && form.errors.lastName}
          required
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="nationalIdNo"
            value={form.values.nationalIdNo}
            label="National ID Number"
            placeholder="Enter National ID Number"
            onChange={form.handleChange}
            helperSpace
            error={form.touched.nationalIdNo && form.errors.nationalIdNo}
            required
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <ASelect
            label="Nationality"
            placeholder="Select Nationality"
            required
            options={nationalityList.map((nation) => {
              return {
                value: nation.nationalityCode,
                label: nation.nationality,
              };
            })}
            value={form.values.nationality}
            onChange={(value) => {
              form.setFieldValue("nationality", value);
            }}
            error={form.touched.nationality && form.errors.nationality}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="email"
          required
          name="email"
          value={form.values.email}
          label="Email"
          onChange={form.handleChange}
          placeholder="Enter Email"
          helperSpace
          error={form.touched.email && form.errors.email}
        />
        <InputField
          type="text"
          name="phoneNo"
          value={form.values.phoneNo}
          label="Phone Number"
          placeholder="Enter Phone Number"
          helperSpace
          error={form.touched.phoneNo && form.errors.phoneNo}
          required
          onChange={form.handleChange}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>Address</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="text"
          name="address"
          value={form.values.address}
          label="Address Name"
          placeholder="Enter Address Name"
          helperSpace
          error={form.touched.address && form.errors.address}
          required
          onChange={form.handleChange}
        />
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="Province"
            placeholder="Select Province"
            options={provincesList.map((province) => {
              return {
                value: province.provinceCode,
                label: province.name_en,
              };
            })}
            value={form.values.provinceCode}
            required
            onChange={(value) => handleOnChangeProvince(value)}
            error={form.touched.provinceCode && form.errors.provinceCode}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ASelect
            label="District"
            placeholder="Select District"
            options={districtsList.map((district) => {
              return {
                value: district.districtCode,
                label: district.name_en,
              };
            })}
            value={form.values.districtCode}
            onChange={(value) => handleOnChangeDistrict(value)}
            error={form.touched.districtCode && form.errors.districtCode}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div style={{ flex: 1 }}>
          <ASelect
            label="Subdistrict"
            placeholder="Select Subdistrict"
            options={subDistrictsList.map((subDistrict) => {
              return {
                value: subDistrict.subDistrictCode,
                label: subDistrict.name_en,
              };
            })}
            value={form.values.subdistrictCode}
            required
            onChange={(value) => handleOnChangeSubDistrict(value)}
            error={form.touched.subdistrictCode && form.errors.subdistrictCode}
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputField
            type="text"
            name="postcode"
            value={form.values.postcode}
            label="Postal Code"
            placeholder="Enter Postal Code"
            helperSpace
            error={form.touched.postcode && form.errors.postcode}
            required
            onChange={form.handleChange}
          />
        </div>
      </div>
      {/* <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <TextAreaField
          name="completeAddress"
          value={form.values.completeAddress}
          label="Complete Address"
          placeholder="Enter Address"
          // required
          // rightHelper={`${form.values.description?.length}/255`}
          maxLength="255"
          helperSpace
          onChange={form.handleChange}
          rows={4}
          style={{ borderRadius: "12px", background: "white", color: "black" }}
        />
      </div> */}
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <div>Password</div>
      </div>
      <div style={{ display: "flex", gap: "24px", marginBottom: "24px" }}>
        <InputField
          type="password"
          name="password"
          value={form.values.password}
          label="Password"
          placeholder="Enter Password"
          onChange={form.handleChange}
          helperSpace
          required
          error={form.touched.password && form.errors.password}
        />
        <InputField
          type="password"
          name="confirmPassword"
          value={form.values.confirmPassword}
          label="Confirm Password"
          placeholder="Enter Password"
          helperSpace
          required
          onChange={form.handleChange}
          error={form.touched.confirmPassword && form.errors.confirmPassword}
        />
      </div>
    </FarmerStepStyled>
  );
};

export default FarmerStep;
