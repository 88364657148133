import axios, {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import storage from "../utils/storage";

export enum AUTH {
  HEALTH_CHECK = "api/health-check",
  POST_REGISTER = "api/v2/authenticate/registration",
  POST_LOGIN = "api/v2/authenticate/login",
  POST_FORGOT_PASSWORD = "api/v2/authenticate/forgot-password",
  POST_RESET_PASSWORD = "api/v2/authenticate/forgot-password/change",
  POST_REFRESH_TOKEN = "api/v2/authenticate/refresh-token",
  POST_LOGOUT = "api/v2/authenticate/logout",

  GET_USER_INFO = "api/v2/user/info",
  GET_ALL_TEAM = "api/v2/team/get/all",
  GET_MEMBER_BY_ID = "api/v2/team/{id}/get/users",
  GET_USER_PERMISSION = "/api/v2/authorize/get-all-permissions-by-user-id",
  GET_TEAM_USER = "/api/v2/team/:id/get/users",

  GET_USER_ORGANIZATION = "/api/v1/organization/get/users",
}

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL || "https://cam-dev.arv.co.th",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      // 'Access-Control-Allow-Credentials': true,
      // 'Content-Type': 'multipart/form-data',
    },
  });
  return instance;
};

const authFetch = axiosInstance();
export const authRequestInterceptor = (config: AxiosRequestConfig<any>) => {
  if (!config.headers) config.headers = {};
  const access_token = storage.get("access_token");
  if (access_token) config.headers.Authorization = `Bearer ${access_token}`;

  return config;
};

const handleRefreshTokenAndRedirectLogout = async (config: InternalAxiosRequestConfig) => {
  const access_token = storage.get("access_token");
  const refresh_token = storage.get("refresh_token");

  const refreshResponse = await authFetch.post(AUTH.POST_REFRESH_TOKEN, {
    refresh_token,
    access_token,
  });

  const newToken = refreshResponse?.data?.response?.token;
  if (newToken) {
    await storage.set("access_token", newToken);
    return authFetch(config);
  } else {
    window.location.href = "/logout";
  }
};

export const authResponseInterceptorSuccess = async (res: AxiosResponse<any, any>) => {
  return res;
};

export const authResponseInterceptorError = (error: AxiosError) => {
  console.error(error);
  const { response, config } = error;
  const data: any = response?.data || {};
  const errorMessage = data?.message || data?.error;
  if (
    (errorMessage === "Bearer token are expired." && data?.statusCode === 400) ||
    data?.statusCode === 401
  ) {
    if (config) return handleRefreshTokenAndRedirectLogout(config);
  }
  return Promise.resolve(error);
};

authFetch.interceptors.request.use(authRequestInterceptor as any);
authFetch.interceptors.response.use(authResponseInterceptorSuccess, authResponseInterceptorError);

export default authFetch;
