import { LightTheme as Ligh } from "@ai-and-robotics-ventures/cumulus-ui";

import { aliasColor } from "./alias";
import ColorSwatch from "./colors";

export const DarkTheme = {
  color: ColorSwatch,
  components: {
    Button: {
      colors: aliasColor,
    },
  },
};
export const LightTheme = {
  ...Ligh,
  color: {
    ...Ligh.color,
    ...ColorSwatch,
  },
};
