import {
  QUERY_ADMIN_DRONE_LIST,
  QUERY_ADMIN_FLIGHT_INFORMATION_LIST,
  QUERY_ADMIN_GET_ALL_UNIQUE_DRONE_INFORMATION,
} from "core/gql/admin";
import { client } from "core/gql/fetchgql";
import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import { TAdminGetAllUniqueDroneInformationResponse } from "types/admin";
import { IDroneInformation, IDroneInformationAndLocation } from "types/droneInformation";
import { IMeta } from "types/pagination";
import { IDroneDetail, TDroneListResponse } from "types/pilot";

import Loading from "components/templates/Loading/Loading";

import { Marker, markersAtom } from "../Map/Map";
import { useDisplayMarker } from "../Map/useDisplay";
import AddDroneStep from "./AddDroneStep/AddDroneStep";
import DroneListStep from "./DroneListStep/DroneListStep";
import DroneMarker from "./DroneMarker/DroneMarker";

const AdminNewDroneListPage = () => {
  const [step, setStep] = useState<string>("list");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDrone, setSelectedDrone] = useState<IDroneInformation | null>(null);
  const [droneList, setDroneList] = useState<IDroneInformationAndLocation[]>([]);

  const [meta, setMeta] = useState<IMeta>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSetStepAll = () => {
    setStep("list");
    setSelectedDrone(null);
  };

  const handleClickEdit = (serialNumber: string | null) => {
    const found = droneList.find((drone) => drone.serialNumber === serialNumber);
    if (found) {
      setSelectedDrone(found);
      setStep("edit");
    }
  };

  const markDronesLocation = () => {
    if (droneList) {
      const markersList: Marker[] = [];
      droneList.map((drone, index) => {
        if (drone.location) {
          const marker: Marker = {
            borderColor: "black",
            color: "red",
            center: [drone.location[0], drone.location[1]],
            icon: <DroneMarker drone={drone} />,
            id: "" + drone.id,
          };
          markersList.push(marker);
        }
      });
      setMarkers(markersList);
    }
  };

  const fetchFlightInformation = async (currentPage: number, pageSize: number) => {
    setIsLoading(true);
    try {
      const variables = {
        options: {
          currentPage,
          itemsPerPage: pageSize,
        },
        search: searchTerm,
      };
      const response: TAdminGetAllUniqueDroneInformationResponse = await client.request(
        QUERY_ADMIN_GET_ALL_UNIQUE_DRONE_INFORMATION,
        variables
      );
      setDroneList(response.adminGetAllUniqueDroneInformation.data);
      setMeta(response.adminGetAllUniqueDroneInformation.meta);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const loadMoreData = async () => {
    if (meta && meta.currentPage * meta.itemsPerPage < meta.totalItems) {
      setIsLoading(true);
      try {
        const nextPage = meta.currentPage + 1;
        const variables = {
          options: {
            currentPage: nextPage,
            itemsPerPage: meta.itemsPerPage,
          },
          search: searchTerm,
        };
        const response: TAdminGetAllUniqueDroneInformationResponse = await client.request(
          QUERY_ADMIN_GET_ALL_UNIQUE_DRONE_INFORMATION,
          variables
        );

        setDroneList((prevList) => [
          ...prevList,
          ...response.adminGetAllUniqueDroneInformation.data,
        ]);
        setMeta(response.adminGetAllUniqueDroneInformation.meta);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchFlightInformation(1, 10);
  }, [searchTerm]);

  useEffect(() => {
    markDronesLocation();
  }, [searchTerm, droneList]);

  const onSuccess = () => {
    setIsLoading(true);
    setStep("list");
    setSelectedDrone(null);
  };

  const renderStep = (step: string) => {
    switch (step) {
      case "list":
        return (
          <DroneListStep
            onSetStep={(value: string) => setStep(value)}
            setSelectedDrone={handleClickEdit}
            droneList={droneList}
            loadMoreData={loadMoreData}
            setSearchTerm={setSearchTerm}
          />
        );
      case "add":
        return <AddDroneStep onSuccess={onSuccess} onCancel={handleSetStepAll} />;
      case "edit":
        return <></>;
    }
  };
  useDisplayMarker(markers);

  return (
    <div>
      {isLoading && <Loading />}
      {renderStep(step)}
    </div>
  );
};

export default AdminNewDroneListPage;
