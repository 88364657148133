import { Button, Icon, InputField, Popover } from "@ai-and-robotics-ventures/cumulus-ui";
import styled from "@emotion/styled";
import UAV from "assets/images/UAV.png";
import { ReactComponent as CirclePlusIcon } from "assets/svgs/circle_plus_icon.svg";
import { ReactComponent as DetailIcon } from "assets/svgs/detail_icon.svg";
import { MUTATION_STATUS_DRONE_LEASING } from "core/gql/drone";
import { client } from "core/gql/fetchgql";
import useErrorModal from "core/hooks/useErrorModal";
import useSuccessModal from "core/hooks/useSuccessModal";
import useWarningModal from "core/hooks/useWarningModal";
import { debounce } from "lodash";
import { AlignType } from "rc-table/lib/interface";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IDroneList } from "types/droneInformation";
import { IMeta } from "types/pagination";
import { IDroneDetail } from "types/pilot";

import ATable from "components/templates/ATable/ATable";
import Badge from "components/templates/Badge/Badge";
import { LockIcon, UnlockIcon } from "components/templates/Icon";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  .action-icon {
    cursor: pointer;
  }
`;

interface DroneListTableProps {
  droneList: IDroneDetail[];
  meta?: IMeta;
  fetchData: (params: {
    currentPage: number;
    pageSize: number;
    searchName: string;
  }) => Promise<void>;
}

const DroneListTable: React.FC<DroneListTableProps> = ({ droneList, meta, fetchData }) => {
  const { t } = useTranslation("drone");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataDisable, setDataDisable] = useState<IDroneDetail>();
  const [searchName, setSearchName] = useState<string>("");

  const navigate = useNavigate();

  const successModal = useSuccessModal({
    title: dataDisable?.isLock ? t`Drone Enabled` : t`Drone Disabled`,
    detail: dataDisable?.isLock ? (
      <div>
        <div>{t`Your drone has been successfully disabled`}</div>
        <div>{t`You can re-enable again at anytime`}</div>
      </div>
    ) : (
      <div>
        <div>{t`Your drone has been successfully enabled`}</div>
        <div>{t`You can re-disable again at anytime`}</div>
      </div>
    ),
  });

  const enableModal = useWarningModal({
    title: t`Are you sure you want to enable this drone ?`,
    detail: (
      <div>
        <div>{t`This drone will be enabled immediately`}</div>
        <div>{t`You can re-disable again at anytime`}</div>
      </div>
    ),
    callBackOnOk() {
      handleOnDisable();
    },
  });

  const errorModal = useErrorModal({
    title: t`Are you sure you want to disable this drone ?`,
    detail: (
      <div>
        <div>{t`This drone will be disabled immediately`}</div>
        <div>{t`You can re-enable again at anytime`}</div>
      </div>
    ),
    callBackOnOk() {
      handleOnDisable();
    },
  });

  const handleOnDisable = async () => {
    if (dataDisable && dataDisable.id) {
      try {
        setIsLoading(true);

        const updatedObject = {
          droneId: dataDisable.id,
          droneLeasingStatusInput: !dataDisable.isLock,
        };

        await client.request(MUTATION_STATUS_DRONE_LEASING, updatedObject);
        setIsLoading(false);

        successModal.openModal();

        fetchData({ currentPage, pageSize, searchName });
      } catch (error) {
        setIsLoading(false);
        console.error("Error change status:", error);
      }
    }
  };

  const handleDroneAction = (detail: IDroneDetail) => {
    setDataDisable(detail);

    if (detail.isLock) {
      enableModal.openModal();
    } else {
      errorModal.openModal();
    }
  };

  const columns = [
    {
      title: t`Drone`,
      dataIndex: "name",
      key: "name",
      width: 120,
      render: (record: IDroneList) => {
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img
              style={{ objectFit: "cover" }}
              src={UAV}
              alt={record?.serialNumber}
              width={60}
              height={60}
            />
          </div>
        );
      },
    },
    {
      title: t`Serial No.`,
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center" as AlignType,
      width: 200,
    },
    {
      title: t`Drone Type`,
      dataIndex: "droneType",
      key: "droneType",
      align: "center" as AlignType,
      width: 100,
    },
    {
      title: t`Brand`,
      dataIndex: "brand",
      key: "brand",
      align: "center" as AlignType,
      width: 100,
    },
    {
      title: t`Model`,
      dataIndex: "model",
      key: "model",
      align: "center" as AlignType,
      width: 100,
    },
    {
      title: t`Weight`,
      dataIndex: "weight",
      key: "weight",
      align: "center" as AlignType,
      width: 100,
    },
    {
      title: t`Status`,
      dataIndex: "status",
      key: "status",
      width: 120,
      align: "center" as AlignType,
      render: (data: string, record: IDroneDetail) => {
        const badgeLabel = record.isLock ? "Locked" : "Active";
        const type = record.isLock ? "normal" : "success";
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Badge label={badgeLabel} type={type} />
          </div>
        );
      },
    },
    {
      title: t`Action`,
      dataIndex: "isLock",
      key: "isLock",
      width: 120,
      render: (isLock: boolean, record: IDroneDetail, index: number) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="action-icon" onClick={() => handleDroneAction(record)}>
              {isLock ? (
                <Popover id="unlock-over" tooltipContent="Unlock">
                  <UnlockIcon />
                </Popover>
              ) : (
                <Popover id="lock-over" tooltipContent="Lock">
                  <LockIcon />
                </Popover>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t`Detail`,
      dataIndex: "detail",
      width: 120,
      render: (text: string, record: { serialNumber: string }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/drone/flight-post/" + record.serialNumber);
          }}
        >
          <DetailIcon />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: 50,
      align: "center" as AlignType,
      render: (row: any, record: any) => (
        <Link to={`/edit-drone/${record.serialNumber}`}>
          <Icon icon="Edit" />
        </Link>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(+event.target.value as number);
  };

  // use lodash debouncd for search
  useEffect(() => {
    const debouncedFetch = debounce(async (page, size) => {
      setIsLoading(true);

      await fetchData({ currentPage: page, pageSize: size, searchName });

      setIsLoading(false);
    }, 300);

    debouncedFetch(currentPage, pageSize);

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchName]);

  useEffect(() => {
    const fetchDrone = async () => {
      setIsLoading(true);
      await fetchData({ currentPage, pageSize, searchName });

      setIsLoading(false);
    };

    fetchDrone();
  }, [currentPage, pageSize]);

  return (
    <StyledWrapper>
      <h2>{t`Drone`}</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "300px" }}>
          <InputField
            placeholder={t`Search drone name, owner name`}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
            suffix={<Icon icon="Search" />}
          />
        </div>
        <Link to={"/add-drone"}>
          <Button style={{ backgroundColor: "#FFB32C", color: "white" }}>
            <CirclePlusIcon />
            <span style={{ marginLeft: "10px" }}>{t`Add new drone`}</span>
          </Button>
        </Link>
      </div>

      <ATable
        isLoading={isLoading}
        columns={columns}
        data={droneList}
        totalItems={meta?.totalItems || 10}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <enableModal.WarningModal />
      <errorModal.ErrorModal />
      <successModal.SuccessModal />
    </StyledWrapper>
  );
};
export default DroneListTable;
