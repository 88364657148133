import styled from "@emotion/styled";
import i18n from "core/configs/i18n";
import { rgba } from "polished";
import { PropsWithChildren, memo, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "components/pages/AuthPage/store/auth";
import Map from "components/pages/Map/Map";

import Navigation from "../Navigation/Navigation";

const AMapLayoutStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grayPrimary[50]};
  z-index: 1;

  .layout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    > .container::before {
      backdrop-filter: blur(10px);
    }
    > .container {
      width: 408px;
      backdrop-filter: blur(10px);
      background: ${({ theme }) => rgba(theme.color.graySecondary[900], 0.6)};
      /* backdrop-filter: blur(10px); */
      /* padding: 0px 24px 24px; */
      /* overflow: auto; */
    }
  }
`;

type AMapLayoutProps = {} & PropsWithChildren;

const AMapLayout: React.FC<AMapLayoutProps> = () => {
  const { user } = useAuth();

  useEffect(() => {
    i18n.changeLanguage(user?.preference?.language || "en");
  }, [user]);

  return (
    <AMapLayoutStyled>
      <div className="layout">
        <Navigation />
        <Outlet />
      </div>
      <div>
        <Map />
      </div>
    </AMapLayoutStyled>
  );
};

export default memo(AMapLayout);
