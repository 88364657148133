import { Button, toast } from "@ai-and-robotics-ventures/cumulus-ui";
import { client } from "core/gql/fetchgql";
import {
  MUTATION_PILOT_REGISTER,
  QUERY_CHECK_DRONE_SERIAL_EXISTS,
  QUERY_CHECK_PILOT_MAIL_EXISTS,
} from "core/gql/pilot";
import useSuccessModal from "core/hooks/useSuccessModal";
import { pilotSignUpSchema, pilotSignupDefaultValue } from "core/schema/pilot";
import { checkIsError } from "core/utils/form";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FormErrors } from "types/base";
import { Insurance } from "types/drone";
import { TCheckDroneSerialResponse, TCheckPilotEmailResponse } from "types/pilot";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import ButtonStepGroup from "components/templates/ButtonStepGroup/ButtonStepGroup";
import {
  BatteryNotSelectedIcon,
  BatterySelectedIcon,
  DroneIconSelected,
  DroneIconWhite,
  InsuranceNotSelectedIcon,
  InsuranceSelectedIcon,
  PayloadNotSelectedIcon,
  PayloadSelectedIcon,
  PilotIconSelected,
  PilotIconWhite,
} from "components/templates/Icon";

import DroneForm from "../AddDronePage/DroneForm/DroneForm";
import { useAuth } from "../AuthPage/store/auth";
import PilotSignupPageStyled from "./PilotSignupPage.styled";
import PilotStep from "./PilotStep/PilotStep";

type TPilotSignSchema = z.infer<typeof pilotSignUpSchema>;

const PilotSignupPage = () => {
  const navigate = useNavigate();

  const {
    user,
    actions: { logout },
  } = useAuth();
  const location = useLocation();
  const isAddPilotPage = (location.pathname = "/add-pilot");
  const [step, setStep] = useState<string>("Pilot");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modal = useSuccessModal({
    title: isAddPilotPage ? "New Pilot Added" : "Registration Successful",
    detail: isAddPilotPage ? (
      <>
        <div>Your pilot has been successfully </div>
        <div>added to the platform</div>
      </>
    ) : (
      <>
        <div>Your registration form has been</div>
        <div>submitted to the system</div>
      </>
    ),
    callBack: () => {
      if (isAddPilotPage) {
        navigate("/pilot-list");
        return;
      }
      navigate("/login");
    },
  });
  const validatePilotEmail = async (email: string) => {
    try {
      const variables = {
        email,
      };
      const result: TCheckPilotEmailResponse = await client.request(
        QUERY_CHECK_PILOT_MAIL_EXISTS,
        variables
      );
      return result.checkPilotEmailExists;
    } catch (e) {
      console.log(e);
    }
  };

  const validatePilotDrone = async (serialNumber: string) => {
    try {
      const variables = {
        serialNumber,
      };
      const result: TCheckDroneSerialResponse = await client.request(
        QUERY_CHECK_DRONE_SERIAL_EXISTS,
        variables
      );
      return result.checkDroneSerialExists;
    } catch (e) {
      console.log(e);
    }
  };
  const formik = useFormik<TPilotSignSchema>({
    initialValues: pilotSignupDefaultValue,
    validationSchema: toFormikValidationSchema(pilotSignUpSchema),
    onSubmit: (values) => handleSubmitForm(values),
    validate: async (values) => {
      const errors: FormErrors = {};
      if (values.email) {
        const asyncError = await validatePilotEmail(values.email);
        if (asyncError) {
          errors.email = "email is already exists";
        }
      }
      if (values.drone.serialNumber) {
        const droneError = await validatePilotDrone(values.drone.serialNumber);
        if (droneError) {
          errors.drone = {};
          errors.drone.serialNumber = "drone is already added";
        }
      }
      return errors;
    },
  });
  const renderStep = (step: string) => {
    switch (step) {
      case "Pilot":
        return <PilotStep form={formik} />;
      default:
        return <DroneForm totalStep={5} step={step} form={formik} />;
    }
  };

  const handleSubmitForm = async (values: any) => {
    try {
      setIsLoading(true);
      const pilotInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        nationalIdNo: values.nationalIdNo,
        nationality: values.nationality,
        email: values.email,
        phoneNo: values.phoneNo,
        password: values.password,
        license: values.license,
        drone: {
          name: values.drone.name,
          description: values.drone.description,
          droneType: values.drone.droneType,
          brand: values.drone.brand,
          serialNumber: values.drone.serialNumber,
          model: values.drone.model,
          weight: values.drone.weight,
          extentions: "-",
          objective: "-",
          images: values.drone.images,
          battery: values.drone.battery,
          insurance: values.drone.insurance?.map((insurance: Insurance) => ({
            number: insurance.number,
            coverage: insurance.coverage,
            fileList: insurance.fileList,
            expireDate: insurance.expireDate,
          })),
          payload: values.drone.payload,
        },
      };
      const variables = {
        pilotInput: pilotInput,
      };
      await client.request(MUTATION_PILOT_REGISTER, variables);
      modal.openModal();
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating item:", error);
      toast({
        title: "",
        description:
          "Registration Error: Unable to process your registration at the moment. Please try again",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.exp > Date.now() / 1000) {
        if (user.roles.includes("FARMER")) {
          navigate("/farm-list");
        }
        if (user.roles.includes("PILOT")) {
          navigate("/report");
        }
        if (user.roles.includes("ADMIN")) {
          navigate("/admin-service-provider-list");
        }
        if (user.roles.includes("ADMIN_SERVICE_PROVIDER")) {
          navigate("/flight-post");
        }
        if (user.roles.includes("LEASING")) {
          navigate("/leasing-list");
        }
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [user]);
  const errors = formik.errors;
  return (
    <PilotSignupPageStyled>
      <form onSubmit={formik.handleSubmit}>
        <div className="container">
          <h1
            style={{
              padding: "32px 16px",
              fontWeight: "bold",
              marginTop: 0,
            }}
          >
            {isAddPilotPage ? "Add Pilot" : "Pilot Registration"}
          </h1>

          <div className="form">
            <div className="step-section">
              <ButtonStepGroup
                data={[
                  {
                    label: "Pilot",
                    selectedIcon: <PilotIconSelected />,
                    notSelectedIcon: <PilotIconWhite />,
                    // isError: checkIsError(errors, [
                    //   "firstName",
                    //   "lastName",
                    //   "nationalIdNo",
                    //   "email",
                    //   "phoneNo",
                    //   "license",
                    //   "password",
                    //   "confirmPassword",
                    // ]),
                  },
                  {
                    label: "Drone",
                    selectedIcon: <DroneIconSelected />,
                    notSelectedIcon: <DroneIconWhite />,
                    // isError: checkIsError(errors, [
                    //   "drone.name",
                    //   "drone.droneType",
                    //   "drone.serialNumber",
                    //   "drone.brand",
                    //   "drone.model",
                    //   "drone.weight",
                    // ]),
                  },
                  {
                    label: "Insurance",
                    selectedIcon: <InsuranceSelectedIcon />,
                    notSelectedIcon: <InsuranceNotSelectedIcon />,
                    // isError: checkIsError(errors, [
                    //   "drone.insurance.number",
                    //   "drone.insurance.expireDate",
                    //   "drone.insurance.coverage",
                    // ]),
                  },
                  {
                    label: "Payload",
                    selectedIcon: <PayloadSelectedIcon />,
                    notSelectedIcon: <PayloadNotSelectedIcon />,
                  },
                  {
                    label: "Battery",
                    selectedIcon: <BatterySelectedIcon />,
                    notSelectedIcon: <BatteryNotSelectedIcon />,
                  },
                ]}
                selected={step}
                onClick={setStep}
              />
            </div>
            {renderStep(step)}
          </div>
          <div className="footer">
            <div className="footer-group">
              <Link to={"/login"}>
                <Button className="cancel-button">Cancel</Button>
              </Link>

              <Button
                type="submit"
                className={
                  isEmpty(formik.errors) && formik.dirty
                    ? "register-button-active"
                    : "register-button"
                }
              >
                {isLoading && <ClipLoader size={16} color="#36d7b7" />}
                <span style={{ marginLeft: "8px" }}>
                  {isAddPilotPage ? "Add Pilot" : "Register"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </form>
      <modal.SuccessModal />
    </PilotSignupPageStyled>
  );
};

export const Component = PilotSignupPage;
export default PilotSignupPage;
