import styled from "@emotion/styled";
import ImageLoginBackground from "assets/images/LoginBackground.png";

const LoginPageStyled = styled.div`
  background-image: url(${ImageLoginBackground});
  background-size: cover;

  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    .login-card {
      width: 460px;
      height: 360px;
      display: flex;
      padding: 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      margin-top: 40px;
      .checkbox-remember {
        .checkbox-label {
          font-size: 16px;
          font-weight: 300;
          line-height: 150%;
          color: #9ca3af;
          margin-left: 8px;
        }
      }
      .button-component {
        margin-top: 28px;
        background-color: #ffb32c;
      }
    }
    .sign-up-section {
      margin-top: 40px;
      text-align: center;
    }
  }
`;

export default LoginPageStyled;
