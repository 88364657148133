import { gql } from "graphql-request";

// export const QUERY_ADMIN_FARMER_LIST = gql`
//   query adminGetFarmers($optionsAdmin: PaginationInput!, $optionsOther: PaginationInput!) {
//     adminGetFarmers(options: $optionsAdmin) {
//       data {
//         email
//         id
//         firstName
//         lastName
//         nationalIdNo
//         nationality
//         phoneNo
//         address
//         provinceCode
//         districtCode
//         subdistrictCode
//         postcode
//         createdAt
//         farms(options: $optionsOther) {
//           data {
//             id
//             name
//             totalArea
//           }
//           meta {
//             totalItems
//           }
//         }
//         flightPost(options: $optionsOther) {
//           meta {
//             totalItems
//           }
//         }
//       }
//     }
//   }
// `;

export const QUERY_ADMIN_FARMER_LIST = gql`
  query adminGetFarmers($optionsAdmin: PaginationInput!, $optionsOther: PaginationInput!) {
    adminGetFarmers(optionsAdmin: $optionsAdmin, optionsOther: $optionsOther) {
      data {
        email
        id
        firstName
        lastName
        nationalIdNo
        nationality
        phoneNo
        address
        provinceCode
        districtCode
        subdistrictCode
        postcode
        createdAt
        totalFarms
        userId
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

// remvoe license because some data not have and it's error when call all
export const QUERY_ADMIN_PILOT_ALL_LIST = gql`
  query adminGetPilots($optionsAdmin: PaginationInput!, $filterByServiceProvider: Boolean) {
    adminGetPilots(optionsAdmin: $optionsAdmin, filterByServiceProvider: $filterByServiceProvider) {
      data {
        email
        firstName
        id
        lastName
        nationalIdNo
        nationality
        phoneNo
        userId
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
// export const QUERY_ADMIN_PILOT_LIST = gql`
//   query adminGetPilots($optionsAdmin: PaginationInput!, $optionsOther: PaginationInput!) {
//     adminGetPilots(options: $optionsAdmin) {
//       data {
//         email
//         firstName
//         id
//         lastName
//         license {
//           caatExpireDate
//           caatLicenseNo
//           caatObjective
//           nbtcExpireDate
//           nbtcLicenseNo
//           nbtcObjective
//         }
//         nationalIdNo
//         nationality
//         phoneNo
//         drones(options: $optionsOther) {
//           meta {
//             totalItems
//           }
//         }
//         flightPosts(options: $optionsOther) {
//           meta {
//             totalItems
//           }
//         }
//       }
//       meta {
//         itemsPerPage
//         totalItems
//         currentPage
//       }
//     }
//   }
// `;
export const QUERY_ADMIN_PILOT_LIST = gql`
  query adminGetPilots($optionsAdmin: PaginationInput!, $filterByServiceProvider: Boolean!) {
    adminGetPilots(optionsAdmin: $optionsAdmin, filterByServiceProvider: $filterByServiceProvider) {
      data {
        email
        firstName
        id
        lastName
        license {
          caatExpireDate
          caatLicenseNo
          caatObjective
          nbtcExpireDate
          nbtcLicenseNo
          nbtcObjective
        }
        nationalIdNo
        nationality
        phoneNo
        droneCount
        userId
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
export const QUERY_ADMIN_DRONE_LIST = gql`
  query adminGetDrones($options: PaginationInput!) {
    adminGetDrones(options: $options) {
      data {
        id
        name
        description
        droneType
        brand
        serialNumber
        model
        weight
        isActive
        imageTopUrls
        battery {
          brand
          model
          amount
          capacity
        }
        droneInsuranceFileUrls
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_GET_DRONE_LIST = gql`
  query adminGetDroneInformationsList($options: PaginationInput!) {
    adminGetDroneInformationsList(options: $options) {
      data {
        serialNumber
        versionConf
        versionFc
        versionParm
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const QUERY_ADMIN_GET_DRONE_INFORMATION_BY_FLIGHT_INFORMATION_ID = gql`
  query adminGetDroneInformationByFlightInformationId($flightInformationId: String!) {
    adminGetDroneInformationByFlightInformationId(flightInformationId: $flightInformationId) {
      id
      serialNumber
      versionConf
      versionFc
      versionParm
      flightInformation {
        id
      }
    }
  }
`;

export const QUERY_ADMIN_GET_FLIGHT_INFORMATION_AND_LOCATION = gql`
  query getFlightInformationAndLocation($flightInformationId: String!) {
    getFlightInformationAndLocation(flightInformationId: $flightInformationId) {
      id
      flight_field_id
      agri_mode
      stat_sprayed_area
      stat_sprayed_quantity
      start_flight_at
      end_flight_at
      stat_boot_count
      stat_flight_time
      stat_flight_length
      stat_spread_quantity
      stat_service_mode_id
      notifyMessage {
        createAt
        message
      }
      createdAt
      location
      field {
        id
        serviced_recipient_firstname
        serviced_recipient_lastname
        serviced_recipient_age
        serviced_recipient_region
        serviced_recipient_province
        serviced_recipient_field_type
        serviced_recipient_area {
          type
          coordinates
        }
      }
      batteryVoltages
    }
  }
`;

export const QUERY_ADMIN_GET_DRONE_INFORMATION_BY_SERIAL_NUMBER = gql`
  query adminGetDroneInformationBySerialNumber(
    $serialNumber: String!
    $options: PaginationInput!
    $filters: FiltersInput!
  ) {
    adminGetDroneInformationBySerialNumber(
      serialNumber: $serialNumber
      options: $options
      filters: $filters
    ) {
      data {
        serialNumber
        versionFc
        versionParm
        versionConf
        flightInformation {
          id
          flight_field_id
          agri_mode
          start_flight_at
          end_flight_at
          stat_boot_count
          stat_flight_time
          stat_flight_length
          stat_sprayed_area
          stat_sprayed_quantity
          stat_spread_quantity
          stat_service_mode_id
          notifyMessage {
            createAt
            message
          }
          createdAt
        }
        field {
          serviced_recipient_firstname
          serviced_recipient_lastname
          serviced_recipient_field_type
        }
        totalDistance
        flightTime
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const QUERY_ADMIN_GET_DRONE_TOTAL_FLIGHT_RECORD_BY_SERIAL_NUMBER = gql`
  query adminGetTotalFlightRecordBySerial($serialNumber: String!, $filters: FiltersInput!) {
    adminGetTotalFlightRecordBySerial(serialNumber: $serialNumber, filters: $filters) {
      fieldType {
        count
        farmType
      }
      lastPositions {
        flightInformationId
        latitude
        longitude
      }
      serialNumber
      totalBootCount
      totalFlightTime
      totalFlightLength
      totalSprayedQuantity
      totalSpreadQuantity
      totalSprayedArea
    }
  }
`;

export const QUERY_ADMIN_GET_FLIGHT_LOG_BY_FLIGHT_INFORMATION_ID = gql`
  query getFlightLogByFlightInformationId(
    $flightInformationId: String!
    $options: PaginationInput!
  ) {
    getFlightLogByFlightInformationId(
      flightInformationId: $flightInformationId
      options: $options
    ) {
      data {
        id
        gnss_location
      }
      meta {
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_FLIGHT_INFORMATION_LIST = gql`
  query getFlightInformation($options: PaginationInput!) {
    getFlightInformation(options: $options) {
      data {
        id
        flight_field_id
        agri_mode
        stat_sprayed_area
        notifyMessage {
          createAt
          message
        }
        createdAt
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_GET_ALL_DRONE_INFORMATION = gql`
  query adminGetAllDroneInformation($options: PaginationInput!) {
    adminGetAllDroneInformation(options: $options) {
      data {
        id
        versionConf
        versionParm
        versionFc
        serialNumber
        location
        flightInformation {
          id
          flight_field_id
          agri_mode
          stat_sprayed_area
          notifyMessage {
            createAt
            message
          }
          createdAt
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
export const QUERY_ADMIN_GET_ALL_UNIQUE_DRONE_INFORMATION = gql`
  query adminGetAllUniqueDroneInformation($options: PaginationInput!, $search: String) {
    adminGetAllUniqueDroneInformation(options: $options, search: $search) {
      data {
        id
        versionConf
        versionParm
        versionFc
        serialNumber
        location
        flightInformation {
          id
          flight_field_id
          agri_mode
          stat_sprayed_area
          notifyMessage {
            createAt
            message
          }
          createdAt
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_GET_FLIGHT_INFORMATION_DASHBOARD = gql`
  query getFlightInformationDashboard($options: PaginationInput!, $filters: FiltersInput!) {
    getFlightInformationDashboard(options: $options, filters: $filters) {
      data {
        id
        stat_sprayed_area
        stat_sprayed_quantity
        stat_spread_quantity
        stat_spread_quantity
        stat_flight_time
        stat_boot_count
        start_flight_at
        end_flight_at
        stat_flight_length
        notifyMessage {
          createAt
          message
        }
        createdAt
        field {
          id
          serviced_recipient_age
          serviced_recipient_firstname
          serviced_recipient_lastname
          serviced_recipient_region
          serviced_recipient_province
          serviced_recipient_field_type
          field_type
          field_features {
            properties {
              id
              name
              shape
              field_id
              type
            }
            geometry {
              type
              coordinates
            }
          }
        }
        droneInformation {
          serialNumber
        }
      }
      meta {
        currentPage
        itemsPerPage
        totalItems
      }
    }
  }
`;

export const QUERY_ADMIN_GET_DASHBOARD_SUMMARY = gql`
  query getSummaryDashboard($filters: FiltersInput!) {
    getSummaryDashboard(filters: $filters) {
      fieldType {
        count
        farmType
      }
      totalFlightInformation
      totalStatSprayedArea
      totalStatSprayedQuantity
      totalStatSpreadQuantity
      totalDroneCount
      resourceUsage {
        month
        statSprayedQuantity
        statSpreadQuantity
      }
    }
  }
`;

export const QUERY_ADMIN_GET_PROFILE = gql`
  query adminGetProfile {
    adminGetProfile {
      id
      firstName
      lastName
      email
      phoneNo
    }
  }
`;

export const QUERY_ADMIN_FARM_LIST = gql`
  query adminGetFarms($options: PaginationInput!) {
    adminGetFarms(options: $options) {
      data {
        id
        name
        farmType
        totalArea
        address
        farmerId
        provinceCode
        districtCode
        subdistrictCode
        postcode
        createdAt
        isActive
        imageListUrls
        imageList {
          ext
          uid
          file_name
          unique_id
        }
        geom {
          type
          coordinates
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_FLIGHT_POST_LIST = gql`
  query adminGetMyFlightPost($options: PaginationInput!, $flightStatus: [EPostStatus]) {
    adminGetMyFlightPost(options: $options, flightStatus: $flightStatus) {
      data {
        id
        name
        description
        startTime
        endTime
        status
        farmId
        farmerId
        createdAt
        pilot {
          firstName
          lastName
        }
        drone {
          name
        }
        farm {
          name
          totalArea
          imageListUrls
          geom {
            coordinates
          }
        }
      }
    }
  }
`;

// export const MUTATION_ADMIN_ADD_DRONE = gql`
//   mutation adminAddDrone($droneInput: RegisterDroneInput!, $pilotEmail: String!) {
//     adminAddDrone(droneInput: $droneInput, pilotEmail: $pilotEmail)
//   }
// `;

export const QUERY_ADMIN_GET_FARMER_BY_ID = gql`
  query adminGetFarmerById($userId: String!) {
    adminGetFarmerById(userId: $userId) {
      email
      id
      firstName
      lastName
      nationalIdNo
      nationality
      phoneNo
      address
      provinceCode
      districtCode
      subdistrictCode
      postcode
      createdAt
    }
  }
`;

// export const QUERY_ADMIN_GET_PILOT_BY_EMAIL = gql`
//   query adminGetPilotByEmail($pilotEmail: String!) {
//     adminGetPilotByEmail(pilotEmail: $pilotEmail) {
//       id
//       firstName
//       lastName
//       nationalIdNo
//       nationality
//       email
//       phoneNo
//       license {
//         caatLicenseNo
//         caatExpireDate
//         caatObjective
//         caatFileList {
//           ext
//           uid
//           file_name
//           unique_id
//           size
//         }
//         nbtcLicenseNo
//         nbtcObjective
//         nbtcExpireDate
//         nbtcFileList {
//           ext
//           uid
//           file_name
//           unique_id
//           size
//         }
//       }
//     }
//   }
// `;
export const QUERY_ADMIN_GET_PILOT_BY_ID = gql`
  query adminGetPilotById($userId: String!) {
    adminGetPilotById(userId: $userId) {
      id
      firstName
      lastName
      nationalIdNo
      nationality
      email
      phoneNo
      license {
        caatLicenseNo
        caatExpireDate
        caatObjective
        nbtcLicenseNo
        nbtcObjective
        nbtcExpireDate
      }
    }
  }
`;
export const MUTATION_ADMIN_CREATE_FLIGHT_POST = gql`
  mutation adminCreateFlightPost($flightPostInput: CreateFlightPostSchema!, $farmerId: String!) {
    adminCreateFlightPost(flightPostInput: $flightPostInput, farmerId: $farmerId)
  }
`;

export const MUTATION_ADMIN_UPDATE_FARMER = gql`
  mutation adminUpdateFarmerProfile($farmer: UpdateFarmerInput!, $userId: String!) {
    adminUpdateFarmerProfile(farmer: $farmer, userId: $userId)
  }
`;

export const MUTATION_ADMIN_UPDATE_PILOT = gql`
  mutation adminUpdatePilotProfile($pilotProfile: UpdatePilotInput!, $pilotId: String!) {
    adminUpdatePilotProfile(pilotProfile: $pilotProfile, pilotId: $pilotId)
  }
`;

export const QUERY_CHECK_ADMIN_MAIL_EXISTS = gql`
  query checkAdminEmailExists($email: String!) {
    checkAdminEmailExists(email: $email)
  }
`;

export const MUTATION_ADMIN_REGISTER = gql`
  mutation registerAdmin($AdminInput: RegisterAdminInput!) {
    registerAdmin(registerAdminInput: $AdminInput)
  }
`;

export const QUERY_ADMIN_ADMIN_SERVICE_PROVIDER_LIST = gql`
  query adminGetAdminServiceProvidersList($optionsAdmin: PaginationInput!) {
    adminGetAdminServiceProvidersList(optionsAdmin: $optionsAdmin) {
      data {
        email
        id
        firstName
        lastName
        nationalIdNo
        nationality
        phoneNo
        serviceProvider {
          name
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;

export const QUERY_ADMIN_SERVICE_PROVIDER_LIST = gql`
  query adminGetServiceProvidersList($optionsAdmin: PaginationInput!) {
    adminGetServiceProvidersList(optionsAdmin: $optionsAdmin) {
      data {
        name
        id
        taxId
        description
        address
        tel_no
        status
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
      }
    }
  }
`;
export const MUTATION_ADMIN_UPDATE_ADMIN_SERVICE_PROVIDER = gql`
  mutation adminUpdateAdminServiceProviderProfile(
    $adminServiceProviderProfile: UpdateAdminInput!
    $userId: String!
  ) {
    adminUpdateAdminServiceProviderProfile(
      adminServiceProviderProfile: $adminServiceProviderProfile
      userId: $userId
    )
  }
`;
export const QUERY_ADMIN_GET_ADMIN_SERVICE_PROVICE_BY_ID = gql`
  query adminGetAdminServiceProviderById($userId: String!) {
    adminGetAdminServiceProviderById(userId: $userId) {
      id
      firstName
      lastName
      nationalIdNo
      nationality
      email
      phoneNo
      serviceProvider {
        name
      }
    }
  }
`;
