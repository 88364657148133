import { createClient as createWSClient } from "graphql-ws";
import { PropsWithChildren } from "react";
import { Provider, createClient, defaultExchanges, subscriptionExchange } from "urql";

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || "http://localhost/graphql";
const GRAPHQL_SOCKET_URL = process.env.REACT_APP_GRAPHQL_SOCKET_URL || "ws://localhost/graphql";

const wsClient = createWSClient({ url: GRAPHQL_SOCKET_URL });
const client = createClient({
  url: GRAPHQL_URL,
  exchanges: [
    ...defaultExchanges,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
  ],
});

const URQLProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <Provider value={client}>{children}</Provider>;
};
export default URQLProvider;
