const WaypointPin: React.FC<{ style?: object; borderColor?: string }> = ({
  style,
  borderColor = "#fff",
}) => {
  return (
    <svg
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <mask id="path-1-inside-1_893_173429" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4811 26.3759C21.364 25.2116 25.8022 19.9823 25.8022 13.7073C25.8022 6.57707 20.0717 0.796875 13.0027 0.796875C5.93367 0.796875 0.203125 6.57707 0.203125 13.7073C0.203125 19.985 4.6452 25.2162 10.5319 26.3774C10.548 26.4542 10.5736 26.531 10.6096 26.6065L12.1038 29.7389C12.4659 30.4982 13.5467 30.4982 13.9089 29.7389L15.4031 26.6065C15.4393 26.5305 15.465 26.4532 15.4811 26.3759Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4811 26.3759C21.364 25.2116 25.8022 19.9823 25.8022 13.7073C25.8022 6.57707 20.0717 0.796875 13.0027 0.796875C5.93367 0.796875 0.203125 6.57707 0.203125 13.7073C0.203125 19.985 4.6452 25.2162 10.5319 26.3774C10.548 26.4542 10.5736 26.531 10.6096 26.6065L12.1038 29.7389C12.4659 30.4982 13.5467 30.4982 13.9089 29.7389L15.4031 26.6065C15.4393 26.5305 15.465 26.4532 15.4811 26.3759Z"
        fill="currentColor"
      />
      <path
        d="M15.4811 26.3759L15.287 25.3949L14.637 25.5236L14.5021 26.1723L15.4811 26.3759ZM10.5319 26.3774L11.5106 26.1722L11.3748 25.5244L10.7254 25.3963L10.5319 26.3774ZM10.6096 26.6065L11.5122 26.1759V26.1759L10.6096 26.6065ZM12.1038 29.7389L11.2012 30.1694L11.2012 30.1694L12.1038 29.7389ZM13.9089 29.7389L14.8115 30.1694L13.9089 29.7389ZM15.4031 26.6065L14.5005 26.1759V26.1759L15.4031 26.6065ZM24.8022 13.7073C24.8022 19.5016 20.7047 24.3227 15.287 25.3949L15.6753 27.3569C22.0233 26.1005 26.8022 20.4629 26.8022 13.7073H24.8022ZM13.0027 1.79688C19.5113 1.79688 24.8022 7.12121 24.8022 13.7073H26.8022C26.8022 6.03294 20.6321 -0.203125 13.0027 -0.203125V1.79688ZM1.20312 13.7073C1.20312 7.12121 6.49407 1.79688 13.0027 1.79688V-0.203125C5.37327 -0.203125 -0.796875 6.03294 -0.796875 13.7073H1.20312ZM10.7254 25.3963C5.30422 24.3269 1.20312 19.5041 1.20312 13.7073H-0.796875C-0.796875 20.4659 3.98618 26.1054 10.3383 27.3585L10.7254 25.3963ZM11.5122 26.1759C11.5104 26.1722 11.5103 26.1711 11.5106 26.1722L9.55314 26.5826C9.58559 26.7374 9.63683 26.8898 9.70702 27.037L11.5122 26.1759ZM13.0063 29.3084L11.5122 26.1759L9.70702 27.037L11.2012 30.1694L13.0063 29.3084ZM13.0063 29.3084H13.0063L11.2012 30.1694C11.9255 31.688 14.0871 31.688 14.8115 30.1694L13.0063 29.3084ZM14.5005 26.1759L13.0063 29.3084L14.8115 30.1694L16.3057 27.037L14.5005 26.1759ZM14.5021 26.1723C14.5023 26.1711 14.5023 26.1722 14.5005 26.1759L16.3057 27.037C16.3763 26.8888 16.4278 26.7354 16.4602 26.5795L14.5021 26.1723Z"
        fill={borderColor}
        mask="url(#path-1-inside-1_893_173429)"
      />
    </svg>
  );
};

export default WaypointPin;
