import { Text } from "@ai-and-robotics-ventures/cumulus-ui";
import { css, useTheme } from "@emotion/react";

import ButtonStep from "../ButtonStep/ButtonStep";

interface IDataButtonGroup {
  label: string;
  key?: string;
  selectedIcon: React.ReactNode;
  notSelectedIcon: React.ReactNode;
  isError?: boolean;
}
interface ButtonStepGroupProps {
  selected: string;
  data: IDataButtonGroup[];
  onClick: (value: string) => void;
}
const ButtonStepGroup: React.FC<ButtonStepGroupProps> = ({ selected, data, onClick }) => {
  const handleClick = (item: IDataButtonGroup) => {
    const value = item?.key ? item.key : item.label;

    onClick(value);
  };

  return (
    <div
      style={{
        height: "200px",
        width: "160px",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "150px",
          width: "160px",
          borderRadius: "100px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "50px",
        }}
      >
        {data.map((d) => {
          return (
            <ButtonStep
              isError={d.isError}
              onClick={() => handleClick(d)}
              label={d.label}
              notSelectedIcon={d.notSelectedIcon}
              selectedIcon={d.selectedIcon}
              key={d.label}
              selected={d.label === selected || d.key === selected}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ButtonStepGroup;
