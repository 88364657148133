import styled from "@emotion/styled";
import { rgba } from "polished";

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* padding: 24px 0px 0px 0px; */
  margin: 0px 0px 16px 0px;
  cursor: pointer;
  > *:not(:last-child) {
    margin-right: 8px;
  }
  > *.filter-mission-template-project {
  }
  * > input {
    height: 44px;
  }
`;

export const Wrapper = styled.div`
  padding: 24px 0px 0px 0px;
  > .mission-template-filter-search {
    > * {
      height: 42px;
    }
    > * {
      color: ${({ theme }) => theme.color.grayPrimary[700]};
    }
  }
`;

export const FilterIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 44px;

  background: ${({ theme }) => rgba(theme.color.graySecondary[900], 0.6)};
  backdrop-filter: blur(10px);

  border-radius: 10px;
`;
